import { useContext, useEffect, useRef, useState } from 'react';
import { Athlete, Exercise, Team, getAllSum, initCompetition, initRankingConfiguration } from '../../../models/models';

import ClassificationAthlete from '../../../components/Classifications/ClassificationAthlete';

import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import ClassificationBuilderCom from '../../../components/Classifications/ClassificationBuilderCom';
import ClassificationSetupComRanking from '../../../components/Classifications/ClassificationSetupComRanking';
import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../contexts/EventEoContext';
import { downloadPDFInWorker } from '../../../models/PDF/Worker/downloadPDFInWorker';
import { USER_TYPE, useAuth } from '../../../models/auth/AuthProvider';
import { COMP_TYPE } from '../../../models/backendReq';
import { getRankingConfiguration, slideElement } from '../../../utility/UtilityFunctions';
import { toolBarElements } from '../SubNavBarLinks';
import './ClassificationCompetitionAthleteScreen.scss';
import { usePDFDownload } from '../../../models/DownloadPDFProvider';

export interface ExtendedAthlete {
	athlete: Athlete | Team;
	exercises: Exercise[];
	sum: number;
	hide?: boolean;
}

function ClassificationCompetitionAthleteScreen() {
	const eventContext = useContext(EventEoContext);
	const pdf = usePDFDownload();

	const params = useParams();

	const [athletesExt, setAthletesExt] = useState<ExtendedAthlete[]>([]);
	const [competitionRule, setCompetitionRule] = useState(0);
	const [numberOfExeType, setNumberOfExeType] = useState<number>();
	const [watermarkFile, setWatermarkFile] = useState<FileList | null>();
	const [showDetails, setShowDetails] = useState(false);
	const [showClassification, setShowClassification] = useState(false);
	const [athletesToHide, setAthletesToHide] = useState<number[]>([]);

	const showFasce = useState(false);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const competitionId = parseInt(params.competitionId ?? "");
	const competition = eventContext.competitions.value.find(c => c.id === competitionId) ?? initCompetition;

	const exerciseTypes = eventContext.associatedexercisetypes.value.filter(x => x.competitionId === competitionId);
	const athletes = eventContext.athletes.value.filter(x => x.competitionId === competitionId);
	const exercises = eventContext.exercises.value.filter(x => x.competitionId === competitionId);
	const rosters = eventContext.rosters.value.filter(x => x.competitionId === competitionId);
	const teams = eventContext.teams.value.filter(x => x.competitionId === competitionId);

	const auth = useAuth();


	useEffect(() => {
		if (!showClassification) {
			setAthletesExt([]);
			return;
		}
		const extendedAthletes = athletes.map((athlete) => {
			const hide = athletesToHide.includes(athlete.id);
			const sum = !hide ? getAllSum(exercises.filter(x => x.athleteId === athlete.id), exerciseTypes, competitionRule, numberOfExeType) : Number.NEGATIVE_INFINITY;
			return { athlete: athlete, exercises: exercises.filter(x => x.athleteId === athlete.id), sum: sum, hide };
		})
		const extendedTeams = teams.map(t => {
			const hide = athletesToHide.includes(t.id);
			const sum = !hide ? getAllSum(exercises.filter(x => x.teamId === t.id), exerciseTypes, competitionRule, numberOfExeType) : Number.NEGATIVE_INFINITY;
			return { athlete: t, exercises: exercises.filter(x => x.teamId === t.id), sum: sum, hide };
		})

		let athAndRos;
		if (competition.type === COMP_TYPE.Collective)
			athAndRos = [...extendedTeams];
		else
			athAndRos = [...extendedAthletes];

		setAthletesExt(athAndRos.sort((a, b) => b.sum - a.sum));
	}, [showClassification, eventContext.exercises.value, athletesToHide]);

	const _toolBarElements: eoToolbarProp[] = [
		{
			...toolBarElements.selectElement,
			callback: () => setShowDetails(!showDetails),
			text: "Mostra dettagli", selected: showDetails
		},
	];

	const rankingConfiguration = eventContext.rankingconfigurations.value.find(rc => rc.competitionId === competitionId) ?? initRankingConfiguration;

	const PDFGeneration = () => {
		const pdfProps = {
			data: athletesExt.filter(a => !a.hide),
			event: eventContext.event,
			rankingConfiguration,
			files: watermarkFile,
			competition,
			exerciseTypes,
			rule: competitionRule
		};
		const fileName = "Classifica atleti - " + competition.name.trim() + ".pdf";
		pdf.download("AthleteAllAroundClassification", pdfProps, fileName);
	}

	if (showClassification)
		_toolBarElements.push(
			{
				...toolBarElements.downloadPDFElement,
				callback: PDFGeneration,
			});

	if (auth.user.type === USER_TYPE.USER)
		_toolBarElements.push(
			{
				...toolBarElements.watermarkElement,
				text: "Carica filigrana",
				selected: watermarkFile?.length! > 0,
				callback: () => {
					if (watermarkFile?.length! > 0)
						setWatermarkFile(null);
					else
						fileInputRef.current?.click();
				},
			});

	if (auth.user.isOrganizer)
		_toolBarElements.push(
			{
				...toolBarElements.rankingElement,
				text: "Fasce",
				callback: () => showFasce[1](true),
			});

	const slideReq = (index: number, direction: boolean) => {
		setAthletesExt(prev => slideElement(prev, index, direction));
	};
	const hideReq = (id: number) => {
		setAthletesToHide(prev => prev.includes(id) ? prev.filter(n => n !== id) : [...prev, id]);
	};


	return (
		<OrganizerEventTable text='CLASSIFICHE ALL AROUND' >
			<EoToolBar elements={_toolBarElements} />
			<ClassificationBuilderCom
				competitionRule={competitionRule}
				numberOfExeType={numberOfExeType}
				showClassification={showClassification}
				setCompetitionRule={setCompetitionRule}
				setNumberOfExeType={setNumberOfExeType}
				setShowClassification={setShowClassification}
				useCompetitionRule useNumberOfExeType />

			{athletesToHide.length > 0 && <div className='clssification-competition-warning-hidden-container'>
				<FontAwesomeIcon icon={faWarning} />
				<span>Alcuni atleti sono nascosti dalla classifica</span>
				<button onClick={() => setAthletesToHide([])}>Mostra tutti</button>
			</div>}
			<div className='clssification-competition-athletes-container'>
				<div className='clssification-competition-athletes-name-top'>
					<div>{competition.name}</div>
					{athletesExt.length > 0 && <div>{`Atleti: ${athletesExt.length}`}</div>}
				</div>
				<input
					accept="image/jpeg, image/png, image/gif"
					multiple={false}
					onChange={e => setWatermarkFile(e.target.files)}
					ref={fileInputRef}
					style={{ display: 'none' }}
					type='file'
				/>
				<div className='classification-athletes-container'>
					{showClassification && <ConditionalListComponent items={athletesExt}
						emptyMesssage='Non ci sono atleti in questa competizione'
						renderList={(athletesExt => athletesExt.map((athleteExt, index, array) => {
							let isPM = false;
							let position = "";

							if (array.length > 1) {
								if (index > 0 && array[index - 1].sum === athleteExt.sum) {
									isPM = true;
								}
							}
							if (rankingConfiguration.chartSections.length > 0) {
								position = getRankingConfiguration(index + 1, rankingConfiguration, array.length);
							} else {
								position = (index + 1).toString();
							}


							return (
								<ClassificationAthlete
									key={athleteExt.athlete.id}
									athleteExt={athleteExt}
									isPM={isPM}
									position={position}
									isRanked={rankingConfiguration.chartSections.length > 0}
									exerciseTypes={exerciseTypes}
									showDetails={showDetails}
									index={index}
									rule={competitionRule}
									slideReq={slideReq}
									hideReq={hideReq} />
							)
						}))} />}
				</div>
			</div>
			<ClassificationSetupComRanking competition={competition} show={showFasce} />


		</OrganizerEventTable>
	)
}

export default ClassificationCompetitionAthleteScreen