import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { useLocalStorage } from './hooks/useLocalStorage';

interface StateLocationProvider extends Location {
	read: <T = any>(property?: string) => T | undefined;
}

function readLocationState<T>(state: any, property?: string) {
	if (state === undefined || state === null)
		return undefined;

	if (property)
		if (Object.keys(state).length > 0 && Object.hasOwn(state, property))
			return state[property] as T;
		else
			return undefined;
	else
		return state;
}

const StateLocationContext = createContext<StateLocationProvider | undefined>(undefined);

export const useStateLocation = (): StateLocationProvider => {
	const context = useContext(StateLocationContext);
	if (context === undefined) {
		throw new Error('useStateLocation must be used within a StateLocationProvider');
	}
	return context;
};


interface StateLocationProviderProps {
	children: React.ReactNode;
}

export const StateLocationProvider: React.FC<StateLocationProviderProps> = ({ children }) => {
	const location = useLocation();
	const [locationValue, setLocationValue] = useLocalStorage("locationStorage", location.state);

	useEffect(() => {
		if (location.state === undefined || location.state === null)
			return;

		setLocationValue(location.state);
	}, [location]);

	const read = <T = any>(property?: string) => {
		const tryFromLocation = readLocationState(location.state, property);
		if (tryFromLocation)
			return tryFromLocation;

		return readLocationState<T>(locationValue, property);
	}

	return (
		<StateLocationContext.Provider value={{ ...location, read, state: locationValue }}>
			{children}
		</StateLocationContext.Provider>
	);
};
