import { ExecutionTurn } from '../../models/models';

import './OrganaizerExecutionTurnCom.scss';

import { useLocation } from 'react-router-dom';
import TableDateCell from './TableComponents/TableDateCell';
import TableLinkRow from './TableComponents/TableLinkRow';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';
import TableTr from './TableComponents/TableTr';
import { useStateLocation } from '../../models/StateLocationProvider';

interface props {
	executionturn: ExecutionTurn;
	del: (executionturn: ExecutionTurn) => void;
	edi: (executionturn: ExecutionTurn) => void;
	lastId?: number;
}

function OrganaizerExecutionTurnCom({ executionturn, del, edi, lastId }: props) {
	const location = useStateLocation();
	const state = { ...location.state, executionturn: executionturn }

	let editReq = () => {
		edi(executionturn);
	}

	let deleteReq = () => {
		del(executionturn);
	}

	return (
		<TableTr id={executionturn.id} lastId={lastId}>
			<TableLinkRow text={executionturn.name} navigate={{ path: `./${executionturn.id}	/Groups`, state: state }} id={executionturn.id} />
			<TableDateCell date={executionturn.start} hasTime />
			<TableDateCell date={executionturn.end} hasTime />
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />
		</TableTr>
	);
}

export default OrganaizerExecutionTurnCom;