import { useEffect, useState } from 'react';
import { del, get, post, put } from '../../../models/backendReq';
import { ExerciseType, HTTP_STATUS_CODES, Jury, JuryAssignment, ScoreFragmentType, handleResponse, responseGetJson } from '../../../models/models';

import { faAdd, faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../models/auth/AuthProvider';
import './OrganizerCompetitionJudgeCom.scss';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';

interface props {
	competitionId: number;
	judge: Jury;
	exerciseTypes: ExerciseType[];
}

function OrganizerCompetitionJuryCom({ competitionId, judge, exerciseTypes }: props) {

	let [exerciseIdToAdd, setExerciseIdToAdd] = useState(0);
	let [judgeAssignmentsAssociated, setJuryAssignmentsAssociated] = useState<JuryAssignment[]>([]);
	let [loadingExerciseTypesAssociated, setLoadingExerciseTypesAssociated] = useState(true);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	useEffect(() => {
		let getJuryAssignmentsAssociated = async () => {
			let judgeAssignmentsAssociatedFromServer = await get.juriesAssignments(competitionId, judge.id).then(r => responseGetJson(r, []))
				.finally(() => setLoadingExerciseTypesAssociated(false));

			setJuryAssignmentsAssociated(judgeAssignmentsAssociatedFromServer);
		};

		getJuryAssignmentsAssociated();
	}, [competitionId, judge]);

	let setExeTypeToJury = async () => {
		if (exerciseIdToAdd !== 0) {
			let exeType = exerciseTypes.find(x => x.id === exerciseIdToAdd);

			if (!exeType) {
				alert.show("Errore durante l'associazione", MESSAGE_TYPE.ERROR, 2000, true, true);
				return;
			}
			let assignments = exeType.scoreComposition.map(x => x.id);
			await post.jurieAssignments(judge.id, competitionId, exeType.id, assignments)
				.then(res => {
					handleResponse(
						res, auth, alert, navigate,
						"Errore durante la modifica",
						undefined,
						(data) => {
							if (exeType)
								setJuryAssignmentsAssociated([...judgeAssignmentsAssociated, data]);
							setExerciseIdToAdd(0);
						}
					)
				});
		}
	};

	let resetExeTypeToJury = async (id: number) => {
		if (id !== 0) {
			await del.juryAssignments(id)
				.then(res => {
					handleResponse(
						res, auth, alert, navigate,
						"Errore durante la modifica",
						undefined,
						() => {
							let exeAssociatedNew = judgeAssignmentsAssociated.filter(x => x.id !== id);
							setJuryAssignmentsAssociated(exeAssociatedNew);
						}
					)
				});
		}
	};

	let updateFragment = async (judgeAssignmentAssociated: JuryAssignment, sc: ScoreFragmentType, type?: ExerciseType) => {
		if (!judgeAssignmentAssociated)
			return

		let ids = type?.scoreComposition.filter(x => judgeAssignmentAssociated?.assignedScoreFragmentTypes.includes(x.label)).map(y => y.id);
		if (!ids)
			return

		if (ids.includes(sc.id)) {
			ids = ids.filter(x => x !== sc.id)
		} else {
			ids.push(sc.id)
		}

		await put.judgeAssignments(judgeAssignmentAssociated.id, ids)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica",
					undefined,
					() => {
						let jaCopy = judgeAssignmentAssociated;
						let labelList = type?.scoreComposition.filter(x => ids?.includes(x.id)).map(y => y.label);

						if (labelList && jaCopy)
							jaCopy = { ...jaCopy, assignedScoreFragmentTypes: [...labelList] };

						let assignments = [...judgeAssignmentsAssociated];
						let assignmentIndex = assignments.findIndex(x => x.id === jaCopy.id);
						assignments[assignmentIndex] = jaCopy

						setJuryAssignmentsAssociated(assignments);
					}
				)
			});
	}

	let filteredExeTypes = exerciseTypes.filter(x => !judgeAssignmentsAssociated.map(y => y.exerciseTypeId).includes(x.id));

	return (
		<div className='competition-setup-judge-container'>
			<div className='competition-setup-judge-header'>
				<p className='competition-setup-judge-header-name'>{judge.name}</p>
				<div className='competition-setup-judge-header-addrow'>
					{/*<Select className='competition-setup-judge-header-select' value={exerciseIdToAdd} onChange={(e) => setExerciseIdToAdd(e)} children={selectOptions} />*/}

					<select className='competition-setup-judge-header-select' value={exerciseIdToAdd} onChange={(e) => setExerciseIdToAdd(parseInt(e.target.value))}>
						<option value={0}>---</option>
						{filteredExeTypes.map(x => {
							return (
								<option key={x.id} value={x.id}>{x.name}</option>
							)
						})}
					</select>
					<button onClick={setExeTypeToJury}>
						<FontAwesomeIcon icon={faAdd} />
						<span>Aggiungi</span>
					</button>

				</div>

			</div>
			{!loadingExerciseTypesAssociated ? (
				<div className='competition-setup-judge-exesassociated-container'>
					{judgeAssignmentsAssociated.length > 0 ? (judgeAssignmentsAssociated.map(judgeAssignmentAssociated => {
						let typeFound = exerciseTypes.find(x => x.id === judgeAssignmentAssociated.exerciseTypeId);

						return (
							<div key={judgeAssignmentAssociated.id} className='competition-setup-judge-exeassociated-container' >
								<div className='competition-setup-judge-exeassociated-name-and-delete'>
									<div className='competition-setup-judge-exeassociated-name'>{typeFound?.displayName}</div>
									<button className='competition-setup-judge-exeassociated-button' onClick={() => resetExeTypeToJury(judgeAssignmentAssociated.id)}>
										<FontAwesomeIcon icon={faTrash} />
									</button>
								</div>
								<div className='competition-setup-judge-exeassociated-fragments-container'>
									{typeFound?.scoreComposition.map(scoreFragment => {
										return (
											<label key={scoreFragment.id} className='competition-setup-judge-exeassociated-fragment'>
												<input
													type={'checkbox'}
													checked={judgeAssignmentAssociated.assignedScoreFragmentTypes.includes(scoreFragment.label)}
													onChange={() => updateFragment(judgeAssignmentAssociated, scoreFragment, typeFound)}
												/>
												<span>{scoreFragment.label}</span>
											</label>
										)
									})}
								</div>
							</div>
						);
					})) : (
						<label style={{ width: '100%' }}>...</label>
					)}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

export default OrganizerCompetitionJuryCom;