import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMP_TYPE, del, get, post, put } from '../../../models/backendReq';
import { Association, Athlete, Competition, Event, HTTP_STATUS_CODES, Roster, Team, handleResponse, responseGetJson } from '../../../models/models';

import { faArrowLeft, faClose, faDownload, faEdit, faMinus, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput from '../../../components/Input/SearchInput';
import { useAuth } from '../../../models/auth/AuthProvider';
import OrganizerCompetitionTeamsAddRequest from './OrganizerCompetitionTeamsAddRequest';
import OrganizerCompetitionTeamsDeleteRequest from './OrganizerCompetitionTeamsDeleteRequest';
import OrganizerCompetitionTeamsEditRequest from './OrganizerCompetitionTeamsEditRequest';

import TableContainer from '../../../components/Organizer/TableComponents/TableContainer';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';
import '../../../utility/prototype';
import './OrganizerCompetitionTeamsScreen.scss';
import { competitionWithTeamLinks, competitionWithoutTeamLinks, toolBarElements } from '../SubNavBarLinks';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import EventEoContext from '../../../contexts/EventEoContext';
import OrganizerCompetitionTable from '../../../components/Organizer/TableComponents/OrganizerCompetitionTable';

function TeamComponent({ addToCompetition, deleteRequest, editRequest, putInRosterRequest, putOutRoster, roster, removeFromCompetition, selectedAthletes, team }: {
	addToCompetition: (team: Team) => void;
	deleteRequest: (team: Team) => void;
	editRequest: (team: Team) => void;
	// putInRoster: (roster: Roster, athletes: Athlete[]) => void;
	putInRosterRequest: (roster: Roster) => void;
	putOutRoster: (roster: Roster, athletes: Athlete[]) => void;
	roster?: Roster;
	removeFromCompetition: (team: Team) => void;
	selectedAthletes: Athlete[],
	team: Team,
}) {
	let [athletesToRemove, setAthletesToRemove] = useState<Athlete[]>([]);

	useEffect(() => {
		setAthletesToRemove([]);
	}, [roster])

	let getAthleteButtonClassName = (length: number) => {
		if (length > 0)
			return "organizer-competiton-team-roster-athletes-button active"

		return "organizer-competiton-team-roster-athletes-button"
	}
	return (
		<div className={'organizer-competiton-team-roster' + (roster ? " active" : "")}>
			<div className='organizer-competiton-team-roster-header'>
				<button className='organizer-competiton-team-roster-edit-team-button' onClick={() => editRequest(team)}>
					<FontAwesomeIcon icon={faEdit} />
				</button>
				<div>{team.name}</div>
				{!roster && <button className='organizer-competiton-team-roster-remove-team-button' onClick={() => deleteRequest(team)}>
					<FontAwesomeIcon icon={faClose} />
				</button>}
			</div>
			{
				roster ? (
					<>
						<div className='organizer-competiton-team-roster-athletes'>
							{roster.athletes.athleteFilter().map(a => {
								const isSelected = athletesToRemove.map(x => x.id).includes(a.id);

								return (
									<div
										className={`organizer-competiton-team-roster-avaiable-athlete${isSelected ? " selected" : ""}`}
										key={a.id}
										onClick={() => {
											if (isSelected) {
												setAthletesToRemove(current => current.filter(x => a.id !== x.id));
											} else {
												setAthletesToRemove(current => [...current, a]);
											}
										}}
									>
										<strong>{a.code}</strong><span>{a.firstName + " " + a.lastName}</span>
									</div>
								)
							})}
						</div>
						<div className='organizer-competiton-team-roster-athletes-buttons-container' >
							<button className={getAthleteButtonClassName(selectedAthletes.length + 1)} onClick={() => putInRosterRequest(roster)}>
								<FontAwesomeIcon icon={faUpload} />
								<div>Aggiungi atleti</div>
							</button>
							<button className={getAthleteButtonClassName(athletesToRemove.length)} onClick={() => putOutRoster(roster, athletesToRemove)}>
								<FontAwesomeIcon icon={faDownload} />
								<div>Rimuovi atleti</div>
							</button>
						</div>
						<button
							className={'organizer-competiton-team-roster-unsubscribe-button' + (roster.athletes.length === 0 ? " active" : "")}
							onClick={() => {
								if (roster.athletes.length === 0)
									removeFromCompetition(team)
							}}>
							Annulla iscrizione
						</button>
					</>
				) : (
					<div className='organizer-competiton-team-roster-subscribe-buttons-container'>
						<button className='organizer-competiton-team-roster-subscribe-button' onClick={() => addToCompetition(team)}>Iscrivi alla gara</button>
					</div>
				)
			}
		</div>
	)
}

function AssociationsSelection({ associations, selected, show }: {
	associations: Association[],
	selected: (association: Association) => void,
	show: boolean,
}) {

	return (
		<div className={`organizer-competiton-team-associations-container${show ? "" : " hide"}`}>
			<div className='organizer-competiton-team-association-name'>SOCIETA'</div>
			{associations.map(a => {
				return (
					<div
						className='organizer-competiton-team-association-container'
						key={a.id}
						onClick={() => selected(a)}
					>
						{a.name}
					</div>
				)
			})}
		</div>
	)
}

interface RostersSelectionProps {
	addToCompetition: (team: Team) => void;
	association?: Association,
	back: () => void,
	competition: Competition,
	eventAthletes: Athlete[];
	putInRoster: (selectedAthletes: Athlete[], roster?: Roster) => void;
	putOutRoster: (roster: Roster, athletes: Athlete[]) => void;
	rosters: Roster[],
	show: boolean,
	removeFromCompetition: (team: Team) => void;
	hideBack?: boolean;
}

export function RostersSelection(props: RostersSelectionProps) {
	const divAddAthleteRef = useRef<HTMLDivElement>(null);
	let showAdd = useState(false);
	let showDel = useState(false);
	let showEdi = useState(false);

	let [putInRosterShow, setPutInRosterShow] = useState<Roster>();
	let [teamToDelete, setTeamToDelete] = useState<Team>();

	let [filterAthlete, setFilterAthlete] = useState("");
	let [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
	let [athletes, setAthletes] = useState<Athlete[]>([]);
	let [associationAthletes, setAssociationAthletes] = useState<Athlete[]>([]);
	let [associationTeams, setAssociationTeams] = useState<Team[]>([]);
	let [teamToPut, setTeamToPut] = useState<Team>();

	useOnClickOutside(divAddAthleteRef, () => setPutInRosterShow(undefined))

	useEffect(() => {
		let getData = async () => {
			const athletesFromServer: Athlete[] = await get.athletesByCompetition(props.competition.id).then(r => responseGetJson(r, []));
			setAthletes(athletesFromServer);
			setSelectedAthletes([]);
		}
		getData();
	}, [props.rosters, props.competition])

	useEffect(() => {
		let getData = async () => {
			const athletesFromServer: Athlete[] = await get.athletesByCompetition(props.competition.id).then(r => responseGetJson(r, []));
			setAthletes(athletesFromServer);
			if (props.association) {
				const associationAthletesFromServer: Athlete[] = await get.athletesByAssociation(props.association.id, ["Owner"]).then(r => responseGetJson(r, []));
				const teamsFromServer: Team[] = await get.teamsByAssociation(props.association.id, ["Owner"]).then(r => responseGetJson(r, []));

				setAssociationAthletes(associationAthletesFromServer);
				setAssociationTeams(teamsFromServer);
				setSelectedAthletes([]);
			} else {
				setAssociationAthletes([]);
			}
		}
		setAssociationTeams([]);
		getData();
	}, [props.association, props.competition])

	const athletesAvailable = associationAthletes.filter(x => !athletes.map(y => y.id).includes(x.id));

	let addNewTeam = (team: Team) => {
		setAssociationTeams(current => [...current, team]);
		showAdd[1](false);
	}
	let deleteRequest = (team: Team) => {
		setTeamToDelete(team);
		showDel[1](true);
	}
	let delTeam = (team: Team) => {
		setAssociationTeams(current => current.filter(x => x.id !== team.id));
		showDel[1](false)
	}
	let editRequest = (team: Team) => {
		showEdi[1](true);
		setTeamToPut(team);
	}
	let editCompleted = (team: Team) => {
		let newTeams = [...associationTeams];
		let index = newTeams.findIndex(x => x.id === team.id);
		newTeams[index] = team;

		setAssociationTeams(newTeams)
		showEdi[1](false)
	}

	//** Horizontal scroll using mouse wheel */
	const scrollContainer = document.getElementById('organizerCompTeamsContainer');
	if (scrollContainer) {
		scrollContainer!.addEventListener('wheel', (evt) => {
			evt.preventDefault();
			scrollContainer!.scrollLeft += evt.deltaY;
		});
	}

	const intBack = () => {
		setPutInRosterShow(undefined);
		props.back();
	}

	const _toolBarElements: eoToolbarProp[] = [];

	if (props.show)
		_toolBarElements.push({ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Crea squadra' });

	return (
		<div className={`organizer-competiton-team-rosters-container${props.show ? "" : " hide"}`}>
			{!props.hideBack && <div className='organizer-competiton-team-roster-avaiable-athlete-association' onClick={intBack}>
				<FontAwesomeIcon icon={faArrowLeft} />
				<span>{props.association?.name}</span>
			</div>}
			<EoToolBar elements={_toolBarElements} />
			<div className='organizer-competiton-team-roster-container'>
				<div ref={divAddAthleteRef} className={`organizer-competiton-team-roster-avaiable-athlete-container${putInRosterShow ? " show" : ""}`}>
					<SearchInput
						className='organizer-competiton-team-roster-avaiable-athlete-search'
						onChange={(e) => setFilterAthlete(e.target.value)}
						placeholder="Cerca atleta"
						value={filterAthlete}
					/>
					<div className="organizer-competiton-team-roster-avaiable-athlete-athletes-container">
						{athletesAvailable?.length > 0 ? (athletesAvailable?.athleteFilter(filterAthlete).map(a => {
							const isSelected = selectedAthletes.map(x => x.id).includes(a.id);
							return (
								<div
									className={`organizer-competiton-team-roster-avaiable-athlete${isSelected ? " selected" : ""}`}
									key={a.id}
									onClick={() => {
										if (isSelected) {
											setSelectedAthletes(current => current.filter(x => a.id !== x.id));
										} else {
											setSelectedAthletes(current => [...current, a]);
										}
									}}
								>
									<div className='organizer-competiton-team-roster-avaiable-athlete-info'>
										<FontAwesomeIcon icon={isSelected ? faMinus : faPlus} />
										<strong>{a.code}</strong><span>{a.firstName + " " + a.lastName}</span>
									</div>
									{
										props.eventAthletes.map(a => a.id).includes(a.id) && isSelected ? (
											<div className='organizer-competiton-team-roster-avaiable-athlete-otherompetwarn'>
												Atleta già presente in un'altra competizione
											</div>
										) : (
											<></>
										)
									}
								</div>
							)
						})) : (
							<p className='organizer-competiton-team-roster-avaiable-athlete-noAthletesFound'>Nessun atleta disponibile per questa squadra</p>
						)}
					</div>
					<div className='organizer-competiton-team-roster-avaiable-athlete-buttons'>
						<button className='organizer-competiton-team-roster-avaiable-athlete-button confirm' onClick={() => {
							props.putInRoster(selectedAthletes, putInRosterShow);
							setPutInRosterShow(undefined);
						}}>CONFERMA</button>
						<button className='organizer-competiton-team-roster-avaiable-athlete-button cancel' onClick={() => setPutInRosterShow(undefined)}>ANNULLA</button>
					</div>
				</div>
				<div className='organizer-competiton-team-roster-teams-container'>
					<div id='organizerCompTeamsContainer' className='organizer-competiton-team-roster-teams'>
						{associationTeams.map(t => {
							return (
								<TeamComponent
									addToCompetition={props.addToCompetition}
									deleteRequest={deleteRequest}
									editRequest={editRequest}
									key={t.id}
									putInRosterRequest={(r) => setPutInRosterShow(r)}
									putOutRoster={props.putOutRoster}
									removeFromCompetition={props.removeFromCompetition}
									roster={props.rosters.find(x => x.teamId === t.id)}
									selectedAthletes={selectedAthletes}
									team={t}
								/>
							)
						})}
					</div>
				</div>
				<OrganizerCompetitionTeamsDeleteRequest deletionCompleted={delTeam} show={showDel} team={teamToDelete} />
				{
					props.association ? (<OrganizerCompetitionTeamsAddRequest addCompleted={addNewTeam} association={props.association} show={showAdd} />) : (<></>)
				}
				<OrganizerCompetitionTeamsEditRequest team={teamToPut} show={showEdi} editCompleted={editCompleted} />

			</div>
		</div>
	)
}

function OrganizerCompetitionTeamsScreen() {
	const competitionContext = useContext(CompetitionEoContext);
	const eventContext = useContext(EventEoContext);

	let [associationSelected, setAssociationSelected] = useState<Association | undefined>();
	let [showRosters, setShowRosters] = useState(false);
	let [showAdd, setShowAdd] = useState(0)

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let selected = (association: Association) => {
		setAssociationSelected(association);
		setShowRosters(true);
	}

	let putInRoster = async (athletes: Athlete[], roster?: Roster) => {
		if (!roster)
			return
		const ids = [...roster.athletes.map(x => x.id), ...athletes.map(x => x.id)];
		const req = await put.rosterAddAthlete(competitionContext.competition.id, roster.teamId, ids);

		handleResponse(req, auth, alert, navigate, "Errore durante l'associazione degli atleti",
			undefined, () => {
				eventContext.athletes.crud.add(athletes.map(a => ({ ...a, competition: competitionContext.competition, competitionId: competitionContext.competition.id })));
				eventContext.rosters.crud.edit({ ...roster, athletes: [...roster.athletes, ...athletes] })
			})
	}
	let putOutRoster = async (roster: Roster, athletes: Athlete[]) => {
		if (athletes.length === 0)
			return
		const ids = roster.athletes.filter(y => !athletes.map(z => z.id).includes(y.id)).map(x => x.id);
		const req = await put.rosterAddAthlete(competitionContext.competition.id, roster.teamId, ids);

		handleResponse(req, auth, alert, navigate, "Errore durante l'eliminazione degli atleti",
			undefined, () => {
				eventContext.athletes.crud.delete(athletes.map(a => ({ ...a, competition: competitionContext.competition, competitionId: competitionContext.competition.id })));
				eventContext.rosters.crud.edit({ ...roster, athletes: roster.athletes.filter(y => !athletes.map(z => z.id).includes(y.id)) });
			})
	}
	let addToCompetition = async (team: Team) => {
		const newRoster: Roster = {
			athletes: [],
			competition: competitionContext.competition,
			competitionId: competitionContext.competition.id,
			team: team,
			teamId: team.id
		}
		const req = await put.rosterAddAthlete(competitionContext.competition.id, team.id, []);
		handleResponse(req, auth, alert, navigate, "Errore durante l'iscrizione della squadra",
			undefined, () => {
				eventContext.teams.crud.add({ ...team, competition: competitionContext.competition, competitionId: competitionContext.competition.id });
				eventContext.rosters.crud.add(newRoster)
			})
	}

	let removeFromCompetition = async (team: Team) => {
		const roster = eventContext.rosters.value.find(x => x.teamId === team.id && x.competitionId === competitionContext.competition.id);
		if (!roster)
			return
		const req = await del.roster(competitionContext.competition.id, team.id);
		handleResponse(req, auth, alert, navigate, "Errore durante l'annullamento dell'iscrizione alla gara",
			undefined, () => {
				eventContext.teams.crud.delete({ ...team, competition: competitionContext.competition, competitionId: competitionContext.competition.id });
				eventContext.rosters.crud.delete(roster);
			})
	}

	return (
		<OrganizerCompetitionTable text='SQUADRE'>
			<div className='organizer-competition-teams-body'>
				<AssociationsSelection associations={eventContext.associations} selected={selected} show={!showRosters} />
				<RostersSelection
					addToCompetition={addToCompetition}
					association={associationSelected}
					back={() => { setShowRosters(false) }}
					competition={competitionContext.competition}
					eventAthletes={eventContext.athletes.value}
					putInRoster={putInRoster}
					putOutRoster={putOutRoster}
					removeFromCompetition={removeFromCompetition}
					rosters={eventContext.rosters.value.filter(x => x.competitionId === competitionContext.competition.id)}
					show={showRosters}
				/>
			</div>
		</OrganizerCompetitionTable>
	);
}

export default OrganizerCompetitionTeamsScreen;