import { Association, Athlete, Competition, ExerciseType, FloorMusicMetadata, Team } from "../models/models";
import { stringFilter, stringFilterWithPermutation, stringSort } from "./UtilityFunctions";

declare global {
	interface Array<T> {
		associationFilter(filter?: string): Array<T>;
		athleteFilter(filter?: string, associationGroup?: boolean): Array<T>;
		competitionFilter(name?: string, categories?: string[], levels?: number[]): Array<T>;
		teamFilter(filter?: string): Array<T>;
		sortExerciseTypes(propertyName?: string): Array<T>;
		sortCompetitions(propertyName?: string): Array<T>;
		sortFloorMusicMetadata(associationId?: number, associationGroup?: boolean): Array<T>;
	}

	interface String {
		isISOString(): boolean;
		noMultiSpace(): string;
	}

}

//Filter association by name and sort it by name
if (!Array.prototype.associationFilter) {
	Array.prototype.associationFilter = function (this: Association[], filter?: string): Association[] {
		let associationSort = (a: Association, b: Association) => {
			return stringSort(a.name, b.name);
		}

		const returnArr = [];
		if (filter)
			for (let i = 0; i < this.length; i++) {
				if (stringFilter(this[i].name, filter)) {
					returnArr.push(this[i]);
				}
			}
		else
			returnArr.push(...this);

		return returnArr.sort(associationSort);
	};
}

//Filter athlete by its properties and sort it by firstName
if (!Array.prototype.athleteFilter) {
	Array.prototype.athleteFilter = function (this: Athlete[], filter?: string, associationGroup?: boolean): Athlete[] {
		let athleteSort = (a: Athlete, b: Athlete) => {
			return stringSort(a.firstName, b.firstName);
		};
		let associationSort = (a: Association | undefined, b: Association | undefined) => {
			if (!a || !b)
				return 0
			return stringSort(a.name, b.name);
		}

		const returnArr = [];
		if (filter) {
			for (let i = 0; i < this.length; i++) {
				const toCompare = `${this[i].firstName} ${this[i].lastName} ${this[i].code}`;

				if (stringFilterWithPermutation(toCompare, filter)) {
					returnArr.push(this[i]);
				}
			}
		}
		else
			returnArr.push(...this);

		if (!associationGroup)
			return returnArr.sort(athleteSort);

		const associations = returnArr.map(a => a.owner)
			.filter(a => a !== undefined)
			.filter((a, index, array) => !array.map(x => x?.id).includes(a?.id, index + 1))
			.sort(associationSort);

		const aux = returnArr;
		const associationWithAthletes = associations.map(a => ({ ...a, athletes: aux.filter(ath => ath.owner?.id === a?.id).sort(athleteSort) }));
		return associationWithAthletes.map(a => a.athletes).flat();
	};
}

//Filter competition by name | level | category and sort it by name
if (!Array.prototype.competitionFilter) {
	Array.prototype.competitionFilter = function (this: Competition[], name?: string, categories?: string[], levels?: number[]): Competition[] {
		let competitionSort = (a: Competition, b: Competition) => {
			return stringSort(a.name, b.name);
		}

		const returnArr = [];
		if (name || categories || levels)
			for (let i = 0; i < this.length; i++) {
				const nameFilterStat = (name && stringFilter(this[i].name, name)) || !name;
				const levelsFilterStat = (levels && levels.length > 0 && levels.includes(this[i].levelId ?? 0)) || (!levels || levels.length === 0);
				const categoriesFilterStat = (categories && this[i].category && categories.includes(this[i].category?.name ?? "")) || (!categories || categories.length === 0);

				if (nameFilterStat && levelsFilterStat && categoriesFilterStat) {
					returnArr.push(this[i]);
				}
			}
		else
			returnArr.push(...this);

		return returnArr.sort(competitionSort);
	};
}

//Sort team by team and filter by name if defined
if (!Array.prototype.teamFilter) {
	Array.prototype.teamFilter = function (this: Team[], filter?: string): Team[] {
		let associationSort = (a: Team, b: Team) => {
			return stringSort(a.name, b.name);
		}

		const returnArr = [];
		if (filter)
			for (let i = 0; i < this.length; i++) {
				if (stringFilter(this[i].name, filter)) {
					returnArr.push(this[i]);
				}
			}
		else
			returnArr.push(...this);

		return returnArr.sort(associationSort);
	};
}

//Sort exercise types based on defined property
//If no property is defined "displayName" is used
if (!Array.prototype.sortExerciseTypes) {
	Array.prototype.sortExerciseTypes = function (this: ExerciseType[], propertyName?: string): ExerciseType[] {
		const sortET = (a: ExerciseType, b: ExerciseType) => {
			switch (propertyName) {
				case "name":
					return stringSort(a.name, b.name);
				case "id":
					return b.id - a.id;
				case "displayName":
				default:
					return stringSort(a.displayName, b.displayName);
			}

		}
		return this.sort(sortET)
	}
}

//Sort competitions based on defined property
//If no property is defined "name" is used
if (!Array.prototype.sortCompetitions) {
	Array.prototype.sortCompetitions = function (this: Competition[], propertyName?: string): Competition[] {
		const sortComp = (a: Competition, b: Competition) => {
			switch (propertyName) {
				case "name":
				default:
					return stringSort(a.name, b.name);
				case "id":
					return b.id - a.id;
				case "type":
					return stringSort(a.type, b.type);
				case "level":
					return stringSort(a.level?.name ?? "", b.level?.name ?? "");
				case "eventId":
					return b.eventId - a.eventId;
				case "category":
					return stringSort(a.category?.name!, b.category?.name!);
			}

		}
		return this.sort(sortComp)
	}
}

//Filter floor music metadata by association Id and sort in by filename
if (!Array.prototype.sortFloorMusicMetadata) {
	Array.prototype.sortFloorMusicMetadata = function (this: FloorMusicMetadata[], filter?: number, associationGroup?: boolean): FloorMusicMetadata[] {
		let floormusicmetadataSort = (a: FloorMusicMetadata, b: FloorMusicMetadata) => {
			return stringSort(a.fileName ?? "", b.fileName ?? "");
		}

		const returnArr = [];
		if (filter)
			for (let i = 0; i < this.length; i++) {
				if (this[i].associationId === filter) {
					returnArr.push(this[i]);
				}
			}
		else
			returnArr.push(...this);

		if (!associationGroup)
			return returnArr.sort(floormusicmetadataSort);

		return returnArr.sort(floormusicmetadataSort).sort((a, b) => a.associationId - b.associationId);

	};
}

//Check ISOString format
if (!String.prototype.isISOString) {
	String.prototype.isISOString = function (this: string) {
		if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(this)) return false;
		const d = new Date(this);
		return d instanceof Date && !isNaN(d.getTime()); // valid date 
	}
}

//Delete unnecessary space
if (!String.prototype.noMultiSpace) {
	String.prototype.noMultiSpace = function (this: string) {
		return this.trim().replace(/\s+/g, ' ');;
	}
}