import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { Athlete, HTTP_STATUS_CODES, handleResponse } from '../../../models/models';
import './OrganizerAthletesDeleteRequest.scss';

interface props {
	athlete?: Athlete;
	deletionCompleted: (association: Athlete) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerAthletesDeleteRequest({ athlete, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteathlete = async (e: React.FormEvent) => {
		if (!athlete) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.athlete(athlete.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione dell'atleta",
					"Atleta cancellato",
					() => deletionCompleted(athlete)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE L'ATLETA" submit={deleteathlete}>
			<p className='organizer-athlete-delete-request-form-athlete-name'>{athlete?.firstName + " " + athlete?.lastName}</p>
		</CrudForm>
	);
}

export default OrganizerAthletesDeleteRequest;