import React, { useContext, useEffect, useState } from 'react'
import ModalWindow from '../../../components/ModalWindow';
import { HTTP_STATUS_CODES, Jury, User } from '../../../models/models';
import { del, get, put } from '../../../models/backendReq';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../models/auth/AuthProvider';

import './OrganizerJuriesAssignUser.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faClose } from '@fortawesome/free-solid-svg-icons';
import EventEoContext from '../../../contexts/EventEoContext';
import SearchInput from '../../../components/Input/SearchInput';

interface props {
	eventId: number;
	jury?: Jury;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	updateUsers: (users: User[], jury?: Jury) => void;
}

function OrganizerJuriesAssignUser(props: props) {
	const eventContext = useContext(EventEoContext);

	const alert = useAlert();
	const auth = useAuth();
	const navigate = useNavigate();

	const [associatedUsers, setAssociatedUsers] = useState<User[]>([]);
	const [userFilter, setUserFilter] = useState("");
	const [showUsers, setShowUsers] = useState(false);

	useEffect(() => {
		setAssociatedUsers(props.jury?.judges ?? []);
	}, [props.jury]);

	useEffect(() => {
		props.updateUsers(associatedUsers, props.jury);
	}, [associatedUsers]);

	const removeUser = async (user: User) => {
		if (!props.jury)
			return
		const response = await del.judgeInJury(props.jury.id, user.id);

		if (response.ok) {
			setAssociatedUsers(prev => prev.filter(x => x.id !== user.id));
		} else {
			if (response.status !== HTTP_STATUS_CODES.UNAUTHORIZED) {
				alert.show("Errore durante la rimozione dell'utente", MESSAGE_TYPE.ERROR, 3000, true, true);
			} else {
				auth.signout();
				navigate('/Utente/Login');
				alert.show(`Sessione utente scaduta`, MESSAGE_TYPE.WARNING, 3000, true, true);
			}
		}
	}

	const addUser = async (user: User) => {
		if (!props.jury)
			return

		const response = await put.judgeInJury(props.jury.id, user.id);

		if (response.ok) {
			setAssociatedUsers(prev => [...prev, user]);
			setShowUsers(false);
		} else {
			if (response.status !== HTTP_STATUS_CODES.UNAUTHORIZED) {
				alert.show("Errore durante l'inserimento dell'utente", MESSAGE_TYPE.ERROR);
			} else {
				auth.signout();
				navigate('/Utente/Login');
				alert.show("Sessione utente scaduta", MESSAGE_TYPE.WARNING, 2000, true, true);
			}
		}
	}


	const usersSort = (a: User, b: User) => {
		return ((a.userName < b.userName) ? -1 : ((a.userName > b.userName) ? 1 : 0));
	}

	const eventUsersToShow = eventContext.eventusers.value.sort(usersSort).filter(user => !associatedUsers.map(x => x.id).includes(user.id));
	const usersToShow = eventContext.users.sort(usersSort)
		.filter(us => us.displayName.toLowerCase().includes(userFilter.toLowerCase()))
		.filter(user => !associatedUsers.map(x => x.id).includes(user.id)).slice(0, 5);

	return (
		<ModalWindow show={props.show} closeButton>
			<div className='juries-assignuser-container'>
				<div className='juries-assignuser-header-parent'>
					<div className='juries-assignuser-header-child'>{props.jury?.name}</div>
				</div>
				<div className={`juries-users-container`}>
					<div className={`juries-assignuser-users-parent${!showUsers ? " hide" : ""}`}>
						<div className='juries-assignuser-users-header'>Utenti liberi</div>
						<div className='juries-assignuser-users-search-users-container'>
							<SearchInput
								className='juries-assignuser-users-search-users'
								onChange={(e) => setUserFilter(e.target.value)}
								placeholder='Cerca utente' value={userFilter} />
							{userFilter !== "" && <div className='juries-assignuser-users-search-users-show-container'>
								{
									usersToShow.map(user => (
										<div key={user.id} className='juries-assignuser-users-user-container ext'>
											<div className='juries-assignuser-users-user-displayname'>{user.displayName}</div>
											<button className='juries-assignuser-users-user-button' onClick={() => addUser(user)}>
												<FontAwesomeIcon icon={faAdd} />
											</button>
										</div>
									))
								}
							</div>}
						</div>
						<div className='juries-assignuser-users-container'>
							{
								eventUsersToShow.length > 0 || usersToShow.length > 0 ? (
									eventUsersToShow.map(user => {
										return (
											<div key={user.id} className='juries-assignuser-users-user-container'>
												<div className='juries-assignuser-users-user-displayname'>{user.displayName}</div>
												<button className='juries-assignuser-users-user-button' onClick={() => addUser(user)}>
													<FontAwesomeIcon icon={faAdd} />
												</button>
											</div>
										)
									})
								) : (
									<div className='juries-assignuser-users-user-nouser'>Lista utenti evento vuota</div>
								)
							}
						</div>
						<button onClick={() => setShowUsers(false)} className='juries-close-user-button'>CHIUDI</button>
					</div>
					<div className={`juries-assignuser-assigned-parent${showUsers ? " hide" : ""}`}>
						<div className='juries-assignuser-assigned-header'>Utenti assegnati a questa giuria</div>
						<div className='juries-assignuser-assigned-container'>
							{
								associatedUsers.length > 0 ? (
									associatedUsers.sort(usersSort).map(associatedUser => {
										return (
											<div key={associatedUser.id} className='juries-assignuser-assigned-user-container'>
												<div className='juries-assignuser-assigned-user-displayname'>{associatedUser.displayName}</div>
												<button className='juries-assignuser-assigned-user-button' onClick={() => removeUser(associatedUser)}>
													<FontAwesomeIcon icon={faClose} />
												</button>
											</div>
										)
									})
								) : (
									<div className='juries-assignuser-assigned-user-nouser'>Scegliere dalla lista utenti gli utenti da assegnare a questa giuria</div>
								)
							}
						</div>
						<button onClick={() => setShowUsers(!showUsers)} className='juries-add-user-button'>AGGIUNGI UTENTE</button>
					</div>
				</div>
			</div>
		</ModalWindow>
	)
}

export default OrganizerJuriesAssignUser	