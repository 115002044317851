import React, { useContext } from 'react';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import EventEoContext from '../../../contexts/EventEoContext';
import { COMP_TYPE } from '../../../models/backendReq';
import { competitionWithTeamLinks, competitionWithoutTeamLinks } from '../../../screens/Organizer/SubNavBarLinks';
import TableContainer from './TableContainer';

interface props {
	children: React.ReactNode;
	text?: string;
	loading?: boolean;
}
function OrganizerCompetitionTable(props: props) {
	const eventContext = useContext(EventEoContext);
	const competitionContext = useContext(CompetitionEoContext);

	const links = competitionContext.competition.type !== COMP_TYPE.Individual ?
		competitionWithTeamLinks(eventContext.event.id, competitionContext.competition.id)
		:
		competitionWithoutTeamLinks(eventContext.event.id, competitionContext.competition.id);

	return (
		<TableContainer
			headerText={props.text}
			sectionName={`${eventContext.event.name} / ${competitionContext.competition.name}`}
			sectionBackPath={`/User/Events/${eventContext.event.id}/Competitions`}
			subBarLinks={links}
			showLoading={competitionContext.loading || eventContext.loading || props.loading}
			hasSection>
			<div style={{ width: "100%" }}>{props.children}</div>
		</TableContainer>
	)
}

export default OrganizerCompetitionTable