import { useEffect, useRef, useState } from 'react';
import { Athlete, Team } from '../../models/models';

import './ClassificationExercise.scss';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import ToolTip from '../ToolTip';
import { convertDate } from '../../models/dateAndTime';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface props {
	athlete: Athlete | Team;
	sum: number;
	isPM: boolean;
	position: number;
	team?: Team;
	hide?: boolean;
	hideReq: (id: number) => void;
}

function ClassificationExercise({ athlete, sum, isPM, position, team, hide, hideReq }: props) {
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (hide)
			setSelected(false);
	}, [hide])
	let containerClassName = "classification-exercise-container";
	if (isPM)
		containerClassName += " PM";

	if (selected)
		containerClassName += " selected";
	if (hide)
		containerClassName += " hide";

	const code = ("firstName" in athlete) ? athlete.code : "";
	const name = ("firstName" in athlete) ? athlete.firstName + " " + athlete.lastName : athlete.name;
	return (
		<div onClick={() => setSelected(!selected)} className={containerClassName}>
			{!hide && <div className='classification-exercise-position'>
				{isPM ? "" : position}
			</div>}
			{
				"firstName" in athlete ? (
					<ToolTip timeOnEnter={1000} text={`Data di nascita: ${convertDate(athlete.birthDate, undefined, true)}`}>
						<div className='classification-exercise-info-container'>
							<div className='classification-exercise-info'>
								{athlete.owner?.name}
							</div>
							<div className='classification-exercise-name'>
								{name}
							</div>
						</div>
					</ToolTip>
				) : (
					<div className='classification-exercise-info-container'>
						<div className='classification-exercise-info'>
							{athlete.owner?.name + (team ? ` | ${team.name}` : "")}
						</div>
						<div className='classification-exercise-athlete-name'>
							{name}
						</div>
					</div>
				)
			}
			{
				!hide && sum !== 0 ? (
					<div className='classification-exercise-athlete-sum'>{sum.toFixed(3)}</div>
				) : (
					<div className='classification-exercise-athlete-sum noScore'>---</div>
				)
			}
			<button className='classification-exercise-hide-button' onClick={() => hideReq(athlete.id)}>
				<FontAwesomeIcon icon={!hide ? faEyeSlash : faEye} />
			</button>
		</div>
	)
}

export default ClassificationExercise