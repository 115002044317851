import { useLocation } from 'react-router-dom';
import JuryCompetitionContext from "../../contexts/JuryCompetitionContext";
import TurnContext from '../../contexts/TurnContext';
import { AthleteClass, Event, ExecutionGroup, ExecutionTurn, ExerciseType, Jury, JuryAssignment, TeamClass, initAthlete, initExecutionTurn, initTeam } from '../../models/models';

import { useContext } from 'react';
import HeaderWithBackButton from '../../components/HeaderWithBackButton';
import JuryContext from '../../contexts/JuryContext';
import { useAuth } from '../../models/auth/AuthProvider';
import './CompetitionLayout.scss';
import TurnNested from './TurnNested';
import { useStateLocation } from '../../models/StateLocationProvider';

interface TurnLayoutData {
	userId?: number;
	assignments: JuryAssignment[];
	executiongroups: ExecutionGroup[];
	turn: ExecutionTurn;
	event: Event;
	exerciseTypes: ExerciseType[];
	juries: Jury[];
	userJury?: Jury;
	competitors?: (AthleteClass | TeamClass)[],
}

function TurnLayout() {
	const auth = useAuth();
	const location = useStateLocation();
	const executionturn = location.read<ExecutionTurn>("executionturn") ?? initExecutionTurn;

	const context = useContext(JuryContext);


	let athleteInEvent = context.exercises.map(exe => {
		if (exe.athleteId)
			return new AthleteClass(exe.athlete ?? initAthlete)
		else
			return new TeamClass(exe.roster?.team ?? initTeam)
	}).filter((ath, index, array) => !array.map(a => a.id).includes(ath.id, index + 1));

	let _competitors: (AthleteClass | TeamClass)[] = [];
	context.executionturns.find(et => et.id === executionturn.id)?.groups?.forEach(eg => {
		const exercises = context.exercises.filter(x => x.executionGroup?.id === eg.id);
		const athletesInGroup = athleteInEvent.filter(athlete => exercises.map(exe => exe.athleteId ?? (exe.teamId * -1))
			.includes(("firstName" in athlete) ? athlete.id : (athlete.id * -1)));
		const athletes: (AthleteClass | TeamClass)[] = athletesInGroup.map(ath => {
			if ("firstName" in ath) {
				const _exercises = exercises.filter(exe => exe.athleteId === ath.id)
					.filter(exe =>
						context.assignments.filter(a => a.competitionId === exe.competitionId)
							.map(a => a.exerciseTypeId)
							.includes(exe.typeId) || auth.user.eventOrganizers.map(eo => eo.id).includes(context.event.ownerId)
					);
				return new AthleteClass(ath, _exercises, eg.id)
			} else {
				const _exercises = exercises.filter(exe => exe.teamId === ath.id * -1)
					.filter(exe =>
						context.assignments.filter(a => a.competitionId === exe.competitionId)
							.map(a => a.exerciseTypeId)
							.includes(exe.typeId) || auth.user.eventOrganizers.map(eo => eo.id).includes(context.event.ownerId)
					);
				return new TeamClass(ath, _exercises, eg.id)
			}
		})

		_competitors.push(...athletes)
	});

	const contextData: TurnLayoutData = {
		userId: auth.user.info.id,
		turn: executionturn,
		assignments: context.assignments,
		executiongroups: context.executionturns.find(t => t.id === executionturn.id)?.groups ?? [],
		event: context.event,
		exerciseTypes: context.exercisetypes,
		juries: context.juries,
		competitors: _competitors,
		userJury: context.userJury
	};

	return (
		<div className='competition-content'>
			<div>
				<HeaderWithBackButton navigateTo={-1} header={contextData.turn.name} />
				<TurnContext.Provider value={contextData}>
					<JuryCompetitionContext.Provider value={{ assignments: contextData.assignments }}>
						<TurnNested />
					</JuryCompetitionContext.Provider>
				</TurnContext.Provider>
			</div>
		</div>
	);
}

export default TurnLayout;