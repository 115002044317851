import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { Association, HTTP_STATUS_CODES, handleResponse, initAssociation } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerAssociationsEditRequest.scss';

interface props {
	association?: Association;
	editCompleted: (association: Association) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerAssociationsEditRequest({ association, show, editCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inesrire il nome per la società" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [associationToPut, setAssociationToPut] = useState<Association>(association ? association : initAssociation);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (association)
			setAssociationToPut(association);
	}, [association]);

	let editAssociation = async (e: React.FormEvent) => {
		e.preventDefault();

		if (associationToPut.name === "") {
			setInputErrors(prev => [...prev, inputNameError])
			return
		}

		await put.association(associationToPut.id, associationToPut.name)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica della società",
					"Società modificata correttamente",
					() => editCompleted(associationToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica la società" submit={editAssociation}>
			<p className='organizer-association-edit-request-form-old-name'>{association?.name}</p>
			<p className='organizer-association-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-association-edit-request-form-input'
				type='text'
				value={associationToPut.name}
				placeholder='Inserisci nome società..'
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setAssociationToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerAssociationsEditRequest;