import { useContext, useState } from 'react';
import OrganizerEventTable from '../../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../../contexts/EventEoContext';
import { getUnique } from '../../../../utility/UtilityFunctions';
import '../../../../utility/prototype';
import './EventDetailsLScoreLive.scss';
import { AthleteLiveCom } from './EventDetailsScoreCompetitionLive';
import HeaderWithBackButton from '../../../../components/HeaderWithBackButton';

function EventDetailsScoreCompetitionsLive() {
	const eventContext = useContext(EventEoContext);
	const exTypes = getUnique(eventContext.associatedexercisetypes.value, "id");
	const [fragmentsRule, setFragmentsRule] = useState(0);
	const [etToShow, setEtToShow] = useState(exTypes.length > 0 ? exTypes[0].id : 0);

	return (
		<OrganizerEventTable text='LIVE'>
			<HeaderWithBackButton navigateTo={'..'} />
			<div className='eventLives-visualization-mode-selection'>
				<span>Visualizzazione punteggio:</span>
				<select onChange={e => setFragmentsRule(parseInt(e.target.value))}>
					<option value={0}>Solo risultato</option>
					<option value={1}>Valore massimo</option>
					<option value={2}>Tutto</option>
				</select>
			</div>
			<div className='evenLives-container'>
				<div className='eventLives-table'>
					<div className='eventLives-table-row header'>
						<div className='eventLives-tableAthleteColumn header'>Atleta</div>
						{exTypes.map(ets => {
							return (
								<div key={ets.id} className='eventLives-tableETColumns header'>{ets.displayName}</div>
							)
						})}
						<div className='eventLives-tableETColumns header responsive'>
							<select value={etToShow} onChange={(e) => setEtToShow(+e.target.value)}>
								{exTypes.map(et => <option key={et.id} value={et.id}>{et.displayName}</option>)}
							</select>
						</div>
					</div>
					<div className='eventLives-tableBody-full-live-container'>
						{
							eventContext.competitions.value.map(competition =>
								<div key={competition.id} className='eventLives-tableBody-full-live'>
									<div className='eventLives-tableBody-competition-name'>{competition.name}</div>
									{eventContext.athletes.value.filter(a => a.competition.id === competition.id).athleteFilter(undefined, true).map((athlete) => {
										const exercises = eventContext.exercises.value.filter(e => e.competition.id === competition.id);
										const exercisesBuild = () => {
											if ("name" in athlete)
												return exercises.filter(x => x.teamId === athlete.id);
											else
												return exercises.filter(x => x.athleteId === athlete.id);
										}

										return (
											<AthleteLiveCom
												key={athlete.id}
												athlete={athlete}
												fragmentsRule={fragmentsRule}
												exercises={exercisesBuild()}
												ets={exTypes}
												etId={etToShow} />
										);
									})}
								</div>
							)}
					</div>
				</div>
			</div>
		</OrganizerEventTable>
	)
}

export default EventDetailsScoreCompetitionsLive