import { getAge } from '../../models/dateAndTime';
import { Athlete } from '../../models/models';

import './OrganaizerAthleteCom.scss';

import TableNameCell from './TableComponents/TableNameCell';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';

interface props {
	athlete: Athlete;
	del: (association: Athlete) => void;
	edi: (association: Athlete) => void;
}

function OrganaizerAthleteCom({ athlete, del, edi }: props) {
	let editReq = () => {
		edi(athlete);
	}

	let deleteReq = () => {
		del(athlete);
	}

	return (
		<tr>
			<TableNameCell text={athlete.firstName + " " + athlete.lastName} />
			<td className='organizer-athlete-col-age'>{getAge(athlete.birthDate)}</td>
			<td className='organizer-athlete-col-code'><strong>{athlete.code}</strong></td>
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />
		</tr>
	);
}

export default OrganaizerAthleteCom;