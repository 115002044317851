import React, { useEffect, useRef, useState } from 'react';
import './AudioComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { getMusicUrl } from '../models/models';


interface props {
	floorMusicId: number;
	className?: string;
}

function AudioComponent(props: props) {
	const [musicUrl, setMusicUrl] = useState<string>();
	const [played, setPlayed] = useState(false);

	const audioRef = useRef<HTMLAudioElement>(null);


	const playIcon = played ? faPause : faPlay;

	useEffect(() => {
		if (!musicUrl)
			return;

		if (!audioRef.current)
			return;

		audioRef.current.play();
		setPlayed(true);

	}, [musicUrl]);

	const handlePlay = async () => {
		if (!musicUrl) {
			setMusicUrl(await getMusicUrl(props.floorMusicId));
			return;
		}
		if (!audioRef.current)
			return;

		if (audioRef.current.paused) {
			audioRef.current.play();
			setPlayed(true);
		} else {
			audioRef.current.pause();
			setPlayed(false);
		}
	}

	const handleStop = () => {
		if (!musicUrl)
			return;

		if (!audioRef.current)
			return;

		audioRef.current.pause();
		audioRef.current.currentTime = 0;
		setPlayed(false);
	}

	return (
		<div className={`audio-component-container${played ? " played" : ""} ${props.className}`}>
			<audio ref={audioRef} src={musicUrl} />
			<button type='button' onClick={handlePlay}>
				<FontAwesomeIcon icon={playIcon} />
			</button>
			<button type='button' onClick={handleStop}>
				<FontAwesomeIcon icon={faStop} />
			</button>
		</div>
	)
}

export default AudioComponent