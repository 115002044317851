import { useContext, useState } from 'react';
import OrganaizerCompetitionCom from '../../../components/Organizer/OrganaizerCompetitionCom';
import OrganizerCompetitionsAddRequest from './OrganizerCompetitionsAddRequest';
import OrganizerCompetitionsDeleteRequest from './OrganizerCompetitionsDeleteRequest';
import OrganizerCompetitionsEditRequest from './OrganizerCompetitionsEditRequest';

import { COMP_TYPE, del, get, post, put } from '../../../models/backendReq';

import { Athlete, Competition, ExerciseType, JuryAssignment, Team, handleResponse } from '../../../models/models';

import { useNavigate } from 'react-router-dom';

import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableLinkRow from '../../../components/Organizer/TableComponents/TableLinkRow';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../../contexts/EventEoContext';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { competitionsFilterBuilder, getUnique } from '../../../utility/UtilityFunctions';
import { toolBarElements } from '../SubNavBarLinks';
import OrganizerCompetitionsAddBulk from './OrganizerCompetitionsAddBulk';
import './OrganizerCompetitionsScreen.scss';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import ElementsLength from '../../../components/ElementsLength';

function OrganizerCompetitionsScreen() {
	const eventContext = useContext(EventEoContext);
	const configurationsContext = useContext(OrganizerConfigurationsContext);

	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	let [competitionsFilterName, setCompetitionsFilterName] = useState("");
	let [categoryFilter, setCategoryFilter] = useState("");
	let [levelFilter, setlevelFilter] = useState(0);
	let [athleteFilter, setAthleteFilter] = useState("");
	let [typeFilter, setTypeFilter] = useState("");
	let [genderFilter, setGenderFilter] = useState("");

	let [competitionToDel, setCompetitionToDel] = useState<Competition>();
	let [competitionToPut, setCompetitionToPut] = useState<Competition>();

	let showDel = useState(false);
	let showAdd = useState(false);
	let showEdi = useState(false);
	let showFilters = useState(false);
	let showBulk = useState(false);

	//MASSIVE OPERATION STATE
	const [enableMassive, setEnableMassive] = useState(false);
	const [competitionsMassive, setCompetitionsMassive] = useState<Competition[]>([]);
	const showDeleteAll = useState(false);



	let deleteRequest = (competition: Competition) => {
		showDel[1](true);
		setCompetitionToDel(competition);
	};
	let deletionCompleted = (competition: Competition) => {
		eventContext.competitions.crud.delete(competition);
		showDel[1](false);
	};
	let addCompleted = (competition: Competition) => {
		eventContext.competitions.crud.add(competition);
		showAdd[1](false);
	};
	let editRequest = (competition: Competition) => {
		showEdi[1](true);
		setCompetitionToPut(competition);
	};
	let editCompleted = (competition: Competition) => {
		eventContext.competitions.crud.edit(competition);
		showEdi[1](false);
	};
	let duplicateReq = async (competition: Competition) => {
		const exercisetypes = eventContext.associatedexercisetypes.value.filter(et => et.competition.id === competition.id);
		const resAssignments = await get.juriesAssignments(competition.id);
		handleResponse(resAssignments, auth, alert, navigate, "Errore durante la copia della gara", undefined,
			async (assignments: JuryAssignment[]) => {
				const res = await post.competition(eventContext.event.id, competition.type, competition.name + " - Copia", [], competition.levelId, competition.category);
				handleResponse(res, auth, alert, navigate, "Errore durante la copia della gara", undefined,
					async (competitionToAdd: Competition) => {
						eventContext.competitions.crud.add(competitionToAdd);
						const promiseEts = exercisetypes.map(async (et) => {
							eventContext.associatedexercisetypes.crud.add({ ...et, competition: competitionToAdd });
							return await put.competitionAddExerciseType(competitionToAdd.id, et.id);
						});
						await Promise.all(promiseEts);
						const promiseAssignments = assignments.map(async (assignment) => {
							const asft = exercisetypes.find(et => et.id === assignment.exerciseTypeId)?.scoreComposition
								.filter(sft => assignment.assignedScoreFragmentTypes.includes(sft.label))
								.map(sftFiltered => sftFiltered.id) ?? [];
							return await post.jurieAssignments(assignment.juryId, competitionToAdd.id, assignment.exerciseTypeId, asft)
						});
						await Promise.all(promiseAssignments);

						alert.show("Gara copiata correttamente", MESSAGE_TYPE.VALID, 3000, true, true);
					});
			});

	}

	let goTo = (path: string) => {
		if (competitionsMassive.length > 0)
			navigate(path, { state: { competitions: competitionsMassive, event: eventContext.event } });
	};


	const createdBulk = (competitions: Competition[], exercisetypes: ExerciseType[]) => {
		eventContext.competitions.crud.add(competitions);
		eventContext.associatedexercisetypes.crud.add(competitions.map(c => exercisetypes.map(et => ({ ...et, competition: c, competitionId: c.id }))).flat())
		showBulk[1](false);
	}

	const deleteSelected = async (e: React.FormEvent<Element>) => {
		e.preventDefault();
		const notDeleted = [];
		const toDelete: Competition[] = [];

		const promise = competitionsMassive.map(async (c) => {
			let competitors: (Team | Athlete)[] = [];
			if (c.type === COMP_TYPE.Collective)
				competitors = eventContext.teams.value.filter(t => t.competition.id === c.id);
			else
				competitors = eventContext.athletes.value.filter(a => a.competition.id === c.id);
			if (competitors.length === 0) {
				const res = await del.competition(c.id);
				handleResponse(res, auth, alert, navigate, `Errore durante la cancellazione della gara ${c.name}`);
				toDelete.push(c);
			} else notDeleted.push(`${c.name}: Non è possibile eliminare gare con atleti iscritti`);
		});

		await Promise.all(promise);
		if (notDeleted.length === 0)
			alert.show("Gare cancellate correttamente", MESSAGE_TYPE.VALID, 3000, true, true);
		else {
			notDeleted.unshift(`Eliminate ${competitionsMassive.length - notDeleted.length} gare su ${competitionsMassive.length}`)
			alert.show(notDeleted, MESSAGE_TYPE.WARNING, 3000, true, true);
		}
		eventContext.competitions.crud.delete(toDelete);
		setCompetitionsMassive([]);
		showDeleteAll[1](false);
	};

	const _filters = competitionsFilterBuilder(eventContext, configurationsContext,
		setCompetitionsFilterName, competitionsFilterName,
		setCategoryFilter, categoryFilter,
		setlevelFilter, levelFilter,
		setAthleteFilter, athleteFilter,
		setTypeFilter, typeFilter,
		setGenderFilter, genderFilter);

	const _catFilter = [];
	const _lvlFilter = [];
	if (categoryFilter !== "")
		_catFilter.push(categoryFilter);

	if (levelFilter !== 0)
		_lvlFilter.push(levelFilter);

	const _toolBarElements: eoToolbarProp[] = [
		{
			...toolBarElements.addElement,
			callback: () => showAdd[1](true),
			text: 'Aggiungi competizione'
		},
		{
			...toolBarElements.filterElement,
			callback: () => showFilters[1](!showFilters[0]),
			notNumber: _filters.filter(f => f.value !== "" && f.value !== 0).length
		},
		{
			...toolBarElements.wizardElement,
			callback: () => showBulk[1](true),
			text: 'Wizard creazione'
		},
		{
			...toolBarElements.selectElement,
			callback: () => setEnableMassive(!enableMassive),
			text: 'Seleziona',
			selected: enableMassive,
			notNumber: enableMassive ? competitionsMassive.length : undefined
		},
	];

	if (enableMassive)
		_toolBarElements.push(
			{
				...toolBarElements.juryElement,
				callback: () => goTo('./Massive/Juries'),
				text: 'Configura giuria',
				disabled: competitionsMassive.length === 0 || !enableMassive
			},
			{
				...toolBarElements.eTypeElement,
				callback: () => goTo('./Massive/ExerciseTypes'),
				text: 'Configura attrezzi',
				disabled: competitionsMassive.length === 0 || !enableMassive
			},
			{
				...toolBarElements.deleteElemet,
				callback: () => { if (competitionsMassive.length > 0) showDeleteAll[1](true) },
				text: 'Cancella selezionate',
				disabled: competitionsMassive.length === 0 || !enableMassive
			});

	const compToShow = eventContext.competitions.value.sortCompetitions()
		.competitionFilter(competitionsFilterName, _catFilter, _lvlFilter)
		.filter(x => eventContext.athletes.value.filter(y => y.competition.id === x.id)
			.athleteFilter(athleteFilter).length > 0 || athleteFilter === "")
		.filter(x => !x.category || !genderFilter || x.category.allowedGenders?.includes(genderFilter))
		.filter(x => !typeFilter || x.type === typeFilter);


	const allSelectChange = () => {
		if (competitionsMassive.length === compToShow.length)
			setCompetitionsMassive([]);
		else
			setCompetitionsMassive(compToShow);
	};

	return (
		<OrganizerEventTable text='COMPETIZIONI'>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar history='competitionsFilters' show={showFilters} filters={_filters} found={_toolBarElements[1].notNumber! > 0 ? compToShow.length : undefined} />
			<CrudForm
				denied={() => showDeleteAll[1](false)}
				back={() => showDeleteAll[1](false)}
				submit={deleteSelected}
				headerText='CONFERMA CANCELLAZIONE DELLE GARE SELEZIONATE'
				show={showDeleteAll}
				submitButtonText='CANCELLA DEFINITIVAMENTE'>
				<div className='organizer-competition-massive-delete-warning'>
					<div className='organizer-competition-massive-delete-warning-header'>
						<FontAwesomeIcon icon={faWarning} />
						<span>Azione irreversibile</span>
					</div>
					<ul>{competitionsMassive.map(c => <li key={c.id}>{c.name}</li>)}</ul>
				</div>
			</CrudForm>
			{/* <ElementsLength length={eventContext.competitions.value.length} /> */}
			<div>{`Numero di gare evento: ${eventContext.competitions.value.length}`}</div>
			<Table>
				<thead>
					<tr>
						<TableLinkRow
							text='Nome'
							selectable={enableMassive}
							fullArray={compToShow}
							selectArray={competitionsMassive}
							selectAll={allSelectChange}
							isHeader />
						<th className='organizer-competition-col-type'>Tipo</th>
						<th className='organizer-competition-col-subnum'>N° Iscritti</th>
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent items={compToShow}
						emptyMesssage='Non ci sono gare create per questo evento'
						renderList={competitions => competitions.map(competition =>
							<OrganaizerCompetitionCom
								competition={competition}
								del={deleteRequest}
								edi={editRequest}
								event={eventContext.event}
								competitionsMassive={competitionsMassive}
								enableMassive={enableMassive}
								key={competition.id}
								setCompetitionsMassive={setCompetitionsMassive}
								lastId={eventContext.lastCompetition?.id}
								duplicateReq={duplicateReq}
							/>
						)} />
				</tbody>
			</Table>
			<OrganizerCompetitionsDeleteRequest competition={competitionToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerCompetitionsAddRequest event={eventContext.event} show={showAdd} addCompleted={addCompleted} />
			<OrganizerCompetitionsEditRequest event={eventContext.event} competition={competitionToPut} show={showEdi} editCompleted={editCompleted} />
			<OrganizerCompetitionsAddBulk event={eventContext.event} show={showBulk} created={createdBulk} />
		</OrganizerEventTable>
	);
}

export default OrganizerCompetitionsScreen;