import React, { ReactNode, useEffect } from 'react'
import './ModalWindow.scss'

interface props {
	noOverflow?: boolean;
	children?: ReactNode;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	closeButton?: boolean;
}

function ModalWindow(props: props) {

	useEffect(() => {
		if (props.show[0]) {
			document.body.style.overflow = 'hidden';
		} else
			document.body.style.overflow = 'unset';

		return () => {
			document.body.style.overflow = 'unset';
		}
	}, [props.show[0]]);

	useEffect(() => {
		window.addEventListener("keydown", keyPressed)
		return () => {
			window.removeEventListener("keydown", keyPressed)
		}
	}, [])

	const keyPressed = (ev: KeyboardEvent) => {
		if (ev.key === "Escape") {
			props.show[1](false)
		}
	}

	return (
		<div className={`modal-window${props.show[0] ? " show" : ""}`}
			onMouseDown={(e) => {
				if (e.target === e.currentTarget)
					props.show[1](false)
			}}>
			<div className={`modal-window-content${(props.show[0] ? " show" : "") + (props.noOverflow ? " noOverflow" : "")}`}>
				{props.children}
				{props.closeButton && <div className='modal-window-close-container'>
					<button onClick={() => props.show[1](false)} className='modal-window-close-button'>Chiudi</button>
				</div>}
			</div>
		</div>
	)
}

export default ModalWindow