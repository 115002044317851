import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { post } from '../../../models/backendReq';
import { Event, ExecutionTurn, handleResponse } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerExecutionTurnsAddRequest.scss';

interface props {
	event: Event;
	addCompleted: (executionturns: ExecutionTurn) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerExecutionTurnsAddRequest({ event, show, addCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per il turno" };

	let initExecutionTurn: ExecutionTurn = {
		id: 0,
		name: "",
		eventId: 0,
		start: new Date(event.startDate).toISOString(),
		end: new Date(event.startDate).toISOString()
	};

	let [executionturnsToAdd, setExecutionTurnToAdd] = useState<ExecutionTurn>(initExecutionTurn);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const firstFocusRef = useRef<HTMLInputElement>(null);

	let denied = () => {
		setExecutionTurnToAdd(initExecutionTurn);
		show[1](false);
	}

	let addExecutionTurn = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (executionturnsToAdd.name === "") {
			_errors.push(inputNameError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await post.executionTurn(event.id, executionturnsToAdd.name, executionturnsToAdd.start, executionturnsToAdd.end)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante l'inserimento del turno",
					"Turno creato correttamente",
					(data) => {
						addCompleted(data);
						setExecutionTurnToAdd(initExecutionTurn);
					},
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci nuovo turno' submit={addExecutionTurn}>
			<p className='organizer-executionturns-add-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-executionturns-add-request-form-input'
				type='text'
				style={{ resize: 'vertical' }}
				value={executionturnsToAdd.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setExecutionTurnToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-executionturns-add-request-form-input-label'>Data inizio</p>
			<InputWithAlert
				errorId="start"
				errors={inputErrors}
				showTimeInput
				className='datepicker-classname organizer-executionturns-add-request-form-input'
				dateSelected={executionturnsToAdd.start}
				onDateChange={(e) => { setExecutionTurnToAdd(prevState => ({ ...prevState, start: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				minDate={new Date(event.startDate)}
				maxDate={new Date(event.endDate)}
			/>
			<p className='organizer-executionturns-add-request-form-input-label'>Data fine</p>
			<InputWithAlert
				errorId="end"
				errors={inputErrors}
				showTimeInput
				className='datepicker-classname organizer-executionturns-add-request-form-input'
				dateSelected={executionturnsToAdd.end}
				onDateChange={(e) => { setExecutionTurnToAdd(prevState => ({ ...prevState, end: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				minDate={new Date(event.startDate)}
				maxDate={new Date(event.endDate)}
			/>
		</CrudForm>
	);
}

export default OrganizerExecutionTurnsAddRequest;