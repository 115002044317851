import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AssociationEoContext from '../../../contexts/AssociationsEoContext';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { get } from '../../../models/backendReq';
import { Association, Athlete, Team, responseGetJson } from '../../../models/models';
import { crudBuilder } from '../../../utility/UtilityFunctions';
import { useStateLocation } from '../../../models/StateLocationProvider';

interface AssociationWithAthletes extends Association {
	athletes: Athlete[];
	teams: Team[];
}

function OrganizerAssociationsLayout() {
	const auth = useAuth();
	const alert = useAlert();
	const location = useStateLocation();

	const [loading, setLoading] = useState(true);
	const [associations, setAssociations] = useState<AssociationWithAthletes[]>([]);

	const association = location.read<Association>("association");

	const getAssociations = async () => {
		if (!auth.user.associations) {
			alert.show("Errore durante il caricamento delle società", MESSAGE_TYPE.ERROR)
			return
		}
		const promise = auth.user.associations.map(async (a) => {
			return {
				...a,
				athletes: await get.athletesByAssociation(a.id).then(r => responseGetJson(r, [])),
				teams: await get.teamsByAssociation(a.id).then(r => responseGetJson(r, []))
			}
		})

		const associationsWithAthletesFromServer = await Promise.all(promise);

		setAssociations(associationsWithAthletesFromServer);
	};

	useEffect(() => {

		const fetchData = async () => {
			if (auth.user.info)
				await getAssociations();

			setLoading(false);
		}
		fetchData();
	}, [auth]);

	const contextVal = {
		loading,
		associations: { value: associations, crud: crudBuilder(setAssociations, getAssociations) },
		lastAssociation: association,
	};

	return (
		<AssociationEoContext.Provider value={contextVal}>
			<Outlet />
		</AssociationEoContext.Provider>
	)
}

export default OrganizerAssociationsLayout