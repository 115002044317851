import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AssociationCom from '../../components/Judge/AssociationExeGroupCom';
import AthleteCom from '../../components/Judge/AthleteCom';
import TurnContext from '../../contexts/TurnContext';
import { Association, AthleteClass, ExecutionGroup, TeamClass } from '../../models/models';
import '../../utility/prototype';

import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput from '../../components/Input/SearchInput';
import TeamCom from '../../components/Judge/TeamCom';
import { useLocalStorage } from '../../models/hooks/useLocalStorage';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import './CompetitionLayout.scss';
import { useStateLocation } from '../../models/StateLocationProvider';

interface TeamWithAthletes extends TeamClass {
	athletes: AthleteClass[];
}

function TurnNested() {
	let location = useStateLocation();

	let context = useContext(TurnContext);

	let [executionGroup, setExecutionGroup] = useState<Association | ExecutionGroup | undefined | string>(location.read<ExecutionGroup>("executiongroup"));
	let [filterShowWithScore, setFilterShowWithScore] = useLocalStorage("filterWithScoring", true);
	let [filterShowWithoutScore, setFilterShowWithoutScore] = useLocalStorage("filterWithoutScoring", true);
	let [expandFilters, setExpandFilters] = useState(false);
	let [athleteFilter, setAthleteFilter] = useState("");
	let [idToView, setIdToView] = useState(0);

	const refFilters = useRef<HTMLDivElement>(null);
	useOnClickOutside(refFilters, () => setExpandFilters(false))

	useEffect(() => {
		const element = document.getElementById('judgeGroupContainer');
		if (element) {
			element!.style.overflowY = 'hidden';
			let height = 190;
			if (context.executiongroups?.length > 0)
				height = context.executiongroups?.length * 45 + 135;

			if (executionGroup)
				height = 45;
			element!.style.height = `${height}px`;

			setTimeout(() => {
				if (executionGroup)
					element!.style.overflowY = 'hidden';
				else
					element!.style.overflowY = 'auto';
			}, 700)
		}
	})

	useEffect(() => {
		if (idToView !== 0)
			document.getElementById(`judgeAthlete${idToView}`)?.scrollIntoView({ block: 'center' });
	}, [idToView])

	const filterCompetitors = (competitor: AthleteClass | TeamClass, index: number, array: (AthleteClass | TeamClass)[]) => {
		let retVal;
		if (typeof (executionGroup) === "string")
			retVal = competitor.exercises?.map(exe => exe.athleteId ?? (exe.teamId * -1))
				.includes(("firstName" in competitor) ? competitor.id : (competitor.id * -1));
		else
			retVal = competitor.exercises?.map(exe => exe.athleteId ?? (exe.teamId * -1))
				.includes(("firstName" in competitor) ? competitor.id : (competitor.id * -1)) && competitor.groupId === executionGroup?.id;

		if (!filterShowWithScore)
			retVal = retVal && !(competitor as (AthleteClass | TeamClass)).hasAllScore;
		if (!filterShowWithoutScore)
			retVal = retVal && (competitor as (AthleteClass | TeamClass)).hasAllScore;

		return retVal
	}

	let competitors: (AthleteClass | TeamClass)[] = context.competitors ?? [];

	if (context.competitors)
		competitors = context.competitors?.filter(filterCompetitors).athleteFilter(athleteFilter);

	const associationName = typeof (executionGroup) === "string" ? "Gruppi" : executionGroup?.name;


	return (
		<div className='company-table'>
			<div id='judgeGroupContainer' className={`judge-associations-selection-container${executionGroup ? " selected" : ""}`}>
				{executionGroup ? (
					<div className='judge-association-selected' onClick={() => setExecutionGroup(undefined)}>
						{associationName}
					</div>
				) : (
					<>
						<h3 style={{ flex: 1 }}>Seleziona un gruppo</h3>
						{context.executiongroups?.length > 0 ? (context.executiongroups.map((executiongroup) => {
							return (
								<AssociationCom key={executiongroup.id} association={executiongroup} setAssociation={setExecutionGroup} />
							);
						})) : (
							<p>Non ci sono gruppi definiti per questo turno</p>
						)}
						<div
							onClick={() => setExecutionGroup("ALL")}
							className='company-container'>
							<p>Tutti	</p>
						</div>
					</>
				)}
			</div>
			<div className={`judge-competitors-selection-container${executionGroup ? " selected" : ""}`}>
				<div className={`judge-competitors-selection-container-competitor`}>
					{
						competitors.map(competitor => {
							let jsxElement: JSX.Element;
							if ("firstName" in competitor)
								jsxElement = <AthleteCom newScoreAssigned={(id) => {
									setAthleteFilter("")
									setIdToView(id);
								}} athlete={competitor} context={context} exercises={competitor.exercises ?? []} key={competitor.id}
									additionalText={executionGroup === "ALL" ? context.executiongroups.find(x => x.id === competitor.groupId)?.name : undefined} />
							else {
								const comp: TeamWithAthletes = { ...competitor, athletes: [] }
								jsxElement = <TeamCom
									athleteFilter={athleteFilter}
									context={context}
									newScoreAssigned={() => setAthleteFilter("")}
									team={comp}
									key={competitor.id}
									filterShowWithScore={filterShowWithScore}
									filterShowWithoutScore={filterShowWithoutScore}
									collective
								/>
							}
							return jsxElement
						})
					}
				</div>
				<div ref={refFilters} className={`judge-competitors-selection-container-filters${expandFilters ? " expand" : ""}`}>
					<button onClick={() => setExpandFilters(!expandFilters)}>
						<FontAwesomeIcon icon={expandFilters ? faClose : faFilter} />
					</button>
					<label>
						<input type='checkbox' checked={filterShowWithScore} onChange={() => setFilterShowWithScore(!filterShowWithScore)} />
						Mostra già assegnati
					</label>
					<label>
						<input type='checkbox' checked={filterShowWithoutScore} onChange={() => setFilterShowWithoutScore(!filterShowWithoutScore)} />
						Mostra da assegnare
					</label>
					<SearchInput className='judge-competitors-selection-container-filters-search' value={athleteFilter} onChange={(e) => setAthleteFilter(e.target.value)} placeholder='Cerca atleta' />
				</div>
			</div>
		</div>
	);
}

export default TurnNested;