import { faArrowLeft, faChevronDown, faChevronUp, faClose, faEllipsisVertical, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { COMP_TYPE, get, put } from '../../../models/backendReq';
import { Athlete, AthleteClass, Competition, Event, ExecutionGroup, Exercise, ExerciseType, HTTP_STATUS_CODES, Team, TeamClass, handleResponse, initAssociation, responseGetJson } from '../../../models/models';
import '../../../utility/prototype';
import SearchInput from '../../Input/SearchInput';
import './OrganizerExecutionGroupMan.scss';
import LoadingSpinner from '../../LoadingSpinner';

function AthleteWithExercisesComponent({ allExercisesRequest, athlete, exerciseTypes, request, buttonClassName, selectedAthlete, show, showAssociation }: {
	allExercisesRequest: (side: string, exercises?: Exercise[]) => void;
	athlete: AthleteClass | TeamClass;
	buttonClassName: string;
	exerciseTypes: ExerciseType[];
	request: (exercise: Exercise) => void;
	selectedAthlete: (athleteId: number) => void;
	show: boolean;
	showAssociation: boolean;
}) {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const exerciseElement = document.getElementById(`Exercise${buttonClassName + athlete.id}`);
		if (exerciseElement) {
			const exeNumber = athlete.exercises?.length ?? 0;
			const height = show ? exeNumber * 5 : 0;
			exerciseElement.style.height = `${height}vh`;
			if (show) {
				setTimeout(() => {
					exerciseElement.scrollIntoView({
						behavior: 'smooth',
						block: 'nearest',
						inline: 'nearest'
					});
				}, 700)
			}
		}
	}, [show, athlete.exercises]);

	let athleteNameClass = 'organizer-executiongroupman-container-athlete';
	if (athlete.exercises?.length === 0)
		athleteNameClass += ' empty'

	const associationName = athlete.owner?.name;
	const nameToShow = ("firstName" in athlete) ? `${athlete.firstName.toUpperCase()} ${athlete.lastName.toUpperCase()}` : athlete.name;
	return (
		<div className={athleteNameClass} >
			<div className={'organizer-executiongroupman-container-athlete-name ' + buttonClassName} onClick={(e) => {
				if (!buttonRef.current?.contains(e.target as Node))
					selectedAthlete(athlete.id)
			}}>
				<FontAwesomeIcon icon={faEllipsisVertical} />
				<div className='organizer-executiongroupman-container-athlete-name-info'>
					{showAssociation ? (
						<div className='organizer-executiongroupman-container-athlete-name-association'>{associationName}</div>
					) : (null)}
					<div className='organizer-executiongroupman-container-athlete-name-name'>{nameToShow}</div>
				</div>
				{athlete.exercises && athlete.exercises?.length > 0 && (
					<button
						className={`organizer-executiongroupman-container-athlete-allbutton ${buttonClassName}`}
						onClick={() => allExercisesRequest(buttonClassName, athlete.exercises)}
						ref={buttonRef}
					>
						<FontAwesomeIcon icon={buttonClassName === "AGGIUNGI" ? faPlus : faMinus} />
					</button>
				)}
			</div>
			<div id={`Exercise${buttonClassName + athlete.id}`} className='organizer-executiongroupman-container-athlete-exercises' >
				{athlete.exercises?.map(exe => {
					const name = exerciseTypes.find(et => et.id === exe.typeId)?.displayName ?? "Attrezzo non definito";
					return (
						<div className='organizer-executiongroupman-container-athlete-exercise' key={exe.id}>
							<div className='organizer-executiongroupman-container-athlete-exercise-name'>{name}</div>
							<button
								className={`organizer-executiongroupman-container-athlete-exercise-button ${buttonClassName}`}
								onClick={() => request(exe)}
								type='button'
							>
								{buttonClassName}
							</button>

						</div>
					)
				})}
			</div>
		</div>
	)
}

interface CompetitionWithAthlete extends Competition {
	athletes?: (AthleteClass | TeamClass)[];
}

interface props {
	event: Event;
	executiongroup?: ExecutionGroup;
	exerciseTypes: ExerciseType[];
	groupUpdate: (groupId: number, exercises: Exercise[]) => void;
	setExecutionGroupToMan: React.Dispatch<React.SetStateAction<ExecutionGroup | undefined>>;
}

function OrganizerExecutionGroupMan({ event, executiongroup, exerciseTypes, groupUpdate, setExecutionGroupToMan }: props) {
	const alert = useAlert();
	const auth = useAuth();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [expandToAdd, setExpandToAdd] = useState(false);
	const [competitions, setCompetitions] = useState<CompetitionWithAthlete[]>([]);
	const [selectedCompetition, setSelectedCompetition] = useState<CompetitionWithAthlete>();
	const [athletesToAdd, setAthletesToAdd] = useState<(AthleteClass | TeamClass)[]>([]);
	const [athletesToExpand, setAthletesToExpand] = useState<number[]>([]);
	const [athleteFilterOnCompetition, setAthleteFilterOnCompetition] = useState("");
	const [athleteFilterOnGroup, setAthleteFilterOnGroup] = useState("");
	const [competitionFilter, setCompetitionFilter] = useState("");

	const getId = (a: AthleteClass | TeamClass) => {
		return ("firstName" in a) ? a.id : a.id * -1
	}

	useEffect(() => {
		const getData = async (executiongroup: ExecutionGroup) => {
			const competitionsFromServer = await get.competitionsByEvent(event.id).then(r => responseGetJson(r, [])) as Competition[];
			const promise = competitionsFromServer.map(async (competition) => {
				let ret: CompetitionWithAthlete = { ...competition };

				const athletes = await get.athletesByCompetition(competition.id, undefined, ["Owner"]).then(r => responseGetJson(r, [])) as Athlete[];
				const teams = await get.teamsByCompetition(competition.id, undefined, ["Owner"]).then(r => responseGetJson(r, [])) as Team[];
				const exercises = await get.excercisesByCompetition(competition.id, undefined, undefined, undefined, ["ExecutionGroup"]).then(r => responseGetJson(r, [])) as Exercise[];
				let athletesClass;
				if (competition.type !== COMP_TYPE.Collective)
					athletesClass = athletes.map(ath => new AthleteClass(ath, exercises.filter(exe => exe.athleteId === ath.id && !exe.executionGroup)));
				else
					athletesClass = teams.map(team => new TeamClass(team, exercises.filter(exe => exe.teamId === team.id && !exe.executionGroup)));

				ret.athletes = [...athletesClass];
				return ret
			});

			const competitionsBuild = await Promise.all(promise);
			setCompetitions(competitionsBuild)

			//Preset athletes to add (took the athletes in the group)
			//Get all unique athlete ids already present in group	
			const athletesIds = executiongroup.exercises?.map(exe => exe.athleteId ?? (exe.teamId * -1)).filter((id, index, array) => array.indexOf(id) === index);
			const allAthletes = competitionsBuild.map(c => c.athletes ?? []).flat()
				.filter((athlete, index, array) => !array.map(a => getId(a))
					.includes((("firstName" in athlete) ? athlete.id : athlete.id * -1), index + 1));

			const athletesToAdd = allAthletes.filter(athlete => athletesIds?.includes(getId(athlete)))
				.map(athlete => {
					let ret;
					if ("firstName" in athlete)
						ret = new AthleteClass(athlete, executiongroup.exercises?.filter(exe => exe.athleteId === athlete.id));
					else
						ret = new TeamClass(athlete, executiongroup.exercises?.filter(exe => exe.teamId === athlete.id));

					return ret
				});	//Athlete with exercises composition

			setAthletesToAdd(athletesToAdd);
			setLoading(false);
		}

		if (executiongroup) {
			getData(executiongroup);
			document.body.style.overflow = 'hidden';
		} else
			document.body.style.overflow = 'unset';

		return () => {
			document.body.style.overflow = 'unset';
		}
	}, [executiongroup]);

	const close = () => {
		setAthletesToExpand([]);
		setAthletesToAdd([]);
		setCompetitions([]);
		setSelectedCompetition(undefined);
		setExecutionGroupToMan(undefined);
		setAthleteFilterOnCompetition("");
		setAthleteFilterOnGroup("");
		setCompetitionFilter("");
		setLoading(true);
		setExpandToAdd(false);
	}

	const findId = (ath: AthleteClass | TeamClass, exe: Exercise) => {
		return ("firstName" in ath) ? exe.athleteId : exe.teamId;
	}

	useEffect(() => {
		const update = async () => {
			if (!executiongroup)
				return
			const exercises = athletesToAdd.map(athlete => athlete.exercises ?? []).flat();
			const resp = await put.executionGroupAddExercises(executiongroup.id, exercises.map(exe => exe.id));
			handleResponse(
				resp, auth, alert, navigate, "Errore durante l'inserimento degli esercizi", undefined,
				() => groupUpdate(executiongroup.id, exercises)
			)
		}
		update();
	}, [athletesToAdd]);

	const save = async () => {
		if (!executiongroup)
			return
		const exercises = athletesToAdd.map(athlete => athlete.exercises ?? []).flat();
		const resp = await put.executionGroupAddExercises(executiongroup.id, exercises.map(exe => exe.id));
		handleResponse(
			resp, auth, alert, navigate,
			"Errore durante l'inserimento degli esercizi",
			undefined,
			() => {
				groupUpdate(executiongroup.id, exercises);
				close();
			}
		)
	}

	const allExercisesRequest = (side: string, exercises?: Exercise[]) => {
		if (!exercises)
			return

		let newCompetitions = [...competitions];
		let newAthletesToAdd = [...athletesToAdd];

		exercises.forEach(exercise => {
			const indexCo = competitions.findIndex(competition => competition.id === exercise.competitionId);
			if (indexCo === -1)
				return

			const indexAt = competitions[indexCo].athletes?.findIndex(athlete => athlete.id === findId(athlete, exercise));
			if (indexAt === -1 || indexAt === undefined)
				return

			let newAthletes = [...newCompetitions[indexCo].athletes ?? []];

			if (side === "AGGIUNGI") {
				const exercisesToAdd = newAthletes[indexAt].exercises?.filter(fExe => fExe.id !== exercise.id);

				newAthletes[indexAt].exercises = [...exercisesToAdd ?? []];
				newCompetitions[indexCo].athletes = newAthletes;

				const athleteToAdd = newAthletesToAdd.find(athlete => athlete.id === findId(athlete, exercise));
				if (athleteToAdd) {
					newAthletesToAdd[newAthletesToAdd.indexOf(athleteToAdd)].exercises = [...newAthletesToAdd[newAthletesToAdd.indexOf(athleteToAdd)].exercises ?? [], exercise];
				} else {
					const toAdd = ("firstName" in newAthletes[indexAt]) ?
						new AthleteClass(newAthletes[indexAt] as AthleteClass, [exercise]) :
						new TeamClass(newAthletes[indexAt] as TeamClass, [exercise]);

					newAthletesToAdd = [...newAthletesToAdd, toAdd];
				}
			} else {
				const exercisesToRemove = [...newAthletes[indexAt].exercises ?? [], exercise];

				newAthletes[indexAt].exercises = [...exercisesToRemove ?? []];
				newCompetitions[indexCo].athletes = newAthletes;


				const athleteToRemove = newAthletesToAdd.find(athlete => athlete.id === findId(athlete, exercise));
				if (athleteToRemove) {
					newAthletesToAdd[newAthletesToAdd.indexOf(athleteToRemove)].exercises = newAthletesToAdd[newAthletesToAdd.indexOf(athleteToRemove)]
						.exercises?.filter(exe => exercise.id !== exe.id);

					if (newAthletesToAdd[athletesToAdd.indexOf(athleteToRemove)].exercises?.length === 0)
						newAthletesToAdd = newAthletesToAdd.filter(pAthlete => pAthlete.id !== athleteToRemove.id);
				}
			}

		});

		setCompetitions(newCompetitions);
		setAthletesToAdd(newAthletesToAdd);
		setAthletesToExpand([]);
	}

	const allAthletesRequest = () => {
		const exercises = selectedCompetition?.athletes?.athleteFilter(athleteFilterOnCompetition).map(ath => ath.exercises ?? []).flat();
		allExercisesRequest("AGGIUNGI", exercises);
	}

	const asociationAthletesRequest = (associationId: number) => {
		const exercises = selectedCompetition?.athletes?.filter(a => a.ownerId === associationId).athleteFilter(athleteFilterOnCompetition).map(ath => ath.exercises ?? []).flat();
		allExercisesRequest("AGGIUNGI", exercises);
	}

	const addExercise = (exercise: Exercise) => {
		const indexAs = competitions.findIndex(competition => competition.id === selectedCompetition?.id);
		if (indexAs === -1)
			return
		const indexAt = competitions[indexAs].athletes?.findIndex(athlete => athlete.id === findId(athlete, exercise));
		if (indexAt === -1 || indexAt === undefined)
			return

		let newCompetitions = [...competitions];
		let newAthletes = [...newCompetitions[indexAs].athletes ?? []];

		const newExercises = newAthletes[indexAt].exercises?.filter(fExe => fExe.id !== exercise.id);

		newAthletes[indexAt].exercises = [...newExercises ?? []];
		newCompetitions[indexAs].athletes = newAthletes;

		setCompetitions(newCompetitions);

		const athleteToAdd = athletesToAdd.find(athlete => athlete.id === findId(athlete, exercise));
		if (athleteToAdd) {
			let newAthletesToAdd = [...athletesToAdd];
			newAthletesToAdd[athletesToAdd.indexOf(athleteToAdd)].exercises = [...newAthletesToAdd[athletesToAdd.indexOf(athleteToAdd)].exercises ?? [], exercise];
			setAthletesToAdd(newAthletesToAdd);
		} else {
			const toAdd = ("firstName" in newAthletes[indexAt]) ?
				new AthleteClass(newAthletes[indexAt] as AthleteClass, [exercise]) :
				new TeamClass(newAthletes[indexAt] as TeamClass, [exercise]);
			setAthletesToAdd(prev => [...prev, toAdd]);
		}
	}

	const removeExercise = async (exercise: Exercise) => {
		const indexCo = competitions.findIndex(competition => competition.id === exercise.competitionId);
		if (indexCo === -1)
			return
		const indexAt = competitions[indexCo].athletes?.findIndex(athlete => athlete.id === findId(athlete, exercise));
		if (indexAt === -1 || indexAt === undefined)
			return

		let newCompetitions = [...competitions];
		let newAthletes = [...newCompetitions[indexCo].athletes ?? []];

		newAthletes[indexAt].exercises = [...newAthletes[indexAt].exercises ?? [], exercise];
		newCompetitions[indexCo].athletes = newAthletes;

		setCompetitions(newCompetitions);

		const athleteToRemove = athletesToAdd.find(athlete => athlete.id === findId(athlete, exercise));
		if (athleteToRemove) {
			let newAthletesToAdd = [...athletesToAdd];
			const newExercises = newAthletesToAdd[athletesToAdd.indexOf(athleteToRemove)].exercises?.filter(exe => exe.id !== exercise.id);
			newAthletesToAdd[newAthletesToAdd.indexOf(athleteToRemove)].exercises = newExercises;

			if (newAthletesToAdd[newAthletesToAdd.indexOf(athleteToRemove)].exercises?.length === 0)
				setAthletesToAdd(prev => [...prev.filter(pAthlete => pAthlete.id !== athleteToRemove.id)]);
			else
				setAthletesToAdd(newAthletesToAdd);
		}
	}

	const athleteExpandRequest = (athleteId: number) => {
		const newAthletes: number[] = [];
		if (athletesToExpand.includes(athleteId))
			setAthletesToExpand(prev => prev.filter(x => x !== athleteId));
		else
			setAthletesToExpand([...newAthletes, athleteId]);

	}

	const associations = selectedCompetition?.athletes?.athleteFilter(athleteFilterOnCompetition)
		.map(a => a.owner ?? initAssociation).filter((a, index, array) => !array.map(a2 => a2.id).includes(a.id, index + 1))
		.associationFilter() ?? [];

	const competitionsToAddIds = athletesToAdd.map(a => a.exercises?.map(e => e.competitionId ?? 0) ?? []).flat()
		.filter((n, index, array) => !array.includes(n, index + 1) && n !== undefined && n !== 0);
	const competitionsToAdd = competitions.filter(c => competitionsToAddIds.includes(c.id));

	return (
		<div
			className={'organizer-executiongroupman-main-container' + (executiongroup ? " show" : "")}
			onClick={(e) => {
				if (e.target === e.currentTarget)
					close();
			}}>
			{
				loading ? (
					<LoadingSpinner />
				) : (
					<div className={'organizer-executiongroupman-container' + (executiongroup ? " show" : "")}>
						{/** Header */}
						<div className='organizer-executiongroupman-container-header'>
							<div className='organizer-executiongroupman-container-header-name'>{executiongroup?.name}</div>
							<button className='organizer-executiongroupman-container-header-close' onClick={close}>
								<FontAwesomeIcon icon={faClose} />
							</button>
						</div>
						{/** This div will contain on left the selection container, on right the athletes selected */}
						<div className='organizer-executiongroupman-container-management'>
							{/** This container will collapse when a competition has been selected */}
							<div
								className={`organizer-executiongroupman-container-competitions-selection${!selectedCompetition ? " show" : ""}${!expandToAdd ? " expand" : ""}`}>
								<div className='organizer-executiongroupman-container-competition-head'>Seleziona una competizione</div>
								<SearchInput
									className='organizer-executiongroupman-container-search-athletes'
									onChange={(e) => setCompetitionFilter(e.target.value)}
									placeholder="Cerca competizione"
									value={competitionFilter}
								/>
								{competitions.filter(c => c.name.toLowerCase().includes(competitionFilter.toLowerCase())).map(competition => {
									return (
										<div
											className='organizer-executiongroupman-container-competition'
											onClick={() => setSelectedCompetition(competition)}
											key={competition.id}
										>
											<div className='organizer-executiongroupman-container-competition-name'>{competition.name}</div>
											<div className='organizer-executiongroupman-container-competition-count'>{competition.athletes?.map(ath => ath.exercises).flat().length ?? 0}</div>
										</div>
									)
								})}
							</div>
							{/** This container will collapse when competition selected is undefined */}
							<div
								className={`organizer-executiongroupman-container-athletes-selection${selectedCompetition ? " show" : ""}${!expandToAdd ? " expand" : ""}`}>
								<div onClick={() => setSelectedCompetition(undefined)} className='organizer-executiongroupman-container-selected-competition-name'>
									<FontAwesomeIcon icon={faArrowLeft} />
									{selectedCompetition?.name}
								</div>
								<SearchInput
									className='organizer-executiongroupman-container-search-athletes'
									onChange={(e) => setAthleteFilterOnCompetition(e.target.value)}
									placeholder="Cerca atleta"
									value={athleteFilterOnCompetition}
								/>
								<div className='organizer-executiongroupman-container-selected-competition-athletes'>
									{
										associations.map(association => {
											return (
												<div key={association.id}>
													<button
														className='organizer-executiongroupman-container-select-association-button'
														onClick={() => asociationAthletesRequest(association.id)}>
														<FontAwesomeIcon icon={faPlus} />
														<span style={{ marginRight: "7px" }}>
															{selectedCompetition?.athletes?.filter(a => a.ownerId === association.id).athleteFilter(athleteFilterOnCompetition).length}
														</span>
														<strong>{association.name}</strong>
													</button>
													{
														selectedCompetition?.athletes?.filter(a => a.ownerId === association.id).athleteFilter(athleteFilterOnCompetition).map(athlete => {
															return (
																<AthleteWithExercisesComponent
																	allExercisesRequest={allExercisesRequest}
																	athlete={athlete}
																	buttonClassName="AGGIUNGI"
																	exerciseTypes={exerciseTypes}
																	key={`Add${athlete.id}`}
																	request={addExercise}
																	selectedAthlete={athleteExpandRequest}
																	show={athletesToExpand.includes(athlete.id)}
																	showAssociation={false}
																/>
															)
														})
													}
												</div>
											)
										})
									}
								</div>
								<button
									className='organizer-executiongroupman-container-select-all-button'
									onClick={allAthletesRequest}
								>Seleziona tutti</button>
							</div>
							{/** This container will show all the athletes selected */}
							<div className='organizer-executiongroupman-container-selected-athletes-container'>
								<div className='organizer-executiongroupman-container-selected-athletes-container-head' onClick={() => setExpandToAdd(!expandToAdd)}>
									<button className='organizer-executiongroupman-container-selected-athletes-container-expand' >
										<FontAwesomeIcon icon={expandToAdd ? faChevronUp : faChevronDown} />
									</button>
									<div className='organizer-executiongroupman-container-selected-athletes-container-head-name'>{executiongroup?.name}</div>
									<div className='organizer-executiongroupman-container-selected-athletes-container-head-count'>{athletesToAdd.length}</div>
								</div>
								<div className={`organizer-executiongroupman-container-selected-athletes${expandToAdd ? " expand" : ""}`}>
									<SearchInput
										onChange={(e) => setAthleteFilterOnGroup(e.target.value)}
										placeholder="Cerca atleta"
										style={{ margin: "10px 0" }}
										value={athleteFilterOnGroup}
									/>
									{
										competitionsToAdd.sortCompetitions().map(competition => (
											<div key={competition.id}>
												<div className='organizer-executiongroupman-container-selected-competition-toadd-name'>{competition.name}</div>
												{athletesToAdd.athleteFilter(athleteFilterOnGroup)
													.filter(a => a.exercises?.map(e => e.competitionId).includes(competition.id)).map(athlete => {
														let athleteToLoad: AthleteClass | TeamClass;
														if ("firstName" in athlete)
															athleteToLoad = new AthleteClass(athlete, athlete.exercises?.filter(e => e.competitionId === competition.id), athlete.groupId);
														else
															athleteToLoad = new TeamClass(athlete, athlete.exercises?.filter(e => e.competitionId === competition.id), athlete.groupId);
														return (
															<AthleteWithExercisesComponent
																allExercisesRequest={allExercisesRequest}
																athlete={athleteToLoad}
																buttonClassName="RIMUOVI"
																exerciseTypes={exerciseTypes}
																key={`Remove${athlete.id}`}
																request={removeExercise}
																selectedAthlete={athleteExpandRequest}
																show={athletesToExpand.includes(athlete.id)}
																showAssociation
															/>
														)
													})}
											</div>
										))
									}
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
	)
}

export default OrganizerExecutionGroupMan