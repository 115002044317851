import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { post } from '../../../models/backendReq';
import { Championship, handleResponse, initChampionship } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerChampionshipsAddRequest.scss';

interface props {
	addCompleted: (championship: Championship) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerChampionshipsAddRequest({ show, addCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inesrire il nome per il campionato" };

	let [championshipToAdd, setChampionshipToAdd] = useState<Championship>(initChampionship);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const firstFocusRef = useRef<HTMLInputElement>(null);

	let denied = () => {
		setChampionshipToAdd(initChampionship);
		show[1](false);
	}

	let addChampionship = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (!championshipToAdd.name || championshipToAdd.name === "") {
			_errors.push(inputNameError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await post.championship(championshipToAdd.name)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante l'inserimento del campionato",
					"Campionato creato correttamente",
					(data) => {
						addCompleted(data);
						setChampionshipToAdd(initChampionship);
					},
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci nuovo campionato' submit={addChampionship}>
			<p className='organizer-championship-add-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-championship-add-request-form-input'
				type='text'
				style={{ resize: 'vertical' }}
				value={championshipToAdd.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setChampionshipToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerChampionshipsAddRequest;