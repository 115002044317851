import { useContext, useEffect, useRef, useState } from 'react';

import { Athlete, Exercise, ExerciseType, getSum, initCompetition, Team } from '../../../models/models';

import ClassificationExerciseType from '../../../components/Classifications/ClassificationExerciseType';

import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import ClassificationBuilderCom from '../../../components/Classifications/ClassificationBuilderCom';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../contexts/EventEoContext';
import { useAuth, USER_TYPE } from '../../../models/auth/AuthProvider';
import { COMP_TYPE } from '../../../models/backendReq';
import { downloadPDFInWorker } from '../../../models/PDF/Worker/downloadPDFInWorker';
import { toolBarElements } from '../SubNavBarLinks';
import './ClassificationCompetitionTypeScreen.scss';
import { usePDFDownload } from '../../../models/DownloadPDFProvider';

interface extData {
	id: number;
	data: {
		exercise: Exercise;
		athlete?: Athlete | Team;
		team?: Team;
		hide?: boolean;
	}[];
	exerciseType: ExerciseType;
}

function ClassificationCompetitionScreen() {
	const eventContext = useContext(EventEoContext);
	const pdf = usePDFDownload();

	const params = useParams();

	const [data, setData] = useState<extData[]>([]);
	const [competitionRule, setCompetitionRule] = useState(0);
	const [watermarkFile, setWatermarkFile] = useState<FileList | null>();
	const [showClassification, setShowClassification] = useState(false);
	const [athletesToHide, setAthletesToHide] = useState<number[]>([]);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const competitionId = parseInt(params.competitionId ?? "");
	const competition = eventContext.competitions.value.find(c => c.id === competitionId) ?? initCompetition;

	const exerciseTypes = eventContext.associatedexercisetypes.value.filter(x => x.competitionId === competitionId);
	const athletes = eventContext.athletes.value.filter(x => x.competitionId === competitionId);
	const exercises = eventContext.exercises.value.filter(x => x.competitionId === competitionId);
	const rosters = eventContext.rosters.value.filter(x => x.competitionId === competitionId);
	const teams = eventContext.teams.value.filter(x => x.competitionId === competitionId);

	let auth = useAuth();

	useEffect(() => {
		if (!showClassification) {
			setData([]);
			return;
		}
		let data = exerciseTypes.map((exerciseType, index) => {
			let exesFiltered = exercises.filter(x => x.typeId === exerciseType.id).sort((a, b) => getSum(b.scores, exerciseType.scoreComposition) - getSum(a.scores, exerciseType.scoreComposition));


			let exeST = exesFiltered.map(exercise => {
				const athlete = () => {
					if (competition.type === COMP_TYPE.Collective)
						return teams.find(x => x.id === exercise.teamId);
					else
						return athletes.find(x => x.id === exercise.athleteId);
				}
				const hide = athletesToHide.includes(athlete()?.id ?? 0)
				const team: Team | undefined = rosters.find(x => x.athletes.map(at => at.id).includes(exercise.athleteId))?.team;

				return { exercise, athlete: athlete(), team, hide }
			})
			return { id: index, data: exeST, exerciseType: exerciseType }
		})

		setData(data);

	}, [showClassification, eventContext.exercises.value, athletesToHide])

	const PDFGeneration = () => {
		const pdfProps = {
			data,
			event: eventContext.event,
			files: watermarkFile,
			competition,
			rule: competitionRule
		};
		const fileName = "Classifica attrezzi - " + competition.name.trim() + ".pdf";
		pdf.download("ExerciseTypeClassification", pdfProps, fileName);
	}

	const _toolBarElements: eoToolbarProp[] = []
	if (showClassification)
		_toolBarElements.push(
			{
				...toolBarElements.downloadPDFElement,
				callback: PDFGeneration,
			});

	if (auth.user.type === USER_TYPE.USER)
		_toolBarElements.push(
			{
				...toolBarElements.watermarkElement,
				text: "Carica filigrana",
				selected: watermarkFile?.length! > 0,
				callback: () => {
					if (watermarkFile?.length! > 0)
						setWatermarkFile(undefined);
					else
						fileInputRef.current?.click();
				},
			});

	const hideReq = (id: number) => {
		setAthletesToHide(prev => prev.includes(id) ? prev.filter(n => n !== id) : [...prev, id]);
	};

	return (
		<OrganizerEventTable text='CLASSIFICHE PER ATTREZZO' >
			<EoToolBar elements={_toolBarElements} />
			<ClassificationBuilderCom
				competitionRule={competitionRule}
				showClassification={showClassification}
				setCompetitionRule={setCompetitionRule}
				setShowClassification={setShowClassification} useCompetitionRule />
			{athletesToHide.length > 0 && <div className='clssification-competition-warning-hidden-container'>
				<FontAwesomeIcon icon={faWarning} />
				<span>Alcuni atleti sono nascosti dalla classifica</span>
				<button onClick={() => setAthletesToHide([])}>Mostra tutti</button>
			</div>}
			<div className='clssification-competitionet-container'>
				<div className='clssification-competitionet-name-top'>
					<div>{competition.name}</div>
					{athletes.length > 0 && <div>{`Atleti: ${athletes.length}`}</div>}
				</div>
				<input
					accept="image/jpeg, image/png, image/gif"
					multiple={false}
					onChange={e => setWatermarkFile(e.target.files)}
					ref={fileInputRef}
					style={{ display: 'none' }}
					type='file'
				/>
				<div className='classification-exercisetypes-container'>
					{showClassification && data.map(data => {

						return (
							<ClassificationExerciseType key={data.id} data={data} competitionRule={competitionRule} hideReq={hideReq} />
						)
					})}
				</div>
			</div>
		</OrganizerEventTable>
	)
}

export default ClassificationCompetitionScreen