import { faCheckSquare, faChevronDown, faChevronUp, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import OrganizerCompetitionAthleteSetupCom from '../../../../components/Organizer/CompetitionSetup/OrganizerCompetitionAthleteSetupCom';
import { Association, Athlete, Competition, ExerciseType } from '../../../../models/models';
import './OrganizerCompetitionSetupSectionAthletes.scss';
import '../../../../utility/prototype';

interface associationProps extends props {
	association: Association;
}
function AssociationCom({
	association,
	athletes,
	associatedExerciseTypes,
	competition,
	deleteAthlete,
	transferAthlete,
	enableSelect,
	athletesSelected,
	setAthletesSelected,
	expandRequest,
}: associationProps) {
	let [showAthletes, setShowAthletes] = useState(false);

	useEffect(() => {
		if (expandRequest && expandRequest > 0)
			setShowAthletes(true);
		else
			setShowAthletes(false);

	}, [expandRequest])
	const athletesToShow = athletes.filter(x => x.owner?.id === association.id);
	const allSelected = athletesSelected?.filter(x => x.owner?.id === association.id).length === athletesToShow.length;

	const show = showAthletes;

	const headerClick = () => {
		if (!enableSelect)
			setShowAthletes(!showAthletes)
	}
	const allSelect = () => {
		setAthletesSelected && setAthletesSelected(prev => {
			if (allSelected)
				return prev.filter(athlete => athlete.ownerId !== association.id);
			else
				return [...prev, ...athletesToShow.filter(x => !prev.map(y => y.id).includes(x.id))]

		})
	}
	return (
		<div className='organizer-competition-athlete-association-container'>
			<div onClick={headerClick} className={`organizer-competition-athlete-association-name${enableSelect ? " hasSelect" : ""}`}>
				{enableSelect && <FontAwesomeIcon onClick={allSelect} icon={allSelected ? faCheckSquare : faSquare} />}
				<FontAwesomeIcon onClick={() => setShowAthletes(!showAthletes)} icon={show ? faChevronUp : faChevronDown} />
				<span>{association.name}</span>
				<span>{athletesToShow.length === 1 ? "1 iscritto" : athletesToShow.length + ' iscritti'}</span>
			</div>
			<div key={association.id} className={"organizer-competition-athlete-athletes-container" + (show ? " show" : "")}>
				{(athletesToShow.athleteFilter().map((athlete) => {
					return (
						<OrganizerCompetitionAthleteSetupCom
							key={athlete.id}
							athlete={athlete}
							competition={competition}
							associatedExerciseTypes={associatedExerciseTypes}
							isIndividual={true}
							deleteAthlete={deleteAthlete}
							transferAthlete={transferAthlete}
							enableSelect={enableSelect}
							athletesSelected={athletesSelected}
							setAthletesSelected={setAthletesSelected}
						/>
					);
				}))}
			</div>
		</div>

	)
}

interface props {
	associatedExerciseTypes: ExerciseType[];
	athletes: Athlete[];
	competition: Competition;
	deleteAthlete: (athlete: Athlete) => void;
	transferAthlete: (sourceCompetition: Competition, athlete: Athlete) => void;
	enableSelect?: boolean;
	athletesSelected?: Athlete[];
	setAthletesSelected?: React.Dispatch<React.SetStateAction<Athlete[]>>;
	expandRequest?: number;
}

function OrganizerCompetitionSetupSectionAthletes({
	athletes,
	associatedExerciseTypes,
	competition,
	deleteAthlete,
	transferAthlete,
	enableSelect,
	athletesSelected,
	setAthletesSelected,
	expandRequest
}: props) {

	let associations = athletes.map(x => x.owner as Association);
	const ids = associations.map(o => o?.id);
	const filtered = associations.filter(({ id }, index) => !ids.includes(id, index + 1));

	return (
		<div>
			{
				filtered.associationFilter().map(association => {
					return (
						<AssociationCom
							key={association.id}
							associatedExerciseTypes={associatedExerciseTypes}
							association={association}
							athletes={athletes}
							competition={competition}
							deleteAthlete={deleteAthlete}
							transferAthlete={transferAthlete}
							enableSelect={enableSelect}
							athletesSelected={athletesSelected}
							setAthletesSelected={setAthletesSelected}
							expandRequest={expandRequest}
						/>
					)
				})}
		</div>
	)
}

export default OrganizerCompetitionSetupSectionAthletes