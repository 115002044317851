import { Team } from '../../models/models';

import './OrganaizerTeamCom.scss';

import TableNameCell from './TableComponents/TableNameCell';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';

interface props {
	team: Team;
	del: (eventuser: Team) => void;
	edi: (eventuser: Team) => void;
}

function OrganaizerTeamCom({ team, del, edi }: props) {
	let editReq = () => {
		edi(team);
	}

	let deleteReq = () => {
		del(team);
	}

	return (
		<tr>
			<TableNameCell text={team.name} />
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />
		</tr>
	);
}

export default OrganaizerTeamCom;