import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { ExecutionTurn, handleResponse } from '../../../models/models';
import './OrganizerExecutionTurnsDeleteRequest.scss';

interface props {
	executionturns?: ExecutionTurn;
	deletionCompleted: (executionturns: ExecutionTurn) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerExecutionTurnsDeleteRequest({ executionturns, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteExecutionTurn = async (e: React.FormEvent) => {
		if (!executionturns) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.executionTurn(executionturns.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione del turno",
					"Turno cancellato",
					() => deletionCompleted(executionturns)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE IL TURNO" submit={deleteExecutionTurn}>
			<p className='organizer-executionturns-delete-request-form-executionturns-name'>{executionturns?.name}</p>
		</CrudForm>
	);
}

export default OrganizerExecutionTurnsDeleteRequest;