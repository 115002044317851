import { useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import EventEoContext, { AthleteWithCompetition, ExerciseTypeWithCompetition, ExerciseWithCompetition, RankingConfigurationWithCompetition, TeamWithCompetition } from '../../../contexts/EventEoContext';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';
import { useAuth } from '../../../models/auth/AuthProvider';
import { get } from '../../../models/backendReq';
import { ScoresHubConnection } from '../../../models/hubs/ScoresHubConnection';
import { Association, Athlete, Competition, Event, ExecutionTurn, Exercise, ExerciseType, FloorMusicMetadata, Jury, RankingConfiguration, Roster, Team, User, initEvent, responseGetJson } from '../../../models/models';
import { useStateLocation } from '../../../models/StateLocationProvider';
import { crudBuilder } from '../../../utility/UtilityFunctions';

function OrganizerEventLayout() {
	const configurationsContext = useContext(OrganizerConfigurationsContext);

	const params = useParams();
	const location = useStateLocation();
	const auth = useAuth();

	const eventId = parseInt(params.eventId ?? "");
	const competition = location.read<Competition>("competition");
	const executionturn = location.read<ExecutionTurn>("executionturn");

	const [event, setEvent] = useState<Event>(initEvent);
	const [athletes, setAthletes] = useState<AthleteWithCompetition[]>([]);
	const [teams, setTeams] = useState<TeamWithCompetition[]>([]);
	const [competitions, setCompetitions] = useState<Competition[]>([]);
	const [executionturns, setExecutionturns] = useState<ExecutionTurn[]>([]);
	const [eventusers, setEventUsers] = useState<User[]>([]);
	const [users, setUsers] = useState<User[]>([]);
	const [juries, setJuries] = useState<Jury[]>([]);
	const [associations, setAssociations] = useState<Association[]>([]);
	const [exercises, setExercises] = useState<ExerciseWithCompetition[]>([]);
	const [rosters, setRosters] = useState<Roster[]>([]);
	const [associatedexercisetypes, setAssociatedexercisetypes] = useState<ExerciseTypeWithCompetition[]>([]);
	const [rankingconfigurations, setRankingconfigurations] = useState<RankingConfigurationWithCompetition[]>([]);
	const [floormusics, setFloorMusics] = useState<FloorMusicMetadata[]>([]);
	const [loading, setLoading] = useState(true);
	const [progress, setProgress] = useState(0);

	const exercisetypes = configurationsContext.exercisetypes.value;

	let getExercises = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseExercises = competition
				.map(async (_competition) => await get.excercisesByCompetition(_competition.id, undefined, undefined, undefined, ["ExecutionGroup", "Athlete", "Roster"])
					.then(async (r) => (await responseGetJson(r, []) as Exercise[])
						.map(data => ({ ...data, competition: _competition, competitionId: _competition.id }))) as ExerciseWithCompetition[]);
			const allExercisesFromServer = (await Promise.all(promiseExercises)).flat();
			setExercises(allExercisesFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const exercisesFromServer = await get.excercisesByCompetition(competition.id, undefined, undefined, undefined, ["ExecutionGroup", "Athlete", "Roster"])
				.then(r => responseGetJson(r, [])) as Exercise[];
			setExercises(p => [...p.filter(e => e.competitionId !== competition.id), ...exercisesFromServer.map(e => ({ ...e, competition: competition }))]);
		}
	}

	let getAthletes = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseAthletes = competition
				.map(async (_competition) => await get.athletesByCompetition(_competition.id, undefined, ["Owner"])
					.then(async (r) => (await responseGetJson(r, []) as Athlete[])
						.map(data => ({ ...data, competition: _competition, competitionId: _competition.id }))) as AthleteWithCompetition[]);
			const allAthletesFromServer = (await Promise.all(promiseAthletes)).flat();
			setAthletes(allAthletesFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const athletesFromServer = await get.athletesByCompetition(competition.id, undefined, ["Owner"])
				.then(r => responseGetJson(r, [])) as Athlete[];
			setAthletes(p => [
				...p.filter(a => a.competition.id !== competition.id),
				...athletesFromServer.map(a => ({ ...a, competition, competitionId: competition.id }))]);
		}
	}

	let getTeams = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseTeams = competition
				.map(async (_competition) => await get.teamsByCompetition(_competition.id, undefined, ["Owner"])
					.then(async (r) => (await responseGetJson(r, []) as Team[])
						.map(data => ({ ...data, competition: _competition, competitionId: _competition.id }))) as TeamWithCompetition[]);
			const allTeamsFromServer = (await Promise.all(promiseTeams)).flat();
			setTeams(allTeamsFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const teamsFromServer = await get.teamsByCompetition(competition.id, undefined, ["Owner"])
				.then(r => responseGetJson(r, [])) as Team[];
			setTeams(p => [
				...p.filter(t => t.competition.id !== competition.id),
				...teamsFromServer.map(t => ({ ...t, competition, competitionId: competition.id }))]);
		}
	}

	let getRosters = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseRosters = competition
				.map(async (_competition) => await get.rostersByCompetition(_competition.id)
					.then(async (r) => (await responseGetJson(r, []) as Roster[])
						.map(data => ({ ...data, competition: _competition }))) as Roster[]);
			const allRostersFromServer = (await Promise.all(promiseRosters)).flat();
			setRosters(allRostersFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const rostersFromServer = await get.rostersByCompetition(competition.id)
				.then(r => responseGetJson(r, [])) as Roster[];
			setRosters(p => [...p.filter(r => r.competitionId !== competition.id), ...rostersFromServer.map(r => ({ ...r, competition: competition }))]);
		}
	}

	let getAssociatedExerciseTypes = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseExerciseTypes = competition
				.map(async (_competition) => await get.excerciseTypesByCompetition(_competition.id, ["ScoreComposition"])
					.then(async (r) => (await responseGetJson(r, []) as ExerciseType[])
						.map(data => ({ ...data, competition: _competition, competitionId: _competition.id }))) as ExerciseTypeWithCompetition[]);
			const allExerciseTypesFromServer = (await Promise.all(promiseExerciseTypes)).flat();
			setAssociatedexercisetypes(allExerciseTypesFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const exerciseTypesFromServer = await get.excerciseTypesByCompetition(competition.id, ["ScoreComposition"])
				.then(r => responseGetJson(r, [])) as ExerciseType[];
			setAssociatedexercisetypes(p => [
				...p.filter(e => e.competition.id !== competition.id),
				...exerciseTypesFromServer.map(e => ({ ...e, competition, competitionId: competition.id }))
			]);
		}
	}

	let getRankingConfigurations = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseRankingConfigurations = competition
				.map(async (_competition) => await get.rankingConfigurations(_competition.id)
					.then(async (r) => ({ ...(await responseGetJson(r, []) as RankingConfiguration), competition: _competition, competitionId: _competition.id })));
			const allRankingConfigurationsFromServer = await Promise.all(promiseRankingConfigurations);
			setRankingconfigurations(allRankingConfigurationsFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const rankingConfigurationsFromServer = await get.rankingConfigurations(competition.id)
				.then(r => responseGetJson(r, [])) as RankingConfiguration;
			setRankingconfigurations(p => [
				...p.filter(e => e.competition.id !== competition.id),
				{ ...rankingConfigurationsFromServer, competition, competitionId: competition.id }
			]);
		}
	}

	let getCompetitions = async () => {
		let competitionsFromServer = await get.competitionsByEvent(eventId, ["Level"])
			.then(r => responseGetJson(r, [])) as Competition[];
		setCompetitions(competitionsFromServer);

		await getAthletes(competitionsFromServer).then(() => setProgress(10));
		await getTeams(competitionsFromServer).then(() => setProgress(20));
		await getExercises(competitionsFromServer).then(() => setProgress(30));
		await getRosters(competitionsFromServer).then(() => setProgress(40));
		await getAssociatedExerciseTypes(competitionsFromServer).then(() => setProgress(50));
		await getRankingConfigurations(competitionsFromServer).then(() => setProgress(60));
	};

	let getExecutionTurns = async () => {
		let executionTurnsFromServer: ExecutionTurn[] = await get.executionTurnsByEvent(eventId, ["Groups"])
			.then(r => responseGetJson(r, []));
		setExecutionturns(executionTurnsFromServer);
	};

	let getUsers = async () => {
		let eventusersFromServer = await get.usersInEvent(eventId)
			.then(r => responseGetJson(r, []));
		if (auth.user.info.userName === "Admin") {
			let usersFromServer = await get.users()
				.then(r => responseGetJson(r, []));
			setUsers(usersFromServer);
		}
		setEventUsers(eventusersFromServer);
	};

	let getJuries = async () => {
		let juriesFromServer = await get.juries(eventId, ["Judges"])
			.then(r => responseGetJson(r, []));
		setJuries(juriesFromServer);
	};

	let getAssociations = async (ownerId: number) => {
		if (auth.user.isAssociation && !auth.user.isOrganizer) {
			setAssociations(auth.user.associations);
			return;
		}
		let associationsFromServer = await get.eventOrganizerAssociations(ownerId)
			.then(r => responseGetJson(r, []));
		setAssociations(associationsFromServer);
	};

	let getFloorMusics = async () => {
		let floormusicsFromServer = await get.getFloormusicEventMetadata(eventId, ["Association"])
			.then(r => responseGetJson(r, []));

		setFloorMusics(floormusicsFromServer);
	}

	useEffect(() => {
		const fetchData = async () => {
			const eventFromServer = await get.event(eventId).then(r => responseGetJson(r)) as Event;
			setEvent(eventFromServer);

			await getCompetitions().then(() => setProgress(65));
			await getExecutionTurns().then(() => setProgress(70));
			await getUsers().then(() => setProgress(75));
			await getJuries().then(() => setProgress(85));
			await getAssociations(eventFromServer.ownerId).then(() => setProgress(95));
			await getFloorMusics().then(() => setProgress(100));
			setLoading(false);
		}
		fetchData();
	}, []);

	useEffect(() => {
		const connect = async () => !loading && await ScoresHubConnection.connect(competitions, setExercises);
		connect();
		return () => {
			const disconnect = async () => !loading && await ScoresHubConnection.disconnect(competitions);
			disconnect();
		}
	}, [competitions, loading]);

	const compAdded = (competition: Competition[] | Competition) => {
		if (Array.isArray(competition))
			setRankingconfigurations(p => [...p, ...competition.map(_c => ({ chartSections: [], competition: _c, competitionId: _c.id }))]);
		else
			setRankingconfigurations(p => [...p, { chartSections: [], competition: competition, competitionId: competition.id }])
	};

	const compRemoved = (competition: Competition[] | Competition) => {
		if (Array.isArray(competition))
			setRankingconfigurations(p => p.filter(rc => !competition.some(c => c.id === rc.competitionId)));
		else
			setRankingconfigurations(p => p.filter(rc => rc.competitionId !== competition.id))
	};

	const contextVal = {
		loading, event, progress,
		athletes: { value: athletes, crud: crudBuilder(setAthletes, getAthletes, undefined, "competitionId") },
		teams: { value: teams, crud: crudBuilder(setTeams, getTeams, undefined, "competitionId") },
		competitions: { value: competitions, crud: crudBuilder(setCompetitions, getCompetitions, undefined, undefined, compAdded, compRemoved) },
		executionturns: { value: executionturns, crud: crudBuilder(setExecutionturns, getExecutionTurns) },
		eventusers: { value: eventusers, crud: crudBuilder(setEventUsers, getUsers) },
		juries: { value: juries, crud: crudBuilder(setJuries, getJuries) },
		exercises: { value: exercises, crud: crudBuilder(setExercises, getExercises) },
		rosters: { value: rosters, crud: crudBuilder(setRosters, getRosters, ["teamId", "competitionId"]) },
		associatedexercisetypes: { value: associatedexercisetypes, crud: crudBuilder(setAssociatedexercisetypes, getAssociatedExerciseTypes, undefined, "competitionId") },
		rankingconfigurations: {
			value: rankingconfigurations,
			crud: crudBuilder(setRankingconfigurations, getRankingConfigurations, "competitionId", "competitionId")
		},
		floormusics: { value: floormusics, crud: crudBuilder(setFloorMusics, getFloorMusics) },
		users,
		exercisetypes,
		associations,
		lastCompetition: competition,
		lastExecutionTurn: executionturn,
	};

	return (
		<EventEoContext.Provider value={contextVal}>
			<Outlet />
		</EventEoContext.Provider>
	)
}

export default OrganizerEventLayout