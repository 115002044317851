import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { Team, handleResponse, initTeam } from '../../../models/models';
import './OrganizerCompetitionTeamsEditRequest.scss';

interface props {
	team?: Team;
	editCompleted: (team: Team) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerCompetitionTeamsEditRequest({ team, show, editCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per la squadra" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [teamToPut, setTeamToPut] = useState<Team>(team ? team : initTeam);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (team)
			setTeamToPut(team);
	}, [team]);

	let editTeam = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];
		if (teamToPut.name === "") {
			_errors.push(inputNameError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return


		await put.team(teamToPut.id, teamToPut.name)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica della squadra",
					"Squadra modificata correttamente",
					() => editCompleted(teamToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica la competizione" submit={editTeam}>
			<p className='organizer-team-edit-request-form-old-name'>{team?.name}</p>
			<p className='organizer-team-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-competition-team-add-request-form-input'
				style={{ resize: 'vertical' }}
				value={teamToPut.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setTeamToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerCompetitionTeamsEditRequest;