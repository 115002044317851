import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { downloadPDFInWorker } from './PDF/Worker/downloadPDFInWorker';
import './DownloadPDFProvider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFilePdf, faHourglass } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../components/LoadingSpinner';

interface DownloadPDFQueueItem {
	id: number;
	fileName: string;
	type: string;
	props: any;
};

interface DownloadPDFQueueState {
	queue: Array<DownloadPDFQueueItem>;
	downloading: boolean;
}

interface DownloadPDFContextProps {
	queue: Array<DownloadPDFQueueItem>;
	download: (type: string, props: any, fileName: string) => void;
}

const DownloadPDFContext = createContext<DownloadPDFContextProps | undefined>(undefined);

export const usePDFDownload = (): DownloadPDFContextProps => {
	const context = useContext(DownloadPDFContext);
	if (context === undefined) {
		throw new Error('useDownload must be used within a DownloadProvider');
	}
	return context;
};


interface DownloadPDFProviderProps {
	children: ReactNode;
}

export const DownloadPDFProvider: React.FC<DownloadPDFProviderProps> = ({ children }) => {
	const [queueState, setQueueState] = useState<DownloadPDFQueueState>({ downloading: false, queue: [] });


	const addToQueue = (file: DownloadPDFQueueItem) => {
		setQueueState((prevState) => ({
			...prevState,
			queue: [...prevState.queue, file],
		}));
	};

	const removeFromQueue = (id: number) => {
		setQueueState((prevState) => ({
			...prevState,
			queue: prevState.queue.filter(x => x.id !== id),
		}));
	};

	const download = (type: string, props: any, fileName: string) => {
		addToQueue({ fileName, id: Date.now(), props, type });
	};

	// Funzione per gestire il download di un singolo file
	const downloadFile = useCallback(async (file: DownloadPDFQueueItem) => {
		try {
			setQueueState((prevState) => ({
				...prevState,
				downloading: true,
			}));

			await downloadPDFInWorker([file.type, file.props], file.fileName);


			// Rimuovi il file dalla coda
			setQueueState((prevState) => ({
				queue: prevState.queue.filter((item) => item.id !== file.id),
				downloading: false,
			}));
		} catch (error) {
			console.error(`Download failed for ${file.fileName}`, error);
			setQueueState((prevState) => ({
				...prevState,
				downloading: false,
			}));
		}
	}, []);

	// Effetto per gestire il download dei file in coda
	useEffect(() => {
		if (!queueState.downloading && queueState.queue.length > 0) {
			const nextFile = queueState.queue[0];
			downloadFile(nextFile);
		}
	}, [queueState.downloading, queueState.queue, downloadFile]);

	return (
		<DownloadPDFContext.Provider value={{ queue: queueState.queue, download }}>
			{children}
			{queueState.queue.length > 0 && <div className='download-pdf-container'>
				{queueState.queue.map((q, index) =>
					<div className='download-pdf-element-container'>
						{index === 0 ? <LoadingSpinner /> : <FontAwesomeIcon icon={faHourglass} />}
						<FontAwesomeIcon icon={faFilePdf} />
						<span>{q.fileName}</span>
						{index !== 0 && <button onClick={() => removeFromQueue(q.id)}>
							<FontAwesomeIcon icon={faClose} />
						</button>}
					</div>)}
			</div>}
		</DownloadPDFContext.Provider>
	);
};
