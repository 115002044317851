import { faArrowLeft, faChevronDown, faChevronUp, faClose, faEllipsisVertical, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { get, put } from '../../../models/backendReq';
import { Championship, Competition, Event, HTTP_STATUS_CODES, handleResponse, responseGetJson } from '../../../models/models';
import '../../../utility/prototype';
import SearchInput from '../../Input/SearchInput';
import './OrganizerChampionshipMan.scss';
import LoadingSpinner from '../../LoadingSpinner';

function CompetitionComponent({ buttonClassName, competition, readOnly, request }: {
	buttonClassName: string;
	competition: Competition;
	readOnly: boolean;
	request: (competition: Competition) => void;
}) {
	let competitionClass = 'organizer-championshipman-container-competition';
	if (readOnly)
		competitionClass += ' empty'

	return (
		<div className={competitionClass} >
			<div className={'organizer-championshipman-container-competition-name ' + buttonClassName}>
				<FontAwesomeIcon icon={faEllipsisVertical} />
				<div className='organizer-championshipman-container-competition-name-info'>
					<div className='organizer-championshipman-container-competition-name-name'>{`${competition.name.toUpperCase()}`}</div>
				</div>
				{
					!readOnly ? (
						<button
							className={`organizer-championshipman-container-competition-allbutton ${buttonClassName}`}
							onClick={() => request(competition)}
						>
							<FontAwesomeIcon icon={buttonClassName === "AGGIUNGI" ? faPlus : faMinus} />
						</button>
					) : (
						<></>
					)
				}
			</div>
		</div>
	)
}

interface props {
	championship?: Championship;
	setChampionshipToMan: React.Dispatch<React.SetStateAction<Championship | undefined>>;
}

function OrganizerChampionshipMan({ championship, setChampionshipToMan }: props) {
	const alert = useAlert();
	const auth = useAuth();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [expandToAdd, setExpandToAdd] = useState(false);
	const [events, setEvents] = useState<Event[]>([]);
	const [eventSelected, setEventSelected] = useState<Event>();
	const [competitions, setCompetitions] = useState<Competition[]>([]);
	const [competitionsToAdd, setCompetitionsToAdd] = useState<Competition[]>([]);
	const [competitionsFilter, setCompetitionFilter] = useState("");

	useEffect(() => {
		const getData = async (championship: Championship) => {
			//Get competitions from this championship
			const competitionsChampFromServer = await get.competitionsByChampionship(championship.id).then(r => responseGetJson(r, [])) as Competition[];

			//Get events for this owne
			const eventFromServer = await get.events(auth.user.info.id).then(r => responseGetJson(r, [])) as Event[];

			setCompetitionsToAdd(competitionsChampFromServer);
			setEvents(eventFromServer);
			setLoading(false);
		}

		if (championship) {
			getData(championship);
			document.body.style.overflow = 'hidden';
		} else
			document.body.style.overflow = 'unset';

		return () => {
			document.body.style.overflow = 'unset';
		}
	}, [championship]);

	useEffect(() => {
		const getCompetitions = async (event: Event) => {
			const competitionsEventFromServer = await get.competitionsByEvent(event.id).then(r => responseGetJson(r, [])) as Competition[];

			setCompetitions(competitionsEventFromServer);
		}
		if (eventSelected)
			getCompetitions(eventSelected)
	}, [eventSelected])

	const close = () => {
		setCompetitionsToAdd([]);
		setCompetitions([]);
		setChampionshipToMan(undefined);
		setEventSelected(undefined);
		setLoading(true);
		setExpandToAdd(false);
	}

	const save = async () => {
		if (!championship)
			return
		const resp = await put.championshipsAddCompetitions(championship.id, competitionsToAdd.map(competition => competition.id));
		handleResponse(
			resp, auth, alert, navigate,
			"Errore durante l'inserimento delle gare",
			undefined,
			() => close()
		)
	}

	const addCompetition = (competition: Competition) => {
		setCompetitionsToAdd(prev => [...prev, competition]);
	}

	const removeCompetition = async (competition: Competition) => {
		setCompetitionsToAdd(prev => prev.filter(comp => comp.id !== competition.id));
	}


	let competitionsToShow = competitions?.filter(x => x.name.toLowerCase().includes(competitionsFilter.toLowerCase()));

	return (
		<div
			className={'organizer-championshipman-main-container' + (championship ? " show" : "")}
			onClick={(e) => {
				if (e.target === e.currentTarget)
					close();
			}}>
			{
				loading ? (
					<LoadingSpinner />
				) : (
					<div className={'organizer-championshipman-container' + (championship ? " show" : "")}>
						{/** Header */}
						<div className='organizer-championshipman-container-header'>
							<div className='organizer-championshipman-container-header-name'>{championship?.name}</div>
							<button className='organizer-championshipman-container-header-close' onClick={close}>
								<FontAwesomeIcon icon={faClose} />
							</button>
						</div>
						{/** This div will contain on left the selection container, on right the competitions selected */}
						<div className='organizer-championshipman-container-management'>
							{/** This container will collapse when an event has been selected */}
							<div
								className={`organizer-championshipman-container-events-selection${!eventSelected ? " show" : ""}${!expandToAdd ? " expand" : ""}`}>
								<div className='organizer-championshipman-container-event-head'>Seleziona un evento</div>
								{events.map(event => {
									return (
										<div
											className='organizer-championshipman-container-event'
											onClick={() => setEventSelected(event)}
											key={event.id}
										>
											<div className='organizer-championshipman-container-event-name'>{event.name}</div>
										</div>
									)
								})}
							</div>
							{/** This container will collapse when event selected is undefined */}
							<div
								className={`organizer-championshipman-container-competitions-selection${eventSelected ? " show" : ""}${!expandToAdd ? " expand" : ""}`}>
								<div onClick={() => setEventSelected(undefined)} className='organizer-championshipman-container-selected-event-name'>
									<FontAwesomeIcon icon={faArrowLeft} />
									{eventSelected?.name}
								</div>
								<SearchInput
									className='organizer-championshipman-container-search-competitions'
									onChange={(e) => setCompetitionFilter(e.target.value)}
									placeholder="Cerca gara"
									value={competitionsFilter}
								/>
								<div className='organizer-championshipman-container-selected-competition-competitions'>
									{competitionsToShow.map(competition => {
										return (
											<CompetitionComponent
												competition={competition}
												buttonClassName="AGGIUNGI"
												key={`Add${competition.id}`}
												request={addCompetition}
												readOnly={competitionsToAdd.map(c => c.id).includes(competition.id)}
											/>
										)
									})}
								</div>
							</div>
							{/** This container will show all the competitions selected */}
							<div className='organizer-championshipman-container-selected-competitions-container'>
								<div className='organizer-championshipman-container-selected-competitions-container-head' onClick={() => setExpandToAdd(!expandToAdd)}>
									<button className='organizer-championshipman-container-selected-competitions-container-expand' >
										<FontAwesomeIcon icon={expandToAdd ? faChevronUp : faChevronDown} />
									</button>
									<div className='organizer-championshipman-container-selected-competitions-container-head-name'>{championship?.name}</div>
									<div className='organizer-championshipman-container-selected-competitions-container-head-count'>{competitionsToAdd.length}</div>
								</div>
								<div className={`organizer-championshipman-container-selected-competitions${expandToAdd ? " expand" : ""}`}>
									{competitionsToAdd.map(competition => {
										return (
											<CompetitionComponent
												competition={competition}
												buttonClassName="RIMUOVI"
												key={`Remove${competition.id}`}
												request={removeCompetition}
												readOnly={false}
											/>
										)
									})}
								</div>
							</div>
						</div>
						<div className='organizer-championshipman-container-button-container'>
							<button
								className='organizer-championshipman-container-button no'
								type="button"
								onClick={close}
							>
								INDIETRO
							</button>

							<button
								className='organizer-championshipman-container-button yes'
								type="button"
								onClick={save}
							>SALVA</button>

						</div>
					</div>
				)
			}
		</div>
	)
}

export default OrganizerChampionshipMan