import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExerciseType, getMultipleSum, getSum, initExerciseType } from '../../models/models';
import { ExtendedAthlete } from '../../screens/Organizer/Classification/ClassificationCompetitionAthleteScreen';
import { ShrinkString, stringSort } from '../../utility/UtilityFunctions';

import './ClassificationAthlete.scss';
import { faChevronDown, faChevronUp, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import ToolTip from '../ToolTip';
import { convertDate } from '../../models/dateAndTime';

interface props {
	athleteExt: ExtendedAthlete;
	isPM: boolean;
	position: string;
	isRanked: boolean;
	showDetails: boolean;
	index: number;
	rule: number;
	slideReq: (index: number, direction: boolean) => void;
	hideReq: (id: number) => void;
	exerciseTypes?: ExerciseType[];
}

function ClassificationAthlete({ athleteExt, isPM, position, isRanked, exerciseTypes, rule, showDetails, index, slideReq, hideReq }: props) {
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (athleteExt.hide)
			setSelected(false);
	}, [athleteExt.hide])
	let containerClassName = "classification-athlete-container";
	if (isPM)
		containerClassName += " PM";

	if (selected)
		containerClassName += " selected";

	if (athleteExt.hide)
		containerClassName += " hide";

	if (showDetails)
		containerClassName += " hasDetails";

	const name = ("firstName" in athleteExt.athlete) ? athleteExt.athlete.firstName + " " + athleteExt.athlete.lastName : athleteExt.athlete.name;
	const compositions = exerciseTypes?.map(et => et.scoreComposition).flat().map(sc => sc.label)
		.filter((label, index, array) => !array.includes(label, index + 1));


	const etIds = athleteExt.exercises
		.sort((a, b) => a.typeId - b.typeId)
		.filter(x => x.scores.length > 0)
		.map(exe => exe.typeId)
		.filter((id, index, array) => array.indexOf(id) === index);

	return (
		<div onClick={() => setSelected(!selected)} className={containerClassName}>
			{!athleteExt.hide && <div className={`classification-athlete-position${isRanked ? " ranked" : ""}`}>
				{(isPM && index > 2 && !isRanked) && !isNaN(parseInt(position)) ? "" : position}
			</div>}
			<div className='classification-athlete-slide-buttons'>
				{!athleteExt.hide && isPM && <button className='classification-athlete-slide-button' onClick={() => slideReq(index, true)}>
					<FontAwesomeIcon icon={faChevronUp} />
				</button>}
			</div>
			{
				"firstName" in athleteExt.athlete ? (
					<ToolTip timeOnEnter={1000} text={`Data di nascita: ${convertDate(athleteExt.athlete.birthDate, undefined, true)}`}>
						<div className='classification-athlete-info-container'>
							<div className='classification-athlete-info'>
								{athleteExt.athlete.owner?.name}
							</div>
							<div className='classification-athlete-name'>
								{name}
							</div>
						</div>
					</ToolTip>
				) : (
					<div className='classification-athlete-info-container'>
						<div className='classification-athlete-info'>
							{athleteExt.athlete.owner?.name}
						</div>
						<div className='classification-athlete-name'>
							{name}
						</div>
					</div>
				)
			}
			{!athleteExt.hide && showDetails && <div className='classification-athlete-exercises-container'>
				<div className='classification-athlete-exercise-container'>
					<div className='classification-athlete-exercise-et' />
					<div className='classification-athlete-exercise-compositions'>
						{compositions?.map((composition, index) => <div key={index} className='classification-athlete-exercise-composition header'>
							{ShrinkString(composition)}</div>)}
					</div>
				</div>
				{etIds.map(id => {
					const et = exerciseTypes?.find(w => w.id === id) ?? initExerciseType;
					const exes = athleteExt.exercises.filter(x => x.scores.length > 0 && x.typeId === id);
					const partialSum = getMultipleSum(exes, rule, et.scoreComposition);
					const partialSumStyle = exes.length > 1 ? 'classification-athlete-exercise-tot partial' : 'classification-athlete-exercise-tot';

					return (
						<div key={id} className='classification-athlete-exercise-container'>
							<div className='classification-athlete-exercise-et'>{et.displayName}</div>
							<div>
								{exes.map(exercise => {
									const sum = getSum(exercise.scores, et?.scoreComposition);
									return (
										<div key={exercise.id} className='classification-athlete-exercise-compositions'>
											{compositions?.map((composition, index) =>
												<div key={index} className='classification-athlete-exercise-composition'>
													{exercise.scores.find(s => s.scoring[composition])?.scoring[composition].toFixed(3)}
												</div>
											)}
											<div className={partialSumStyle}>{sum.toFixed(3)}</div>
										</div>
									)
								}
								)}
								{
									exes.length > 1 && <div className='classification-athlete-exercise-compositions'>
										{compositions?.map((composition, index) =>
											<div key={index} className='classification-athlete-exercise-composition' />
										)}
										<div className='classification-athlete-exercise-tot'>{partialSum.toFixed(3)}</div>
									</div>
								}
							</div>
						</div>
					)
				}
				)}
			</div>}
			{
				!athleteExt.hide && athleteExt.sum !== 0 ? (
					<div className='classification-athlete-sum'>{athleteExt.sum.toFixed(3)}</div>
				) : (
					<div className='classification-athlete-sum noScore'>---</div>
				)
			}
			<button className='classification-athlete-hide-button' onClick={() => hideReq(athleteExt.athlete.id)}>
				<FontAwesomeIcon icon={!athleteExt.hide ? faEyeSlash : faEye} />
			</button>
		</div>
	)
}

export default ClassificationAthlete