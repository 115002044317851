import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import './SearchInput.scss';

interface props {
	className?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	name?: string;
	placeholder?: string;
	style?: React.CSSProperties | undefined;
	value?: string;
}

function SearchInput({ className, name, onChange, placeholder, style, value }: props) {
	return (
		<div className={`${className} search-input`} style={style}>
			<input name={name} value={value} onChange={onChange} type="text" placeholder={placeholder} />
			<FontAwesomeIcon className=" icon" icon={faSearch} />
		</div>
	)
}

export default SearchInput