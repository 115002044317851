import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { Jury, handleResponse } from '../../../models/models';
import './OrganizerJuriesDeleteRequest.scss';

interface props {
	jury?: Jury;
	deletionCompleted: (juries: Jury) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerJuriesDeleteRequest({ jury, show, deletionCompleted }: props) {
	const alert = useAlert();
	let navigate = useNavigate();
	let auth = useAuth();

	let deleteJury = async (e: React.FormEvent) => {
		if (!jury) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.jury(jury.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione della giuria",
					`Giuria cencellata`,
					() => deletionCompleted(jury)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE LA GIURIA" submit={deleteJury}>
			<p className='organizer-juries-delete-request-form-juries-name'>{jury?.name}</p>
		</CrudForm>
	);
}

export default OrganizerJuriesDeleteRequest;