import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { COMP_TYPE, post } from '../../../models/backendReq';
import { Category, Competition, Event, handleResponse, initCategory, initCompetition } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { getCompTypeString } from '../../../utility/UtilityFunctions';
import './OrganizerCompetitionsAddRequest.scss';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';

interface props {
	event: Event;
	addCompleted: (competition: Competition) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerCompetitionsAddRequest({ event, show, addCompleted }: props) {
	const configurationContext = useContext(OrganizerConfigurationsContext);

	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per la competizione" };
	const inputLevelError: ErrorType = { id: "level", text: "Inserire un livello per la competizione" };
	const inputCategoryNameError: ErrorType = { id: "categoryName", text: "Inserire un nome per la categoria della competizione" };
	const inputCategoryDateMinError: ErrorType = { id: "categoryDateMin", text: "Inserire una data minima per la categoria della competizione" };
	const inputCategoryDateMaxError: ErrorType = { id: "categoryDateMax", text: "Inserire una data massima per la categoria della competizione" };
	const inputTypeError: ErrorType = { id: "type", text: "Inserire una tipologia per la competizione" };

	let [competitionToAdd, setCompetitionToAdd] = useState<Competition>(initCompetition);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const firstFocusRef = useRef<HTMLInputElement>(null);

	let denied = () => {
		setCompetitionToAdd(initCompetition);
		show[1](false);
	};

	let addCompetition = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (!competitionToAdd.name || competitionToAdd.name === "") {
			_errors.push(inputNameError);
		}

		if (!competitionToAdd.type || competitionToAdd.type === "") {
			_errors.push(inputTypeError);
		}
		if (!competitionToAdd.levelId || competitionToAdd.levelId === 0) {
			_errors.push(inputLevelError);
		}
		if (!competitionToAdd.category || competitionToAdd.category.name === "") {
			_errors.push(inputCategoryNameError);
		}
		if (!competitionToAdd.category || competitionToAdd.category.minBirthDate === "") {
			_errors.push(inputCategoryDateMinError);
		}
		if (!competitionToAdd.category || competitionToAdd.category.maxBirthDate === "") {
			_errors.push(inputCategoryDateMaxError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return;

		await post.competition(event.id, competitionToAdd.type, competitionToAdd.name, [], competitionToAdd.levelId, competitionToAdd.category)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante l'inserimento della gara",
					"Gara creata correttamente",
					(data) => {
						addCompleted(data);
						setCompetitionToAdd(initCompetition);
					},
					setInputErrors
				);
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show]);

	let setCategory: Dispatch<SetStateAction<Category>> = useCallback(
		(input) => setCompetitionToAdd(c => ({ ...c, category: (typeof input === 'function') ? input(c.category ?? initCategory) : input })),
		[setCompetitionToAdd]
	);

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci nuova competizione' submit={addCompetition}>
			<p className='organizer-competition-add-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-competition-add-request-form-input'
				style={{ resize: 'vertical' }}
				value={competitionToAdd.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setCompetitionToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-competition-add-request-form-input-label'>Tipo</p>
			<InputWithAlert
				errorId="type"
				errors={inputErrors}
				className='organizer-competition-add-request-form-input'
				value={competitionToAdd.type}
				name='type'
				onSelectChange={(e) => {
					const { name, value } = e.target;

					setCompetitionToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				type='select'
				setErrors={setInputErrors}>
				<option value="">---</option>
				<option value={COMP_TYPE.Individual}>{getCompTypeString(COMP_TYPE.Individual)}</option>
				<option value={COMP_TYPE.Cumulative}>{getCompTypeString(COMP_TYPE.Cumulative)}</option>
				<option value={COMP_TYPE.Collective}>{getCompTypeString(COMP_TYPE.Collective)}</option>
			</InputWithAlert>
			<p className='organizer-competition-add-request-form-input-label'>Livello</p>
			<InputWithAlert
				errorId="level"
				type='select'
				errors={inputErrors}
				className='organizer-competition-add-request-form-input'
				style={{ resize: 'vertical' }}
				value={competitionToAdd.levelId}
				name="levelId"
				onSelectChange={(e) => {
					const { name, value } = e.target;

					setCompetitionToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			>
				<option value={0}>---</option>
				{configurationContext.competitionLevels.value.filter(l => l.ownerId === event.ownerId).map(competionlevel =>
					<option key={competionlevel.id} value={competionlevel.id}>{competionlevel.name}</option>)}
			</InputWithAlert>
			<p className='organizer-competition-add-request-form-input-label'>Categoria</p>
			<InputWithAlert
				categoryNameErrorId={inputCategoryNameError.id}
				categoryDateMaxErrorId={inputCategoryDateMaxError.id}
				categoryDateMinErrorId={inputCategoryDateMinError.id}
				errors={inputErrors}
				className='organizer-competition-add-request-form-input'
				style={{ resize: 'vertical' }}
				categoryValue={competitionToAdd.category}
				type='category'
				name="category"
				onCategoryChange={setCategory}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerCompetitionsAddRequest;