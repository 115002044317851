
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../models/auth/AuthProvider';
import { Jury } from '../../models/models';
import './CompetitionsJurySelection.scss';

function CompetitionsJurySelection() {
	const navigate = useNavigate();
	const params = useParams()
	const auth = useAuth();
	const juries = auth.user.juries?.filter(j => j.eventId === parseInt(params.eventId ?? ""))

	const goTo = (jury?: Jury) => {
		navigate(`../Jury${jury ? `?juryId=${jury.id}` : ''}`)
	}

	useEffect(() => {
		if (juries?.length === 1)
			goTo(juries[0]);

		if (auth.user.isOrganizer && juries?.length === 0)
			goTo()
	}, [auth])

	return (
		<div className='competition-juries-selection-container'>
			<div className='competition-juries-selection-header'>Selezione giuria di riferimento</div>
			<div className='competition-juries-selection-juries'>
				{
					juries && juries.length > 0 ?
						juries?.sort((a, b) => a.name.localeCompare(b.name)).map(jury => (
							<div key={jury.id} className='competition-juries-selection-jury'>
								<div className='competition-juries-selection-jury-name'>{jury.name}</div>
								<button onClick={() => goTo(jury)} className='competition-juries-selection-jury-button'>Seleziona</button>
							</div>
						)) : <div>Non hai nessuna giuria associata.</div>
				}
			</div>
		</div>
	);
}

export default CompetitionsJurySelection;