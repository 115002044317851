import { faAdd, faAddressCard, faArrowUpFromBracket, faAward, faClock, faDownload, faDumbbell, faFilePdf, faFilter, faGavel, faGear, faHeadset, faImage, faLink, faListOl, faMedal, faMusic, faPeopleRoof, faPersonRunning, faPlay, faRefresh, faRuler, faSquare, faSquareCheck, faTable, faTrash, faTrophy, faUser, faUserGroup, faUsersViewfinder, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";

export const eventLinks = (eventId: number) => {
	return [
		{ path: `/User/Events/${eventId}/Competitions`, name: 'Gare', icon: faMedal },
		{ path: `/User/Events/${eventId}/Turns`, name: 'ODL', icon: faClock },
		{ path: `/User/Events/${eventId}/Users`, name: 'Utenti', icon: faUser },
		{ path: `/User/Events/${eventId}/Jury`, name: 'Giuria', icon: faGavel },
		{ path: `/User/Events/${eventId}/Info`, name: 'Info' },
		{ path: `/User/Events/${eventId}/Live`, name: 'Live', icon: faHeadset },
		{ path: `User/Events/${eventId}/Musics`, name: 'Musiche', icon: faMusic },
		{ path: `User/Events/${eventId}/Classifications`, name: 'Classifiche', icon: faTrophy },
		{ path: `User/Events/${eventId}/RankingConfigurations`, name: 'Premiazioni', icon: faAward },
	]
};
export const eventLinksWithSubscriptions = (eventId: number) => {
	return [
		{ path: `/User/Events/${eventId}/Competitions`, name: 'Gare', icon: faMedal },
		{ path: `/User/Events/${eventId}/Turns`, name: 'ODL', icon: faClock },
		{ path: `/User/Events/${eventId}/Users`, name: 'Utenti', icon: faUser },
		{ path: `/User/Events/${eventId}/Jury`, name: 'Giuria', icon: faGavel },
		{ path: `/User/Events/${eventId}/Subscriptions`, name: 'Iscrizioni', icon: faAddressCard },
		{ path: `/User/Events/${eventId}/Musics`, name: 'Musiche', icon: faMusic },
		{ path: `/User/Events/${eventId}/Info`, name: 'Info' },
		{ path: `/User/Events/${eventId}/Live`, name: 'Live', icon: faHeadset },
		{ path: `/User/Events/${eventId}/Classifications`, name: 'Classifiche', icon: faTrophy },
		{ path: `/User/Events/${eventId}/RankingConfigurations`, name: 'Premiazioni', icon: faAward },
	]
};

export const eventLinksForAssociations = (eventId: number) => {
	return [
		{ path: `/User/Events/${eventId}/Subscriptions`, name: 'Iscrizioni', icon: faAddressCard },
		{ path: `/User/Events/${eventId}/Musics`, name: 'Musiche', icon: faMusic },
	]
};

export const competitionWithoutTeamLinks = (eventId: number, competitionId: number) => {
	return [
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/ExerciseTypes`, name: 'Attrezzi', icon: faDumbbell },
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/Juries`, name: 'Giuria', icon: faGavel },
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/Athletes`, name: 'Atleti', icon: faPersonRunning },
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/Info`, name: 'Info' },
	]
};
export const competitionWithTeamLinks = (eventId: number, competitionId: number) => {
	return [
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/ExerciseTypes`, name: 'Attrezzi', icon: faDumbbell },
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/Juries`, name: 'Giuria', icon: faGavel },
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/Teams`, name: 'Squadre', icon: faUserGroup },
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/Athletes`, name: 'Atleti', icon: faPersonRunning },
		{ path: `/User/Events/${eventId}/Competitions/${competitionId}/Info`, name: 'Info' },
	]
};

export const turnLinks = (eventId: number, turnId: number) => {
	return [
		{ path: `/User/Events/${eventId}/Turns/${turnId}/Groups`, name: 'Gruppi', icon: faUsersViewfinder },
		{ path: `/User/Events/${eventId}/Turns/${turnId}/Info`, name: 'Info' }
	]
};

export const associationLinks = (associationId: number) => {
	return [
		{ path: `/User/Association/${associationId}/Athletes`, name: 'Atleti', icon: faPersonRunning },
		{ path: `/User/Association/${associationId}/Teams`, name: 'Squadre', icon: faUserGroup },
	]
};

export const associationLinksWithMembership = (associationId: number) => {
	return [
		{ path: `/User/Association/${associationId}/Athletes`, name: 'Atleti', icon: faPersonRunning },
		{ path: `/User/Association/${associationId}/Teams`, name: 'Squadre', icon: faUserGroup },
		{ path: `/User/Association/${associationId}/Memberships`, name: 'Membri', icon: faPeopleRoof },
	]
};

export const toolBarElements = {
	filterElement: { icon: faFilter, text: 'Filtri' },
	addElement: { icon: faAdd, reverse: true },
	wizardElement: { icon: faWandMagicSparkles },
	selectElement: { icon: faSquare, selectedIcon: faSquareCheck },
	deleteElemet: { icon: faTrash },
	eTypeElement: { icon: faDumbbell },
	juryElement: { icon: faGavel },
	transferElement: { icon: faArrowUpFromBracket },
	downloadPDFElement: { icon: faFilePdf, text: 'Scarica PDF' },
	dumbbellElement: { icon: faDumbbell },
	ruleElement: { icon: faRuler },
	rankingElement: { icon: faListOl },
	watermarkElement: { icon: faImage },
	simplifiedViewElement: { icon: faGear, selectedIcon: faTable },
	refreshElement: { icon: faRefresh },
	athleteElement: { icon: faPersonRunning },
	linkElement: { icon: faLink },
	playViewElement: { icon: faPlay, text: "Riproduzione" },
	downloadElement: { icon: faDownload, text: "Scarica" }
}