import { useContext, useState } from 'react';
import OrganizerFloorMusicsAddRequest from './OrganizerFloorMusicsAddRequest';
import OrganizerFloorMusicsDeleteRequest from './OrganizerFloorMusicsDeleteRequest';


import { FloorMusicMetadata, handleResponse } from '../../../models/models';


import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganaizerFloorMusicCom from '../../../components/Organizer/OrganaizerFloorMusicCom';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableNameCell from '../../../components/Organizer/TableComponents/TableNameCell';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../../contexts/EventEoContext';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerFloorMusicsScreen.scss';
import '../../../utility/prototype'
import FiltersBar from '../../../components/Organizer/FiltersBar';
import OrganizerFloorMusicPlayView from './OrganizerFloorMusicPlayView';
import { get } from '../../../models/backendReq';
import { useAlert } from '../../../models/AlertProvider';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../models/auth/AuthProvider';
import { saveAs } from 'file-saver';

function OrganizerFloorMusicsScreen() {
	const context = useContext(EventEoContext);
	const alert = useAlert();
	const navigate = useNavigate();
	const auth = useAuth();

	let [floorMusicMetadataToDel, setFloorMusicMetadataToDel] = useState<FloorMusicMetadata>();
	let [associationFilter, setAssociationFilter] = useState(0);
	let [playView, setPlayView] = useState(false);

	const downloadMedia = async () => {
		const res = await get.getFloormusicEventMedia(context.event.id);
		handleResponse(res, auth, alert, navigate, "Errore durante il caricamento delle musiche", undefined,
			async () => saveAs(await res.blob()));
	}

	let showDel = useState(false);
	let showAdd = useState(false);
	let showFilters = useState(false);

	let deleteRequest = (floorMusicMetadata: FloorMusicMetadata) => {
		showDel[1](true);
		setFloorMusicMetadataToDel(floorMusicMetadata);
	}
	let deletionCompleted = (floorMusicMetadata: FloorMusicMetadata) => {
		context.floormusics.crud.delete(floorMusicMetadata);
		showDel[1](false)
	}
	let addCompleted = (floorMusicMetadata: FloorMusicMetadata) => {
		context.floormusics.crud.add(floorMusicMetadata);
		showAdd[1](false)
	}


	const _filters = [
		{
			name: 'Società',
			setNum: setAssociationFilter,
			value: associationFilter,
			type: "select",
			options: context.associations
				.map(a => ({ label: a.name, value: a.id }))
		},
	];

	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi brano' },
		{
			...toolBarElements.filterElement,
			callback: () => showFilters[1](!showFilters[0]),
			notNumber: _filters.filter(f => (f.value && f.value !== 0)).length
		}
	];
	if (auth.user.isOrganizer) {
		_toolBarElements.push(
			{ ...toolBarElements.playViewElement, callback: () => setPlayView(!playView), selected: playView });
		_toolBarElements.push({ ...toolBarElements.downloadElement, callback: downloadMedia });
	}

	const musicToShow = context.floormusics.value.sortFloorMusicMetadata(associationFilter, true)
		.filter(m => context.associations.some(a => a.id === m.associationId));

	return (
		<OrganizerEventTable text='MUSICHE EVENTO' >
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar show={showFilters} filters={_filters} found={_toolBarElements[0].notNumber! > 0 ? musicToShow.length : undefined} />
			{!playView ? <Table>
				<thead>
					<tr>
						<TableNameCell text='Brano' isHeader />
						<TableNameCell text='Società' isHeader />
						<th></th>
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={musicToShow}
						emptyMesssage='Nessun brano caricato'
						renderList={floorMusicMetadatas => floorMusicMetadatas.map(floorMusicMetadata =>
							<OrganaizerFloorMusicCom key={floorMusicMetadata.id} floorMusicMetadata={floorMusicMetadata} del={deleteRequest} />
						)} />
				</tbody>
			</Table> : <OrganizerFloorMusicPlayView />}
			<OrganizerFloorMusicsDeleteRequest floorMusicMetadata={floorMusicMetadataToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerFloorMusicsAddRequest event={context.event} show={showAdd} addCompleted={addCompleted} />
		</OrganizerEventTable>
	);
}

export default OrganizerFloorMusicsScreen;