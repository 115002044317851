import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PasswordInput from "../../components/Input/PasswordInput";
import { USER_TYPE, useAuth } from '../../models/auth/AuthProvider';
import './LoginOrganizer.scss';
import { useStateLocation } from '../../models/StateLocationProvider';


function Login() {
	let [notFound, setNotFound] = useState<boolean>(false);
	let [user, setUser] = useState<string>("");
	let [password, setPassword] = useState<string>("");
	let navigate = useNavigate();
	let auth = useAuth();

	let location = useStateLocation();

	let navigatePathname = useMemo(() => {
		let from = location.read<string>("from");

		if (from) {
			return '/User';
		}

		return '/User';
	}, [location]);


	let classnameNotFound = notFound ? "login-organizer-not-found show" : "login-organizer-not-found";

	let onLogin = useCallback((e: React.FormEvent) => {
		e.preventDefault();

		setNotFound(false);
		setTimeout(async () => {
			let loginState = await auth.signin(user.trim(), password);
			setNotFound(!loginState);
		}, 10)
	}, [auth, password, user]);

	useEffect(() => {
		if (auth.user.type === USER_TYPE.USER)
			navigate(navigatePathname, { replace: true });
	}, [auth.user.auth, navigate, navigatePathname]);

	return (
		<div className='login-organizer-container'>
			<form
				className='login-organizer-form'
				onSubmit={(e) => { onLogin(e); }}>
				<div style={{ width: "100%", marginBottom: "20px" }}>
					<p className='p-organizer-title'>
						ACCESSO
					</p>
				</div>

				<input
					className='input-organizer-field'
					type='text'
					placeholder='Nome utente'
					value={user}
					onChange={(e) => setUser(e.target.value)}
				/>

				<PasswordInput
					className='input-organizer-field'
					placeholder='Password'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<button
					type='submit'
					className='login-organizer-btn'>
					LOGIN
				</button>
				<p className={classnameNotFound}>Login fallito</p>

			</form>
		</div>
	);
}

export default Login;