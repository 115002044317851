import React, { useContext } from 'react';
import EventEoContext from '../../../contexts/EventEoContext';
import { useAuth } from '../../../models/auth/AuthProvider';
import { eventLinks, eventLinksForAssociations, eventLinksWithSubscriptions } from '../../../screens/Organizer/SubNavBarLinks';
import TableContainer from './TableContainer';
import EventsEoContext from '../../../contexts/EventsEoContext';

interface props {
	children: React.ReactNode;
	text?: string;
	loading?: boolean;
}
function OrganizerEventTable(props: props) {
	const contextEvent = useContext(EventEoContext);
	const contextEvents = useContext(EventsEoContext);
	const auth = useAuth();

	let links;

	links = eventLinksForAssociations(contextEvent.event.id);
	if (auth.user.eventOrganizers.map(eo => eo.id).includes(contextEvent.event.ownerId)) {
		links = eventLinks(contextEvent.event.id);

		if (links && auth.user.isAssociation)
			links = eventLinksWithSubscriptions(contextEvent.event.id);
	}

	return (
		<TableContainer
			progress={contextEvent.progress}
			headerText={props.text}
			sectionName={contextEvent.event.name}
			sectionBackPath='/User/Events'
			showLoading={contextEvents.loading || contextEvent.loading || props.loading}
			subBarLinks={links}
			hasSection>
			<div style={{ width: "100%" }}>{props.children}</div>
		</TableContainer>
	)
}

export default OrganizerEventTable