import { useEffect, useState } from 'react';
import ChampionshipCom from '../components/ChampionshipCom';
import { get } from '../models/backendReq';
import { Championship, responseGetJson } from '../models/models';

import LoadingSpinner from '../components/LoadingSpinner';
import { useAuth, USER_TYPE } from '../models/auth/AuthProvider';
import './ChampionshipsScreen.scss';

function ChampionshipsScreen() {
	let [championships, setChampionships] = useState<Championship[]>([]);
	let [loadingChampionships, setLoadingChampionships] = useState(true);
	const auth = useAuth();

	useEffect(() => {

		let getChampionships = async () => {
			let championshipsFromServer = await get.championships()
				.then(res => responseGetJson(res, []))
				.finally(() => setLoadingChampionships(false));

			setChampionships(championshipsFromServer);
		};

		getChampionships();

	}, []);

	const championshipToShow = championships.filter(championship => auth.user.type === USER_TYPE.USER ? championship.ownerId === auth.user.info.id : true);
	return (
		<div>
			{!loadingChampionships ? (
				championships && championships.length > 0 ? (
					<div className='championships-table'>
						{championshipToShow.map((championship) => {
							return (
								<ChampionshipCom key={championship.id} championship={championship} />
							);
						})}
					</div>
				) : (
					<div className='loading-gif-championship-container'>
						<p>Non ci sono campionati disponibili</p>
					</div>
				)
			) : (
				<LoadingSpinner />
			)}
			{/* <iframe src="https://calendar.google.com/calendar/embed?src=ab97f5ca7888c52fd306864c09ca21f80be99ea06b2c67098a908b7a06f53e45%40group.calendar.google.com&ctz=Europe%2FRome" style={{ border: 0, width: "800", height: "600", overflow: "hidden" }}></iframe> */}
			{/* <ChampionshipSetupCom championship={championship} setChampionship={setChampionship} /> */}
		</div>
	);
}

export default ChampionshipsScreen;