import { Event } from '../../models/models';

import './OrganaizerEventCom.scss';

import { useAuth } from '../../models/auth/AuthProvider';
import TableDateCell from './TableComponents/TableDateCell';
import TableLinkRow from './TableComponents/TableLinkRow';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';
import TableTr from './TableComponents/TableTr';
import { permutator } from '../../utility/UtilityFunctions';

interface props {
	event: Event;
	del: (event: Event) => void;
	edi: (event: Event) => void;
	lastId?: number;
}

function OrganaizerEventCom({ event, del, edi, lastId }: props) {
	const state = { event: event };
	const auth = useAuth();
	const isOwner = auth.user.eventOrganizers.map(eo => eo.id).includes(event.ownerId);
	const navPath = isOwner ? `./${event.id}/Competitions` : `./${event.id}/Subscriptions`;

	let editReq = () => {
		edi(event);
	}

	let deleteReq = () => {
		del(event);
	}

	return (
		<TableTr id={event.id} lastId={lastId}>
			<TableLinkRow navigate={{ path: navPath, state: state }} id={event.id} text={event.name} />
			<td>{auth.user.eventOrganizers.find(eo => eo.id === event.ownerId)?.name}</td>
			<TableDateCell date={event.startDate} />
			<TableDateCell date={event.endDate} />
			{auth.user.isOrganizer && <TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />}
		</TableTr>
	);
}

export default OrganaizerEventCom;