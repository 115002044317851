import { faArrowLeft, faCheck, faCircle, faCircleCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_TYPE, useAuth } from '../../models/auth/AuthProvider';
import { del, get, post, put } from '../../models/backendReq';
import { Athlete, Exercise, ExerciseType, HTTP_STATUS_CODES, Jury, JuryAssignment, Score, ScoreFragmentType, Team, getMusicUrl, handleResponse } from '../../models/models';
import './ManageScoreCom.scss';
import { MESSAGE_TYPE, useAlert } from '../../models/AlertProvider';
import { idbKeyval } from '../../OfflineFirst/idb';
import { useServerStatus } from '../../models/ServerStatusProvider';
import JuryContext from '../../contexts/JuryContext';
import AudioComponent from '../AudioComponent';

function InputFragment({ defaultValue, enabled, newValueDone, scoreComposition }: {
	defaultValue: number,
	enabled: boolean,
	newValueDone: (value: number, label: string) => void,
	scoreComposition: ScoreFragmentType,
}) {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setScoreValue(defaultValue.toFixed(3));
	}, [defaultValue])

	let [scoreValue, setScoreValue] = useState(defaultValue.toFixed(3));

	let onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		let newValue = e.target.valueAsNumber;

		setScoreValue(e.target.value);
		newValueDone(newValue, scoreComposition.label);
	};

	let onBlur = () => {
		if (!enabled)
			return
		var actSV = scoreValue;
		if (actSV === "") { actSV = "0" };
		var num = parseFloat(actSV);
		var cleanNum = num.toFixed(3);
		setScoreValue(cleanNum);
	}

	let onFocus = () => {
		if (!enabled)
			return

		if (parseFloat(scoreValue) === 0) {
			setScoreValue("");
		} else
			ref.current?.select();
	}

	let inputClassName = "judge-score-management-form-composition-input";
	if (enabled) {
		inputClassName += " enabled"
		if (scoreComposition.weight < 0)
			inputClassName += " red"
	}

	return (
		<div
			className='judge-score-management-form-composition-container'>
			<label className='judge-score-management-form-composition-label'>{scoreComposition.label}</label>
			<input
				min={scoreComposition.min}
				max={scoreComposition.max}
				className={inputClassName}
				type='number'
				pattern="\d*"
				step="0.001"
				onBlur={onBlur}
				onFocus={onFocus}
				readOnly={!enabled}
				value={scoreValue}
				onChange={onChangeValue}
				ref={ref}
			/>
		</div>
	)
}
interface props {
	athlete: Athlete | Team,
	assignments: JuryAssignment[],
	exercises?: Exercise[],
	exerciseTypes?: ExerciseType[],
	addCompleted: (score: Score) => void,
	editCompleted: (scores: Score[]) => void,
	deleteCompleted: (score: Score) => void,
	juries: Jury[],
	setShowScore: React.Dispatch<React.SetStateAction<boolean>>,
	show: boolean,
	userJury?: Jury,
}

function ManageScoreCom(props: props) {
	const { isServerOnline } = useServerStatus();
	const context = useContext(JuryContext);

	const [exercise, setExercise] = useState<Exercise>();

	const exerciseType = props.exerciseTypes?.find(et => et.id === exercise?.typeId);

	const navigate = useNavigate();
	const alert = useAlert();
	const auth = useAuth();

	const alreadyAssigned = !auth.user.isOrganizer ? exercise?.scores.some(x => x.juryId === props.userJury?.id) : exercise?.scores.length ?? -1 > 0;
	const assignmentStrings = props.assignments.filter(x => x.exerciseTypeId === exerciseType?.id).map(y => y.assignedScoreFragmentTypes).flat();
	const clearableScores = exercise?.scores.filter(score => !auth.user.isOrganizer ? score.juryId === props.userJury?.id : true) ?? [];
	const scoringArray = exercise?.scores.map(score => score.scoring).map(rec => Object.entries(rec)).flat() ?? [];

	const [fragments, setFragments] = useState<Record<string, number>>({});

	useEffect(() => {
		if (!props.exercises || props.exercises?.length === 0)
			return

		if (props.exercises.length === 1) {
			setExercise(props.exercises[0]);
			return
		}
		let exercise = props.exercises.find(e => props.userJury?.assignments?.some(a => a.exerciseTypeId === e.typeId) && e.scores.length === 0);
		// exercise = props.exercises.find(e => props.userJury?.assignments?.some(a => a.exerciseTypeId === e.typeId));

		setExercise(exercise);

	}, [props.exercises]);

	useEffect(() => {
		const updateFragments = () => {
			let scoring = {};
			if (props.userJury) {
				const score = exercise?.scores.find(score => score.juryId === props.userJury?.id);
				const otherScores = exercise?.scores.filter(score => score.juryId !== props.userJury?.id);
				const otherAssignments = otherScores?.map(score => score.scoring).map(rec => Object.entries(rec)).flat().map(x => x[0]);

				if (score) {
					scoring = Object.assign({}, ...Object.entries(score.scoring).map(([key, value]) => {
						if (assignmentStrings.includes(key) && !otherAssignments?.includes(key))
							return ({ [key]: value })
					}));
				} else {
					if (exerciseType)
						scoring = Object.assign({}, ...exerciseType.scoreComposition.map((x) => {
							if (assignmentStrings.includes(x.label) && !otherAssignments?.includes(x.label))
								return ({ [x.label]: x.defaultValue })
						}));
				}
			}
			if (auth.user.isOrganizer)
				scoring = Object.assign({ ...scoring }, ...scoringArray.map(([key, value]) => ({ [key]: value })));

			return scoring
		}



		setFragments(updateFragments());
	}, [props.show, auth, exercise, exercise?.scores]);

	let newValueDone = (value: number, type: string) => {
		if (fragments) {
			fragments[type] = value;

			setFragments(fragments);
		}
	};

	const addInIndexDB = async () => {
		if (!exercise || !props.userJury || !fragments)
			return;

		const allkeys = await idbKeyval.keys('scoresToAdd');
		const offlineScore: Score = { exerciseId: exercise.id, id: allkeys.length + 1, juryId: props.userJury.id, scoring: fragments };
		await idbKeyval.set<Score>('scoresToAdd', offlineScore);
		props.addCompleted(offlineScore);
		const sameTypeExercises = props.exercises?.filter(x => x.typeId === exercise.typeId) ?? [];
		if (sameTypeExercises.length > 1) {
			const exeToFind = sameTypeExercises.findIndex(x => x.scores.length === 0);
			if (exeToFind === -1)
				props.setShowScore(false)
		}
		else
			props.setShowScore(false)
	}

	const deleteFromIndexDB = async (score: Score) => {
		const scoreInDb = await idbKeyval.get('scoresToAdd', score.id);
		scoreInDb ?
			await idbKeyval.delete('scoresToAdd', score.id)
			:
			await idbKeyval.set('scoresToDel', score);

		if (await idbKeyval.get('scoresToEdit', score.id))
			await idbKeyval.delete('scoresToEdit', score.id);

		props.deleteCompleted(score);
	};

	const editScoreInIndexDB = async (score: Score, newScore: Score) => {
		const scoreInDb = await idbKeyval.get('scoresToAdd', score.id);
		scoreInDb ?
			await idbKeyval.set<Score>('scoresToAdd', newScore)
			:
			await idbKeyval.set<Score>('scoresToEdit', newScore);
		return newScore;
	}

	let deleteScore = async (score: Score) => {
		if (isServerOnline) {
			const res = await del.score(score.id);
			handleResponse(res, auth, alert, navigate, "Errore durante la cancellazione del voto", undefined,
				() => props.deleteCompleted(score), undefined, () => deleteFromIndexDB(score));
		} else {
			deleteFromIndexDB(score);
		}
	}

	let onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (Object.entries(fragments!).length === 0 && !alreadyAssigned && auth.user.isOrganizer) {
			props.setShowScore(false);
			return
		}

		if (!alreadyAssigned) {
			if (exercise && auth.user && fragments && props.userJury) {
				if (isServerOnline) {
					const res = await post.scores(exercise.id, props.userJury.id, fragments);
					handleResponse(res, auth, alert, navigate, "Errore durante l'inserimento del voto", undefined,
						(data) => {
							const sameTypeExercises = props.exercises?.filter(x => x.typeId === exercise.typeId) ?? [];
							props.addCompleted(data)
							if (sameTypeExercises.length > 1) {
								const exeToFind = sameTypeExercises.findIndex(x => x.scores.length === 0);
								if (exeToFind === -1)
									props.setShowScore(false)
							}
							else
								props.setShowScore(false)
						}, undefined, addInIndexDB
					);
				} else addInIndexDB();
			}
		} else {
			const promise = clearableScores.map(async (score) => {
				let newScore = score;
				Object.keys(score.scoring).forEach(key => { newScore.scoring[key] = fragments[key] })
				if (exercise && auth.user && fragments) {
					if (isServerOnline) {
						const res = await put.scores(score.id, newScore.scoring);
						return handleResponse(
							res, auth, alert, navigate, "Errore durante la modifica del voto", undefined,
							() => { return newScore }, undefined, () => editScoreInIndexDB(score, newScore)
						)
					} else editScoreInIndexDB(score, newScore);
				}
			});

			let newScores = await Promise.all(promise);

			props.setShowScore(false)
			props.editCompleted(newScores)
		}

	};

	function ScoreComponent({ deleteScore, judge, score }: {
		deleteScore: (score: Score) => void,
		judge?: Jury
		score: Score,
	}) {
		const [confirmDeleting, setConfirmDeleting] = useState(false);

		return (
			<div className='judge-score-management-form-score-container'>
				<button className='judge-score-management-form-score-container-abort-delete' type='button' onClick={() => setConfirmDeleting(!confirmDeleting)}>
					<FontAwesomeIcon icon={confirmDeleting ? faArrowLeft : faClose} />
				</button>
				{
					confirmDeleting ? (
						<button className='judge-score-management-form-score-container-button' type='button' onClick={() => deleteScore(score)}>
							<FontAwesomeIcon icon={faCheck} />
						</button>
					) : (<></>)
				}
				<div>Punteggio assegnato da giuria:<strong>{" " + judge?.name}</strong></div>
			</div>
		)
	}

	const athleteName = ("firstName" in props.athlete) ? `${props.athlete.code} - ${props.athlete.firstName} ${props.athlete.lastName}` : props.athlete.name;

	return (
		<div className={props.show ? 'judge-score-management-container show' : 'judge-score-management-container'}
			onClick={(e) => {
				if (e.target === e.currentTarget)
					props.setShowScore(false)
			}}>
			<form onSubmit={(e) => onSubmit(e)}
				className={props.show ? 'judge-score-management-form show' : 'judge-score-management-form'}
			>
				<div className='judge-score-management-form-header'>
					<div className='judge-score-management-form-header-text'>Gestione punteggi</div>
					<button className='judge-score-management-form-header-button' type='button' onClick={() => props.setShowScore(false)}>
						<FontAwesomeIcon icon={faClose} />
					</button>
				</div>
				<div className='judge-score-management-form-info-container'>
					<div>{context.competitions.find(x => x.id === exercise?.competitionId)?.name}</div>
					<div className='judge-score-management-form-info-athlete'> {athleteName}</div>

					{
						props.exercises?.length !== 1 ? (
							<div className='judge-score-management-form-info-exetype selection'>
								{props.exercises?.sort((a, b) => a.typeId - b.typeId).map((exe, index, array) => {
									let className = `judge-score-management-exe-change${exe.id === exercise?.id ? " selected" : ""}`;
									if (exe.scores.length > 0)
										className += ' hasScore'
									const firstIndex = array.findIndex(e => e.typeId === exe.typeId);
									const number = array.filter(e => e.typeId === exe.typeId).length > 1 ? (index - firstIndex + 1).toString() : "";
									return (
										<button
											key={exe.id}
											className={className}
											type='button'
											onClick={() => setExercise(props.exercises?.find(e => e.id === exe.id))}>
											<FontAwesomeIcon icon={exe.scores.length > 0 ? faCircleCheck : faCircle} />
											<div>{props.exerciseTypes?.find(et => et.id === exe.typeId)?.displayName + " " + number}</div>
										</button>
									)
								})}
							</div>
						) : (
							<div className='judge-score-management-form-info-exetype'>{exerciseType?.displayName}</div>
						)
					}
				</div>
				<div className='judge-score-management-form-scores-container'>
					{
						clearableScores.map(score => {
							const judge = props.juries.find(jury => jury.id === score.juryId);
							return (
								<ScoreComponent key={score.id} deleteScore={deleteScore} judge={judge} score={score} />
							)
						})
					}
				</div>
				<div className='judge-score-management-form-compositions-container'>
					{
						exerciseType?.scoreComposition.sort((a, b) => a.order - b.order).map(composition => {
							const defaultValue = scoringArray.find(([key, value]) => key === composition.label)?.[1];
							const isEnabled = fragments !== undefined ? Object.entries(fragments).some(([key, value]) => key === composition.label) : false;
							return (
								<InputFragment
									key={composition.id}
									defaultValue={defaultValue ?? composition.defaultValue}
									enabled={isEnabled}
									newValueDone={newValueDone}
									scoreComposition={composition}
								/>
							)
						})
					}
				</div>
				<div>
					{exercise?.floorMusicMetadataId && <AudioComponent className='judge-score-managment-form-music' floorMusicId={exercise.floorMusicMetadataId} />}
				</div>
				<div className='judge-score-management-form-button-container'>
					<button
						className='judge-score-management-form-button no'
						type="button"
						onClick={() => props.setShowScore(false)}
					>
						INDIETRO
					</button>
					{
						Object.entries(fragments!).length === 0 && !alreadyAssigned && auth.user.isOrganizer ? (
							<></>
						) : (
							<button className='judge-score-management-form-button yes' type="submit">{alreadyAssigned ? "MODIFICA" : "AGGIUNGI"}</button>
						)
					}
				</div>
			</form>
		</div>
	);
}

export default ManageScoreCom;