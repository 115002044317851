import { User } from '../../models/models';

import './OrganaizerEventuserCom.scss';

import TableNameCell from './TableComponents/TableNameCell';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';

interface props {
	eventuser: User;
	del: (eventuser: User) => void;
	edi: (eventuser: User) => void;
}

function OrganaizerEventuserCom({ eventuser, del, edi }: props) {
	let editReq = () => {
		edi(eventuser);
	}

	let deleteReq = () => {
		del(eventuser);
	}

	return (
		<tr>
			<TableNameCell text={eventuser.displayName} />
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />
		</tr>
	);
}

export default OrganaizerEventuserCom;