import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../models/auth/AuthProvider';
import './OrganizerConfigurationsSidebar.scss';


function OrganizerConfigurationsSidebar() {
	const auth = useAuth();

	const sidebarElements = [
		{ elementName: 'Livelli', path: './Levels' },
	]

	if (auth.user.info.userName === "Admin") {
		sidebarElements.push({ elementName: 'Attrezzi', path: './ExerciseTypes' });
		sidebarElements.push({ elementName: 'Società', path: './Associations' });
	}
	return (
		<div className='organizer-configurations-sidebar-container'>
			{sidebarElements.map((element, index) =>
				<NavLink key={index} className='organizer-configurations-sidebar-element' to={element.path}>{element.elementName}</NavLink>)}
		</div>
	)
}

export default OrganizerConfigurationsSidebar