import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { CompetitionContextData } from '../../contexts/CompetitionContext';
import { TurnContextData } from '../../contexts/TurnContext';
import { AthleteClass, Exercise, Score, getAllSum, getSum } from '../../models/models';
import './AthleteCom.scss';
import ManageScoreCom from './ManageScoreCom';
import JuryContext from '../../contexts/JuryContext';

interface props {
	athlete: AthleteClass;
	context: CompetitionContextData | TurnContextData;
	exercises: Exercise[];
	newScoreAssigned: (id: number) => void;
	additionalText?: string;
}

function AthleteCom({ athlete, context, exercises, newScoreAssigned, additionalText }: props) {
	const { exerciseTypes, assignments, juries, userJury } = context;
	const jurycontext = useContext(JuryContext);
	const [showScore, setShowScore] = useState(false);

	//let [cachedExercises, setCachedExercises] = useState<Exercise[]>(exercises);

	const addAnimation = () => {
		const animationKeyFrames = {
			backgroundColor: ["#00FF0055", "#FFFFFF"],
			boxShadow: ["0 0 5px #00FF00", "0 0 2px #2F4858"]
		};

		const animationOption: KeyframeAnimationOptions = {
			duration: 700,
			iterations: 1,
			// iterationStart: 2000
		}

		document.getElementById(`judgeAthlete${athlete.id}`)?.animate(animationKeyFrames, animationOption);
	};

	const deleteAnimation = () => {
		const animationKeyFrames = {
			backgroundColor: ["#AA000055", "#FFFFFF"],
			boxShadow: ["0 0 5px #AA0000", "0 0 2px #2F4858"]
		};

		const animationOption: KeyframeAnimationOptions = {
			duration: 700,
			iterations: 1,
		}

		document.getElementById(`judgeAthlete${athlete.id}`)?.animate(animationKeyFrames, animationOption);
	};

	const editAnimation = () => {
		const animationKeyFrames = {
			backgroundColor: ["#ffa50055", "#FFFFFF"],
			boxShadow: ["0 0 5px #ffa500", "0 0 2px #2F4858"]
		};

		const animationOption: KeyframeAnimationOptions = {
			duration: 700,
			iterations: 1,
		}

		document.getElementById(`judgeAthlete${athlete.id}`)?.animate(animationKeyFrames, animationOption);
	};

	let addScore = async (score: Score) => {
		// const index = cachedExercises.findIndex(exe => exe.id === score.exerciseId);
		// const newExercises = [...cachedExercises];
		// newExercises[index].scores.push(score);
		// setCachedExercises(newExercises);
		addAnimation();
		newScoreAssigned(athlete.id);
		jurycontext.scoresHandler.add([score]);
	}

	let deleteScore = async (score?: Score) => {
		if (!score)
			return
		// const index = cachedExercises.findIndex(x => x.id === score.exerciseId);
		// const newExercises = [...cachedExercises];
		// newExercises[index].scores = newExercises[index].scores.filter(x => x.id !== score.id);
		// setCachedExercises(newExercises);
		deleteAnimation();
		jurycontext.scoresHandler.delete([score]);
	};

	let editScores = async (scores: Score[]) => {
		// const newExercises = [...cachedExercises];
		// scores.forEach(score => {
		// 	const exeIndex = newExercises.findIndex(x => x.id === score.exerciseId);
		// 	const scoreIndex = newExercises[exeIndex].scores.findIndex(x => x.id === score.id);
		// 	newExercises[exeIndex].scores[scoreIndex] = score;
		// 	setCachedExercises(newExercises);
		// })
		editAnimation();
		newScoreAssigned(athlete.id);
		jurycontext.scoresHandler.edit(scores);
	}

	// const exerType = exerciseTypes.find(et => et.id === exerciseToChange?.typeId);

	let exeStatClassName = 'athlete-exercises-container';
	const atLeastOneScore = exercises
		.some(exe => exe.scores.find(score => assignments.some(assignment => assignment.assignedScoreFragmentTypes.some(asft => score.scoring[asft]))));
	const allScoreDone = exercises
		.every(exe => exe.scores.find(score => assignments.some(assignment => assignment.assignedScoreFragmentTypes.some(asft => score.scoring[asft]))));
	if (atLeastOneScore)
		exeStatClassName += ' some';
	if (allScoreDone)
		exeStatClassName += ' every';

	return (
		<>
			<div id={`judgeAthlete${athlete.id}`} className='athlete-container' onClick={() => setShowScore(true)}>
				<div className={exeStatClassName}>
					<FontAwesomeIcon icon={atLeastOneScore ? faCircleCheck : faCircle} />
				</div>
				<div className='athlete-name-container'>
					<strong>{athlete.code}</strong>
					<div>
						{additionalText && <div className='athlete-name-association'> {additionalText}</div>}
						<div>{athlete.getName()}</div>
					</div>
				</div>
				<div>
					{exercises.sort((a, b) => a.typeId - b.typeId).map(exercise => <div className='judge-athlete-exercise' key={exercise.id}>
						<span className='judge-athlete-exercise-type-name'>{context.exerciseTypes.find(et => et.id === exercise.typeId)?.displayName}</span>
						<span className='judge-athlete-exercise-type-score'>{getSum(exercise.scores, context.exerciseTypes.find(et => et.id === exercise.typeId)?.scoreComposition).toFixed(3)}</span>
					</div>)}
					{/* {getAllSum(cachedExercises, exerciseTypes, 0).toFixed(3)} */}
				</div>

			</div>
			{showScore &&
				<ManageScoreCom
					addCompleted={addScore}
					assignments={assignments}
					athlete={athlete}
					deleteCompleted={deleteScore}
					editCompleted={editScores}
					exercises={exercises}
					exerciseTypes={exerciseTypes}
					juries={juries}
					setShowScore={setShowScore}
					show={showScore}
					userJury={userJury}
				/>
			}
		</>
	);
}

export default AthleteCom;