import { Outlet } from 'react-router-dom'
import OrganizerConfigurationsSidebar from '../../../components/Organizer/Configurations/OrganizerConfigurationsSidebar'
import './OrganizerConfigurationsScreen.scss'

function OrganizerConfigurationsScreen() {
	return (
		<div className='organizer-configurations-container'>
			<OrganizerConfigurationsSidebar />
			<div className='organizer-configurations-screen'>
				<Outlet />
			</div>
		</div>
	)
}

export default OrganizerConfigurationsScreen