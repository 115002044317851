import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ConfirmCancelButtons.scss'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface props {
	no: React.MouseEventHandler<HTMLButtonElement> | undefined;
	yes?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	buttonType?: "button" | "submit" | "reset" | undefined;
	showArrows?: boolean;
	showArrowsNext?: boolean;
	showArrowsPrevious?: boolean;
	disableArrowNext?: boolean;
	disableArrowPrevious?: boolean;
	next?: () => void;
	previous?: () => void;
	submitButtonText?: string;
	denieButtonText?: string;
}

function ConfirmCancelButtons(props: props) {
	return (
		<div className='confirm-cancel-button-container'>
			{props.showArrows ? (
				<>
					{props.showArrowsPrevious &&
						<button
							disabled={props.disableArrowPrevious}
							className={`confirm-cancel-button arrow${props.disableArrowPrevious ? " disabled" : ""}`}
							type="button"
							onClick={props.previous}>
							<FontAwesomeIcon icon={faArrowLeft} />
						</button>}
					{props.showArrowsNext &&
						<button
							disabled={props.disableArrowNext}
							className={`confirm-cancel-button arrow${props.disableArrowNext ? " disabled" : ""}`}
							onClick={props.next}
							type="button">
							<FontAwesomeIcon icon={faArrowRight} />
						</button>}
				</>) : (
				<>
					<button className='confirm-cancel-button no' type="button" onClick={props.no}>{props.denieButtonText || "INDIETRO"}</button>
					<button className='confirm-cancel-button yes' onClick={props.yes} type={props.buttonType ?? "submit"}>{props.submitButtonText || "CONFERMA"}</button>
				</>
			)
			}
		</div>
	)
}

export default ConfirmCancelButtons