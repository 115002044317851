import { Association, Athlete, Team } from '../../models/models';

import './OrganaizerAssociationCom.scss';

import { useLocation } from 'react-router-dom';
import { useAuth } from '../../models/auth/AuthProvider';
import TableLinkRow from './TableComponents/TableLinkRow';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';
import TableTr from './TableComponents/TableTr';
import { useStateLocation } from '../../models/StateLocationProvider';

interface AssociationWithAthletes extends Association {
	athletes?: Athlete[];
	teams?: Team[];
}

interface props {
	association: AssociationWithAthletes;
	del: (association: Association) => void;
	edi: (association: Association) => void;
	lastId?: number;
}

function OrganaizerAssociationCom({ association, del, edi, lastId }: props) {
	const location = useStateLocation();
	const state = { ...location.state, association: association };
	const auth = useAuth();

	let editReq = () => {
		edi(association);
	}

	let deleteReq = () => {
		del(association);
	}

	return (
		<TableTr id={association.id} lastId={lastId}>
			<TableLinkRow navigate={{ path: `./${association.id}/Athletes`, state: state }} id={association.id} text={association.name} />
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} hideRemove={!auth.user.isOrganizer} />
		</TableTr >
	);
}

export default OrganaizerAssociationCom;	