import { Navigate, useLocation } from "react-router-dom";
import { useAuth, USER_TYPE } from "./AuthProvider";

export function RequireAuth({ children, types, entities }: {
	children: JSX.Element,
	types: string[],
	entities?: ("EventOrganizer" | "Association" | "Admin")[]
}) {
	const auth = useAuth();
	const location = useLocation();

	const userEntities: ("EventOrganizer" | "Association" | "Admin")[] = [];
	if (auth.user.isAssociation)
		userEntities.push('Association');

	if (auth.user.isOrganizer)
		userEntities.push('EventOrganizer');

	if (auth.user.info?.userName === "Admin")
		userEntities.push('Admin');


	if (!auth.user.auth ||
		!types.includes(auth.user.type) ||
		(entities && (!entities.some(e => userEntities.includes(e))))) {
		switch (types[0]) {
			case USER_TYPE.NONE:
				return <Navigate to={'/'} state={{ from: location }} replace />;

			case USER_TYPE.EVENTUSER:
				if (auth.user.info)
					return <Navigate to={`/Events/${auth.user.info.eventId}/Jury/Login`} state={{ from: location }} replace />;
				return <Navigate to={'/'} state={{ from: location }} replace />;


			case USER_TYPE.USER:
				return <Navigate to={'/User/Login'} state={{ from: location }} replace />;

			default:
				return <Navigate to={'/'} state={{ from: location }} replace />;
		}
	}

	return children;
}