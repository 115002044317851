import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { post } from '../../../models/backendReq';
import { Event, handleResponse, initEvent } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerEventsAddRequest.scss';

interface props {
	addCompleted: (event: Event) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerEventsAddRequest({ show, addCompleted }: props) {
	const inputNameError: ErrorType = { id: "Name", text: "Inserire il nome per l'evento" };
	const ownerIdError: ErrorType = { id: "OwnerId", text: "Definire un proprietario" };
	const startDateError: ErrorType = { id: "StartDate", text: "Definire una data di inizio evento" };
	const endDateError: ErrorType = { id: "EndDate", text: "Definire una data di fine evento" };
	const subscriptionEndDateError: ErrorType = { id: "SubscriptionEndDate", text: "Definire una data di fine iscrizioni" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const ownerId = auth.user.eventOrganizers && auth.user.eventOrganizers.length > 0 ? auth.user.eventOrganizers[0].id : 0;

	let [eventToAdd, setEventToAdd] = useState<Event>({ ...initEvent, ownerId: ownerId });
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);


	const firstFocusRef = useRef<HTMLInputElement>(null);

	let denied = () => {
		setEventToAdd({ ...initEvent, id: ownerId });
		show[1](false);
	}

	let addEvent = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (eventToAdd.name === "")
			_errors.push(inputNameError);

		if (eventToAdd.startDate === "")
			_errors.push(startDateError);

		if (eventToAdd.endDate === "")
			_errors.push(endDateError);

		if (eventToAdd.subscriptionEndDate === "")
			_errors.push(subscriptionEndDateError);

		if (eventToAdd.ownerId === 0)
			_errors.push(ownerIdError);

		setInputErrors(_errors);
		if (_errors.length > 0)
			return
		await post.event(eventToAdd.ownerId, eventToAdd.name, eventToAdd.startDate, eventToAdd.endDate, eventToAdd.subscriptionEndDate, eventToAdd.subscriptionStartDate)
			.then(res =>
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante l'inserimento dell'evento",
					"Evento creato correttamente",
					(data) => {
						addCompleted(data);
						setEventToAdd(initEvent);
					},
					setInputErrors
				));
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci nuovo evento' submit={addEvent}>
			<p className='organizer-event-add-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="Name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-event-add-request-form-input'
				value={eventToAdd.name}
				name="name"
				style={{ resize: 'vertical' }}
				onChange={(e) => {
					const { name, value } = e.target;
					setEventToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-event-add-request-form-input-label'>Data inizio</p>
			<InputWithAlert
				errorId="StartDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-add-request-form-input'
				dateSelected={eventToAdd.startDate}
				onDateChange={(e) => { setEventToAdd(prevState => ({ ...prevState, startDate: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
			/>
			<p className='organizer-event-add-request-form-input-label'>Data fine</p>
			<InputWithAlert
				errorId="EndDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-add-request-form-input'
				dateSelected={eventToAdd.endDate}
				onDateChange={(e) => { setEventToAdd(prevState => ({ ...prevState, endDate: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
			/>
			<p className='organizer-event-add-request-form-input-label'>Data inizio iscrizioni (facoltativo)</p>
			<InputWithAlert
				errorId="SubscriptionStartDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-add-request-form-input'
				dateSelected={eventToAdd.subscriptionStartDate}
				onDateChange={(e) => { setEventToAdd(prevState => ({ ...prevState, subscriptionStartDate: e === "" ? undefined : e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				showTimeInput
			/>
			<p className='organizer-event-add-request-form-input-label'>Data fine iscrizioni</p>
			<InputWithAlert
				errorId="SubscriptionEndDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-add-request-form-input'
				dateSelected={eventToAdd.subscriptionEndDate}
				onDateChange={(e) => { setEventToAdd(prevState => ({ ...prevState, subscriptionEndDate: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				showTimeInput
			/>
			{
				auth.user.eventOrganizers && auth.user.eventOrganizers.length > 1 && (
					<>
						<p className='organizer-event-add-request-form-input-label'>Organizzatore</p>
						<InputWithAlert
							errorId="OwnerId"
							errors={inputErrors}
							className='organizer-event-add-request-form-input'
							value={eventToAdd.ownerId}
							name='ownerId'
							onSelectChange={(e) => {
								const { name, value } = e.target;

								setEventToAdd(prevState => ({ ...prevState, [name]: parseInt(value) }));
							}}
							setErrors={setInputErrors}
							type='select'>
							<option value={0}>Selezione un proprietario</option>
							{
								auth.user.eventOrganizers?.map(eo => (
									<option key={eo.id} value={eo.id}>{eo.name}</option>
								))
							}
						</InputWithAlert>
					</>
				)
			}
		</CrudForm>
	);
}

export default OrganizerEventsAddRequest;