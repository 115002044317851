import { useLocation, useNavigate } from 'react-router-dom';
import { Association, Athlete, ExecutionTurn, Exercise, Team } from '../../models/models';


import '../../utility/prototype';
import './ExecutionTurnDetails.scss';
import { useStateLocation } from '../../models/StateLocationProvider';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

interface AthleteWithGroupId extends Athlete {
	groupId?: number;
}
interface TeamWithGroupId extends Team {
	groupId?: number;
}

interface ExecutionTurnWithAthletes extends ExecutionTurn {
	athletes: (AthleteWithGroupId | TeamWithGroupId)[];
	exercises: Exercise[];
}

interface props {
	executionturn: ExecutionTurnWithAthletes;
	associations: Association[];
}

function ExecutionTurnDetails(props: props) {
	const location = useStateLocation();

	const [showState, setShowState] = useState(false);

	const lastSelected = location.read<ExecutionTurnWithAthletes>("executionTurn")?.id === props.executionturn.id;

	return (
		<div className={'executionTurnDetails-container' + (lastSelected ? " ls" : "")}>
			<div className='executionTurnDetails-header'>
				<button className='executionTurnDetails-header-extend-button' onClick={() => setShowState(!showState)}>
					<FontAwesomeIcon icon={showState ? faChevronUp : faChevronDown} />
				</button>
				<div className='executionTurnDetails-header-name'>
					{props.executionturn.name}
				</div>
				<div className='executionTurnDetails-header-number'>
					<div className='executionTurnDetails-header-number-text'>atleti</div>
					<div className='executionTurnDetails-header-number-value'>{props.executionturn.athletes.length}</div>
				</div>
				<NavLink
					className='executionTurnDetails-liveButton'
					to={`./Turn/${props.executionturn.id}`} state={{ ...location.state, executionTurn: props.executionturn }}>
					LIVE
					<FontAwesomeIcon icon={faChevronRight} />
				</NavLink>
			</div>
			{showState && <ul>{
				props.executionturn.groups?.map(group => {
					return (
						<div key={group.id} className='executionTurnDetails-group'>
							<div className='executionTurnDetails-group-name'>{group.name}</div>
							{
								props.executionturn.athletes.filter(a => a?.groupId === group.id).athleteFilter().map(athlete => {
									const name = ("firstName" in athlete) ? athlete.firstName + ' ' + athlete.lastName : athlete.name;
									return (
										<li key={athlete.id} className='executionTurnDetails-athlete'>
											<div className='executionTurnDetails-athlete-association'>
												{props.associations?.find(a => athlete.ownerId === a.id)?.name}
											</div>
											<div className='executionTurnDetails-athlete-name'>{name}</div>
										</li>
									)
								})
							}
						</div>
					)
				})}
			</ul>}
		</div>
	)
}

export default ExecutionTurnDetails