import { useState } from "react";

export const useLocalStorage = (keyName: string, defaultValue: any | null) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const value = window.localStorage.getItem(keyName);

			if (value) {
				let readValue = JSON.parse(value);
				return readValue;
			} else {
				let value = defaultValue;
				window.localStorage.setItem(keyName, JSON.stringify(value));
				return defaultValue;
			}
		} catch (err) {
			return defaultValue;
		}
	});
	const setValue = (newValue: any) => {
		try {
			let value = newValue;
			window.localStorage.setItem(keyName, JSON.stringify(value));
		} catch (err) { }
		setStoredValue(newValue);
	};
	return [storedValue, setValue];
};