import React, { useEffect, useRef } from 'react'
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';

interface props {
	children: React.ReactNode;
	id?: number;
	lastId?: number;
}

function TableTr(props: props) {
	const ref = useRef<HTMLTableRowElement>(null);

	useOnClickOutside(ref, () => {
		const trElement = document.getElementById(`tr-${props.id?.toString()}`);
		if (trElement && props.id && props.id === props.lastId) {
			trElement!.style.color = '#000';
			trElement!.style.boxShadow = 'none';
			trElement!.style.backgroundColor = 'transparent';
			trElement!.style.textDecoration = 'none';
		}
	});

	useEffect(() => {
		const trElement = document.getElementById(`tr-${props.id?.toString()}`);
		if (trElement && props.id && props.id === props.lastId) {
			trElement!.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest'
			});

			trElement!.style.color = '#2F4858';
			trElement!.style.boxShadow = '0 0 3px #2F4858';
			trElement!.style.backgroundColor = '#2F485822';
			trElement!.style.textDecoration = 'underline';
		}
	}, [props.id]);

	return (
		<tr ref={ref} className='table-tr' id={`tr-${props.id?.toString()}`}>
			{props.children}
		</tr>
	)
}

export default TableTr