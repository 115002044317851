import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { FloorMusicMetadata, handleResponse } from '../../../models/models';
import './OrganizerFloorMusicsDeleteRequest.scss';

interface props {
	floorMusicMetadata?: FloorMusicMetadata;
	deletionCompleted: (floorMusicMetadata: FloorMusicMetadata) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerFloorMusicsDeleteRequest({ floorMusicMetadata, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteFloorMusicMetadata = async (e: React.FormEvent) => {
		if (!floorMusicMetadata) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.floorMusic(floorMusicMetadata.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione del brano",
					"Brano cancellato correttamente",
					() => deletionCompleted(floorMusicMetadata)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE L'UTENTE" submit={deleteFloorMusicMetadata}>
			<p className='organizer-floorMusicMetadata-delete-request-form-floorMusicMetadata-name'>{floorMusicMetadata?.fileName}</p>
		</CrudForm>
	);
}

export default OrganizerFloorMusicsDeleteRequest;