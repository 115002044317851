import { faAdd, faChevronDown, faChevronUp, faClose, faFileAudio, faMusic, faTable, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Exercise, ExerciseType, handleResponse } from '../../../models/models';
import './OrganizerCompetitionAthleteSetupExeCom.scss';
import { useContext, useRef, useState } from 'react';
import ModalWindow from '../../ModalWindow';
import { del, put } from '../../../models/backendReq';
import { useAuth } from '../../../models/auth/AuthProvider';
import { useAlert } from '../../../models/AlertProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import EventEoContext from '../../../contexts/EventEoContext';
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';
import ToolTip from '../../ToolTip';
import { NavLink } from 'react-router-dom';
import { useStateLocation } from '../../../models/StateLocationProvider';

interface props {
	exerciseType: ExerciseType;
	exercises: Exercise[];
	deleteExercise: (exercise?: Exercise) => void;
	addExercise: (exerciseTypeId: number, n: number) => void;
	isMono?: boolean;
	disableAdd?: boolean;
	disableDelete?: boolean;
}

function OrganizerCompetitionAthleteSetupExeCom({
	exercises,
	exerciseType,
	deleteExercise,
	addExercise,
	isMono,
	disableAdd,
	disableDelete }: props) {
	const context = useContext(EventEoContext);
	const n = exercises.length;

	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();
	const location = useStateLocation();

	const [showMusicSelection, setShowMusicSelection] = useState(false);
	const [floorMusicMetadataId, setFloorMusicMetadataId] = useState(0);

	const _closeMusicSelection = () => {
		const element = document.getElementById("exercise-music-selection");
		if (!element) {
			setShowMusicSelection(false);
			return;
		}

		const animationKeyFrames = {
			maxHeight: [element.getBoundingClientRect().height + "px", "0px"]
		};

		const animationOption: KeyframeAnimationOptions = {
			duration: 300,
			iterations: 1,
			easing: 'linear'
		}
		document.getElementById("exercise-music-selection")?.animate(animationKeyFrames, animationOption);
		setTimeout(() => setShowMusicSelection(false), 300);
	}

	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, _closeMusicSelection);

	const addDisabled = disableAdd || (isMono && n >= 1);
	const delDisabled = disableDelete || n <= 0;

	const _addMusic = async (exerciseId: number) => {
		if (exerciseId === 0 || floorMusicMetadataId === 0)
			return;

		const res = await put.floormusic(exerciseId, floorMusicMetadataId);
		handleResponse(res, auth, alert, navigate, "Errore l'associazione del brano", undefined,
			() => {
				const exerciseToEdit = context.exercises.value.find(e => e.id === exerciseId);
				if (exerciseToEdit)
					context.exercises.crud.edit({ ...exerciseToEdit, floorMusicMetadataId });
			}
		);
	};
	const _deleteMusic = async (exerciseId: number) => {
		if (exerciseId === 0)
			return;

		const res = await del.floorMusicExercise(exerciseId);
		handleResponse(res, auth, alert, navigate, "Errore durante la rimozione del brano", undefined,
			() => {
				const exerciseToEdit = context.exercises.value.find(e => e.id === exerciseId);
				if (exerciseToEdit)
					context.exercises.crud.edit({ ...exerciseToEdit, floorMusicMetadataId: undefined })
			}
		);
	};

	const handleMusicClick = () => {
		if (n <= 0)
			return;

		// if (n === 1 && exercises[0].floorMusicMetadataId) {
		// 	_deleteMusic(exercises[0].id);
		// 	return;
		// }

		setShowMusicSelection(true);
	}

	const musicsToShow = context.floormusics.value
		.filter(fm => exercises.some(x => x.athlete?.ownerId === fm.associationId));

	return (
		<div className='organizer-competition-setup-athlete-exercise-container-parent'>
			<div className='organizer-competition-setup-athlete-exercise-container-et'>
				{exerciseType.displayName}
			</div>
			<div className={`organizer-competition-setup-athlete-exercise${n > 0 ? " active" : ""}`}>
				<div className='organizer-competition-setup-athlete-exercise-fullname'>
					<button className={exercises.filter(x => x.floorMusicMetadataId).length > 0 ? "hasMusic" : ""} onClick={handleMusicClick}>
						{n > 0 && <FontAwesomeIcon icon={faMusic} />}
					</button>
					<button className={delDisabled ? "disabled" : ""} onClick={() => !delDisabled && deleteExercise(exercises[0])}>
						{!delDisabled && <FontAwesomeIcon icon={n > 1 ? faChevronDown : faClose} />}
					</button>
					<div className='organizer-competition-setup-athlete-exercise-name'>{!isMono ? n : n > 0 ? "ok" : ""}</div>
					<button className={addDisabled ? "disabled" : ""} onClick={() => !addDisabled && addExercise(exerciseType.id, 1)}>
						{!addDisabled && <FontAwesomeIcon icon={!isMono ? faChevronUp : faAdd} />}
					</button>
				</div>
			</div>
			{showMusicSelection &&
				<div ref={ref} id='exercise-music-selection' className='organizer-competition-setup-athlete-exercise-music-selection-container'>
					<div>
						<div>
							{musicsToShow.length > 0 ? <select value={floorMusicMetadataId} onChange={e => setFloorMusicMetadataId(parseInt(e.target.value))}>
								<option value={0}>---</option>
								{musicsToShow.map(fm => <option key={fm.id} value={fm.id}>{fm.fileName}</option>)}
							</select> : <div>Non ci sono <NavLink state={location.state} to='../musics'>musiche</NavLink> caricate</div>}
						</div>
						{exercises.map((exercise, index) => {
							const music = context.floormusics.value.find(fm => fm.id === exercise.floorMusicMetadataId);
							return <div key={exercise.id} className='organizer-competition-setup-athlete-exercise-music-selection-container-exercise-music'>
								<ToolTip text={music?.fileName} timeOnEnter={1000}>
									<FontAwesomeIcon className={`file-name${music ? " music-active" : ""}`} icon={faFileAudio} />
								</ToolTip>
								<span className={`execution-number${music ? " music-active" : ""}`}>{`Esecuzione n°${index + 1}`}</span>
								<button className={floorMusicMetadataId > 0 || music ? "active" : ""} onClick={() => music ? _deleteMusic(exercise.id) : _addMusic(exercise.id)}>
									<FontAwesomeIcon icon={music ? faTrash : faUpload} />
								</button>
							</div>
						})}
						<div>
							<button className='organizer-competition-setup-athlete-exercise-music-selection-container-close' onClick={() => _closeMusicSelection()}>Chiudi</button>
						</div>
					</div>
				</div>}
		</div>
	)
}

export default OrganizerCompetitionAthleteSetupExeCom