import { faArrowLeft, faClose, faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import SearchInput from '../../../../components/Input/SearchInput';
import { MESSAGE_TYPE, useAlert } from '../../../../models/AlertProvider';
import { get } from '../../../../models/backendReq';
import { Association, Athlete, Competition, responseGetJson } from '../../../../models/models';
import '../../../../utility/prototype';
import './OrganizerCompetitionAddAthleteRequest.scss';
import AthleteSelection from '../../../../components/Organizer/AthleteSelection';
import CrudForm from '../../../../components/Organizer/TableComponents/CrudForm';
import { checkCategory } from '../../../../utility/UtilityFunctions';
import WarningPopup from '../../../../components/WarningPopup';

interface props {
	addCompleted: (athletes: Athlete[]) => void;
	associations: Association[];
	athletes: Athlete[];
	eventAthletes: Athlete[];
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerCompetitionAddAthleteRequest({ addCompleted, athletes, associations, eventAthletes, show }: props) {
	const alert = useAlert();

	let [associationsFilter, setAssociationFilter] = useState("");
	let [athleteFilter, setAthleteFilter] = useState("");

	let [searchAssociationId, setSearchAssociationId] = useState<number>(0);
	let [AthletesToInsert, setAthletesToInsert] = useState<Athlete[]>([]);
	let [athleteByAssociation, setAthleteByAssociation] = useState<Athlete[]>();

	const associationsToShow = associations?.associationFilter(associationsFilter);
	const athletesToShow = athleteByAssociation?.athleteFilter(athleteFilter);
	const athleteIds = athletes.map(y => y.id);

	let addAthlete = () => {

		if (AthletesToInsert.length === 0) {
			alert.show("Selezionare un atleta", MESSAGE_TYPE.WARNING, 2000, true, true);
			return
		}
		addCompleted(AthletesToInsert);

		setSearchAssociationId(0);
		setAthletesToInsert([]);
	};
	const addRequest = async (e?: React.FormEvent) => {
		e?.preventDefault();

		addAthlete();
	}

	let denied = () => {
		setSearchAssociationId(0);
		setAthletesToInsert([]);
		setAthleteByAssociation(undefined);
		setAthleteFilter("");
		setAssociationFilter("");
		show[1](false);
	}

	useEffect(() => {
		const getAthletes = async () => {

			// setAthletesToInsert([]);
			if (searchAssociationId !== 0) {
				let athleteByAssociationFromServer = await get.athletesByAssociation(searchAssociationId, ["Owner"])
					.then(r => responseGetJson(r, []));
				setAthleteByAssociation(athleteByAssociationFromServer);
			} else {
				setAthleteByAssociation(undefined);
			}

		}

		getAthletes();
	}, [searchAssociationId]);

	return (
		<CrudForm denied={denied} back={denied} show={show} submit={(e) => addRequest(e)} headerText='Inserisci atleti'>
			<div className='organizer-competition-athlete-add-request-form-container'>
				<div className={`organizer-competition-athlete-add-request-form-associations${!athleteByAssociation ? " selected" : ""}`}>
					<label className='organizer-competition-athlete-add-request-form-header-association-header'>Società:</label>
					<SearchInput placeholder='Cerca società' onChange={(e) => setAssociationFilter(e.target.value)} />
					<div className='organizer-competition-athlete-add-request-form-associations-container'>
						{associationsToShow.map(a => {
							let isSelected = searchAssociationId === a.id ? " selected" : ""
							return (
								<div
									key={a.id}
									onClick={() => setSearchAssociationId(a.id)}
									className={`organizer-competition-athlete-add-request-form-header-association${isSelected}`}
								>
									{a.name}
								</div>
							)
						})}
					</div>

				</div>

				<div className={`organizer-competition-athlete-add-request-form-athletes${athleteByAssociation ? " selected" : ""}`}>
					<div className='organizer-competition-athlete-add-request-form-header-athlete-header'>
						<button className='organizer-competition-athlete-add-request-form-header-athlete-button' type='button' onClick={() => setSearchAssociationId(0)}>
							<FontAwesomeIcon icon={faArrowLeft} />
						</button>
						<label >Atleti:</label>
					</div>
					<div className='organizer-competition-athlete-add-request-form-interactions-header'>
						<SearchInput placeholder='Cerca atleta' onChange={(e) => setAthleteFilter(e.target.value)} />
					</div>
					<div className='organizer-competition-athlete-add-request-form-athletes-container'>
						{athletesToShow?.map(a => {
							const included = AthletesToInsert.map(x => x.id).includes(a.id);
							return (
								<AthleteSelection
									key={a.id}
									athlete={a}
									setAthletesToInsert={setAthletesToInsert}
									isInOtherCompetitions={eventAthletes.map(a => a.id).includes(a.id)}
									isInThisCompetition={athleteIds.includes(a.id)}
									selected={included || athleteIds.includes(a.id)}
								/>
							)
						})}
					</div>
					<div style={{ marginTop: "10px" }}>Atleti selezionati: {" " + AthletesToInsert.length}</div>
				</div>
			</div>
		</CrudForm>
	);
}

export default OrganizerCompetitionAddAthleteRequest;