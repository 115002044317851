import { faBars, faBuilding, faCalendarDay, faChevronDown, faClose, faGavel, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import GalagoImg from '../img/Logo.png';
import { USER_TYPE, useAuth } from '../models/auth/AuthProvider';
import useOnClickOutside from '../models/hooks/useOnClickOutside';
import './NavBar.scss';

/** ################################################ */
/**
	Navigation bar development, services to offer:
		Shrink/Expand (Via menù/close icon)
		Logo (With home page access)
		Events (filtered by regions )
		Championships
		Signin/Signout


*/
/** ################################################ */

function UserComponent() {
	const [showMenu, setShowMenu] = useState(false);
	const auth = useAuth();
	const navigate = useNavigate();
	const containerRef = useRef<HTMLLIElement>(null);
	useOnClickOutside(containerRef, () => setShowMenu(false));

	const signout = () => {
		if (auth.user.type === USER_TYPE.EVENTUSER) {
			auth.signout();
			navigate(`/Events/${auth.user.info.eventId}/Jury/Login`);
		} else {
			auth.signout();
		}
	}

	return (
		!auth.user.auth ? (
			<li ref={containerRef} id='login'>
				<NavLink className='login-link signin' to={'User/Login'}>
					<FontAwesomeIcon icon={faUser} />
					<div>Login</div>
				</NavLink>
			</li>
		) : (
			auth.user.type === USER_TYPE.EVENTUSER ? (
				<li ref={containerRef} id='login' onClick={() => setShowMenu(!showMenu)}>
					<div className='login-link'>
						<FontAwesomeIcon icon={faChevronDown} />
						<div>{auth.user.info.displayName}</div>
					</div>
					<div className={`sub-menù-container${showMenu ? " active" : ""}`}>
						<div className='header-login-menu-item' onClick={() => navigate(`/Events/${auth.user.info.eventId}/JurySelection`)}>
							<FontAwesomeIcon style={{ width: '100%' }} icon={faGavel} />
							<div>Pagina giuria</div>
						</div>
						<div className='header-logout-btn' onClick={signout} >
							<FontAwesomeIcon style={{ width: '100%' }} icon={faSignOut} />
							<div>Logout</div>
						</div>
					</div>
				</li>
			) : (
				<li ref={containerRef} id='login' onClick={() => setShowMenu(!showMenu)}>
					<div className={`login-link`}>
						<FontAwesomeIcon icon={faChevronDown} />
						<div>{auth.user.info.displayName}</div>
					</div>
					<div className={`sub-menù-container${showMenu ? " active" : ""}`}>
						<div className='header-login-menu-item' onClick={() => navigate('/Events')}>
							<FontAwesomeIcon style={{ width: '100%' }} icon={faGavel} />
							<div>Pagina giuria</div>
						</div>
						<div className='header-login-menu-item' onClick={() => navigate('/User/Events')}>
							<FontAwesomeIcon style={{ width: '100%' }} icon={faCalendarDay} />
							<div >Eventi</div>
						</div>
						{/* <div className='header-login-menu-item' onClick={() => navigate('/Utente/Campionati')}>
										<FontAwesomeIcon style={{ width: '100%' }} icon={faTrophy} />
										<div >Campionati</div>
									</div> */}
						<div className='header-login-menu-item' onClick={() => navigate('/User/Association')}>
							<FontAwesomeIcon style={{ width: '100%' }} icon={faBuilding} />
							<div>Società </div>
						</div>
						<div className='header-logout-btn' onClick={signout}>
							<FontAwesomeIcon style={{ width: '100%' }} icon={faSignOut} />
							<div>Logout</div>
						</div>
					</div>
				</li>
			)
		)
	)
}

function NavBar() {
	const [responsive, setResponsive] = useState(false);
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const containerRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(containerRef, () => setResponsive(false));

	useEffect(() => {
		setResponsive(false)
	}, [location])

	return (
		<nav>
			<div id='NavLogo'>
				<NavLink
					to='/'
					onClick={() => {
						navigate('/');
						if (auth.user.type === USER_TYPE.EVENTUSER)
							auth.signout();
					}}>
					<img className='galago-png' src={GalagoImg} alt="" />

				</NavLink>
			</div>
			<div ref={containerRef}>
				<ul id='NavElements' className={responsive ? "active" : ""}>
					<li>
						<NavLink to={'/Events'}>Eventi</NavLink>
					</li>
					<UserComponent />
				</ul>
			</div>
			{/* Responsive div */}
			<div id='Responsive' className={responsive ? "active" : ""} onClick={() => setResponsive(!responsive)}>
				<FontAwesomeIcon icon={responsive ? faClose : faBars} />
			</div>
		</nav>
	)
}

export default NavBar