import { useContext } from 'react';
import OrganizerCompetitionTable from '../../../components/Organizer/TableComponents/OrganizerCompetitionTable';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import { COMP_TYPE } from '../../../models/backendReq';
import { competitionWithTeamLinks, competitionWithoutTeamLinks } from '../SubNavBarLinks';
import './OrganizerCompetitionInfo.scss';

function OrganizerCompetitionInfo() {

	const { competition } = useContext(CompetitionEoContext);

	const links = competition.type !== COMP_TYPE.Individual ? competitionWithTeamLinks : competitionWithoutTeamLinks;

	return (
		<OrganizerCompetitionTable text='GARA'>
			<div className='organizer-competition-info-container'>
				<h3>{competition.name}</h3>
			</div>
		</OrganizerCompetitionTable>
	)
}

export default OrganizerCompetitionInfo