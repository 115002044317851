import { useContext, useState } from 'react';
import OrganizerExecutionGroupMan from '../../../components/Organizer/ExecutionGroup/OrganizerExecutionGroupMan';
import OrganaizerExecutionGroupCom from '../../../components/Organizer/OrganaizerExecutionGroupCom';
import OrganizerExecutionGroupsAddRequest from './OrganizerExecutionGroupsAddRequest';
import OrganizerExecutionGroupsDeleteRequest from './OrganizerExecutionGroupsDeleteRequest';
import OrganizerExecutionGroupsEditRequest from './OrganizerExecutionGroupsEditRequest';


import { ExecutionGroup, Exercise } from '../../../models/models';


import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import OrganizerExecutionTurnTable from '../../../components/Organizer/TableComponents/OrganizerExecutionTurnTable';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableNameCell from '../../../components/Organizer/TableComponents/TableNameCell';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../../contexts/EventEoContext';
import ExecutionTurnEoContext from '../../../contexts/ExecutionTurnEoContext';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerExecutionGroupsScreen.scss';
import ConditionalListComponent from '../../../components/ConditionalListComponent';

function OrganizerExecutionGroupsScreen() {
	const eventContext = useContext(EventEoContext);
	const executionturnContext = useContext(ExecutionTurnEoContext);

	let [executiongroupsFilterName, setExecutionGroupsFilterName] = useState("");
	let [executiongroupsToDel, setExecutionGroupToDel] = useState<ExecutionGroup>();
	let [executiongroupsToPut, setExecutionGroupToPut] = useState<ExecutionGroup>();
	let [executionGroupToMan, setExecutionGroupToMan] = useState<ExecutionGroup>()

	let showDel = useState(false);
	let showAdd = useState(false);
	let showFilters = useState(false);
	let showEdi = useState(false);


	let handelExpand = (executiongroup: ExecutionGroup) => {
		setExecutionGroupToMan(executiongroup)
	}

	let deleteRequest = (executiongroups: ExecutionGroup) => {
		showDel[1](true);
		setExecutionGroupToDel(executiongroups);
	}
	let deletionCompleted = (executiongroup: ExecutionGroup) => {
		executionturnContext.executiongroups.crud.delete(executiongroup);
		showDel[1](false)
	}
	let addCompleted = (executiongroup: ExecutionGroup) => {
		executionturnContext.executiongroups.crud.add(executiongroup);
		showAdd[1](false)
	}
	let editRequest = (executiongroup: ExecutionGroup) => {
		showEdi[1](true);
		setExecutionGroupToPut(executiongroup);
	}
	let editCompleted = (executiongroup: ExecutionGroup) => {
		executionturnContext.executiongroups.crud.edit(executiongroup);
		showEdi[1](false)
	}

	let executiongroupsToShow = executionturnContext.executiongroups.value.filter(x => x.name.toLowerCase().includes(executiongroupsFilterName.toLowerCase()));

	let sortRule = (a: ExecutionGroup, b: ExecutionGroup) => {
		return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
	};

	const groupUpdate = (groupId: number, exercises: Exercise[]) => {
		const index = executionturnContext.executiongroups.value.findIndex(eg => eg.id === groupId);
		if (index === -1)
			return
		executionturnContext.executiongroups.crud.edit({ ...executionturnContext.executiongroups.value[index], exercises });
	}

	const _filters = [
		{ name: 'Gruppo', set: setExecutionGroupsFilterName, value: executiongroupsFilterName }
	];

	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi gruppo' },
		{ ...toolBarElements.filterElement, callback: () => showFilters[1](!showFilters[0]), notNumber: _filters.filter(f => f.value !== "").length }
	];

	return (
		<OrganizerExecutionTurnTable text='GRUPPI'>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar show={showFilters} filters={_filters} found={_toolBarElements[1].notNumber! > 0 ? executiongroupsToShow.length : undefined} />
			<Table>
				<thead>
					<tr>
						<TableNameCell text='Nome' isHeader />
						<th className='organizer-executiongroup-col-count'>Esercizi</th>
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={executiongroupsToShow.sort(sortRule)}
						emptyMesssage='Nessun gruppo creato per questo turno'
						renderList={executiongroups => executiongroups.map(executiongroup =>
							<OrganaizerExecutionGroupCom
								executiongroup={executiongroup}
								del={deleteRequest}
								edi={editRequest}
								exp={handelExpand}
								key={executiongroup.id}
							/>
						)} />
				</tbody>
			</Table>
			<OrganizerExecutionGroupsDeleteRequest executiongroup={executiongroupsToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerExecutionGroupsAddRequest executionturn={executionturnContext.executionturn} show={showAdd} addCompleted={addCompleted} />
			<OrganizerExecutionGroupsEditRequest executiongroup={executiongroupsToPut} show={showEdi} editCompleted={editCompleted} />
			<OrganizerExecutionGroupMan event={eventContext.event} executiongroup={executionGroupToMan} exerciseTypes={eventContext.exercisetypes} setExecutionGroupToMan={setExecutionGroupToMan} groupUpdate={groupUpdate} />
		</OrganizerExecutionTurnTable>
	);
}

export default OrganizerExecutionGroupsScreen;