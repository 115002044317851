import React, { useEffect, useRef, useState } from 'react'
import './ToolTip.scss'

interface props {
	text?: React.ReactNode;
	children: React.ReactNode;
	timeOnEnter?: number;
	timeOnLeave?: number;
	className?: string;
}
function ToolTip({ children, text, className, timeOnEnter, timeOnLeave }: props) {
	const [show, setShow] = useState(false)
	const ref = useRef<HTMLSpanElement>(null)


	let enterTime: NodeJS.Timeout;
	let leaveTime: NodeJS.Timeout;

	const enter = () => {
		clearTimeout(leaveTime);
		enterTime = setTimeout(() => { text && setShow(true) }, timeOnEnter);
	}
	const leave = () => {
		clearTimeout(enterTime);
		leaveTime = setTimeout(() => { setShow(false) }, timeOnLeave);
	}

	useEffect(() => {
		if (ref.current) {
			ref.current.addEventListener("mouseenter", enter);
			ref.current.addEventListener("mouseleave", leave);
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener("mouseenter", enter);
				ref.current.removeEventListener("mouseleave", leave);
			}
		};
	}, []);

	return (
		<span ref={ref} className={`${className} tool-tip-parent`}>
			{children}
			<div className={`tool-tip ${show ? 'show' : ''}`}>{text}</div>
		</span>
	)
}

export default ToolTip