import { createContext } from "react";
import { Association, CompetitionLevel, Crud, EventOrganizer, ExerciseType } from "../models/models";

export interface AssociationWithEventorganizer extends Association {
	eventorganizer: EventOrganizer;
	eventorganizerId: number;
}

export interface OrganizerConfigurationsContext {
	loading: boolean,
	competitionLevels: { value: CompetitionLevel[], crud: Crud<CompetitionLevel | CompetitionLevel[]> },
	exercisetypes: { value: ExerciseType[], crud: Crud<ExerciseType | ExerciseType[]> },
	associations: { value: AssociationWithEventorganizer[], crud: Crud<AssociationWithEventorganizer | AssociationWithEventorganizer[]> },
}

export default createContext<OrganizerConfigurationsContext>(null!);