import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { Event, handleResponse, initEvent } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerEventsEditRequest.scss';

interface props {
	event?: Event;
	editCompleted: (event: Event) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerEventsEditRequest({ event, show, editCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per l'evento" };
	const startDateError: ErrorType = { id: "StartDate", text: "Definire una data di inizio evento" };
	const endDateError: ErrorType = { id: "EndDate", text: "Definire una data di fine evento" };
	const subscriptionEndDateError: ErrorType = { id: "SubscriptionEndDate", text: "Definire una data di fine iscrizioni" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [eventToPut, setEventToPut] = useState<Event>(event ? event : initEvent);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (event)
			setEventToPut(event);
	}, [event]);

	let editEvent = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (eventToPut.name === "") {
			_errors.push(inputNameError);
		}

		if (eventToPut.startDate === "")
			_errors.push(startDateError);

		if (eventToPut.endDate === "")
			_errors.push(endDateError);

		if (eventToPut.subscriptionEndDate === "")
			_errors.push(subscriptionEndDateError);

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await put.event(eventToPut.id, eventToPut.name, eventToPut.startDate, eventToPut.endDate, eventToPut.subscriptionEndDate, eventToPut.subscriptionStartDate)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica dell'evento",
					"Evento modificato correttamente",
					() => editCompleted(eventToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica l'evento" submit={editEvent}>
			<p className='organizer-event-edit-request-form-old-name'>{event?.name}</p>
			<p className='organizer-event-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-event-edit-request-form-input'
				value={eventToPut.name}
				name="name"
				style={{ resize: 'vertical' }}
				onChange={(e) => {
					const { name, value } = e.target;

					setEventToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-event-edit-request-form-input-label'>Data inizio</p>
			<InputWithAlert
				errorId="StartDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-edit-request-form-input'
				dateSelected={eventToPut.startDate}
				onDateChange={(e) => { setEventToPut(prevState => ({ ...prevState, startDate: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
			/>
			<p className='organizer-event-edit-request-form-input-label'>Data fine</p>
			<InputWithAlert
				errorId="EndDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-edit-request-form-input'
				dateSelected={eventToPut.endDate}
				onDateChange={(e) => { setEventToPut(prevState => ({ ...prevState, endDate: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
			/>
			<p className='organizer-event-add-request-form-input-label'>Data inizio iscrizioni</p>
			<InputWithAlert
				errorId="SubscriptionStartDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-add-request-form-input'
				dateSelected={eventToPut.subscriptionStartDate}
				onDateChange={(e) => { setEventToPut(prevState => ({ ...prevState, subscriptionStartDate: e === "" ? undefined : e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				showTimeInput
			/>
			<p className='organizer-event-add-request-form-input-label'>Data fine iscrizioni</p>
			<InputWithAlert
				errorId="SubscriptionEndDate"
				errors={inputErrors}
				className='datepicker-classname organizer-event-add-request-form-input'
				dateSelected={eventToPut.subscriptionEndDate}
				onDateChange={(e) => { setEventToPut(prevState => ({ ...prevState, subscriptionEndDate: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				showTimeInput
			/>
		</CrudForm>
	);
}

export default OrganizerEventsEditRequest;