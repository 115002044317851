import React from 'react'
import './TableDateCell.scss'
import { convertDate } from '../../../models/dateAndTime'

interface props {
	hasTime?: boolean;
	isHeader?: boolean;
	date?: string;
	headerText?: string;
}
function TableDateCell(props: props) {
	const date = props.date ?? (new Date()).toISOString();
	return (
		props.isHeader ? (
			<th className='organizer-time-col'>{props.headerText}</th>
		) : (
			<td className='organizer-time-col rows'>
				<div>{convertDate(date, false, true, false)}</div>
				{props.hasTime && <div>{convertDate(date, true, false, true)}</div>}
			</td>
		)
	)
}

export default TableDateCell