import { Championship } from '../../models/models';

import './OrganaizerChampionshipCom.scss';

import TableNameCell from './TableComponents/TableNameCell';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';
;

interface props {
	championship: Championship;
	del: (championship: Championship) => void;
	edi: (championship: Championship) => void;
}

function OrganaizerChampionshipCom({ championship, del, edi }: props) {
	let editReq = () => {
		edi(championship);
	}

	let deleteReq = () => {
		del(championship);
	}

	return (
		<tr>
			<TableNameCell text={championship.name} />
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />
		</tr>
	);
}

export default OrganaizerChampionshipCom;