import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './EoToolBar.scss';

export interface eoToolbarProp {
	callback: VoidFunction,
	text: string,
	icon: IconProp,
	disabled?: boolean,
	selected?: boolean,
	reverse?: boolean,
	selectedIcon?: IconProp,
	notNumber?: number,
}

interface props {
	elements: eoToolbarProp[];
}

const classNameBuilder = (disabled?: boolean, reverse?: boolean) => {
	let cn = 'eotoolbar-element';
	if (disabled)
		cn += ' disabled';

	if (reverse)
		cn += ' reverse';

	return cn;
}

function EoToolBar(props: props) {
	return (
		props.elements.length > 0 ? (
			<div className={`eotoolbar-container`}>
				{props.elements.map((element, index) => (
					<button disabled={element.disabled} key={index} className={classNameBuilder(element.disabled, element.reverse)} onClick={element.callback}>
						<FontAwesomeIcon className='eotoolbar-element-icon' icon={element.selected && element.selectedIcon ? element.selectedIcon : element.icon} />
						<div className='eotoolbar-element-text'>{element.text}</div>
						{element.notNumber && element.notNumber > 0 ? <div className={`eotoolbar-element-notification`}>{element.notNumber}</div> : <></>}
					</button>
				))}
			</div>
		) : <></>
	)
}

export default EoToolBar