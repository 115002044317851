import { createContext } from "react";
import { Association, Athlete, Crud, EventOrganizer, Team } from "../models/models";

interface CrudData<T> {
	add: (data: T) => void;
	edit: (data: T) => void;
	delete: (data: T) => void;
}

export interface AssociationEoContextData {
	association: Association;
	athletes: { value: Athlete[], crud: CrudData<Athlete> };
	teams: { value: Team[], crud: CrudData<Team> };
}

export default createContext<AssociationEoContextData>(null!);