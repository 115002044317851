import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { Event, ExecutionTurn, handleResponse } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerExecutionTurnsEditRequest.scss';

interface props {
	executionturns?: ExecutionTurn;
	editCompleted: (executionturns: ExecutionTurn) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	event: Event;
}

function OrganizerExecutionTurnsEditRequest({ executionturns, show, editCompleted, event }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per il turno" };

	let initExecutionTurn: ExecutionTurn = { id: 0, name: "", eventId: 0, start: new Date().toISOString().split('T')[0], end: new Date().toISOString().split('T')[0] };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [executionturnsToPut, setExecutionTurnToPut] = useState<ExecutionTurn>(executionturns ? executionturns : initExecutionTurn);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (executionturns)
			setExecutionTurnToPut(executionturns);
	}, [executionturns]);

	let editExecutionTurn = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (executionturnsToPut.name === "") {
			_errors.push(inputNameError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await put.executionTurn(executionturnsToPut.id, executionturnsToPut.name, executionturnsToPut.start, executionturnsToPut.end)
			.then(async (res) => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica del turno",
					"Turno modificato correttamente",
					() => editCompleted(executionturnsToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica turno" submit={editExecutionTurn}>
			<p className='organizer-executionturns-edit-request-form-old-name'>{executionturns?.name}</p>

			<p className='organizer-executionturns-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				type='text'
				className='organizer-executionturns-edit-request-form-input'
				style={{ resize: 'vertical' }}
				value={executionturnsToPut.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setExecutionTurnToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-executionturns-edit-request-form-input-label'>Data inizio</p>
			<InputWithAlert
				errorId="start"
				errors={inputErrors}
				showTimeInput
				className='datepicker-classname organizer-executionturns-edit-request-form-input'
				dateSelected={executionturnsToPut.start}
				onDateChange={(e) => { setExecutionTurnToPut(prevState => ({ ...prevState, start: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				minDate={new Date(event.startDate)}
				maxDate={new Date(event.endDate)}
			/>
			<p className='organizer-executionturns-edit-request-form-input-label'>Data fine</p>
			<InputWithAlert
				errorId="end"
				errors={inputErrors}
				showTimeInput
				className='organizer-executionturns-edit-request-form-input'
				dateSelected={executionturnsToPut.end}
				onDateChange={(e) => { setExecutionTurnToPut(prevState => ({ ...prevState, end: e })); }}
				type='date'
				setErrors={setInputErrors}
				useDatePicker
				minDate={new Date(event.startDate)}
				maxDate={new Date(event.endDate)}
			/>
		</CrudForm>
	);
}

export default OrganizerExecutionTurnsEditRequest;