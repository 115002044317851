import { useLocation, useNavigate } from 'react-router-dom';
import { Competition, ExecutionTurn } from '../../models/models';

import './CompetitionTurn.scss';
import { convertDate } from '../../models/dateAndTime';

interface props {
	competition: Competition | ExecutionTurn;
}

function CompetitionTurn({ competition }: props) {
	const navigate = useNavigate();
	const location = useLocation();

	let UpdateCompetitionStorage = () => {
		const queryParams = new URLSearchParams(location.search);

		window.localStorage.setItem("Competition", JSON.stringify(competition));
		if ('start' in competition) {
			navigate({ pathname: `Turn`, search: `?${queryParams.toString()}` }, { state: { executionturn: competition } })
		} else {
			navigate({ pathname: `Competition`, search: `?${queryParams.toString()}` }, { state: { competition: competition } })
		}

	};

	return (
		<div
			onClick={UpdateCompetitionStorage}
			className='competition-turn-container'>
			{/* <FontAwesomeIcon className='competition-component-icon' icon={peopleIcon} /> */}
			<p className='competition-component-name'>{competition.name}</p>
			{'start' in competition && <div className='competition-component-turn-time'>
				{`${convertDate(competition.start, true, false, true)} / ${convertDate(competition.end, true, false, true)}`}
			</div>}
		</div>
	);
}

export default CompetitionTurn;