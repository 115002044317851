import React, { useState } from 'react';
import './AthletesSubscribedWindow.scss';
import { Association, Athlete, Competition, Team } from '../../models/models';
import ModalWindow from '../ModalWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


interface CompetitionWithCompetitors extends Competition {
	competitors: (Athlete | Team)[]
}
interface AssociationExtended extends Association {
	competitions: CompetitionWithCompetitors[];
}
interface props {
	association?: AssociationExtended;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function CompetitionsComponent({ competition }: { competition: CompetitionWithCompetitors }) {
	const [show, setShow] = useState(true);

	return (
		<div className={`athletes-subscribed-window-athletes-competition-container${show ? " show" : ""}`}>
			<div onClick={() => setShow(!show)} className='athletes-subscribed-window-athletes-competition-name'>
				<span>{competition.name}</span>
				<FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
			</div>
			{show && <div className='athletes-subscribed-window-athletes-container'>
				{competition.competitors.map(competitor => <div className='athletes-subscribed-window-athlete' key={competitor.id}>
					{"firstName" in competitor ? `${competitor.firstName} ${competitor.lastName}` : competitor.name}
				</div>)}
			</div>}
		</div>
	)
}

function AthletesSubscribedWindow(props: props) {
	return (
		<ModalWindow show={props.show} closeButton>
			<div className='athletes-subscribed-window-container'>
				<div className='athletes-subscribed-window-header-container'>
					<div>Iscrizioni società</div>
					<div>{props.association?.name}</div>
				</div>
				<div className='athletes-subscribed-window-competitions-container'>
					{
						props.association?.competitions.map(competition => <CompetitionsComponent key={competition.id} competition={competition} />)
					}
				</div>
			</div>
		</ModalWindow>
	)
}

export default AthletesSubscribedWindow