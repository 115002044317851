import { useEffect, useState } from 'react';
import OrganizerChampionshipMan from '../../../components/Organizer/Championship/OrganizerChampionshipMan';
import OrganaizerChampionshipCom from '../../../components/Organizer/OrganaizerChampionshipCom';
import OrganizerChampionshipsAddRequest from './OrganizerChampionshipsAddRequest';
import OrganizerChampionshipsDeleteRequest from './OrganizerChampionshipsDeleteRequest';
import OrganizerChampionshipsEditRequest from './OrganizerChampionshipsEditRequest';

import { get } from '../../../models/backendReq';

import { Championship, responseGetJson } from '../../../models/models';


import SearchInput from '../../../components/Input/SearchInput';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableContainer from '../../../components/Organizer/TableComponents/TableContainer';
import TableNameCell from '../../../components/Organizer/TableComponents/TableNameCell';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import { useAuth } from '../../../models/auth/AuthProvider';
import './OrganizerChampionshipsScreen.scss';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import { toolBarElements } from '../SubNavBarLinks';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import ConditionalListComponent from '../../../components/ConditionalListComponent';

function OrganizerChampionshipsScreen() {

	let [championshipsFilterName, setChampionshipsFilterName] = useState("");

	let [championships, setChampionships] = useState<Championship[]>([]);
	let [loadingChampionships, setLoadingChampionships] = useState(true);

	let [championshipsToDel, setChampionshipToDel] = useState<Championship>();
	let [championshipsToPut, setChampionshipToPut] = useState<Championship>();
	let [executionGroupToMan, setChampionshipToMan] = useState<Championship>()

	let showDel = useState(false);
	let showAdd = useState(false);
	let showEdi = useState(false);
	let showFilters = useState(false);

	let auth = useAuth();

	useEffect(() => {
		let getChampionships = async () => {
			let championshipsFromServer = await get.championships()
				.then(r => responseGetJson(r, []))
				.finally(() => setLoadingChampionships(false)) as Championship[];

			setChampionships(championshipsFromServer.filter(c => c.ownerId === auth.user.info.id));
		};

		getChampionships();
	}, []);

	let deleteRequest = (championships: Championship) => {
		showDel[1](true);
		setChampionshipToDel(championships);
	}
	let deletionCompleted = (championship: Championship) => {
		setChampionships([...championships].filter(x => x.id !== championship.id))
		showDel[1](false)
	}
	let addCompleted = (championship: Championship) => {
		let newChampionships = [...championships];
		newChampionships.push(championship);

		setChampionships(newChampionships)
		showAdd[1](false)
	}
	let editRequest = (championship: Championship) => {
		showEdi[1](true);
		setChampionshipToPut(championship);
	}
	let editCompleted = (championship: Championship) => {
		let newChampionships = [...championships];
		let index = newChampionships.findIndex(x => x.id === championship.id);
		newChampionships[index] = championship;

		setChampionships(newChampionships)
		showEdi[1](false)
	}

	let championshipsToShow = championships?.filter(x => x.name.toLowerCase().includes(championshipsFilterName.toLowerCase()));

	let sortRule = (a: Championship, b: Championship) => {
		return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
	};

	const _filters = [
		{ name: 'Campionato', set: setChampionshipsFilterName, value: championshipsFilterName }
	];

	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi campionato' },
		{ ...toolBarElements.filterElement, callback: () => showFilters[1](!showFilters[0]), notNumber: _filters.filter(f => f.value !== "").length }
	];

	return (
		<TableContainer headerText='CAMPIONATI' showLoading={loadingChampionships}>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar show={showFilters} filters={_filters} found={_toolBarElements[1].notNumber! > 0 ? championshipsToShow.length : undefined} />
			<Table>
				<thead>
					<tr>
						<TableNameCell text='Nome' isHeader />
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={championshipsToShow.sort(sortRule)}
						emptyMesssage='Nessun campionato presente'
						renderList={championships => championships.map(championship =>
							<OrganaizerChampionshipCom
								championship={championship}
								del={deleteRequest}
								edi={editRequest}
								key={championship.id}
							/>
						)} />
				</tbody>
			</Table>
			<OrganizerChampionshipsDeleteRequest championship={championshipsToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerChampionshipsAddRequest show={showAdd} addCompleted={addCompleted} />
			<OrganizerChampionshipsEditRequest championship={championshipsToPut} show={showEdi} editCompleted={editCompleted} />
			<OrganizerChampionshipMan championship={executionGroupToMan} setChampionshipToMan={setChampionshipToMan} />
		</TableContainer>
	);
}

export default OrganizerChampionshipsScreen;