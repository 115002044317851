import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ModalWindow from '../../ModalWindow';
import ConfirmCancelButtons from '../ConfirmCancelButtons';
import './CrudForm.scss';

interface props {
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	denied: VoidFunction;
	back: VoidFunction;
	submit?: (e: React.FormEvent) => Promise<void>;
	confirm?: VoidFunction;
	headerText?: string;
	children?: React.ReactNode;
	buttonType?: "button" | "submit" | "reset" | undefined;
	showArrows?: boolean;
	showArrowsNext?: boolean;
	showArrowsPrevious?: boolean;
	disableArrowNext?: boolean;
	disableArrowPrevious?: boolean;
	next?: () => void;
	previous?: () => void;
	submitButtonText?: string;
	denieButtonText?: string;
}

function CrudForm(props: props) {
	const [submitted, setSubmitted] = useState(false);

	const _submit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (submitted)
			return
		if (props.submit) {
			setSubmitted(true);
			await props.submit(e);
		}
		setSubmitted(false);
	}
	return (
		<ModalWindow show={props.show} noOverflow>
			<form onSubmit={_submit} className='organizer-request-form'>
				<div className='organizer-request-form-header'>
					<div className='organizer-request-form-header-text'>{props.headerText}</div>
					<button className='organizer-request-form-header-button' type='button' onClick={props.denied}>
						<FontAwesomeIcon icon={faClose} />
					</button>
				</div>
				{props.children}
				<ConfirmCancelButtons
					buttonType={props.buttonType}
					next={props.next}
					previous={props.previous}
					no={props.back}
					yes={props.confirm}
					showArrows={props.showArrows}
					showArrowsNext={props.showArrowsNext}
					showArrowsPrevious={props.showArrowsPrevious}
					disableArrowNext={props.disableArrowNext}
					disableArrowPrevious={props.disableArrowPrevious}
					submitButtonText={props.submitButtonText}
					denieButtonText={props.denieButtonText}
				/>
			</form>
		</ModalWindow>
	)
}

export default CrudForm