import React, { useContext } from 'react';
import EventEoContext from '../../../contexts/EventEoContext';
import ExecutionTurnEoContext from '../../../contexts/ExecutionTurnEoContext';
import { turnLinks } from '../../../screens/Organizer/SubNavBarLinks';
import TableContainer from './TableContainer';

interface props {
	children: React.ReactNode;
	text?: string;
	loading?: boolean;
}
function OrganizerExecutionTurnTable(props: props) {
	const eventContext = useContext(EventEoContext);
	const executionturnContext = useContext(ExecutionTurnEoContext);

	return (
		<TableContainer
			headerText={props.text}
			sectionName={`${eventContext.event.name} / ${executionturnContext.executionturn.name}`}
			sectionBackPath={`/User/Events/${eventContext.event.id}/Turns`}
			subBarLinks={turnLinks(eventContext.event.id, executionturnContext.executionturn.id)}
			hasSection>
			<div style={{ width: "100%" }}>{props.children}</div>
		</TableContainer>
	)
}

export default OrganizerExecutionTurnTable