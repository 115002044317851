import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import { COMP_TYPE, del, get, post } from '../../../models/backendReq';
import { Athlete, Competition, Exercise, ExerciseType, Team, handleResponse, responseGetJson } from '../../../models/models';

import OrganizerCompetitionAthleteSetupCom from './OrganizerCompetitionAthleteSetupCom';

import { useNavigate } from 'react-router-dom';
import EventEoContext from '../../../contexts/EventEoContext';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import OrganizerCompetitionAthleteSetupExeCom from './OrganizerCompetitionAthleteSetupExeCom';
import './OrganizerCompetitionTeamSetupCom.scss';

interface props {
	team: Team;
	associatedExerciseTypes: ExerciseType[];
	competition: Competition;
}

function OrganizerCompetitionTeamSetupCom({ team, associatedExerciseTypes, competition }: props) {
	const eventContext = useContext(EventEoContext);

	const exercises = eventContext.exercises.value.filter(exe => exe.competitionId === competition.id && exe.teamId === team.id);

	let [athletes, setAthletes] = useState<Athlete[]>([]);
	let [showAthletes, setShowAthletes] = useState(false);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const exercisesRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let getAthletes = async () => {
			let athletesFromServer = await get.athletesByTeam(team.id, competition.id).then(r => responseGetJson(r, []));

			setAthletes(athletesFromServer);
		};

		getAthletes();
	}, [competition, team]);


	let addNewExercise = async (exerciseTypeId: number, n: number) => {
		let toAdd: Exercise[] = [];
		for (let y = 0; y < n; y++) {
			let res = await post.exercise(exerciseTypeId, competition.id, undefined, team.id);
			await handleResponse(
				res, auth, alert, navigate,
				"Errore durante l'inserimento dell'esercizio",
				undefined,
				(data) => toAdd.push(data)
			)
		};
		eventContext.exercises.crud.add(toAdd.map(x => ({ ...x, competition })));
	};

	let deleteExercise = async (exercise?: Exercise) => {
		if (!exercise)
			return
		await del.exercise(exercise.id).then(res => {
			handleResponse(
				res, auth, alert, navigate,
				"Errore durante la cancellazione",
				undefined,
				() => eventContext.exercises.crud.delete({ ...exercise, competition })
			)
		});
	};

	let athletesClassName = showAthletes ? "organizer-competition-setup-team-athletes show" : "organizer-competition-setup-team-athletes";
	let athleteSort = (a: Athlete, b: Athlete) => {
		return ((a.firstName < b.firstName) ? -1 : ((a.firstName > b.firstName) ? 1 : 0));
	}

	return (
		<div className={`organizer-competition-setup-team-container${showAthletes ? " expanded" : "	"}`}>
			<div onClick={(e) => {
				if (!exercisesRef.current?.contains(e.target as Node))
					setShowAthletes(!showAthletes)
			}} className='organizer-competition-setup-team-name'>
				<div className='organizer-competition-setup-team-info'>
					<FontAwesomeIcon icon={showAthletes ? faMinus : faPlus} />
					<div className='organizer-competition-setup-team-name-name'>{team.name}</div>
				</div>
				<div ref={exercisesRef} className='organizer-competition-setup-team-collective-exercises-container'>
					{
						competition.type === COMP_TYPE.Collective && (
							associatedExerciseTypes?.sort((a, b) => a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0).map((et) => {
								return (
									<OrganizerCompetitionAthleteSetupExeCom
										key={et.id}
										exercises={exercises.filter(x => et.id === x.typeId)}
										exerciseType={et}
										deleteExercise={deleteExercise}
										addExercise={addNewExercise}
									/>
								);
							})
						)
					}
				</div>
			</div>
			<div className={athletesClassName}>
				{athletes.length > 0 ? (
					athletes?.sort(athleteSort).map((athlete) => {
						return (
							<OrganizerCompetitionAthleteSetupCom key={athlete.id} associatedExerciseTypes={associatedExerciseTypes} athlete={athlete} competition={competition} isIndividual={false} deleteAthlete={() => { }} transferAthlete={() => { }} />
						);
					})
				) : (
					<p>Non ci sono atleti in questa squadra</p>
				)
				}
			</div>
		</div>
	);
}

export default OrganizerCompetitionTeamSetupCom;