import React, { useCallback, useContext, useEffect } from "react";
import { get, post } from "../backendReq";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Association, EventOrganizer, Jury, responseGetJson } from "../models";

export enum USER_TYPE {
	NONE = '',
	EVENTUSER = 'EventUser',
	USER = 'User',
}

interface AuthInfo {
	auth: boolean;
	type: USER_TYPE;
	info: any;
	juries?: Jury[];
	eventOrganizers: EventOrganizer[];
	associations: Association[];
	isOrganizer: boolean;
	isAssociation: boolean;
}

interface AuthContextType {
	user: AuthInfo;
	signin: (user: string, password: string) => Promise<boolean>;
	signinJury: (eventId: number, accessCode: string) => Promise<boolean>;
	signout: () => void;
	update: (type?: USER_TYPE) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);
let defaultAuth = { auth: false, type: USER_TYPE.NONE, info: null };

export function AuthProvider({ children }: { children: React.ReactNode; }) {
	let [user, setUser] = useLocalStorage("User", defaultAuth);

	let getSelf = useCallback(async (type?: USER_TYPE) => {
		if (!type) {
			setUser(defaultAuth);
			return;
		}
		await get.accountSelf()
			.then(async (res) => {
				if (!res.ok) {
					setUser(defaultAuth);
					return;
				}

				let data = await responseGetJson(res);
				let userFromServer;
				switch (type) {
					case USER_TYPE.EVENTUSER:
						userFromServer = await get.userInEvent(data.id).then(r => responseGetJson(r));
						break;
					case USER_TYPE.USER:
						userFromServer = await get.user(data.id).then(r => responseGetJson(r));
						break;
				}

				const eventOrganizers = await get.selfEventOrganizers().then(r => responseGetJson(r, []));
				const associations = await get.selfAssociations().then(r => responseGetJson(r, []));
				const juries = await get.selfJury(["Assignments", "Judges"]).then(r => responseGetJson(r, []));


				setUser({
					auth: true,
					type,
					info: userFromServer,
					eventOrganizers,
					associations,
					juries,
					isOrganizer: eventOrganizers ? eventOrganizers.length > 0 : false,
					isAssociation: associations ? associations.length > 0 : false
				});
			}).catch(() => setUser(defaultAuth));
	}, [setUser]);

	useEffect(() => {
		getSelf(user.type);
	}, [])



	let signin = useCallback(async (user: string, password: string) => {
		const response = await post.login(user, password);
		if (!response.ok) {
			return false;
		}

		await getSelf(USER_TYPE.USER);

		return true;
	}, [getSelf]);

	let signinJury = useCallback(async (eventId: number, accessCode: string) => {
		const response = await post.eventUserLogin(eventId, accessCode);
		if (!response.ok) {
			return false;
		}

		await getSelf(USER_TYPE.EVENTUSER);

		return true;
	}, [getSelf]);

	let signout = useCallback(async () => {
		const response = await post.logout();

		if (response.ok || response.status === 401) {
			setUser(defaultAuth);
		}

	}, [setUser]);

	let update = getSelf;

	let value = { user, signin, signinJury, signout, update };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
	return useContext(AuthContext);
};