import { useContext } from 'react';
import AudioComponent from '../../../components/AudioComponent';
import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EventEoContext from '../../../contexts/EventEoContext';
import { compareCompetitorExercise, getCompetitorId, getCompetitorName } from '../../../utility/UtilityFunctions';
import './OrganizerFloorMusicPlayView.scss';

function OrganizerFloorMusicPlayView() {
	const context = useContext(EventEoContext);

	return (
		<div className='organizer-musics-playview-container'>
			{context.executionturns.value.map(executionturn => <div className='organizer-musics-playview-turn-container' key={executionturn.id}>
				<p className='organizer-musics-playview-turn-name'>{executionturn.name}</p>
				<div className='organizer-musics-playview-groups-container'>
					{executionturn.groups?.map(executiongroup => {
						const exercises = context.exercises.value.filter(x => x.floorMusicMetadataId && x.executionGroup?.id === executiongroup.id);
						const athletes = context.athletes.value.filter(x => exercises.some(e => e.athleteId === x.id));
						const teams = context.teams.value.filter(x => exercises.some(e => e.teamId === x.id));

						return <div className='organizer-musics-playview-group-container' key={executiongroup.id}>
							<div className='organizer-musics-playview-group-name'>{executiongroup.name}</div>
							<ConditionalListComponent items={[...athletes, ...teams]} emptyMesssage={"---"}
								renderList={(competitors) => competitors.map(competitor => <div
									className='organizer-musics-playview-competitor-container' key={getCompetitorId(competitor)}>
									<span className='organizer-musics-playview-competitor-name'>{getCompetitorName(competitor)}</span>
									<div>
										{exercises.filter(x => compareCompetitorExercise(competitor, x))
											.map(exercise => <div className='organizer-musics-playview-exercise-container' key={exercise.id}>
												<span className='organizer-musics-playview-exercise-type-name'>{context.exercisetypes.find(et => et.id === exercise.typeId)?.displayName}</span>
												<AudioComponent floorMusicId={exercise.floorMusicMetadataId ?? 0} />
											</div>)}
									</div>
								</div>)} />
						</div>
					})}
				</div>
			</div>)}
		</div>
	)
}

export default OrganizerFloorMusicPlayView