import React, { useContext, useState } from 'react';
import ModalWindow from '../../../components/ModalWindow';
import EventEoContext from '../../../contexts/EventEoContext';
import './OrganizerRankingConfigurationsWizard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { ChartSection } from '../../../models/models';
import { put } from '../../../models/backendReq';

interface props {
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}
function OrganizerRankingConfigurationsWizard(props: props) {
	const context = useContext(EventEoContext);

	const regex = /([\d]+|[\d]+%)$/;

	const [selectedCompetitions, setSelectedCompetitions] = useState<number[]>([]);
	const [chartSections, setChartSections] = useState<{ name: string, size?: string }[]>([])
	const [newChartSection, setNewChartSection] = useState<{ name: string, size?: string }>({ name: "" });

	const allCompetitionHandle = () => {
		if (context.competitions.value.length === selectedCompetitions.length)
			setSelectedCompetitions([]);
		else
			setSelectedCompetitions(context.competitions.value.map(c => c.id));
	}

	const editChartSectionsNameHandle = (newName: string, index: number) => {
		const oldElement = chartSections[index];
		const newArray = [...chartSections];
		newArray.splice(index, 1, { ...oldElement, name: newName });
		setChartSections(newArray);
	}

	const editChartSectionsSizeHandle = (newSize: string, index: number) => {
		if (newSize !== "" && !regex.test(newSize))
			return;

		const oldElement = chartSections[index];
		const newArray = [...chartSections];
		newArray.splice(index, 1, { ...oldElement, size: newSize === "" ? undefined : newSize });

		setChartSections(newArray);
	}

	const removeHandle = (id: number) => {
		setChartSections(p => p.filter((_, index) => index !== id));
	}

	const addNewHandle = () => {
		if (newChartSection.name === "")
			return

		if (newChartSection.size !== undefined && !regex.test(newChartSection.size))
			return

		setChartSections(p => [...p, newChartSection]);
		setNewChartSection({ name: "", size: undefined });
	}

	const handleConfirm = async () => {
		const competitions = context.competitions.value.filter(competition => selectedCompetitions.includes(competition.id));

		const newRankingConfigurations = competitions.map(competition => {
			const athletesNumber = context.athletes.value.filter(a => a.competitionId === competition.id).length;

			const _chartSections: ChartSection[] = chartSections.map(cs => {
				let size: number | undefined;
				if (cs.size && cs.size.includes("%"))
					size = Math.ceil((athletesNumber / 100) * parseInt(cs.size));
				else if (cs.size)
					size = parseInt(cs.size);
				else
					size = undefined;

				return { name: cs.name, size }
			});
			return { competition, competitionId: competition.id, chartSections: _chartSections }
		});

		const promise = newRankingConfigurations.map(rc =>
			put.rankingConfigurations(rc.competitionId, rc)
		);

		await Promise.all(promise);

		context.rankingconfigurations.crud.edit(newRankingConfigurations);
		props.show[1](false);
	}

	return (
		<ModalWindow show={props.show} closeButton>
			<div className='organizer-ranking-configurations-wizard'>
				<h2>Configurazione premiazioni</h2>
				<div className='organizer-ranking-configurations-wizard-config-parent'>
					{/** Selezione delle gare */}
					<div className='organizer-ranking-configurations-wizard-competitions-container'>
						<button onClick={allCompetitionHandle}>Seleziona tutte</button>
						{context.competitions.value.map(competition => {
							const athletesNumber = context.athletes.value.filter(a => a.competitionId === competition.id).length;
							const checked = selectedCompetitions.includes(competition.id);
							return (
								<label key={competition.id}>
									<input
										onChange={e => setSelectedCompetitions(p => checked ? p.filter(x => x !== competition.id) : [...p, competition.id])}
										type='checkbox'
										checked={checked} />
									{`${competition.name} (${athletesNumber})`}
								</label>
							)
						})}
					</div>
					{/** Definizione delle premiazioni */}
					<div>
						<div>
							<input
								onChange={e => setNewChartSection(p => ({ ...p, name: e.target.value }))}
								value={newChartSection.name}
							/>
							<input
								onChange={e => setNewChartSection(p => {
									if (e.target.value && !regex.test(e.target.value))
										return p;
									return { ...p, size: e.target.value === "" ? undefined : e.target.value }
								})}
								value={newChartSection.size ?? ""}
							/>
						</div>
						<button onClick={addNewHandle}>Aggiungi</button>
						{chartSections.map((cs, index) => <div key={index}>
							<input
								onChange={e => editChartSectionsNameHandle(e.target.value, index)}
								value={cs.name}
							/>
							<input
								onChange={e => editChartSectionsSizeHandle(e.target.value, index)}
								value={cs.size}
							/>
							<button onClick={() => removeHandle(index)}>
								<FontAwesomeIcon icon={faTrash} />
							</button>
						</div>)}
					</div>
				</div>
				<div>
					<div>
						<FontAwesomeIcon icon={faWarning} />
						<span>Attenzione! Le gare selezionate perderanno la loro attuale configurazione delle premiazioni!</span>
					</div>
					<button onClick={handleConfirm}>
						Conferma premiazioni
					</button>
				</div>
			</div>
		</ModalWindow>
	)
}

export default OrganizerRankingConfigurationsWizard