import { createContext } from "react";
import { Association, Athlete, Crud, Team } from "../models/models";

interface AssociationWithAthletes extends Association {
	athletes: Athlete[];
	teams: Team[];
}

export interface AssociationsEoContextData {
	loading: boolean;
	associations: { value: AssociationWithAthletes[], crud: Crud<AssociationWithAthletes | AssociationWithAthletes[]> };
	lastAssociation?: Association;
}

export default createContext<AssociationsEoContextData>(null!);