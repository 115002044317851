import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './LoadingSpinner.scss'

interface props {
	className?: string | undefined;
}
function LoadingSpinner(props: props) {
	return (
		<FontAwesomeIcon className={`loading-spinner ${props.className}`} icon={faSpinner} />
	)
}

export default LoadingSpinner