import { useEffect, useState } from 'react';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del, get, post, put } from '../../../models/backendReq';
import '../../../utility/prototype';
import { Association, EventOrganizer, User, handleResponse, initUser, responseGetJson } from '../../../models/models';
import './OrganizerAdministratorScreen.scss';
import InputWithAlert from '../../../components/Input/InputWithAlert';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import { useNavigate } from 'react-router-dom';
import { changeObjInAry } from '../../../utility/UtilityFunctions';

interface createUser {
	userName: string;
	password: string;
	displayName: string;
}
interface EventOrganizerWithAdmins extends EventOrganizer {
	admins: User[];
}

interface AssociationWithAdmins extends EventOrganizer {
	admins: User[];
}

function OrganizerAdministratorScreen() {
	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const [users, setUsers] = useState<User[]>([]);
	const [selecteduser, setSelectedUser] = useState<User>();
	const [eventOrganizers, setEventOrganizers] = useState<EventOrganizerWithAdmins[]>([]);
	const [associations, setAssociations] = useState<AssociationWithAdmins[]>([]);


	const [newEventOrganizerName, setNewEventOrganizerName] = useState("");
	const [newAssociationName, setNewAssociationName] = useState("");

	const [newUser, setNewUser] = useState<createUser>({ userName: "", password: "", displayName: "" });

	useEffect(() => {
		const loadData = async () => {
			const usersFromServer = await get.users().then(r => responseGetJson(r, [])) as User[];
			const eoFromServer = await get.eventOrganizers().then(r => responseGetJson(r, [])) as EventOrganizer[];
			const asFromServer = await get.associations().then(r => responseGetJson(r, [])) as Association[];

			const eoWithAdmins = await Promise.all(eoFromServer.map(async (eo) => {
				const admins = await get.eventOrganizerAdmins(eo.id).then(r => responseGetJson(r, [])) as User[];
				return { ...eo, admins }
			}));

			const asWithAdmins = await Promise.all(asFromServer.map(async (as) => {
				const admins = await get.associationAdmins(as.id).then(r => responseGetJson(r, [])) as User[];
				return { ...as, admins }
			}));

			setUsers(usersFromServer);
			setEventOrganizers(eoWithAdmins);
			setAssociations(asWithAdmins);
		}

		loadData();
	}, []);

	const createUser = async () => {
		const response = await post.user(newUser.userName, newUser.password, newUser.displayName);
		handleResponse(response, auth, alert, navigate, "Errore durante la creazione dell'utente", undefined,
			(data) => setUsers(prev => [...prev, data]));
	}
	const deleteUser = async (userId: number) => {
		const response = await del.user(userId);
		handleResponse(response, auth, alert, navigate, "Errore durante la cancellazione dell'utente", undefined,
			() => setUsers(prev => prev.filter(u => u.id !== userId)));
	}
	const createEventOrganizer = async () => {
		const response = await post.eventOrganizer(newEventOrganizerName);
		handleResponse(response, auth, alert, navigate, "Errore durante la creazione dell'organizzatore", undefined,
			(data) => setEventOrganizers(prev => [...prev, { ...data, admins: [] }]));
	}
	const deleteEventOrganizer = async (eoId: number) => {
		const response = await del.eventOrganizer(eoId);
		handleResponse(response, auth, alert, navigate, "Errore durante la cancellazione dell'utente", undefined,
			() => setEventOrganizers(prev => prev.filter(u => u.id !== eoId)));
	}
	const createAssociation = async () => {
		const response = await post.association(newAssociationName);
		handleResponse(response, auth, alert, navigate, "Errore durante la creazione della società", undefined,
			(data) => setAssociations(prev => [...prev, { ...data, admins: [] }]));
	}
	const deleteAssociation = async (associationId: number) => {
		const response = await del.association(associationId);
		handleResponse(response, auth, alert, navigate, "Errore durante la cancellazione dell'utente", undefined,
			() => setAssociations(prev => prev.filter(u => u.id !== associationId)));
	}

	const eoClick = async (eventOrganizer: EventOrganizerWithAdmins) => {
		if (!selecteduser)
			return

		if (eventOrganizer.admins.map(a => a.id).includes(selecteduser.id)) {
			const res = await del.userInEventOrganizer(eventOrganizer.id, selecteduser.id);
			handleResponse(res, auth, alert, navigate, "Errore durante la rimozione dell'utente", undefined,
				() => {
					const newEO = { ...eventOrganizer, admins: eventOrganizer.admins.filter(ad => ad.id !== selecteduser.id) };
					setEventOrganizers(changeObjInAry(eventOrganizers, newEO));
				});
		} else {
			const res = await put.adminInEventOrganizer(eventOrganizer.id, selecteduser.id);
			handleResponse(res, auth, alert, navigate, "Errore durante la rimozione dell'utente", undefined,
				() => {
					const newEO = { ...eventOrganizer, admins: [...eventOrganizer.admins, selecteduser] };
					setEventOrganizers(changeObjInAry(eventOrganizers, newEO));
				});
		}
	}

	const asClick = async (association: AssociationWithAdmins) => {
		if (!selecteduser)
			return

		if (association.admins.map(a => a.id).includes(selecteduser.id)) {
			const res = await del.userInAssociation(association.id, selecteduser.id);
			handleResponse(res, auth, alert, navigate, "Errore durante la rimozione dell'utente", undefined,
				() => {
					const newAS = { ...association, admins: association.admins.filter(ad => ad.id !== selecteduser.id) };
					setAssociations(changeObjInAry(associations, newAS));
				});
		} else {
			const res = await put.adminInAssociation(association.id, selecteduser.id);
			handleResponse(res, auth, alert, navigate, "Errore durante la rimozione dell'utente", undefined,
				() => {
					const newAS = { ...association, admins: [...association.admins, selecteduser] };
					setAssociations(changeObjInAry(associations, newAS));
				});
		}
	}

	return (
		<div>
			PAGINA DI AMMINISTRAZIONE
			<div>
				<div className='administration-container'>
					<div className='administration-users-container'>
						<div>Utenti</div>
						<div>
							<InputWithAlert
								name='userName'
								placeholder='userName'
								onChange={({ target }) => setNewUser(p => { return { ...p, [target.name]: target.value } })}
								value={newUser.userName} />
							<InputWithAlert
								name='password'
								placeholder='password'
								onChange={({ target }) => setNewUser(p => { return { ...p, [target.name]: target.value } })}
								value={newUser.password} />
							<InputWithAlert
								name='displayName'
								placeholder='displayName'
								onChange={({ target }) => setNewUser(p => { return { ...p, [target.name]: target.value } })}
								value={newUser.displayName} />
							<button onClick={createUser}>Crea nuovo utente</button>
						</div>
						<div>
							{
								users.map(user => (
									<div key={user.id} className={`administration-user-container${selecteduser?.id === user.id ? " selected" : ""}`}>
										<strong>{user.id}</strong>
										<span onClick={() => setSelectedUser(user)}>{`${user.userName} ${user.displayName}`}</span>
										{user.userName !== "Admin" && <button onClick={() => deleteUser(user.id)}>Cancella</button>}
									</div>
								))
							}
						</div>
					</div>
					<div className='administration-entity-container'>
						<div>
							<InputWithAlert onChange={e => setNewEventOrganizerName(e.target.value)} value={newEventOrganizerName} />
							<button onClick={createEventOrganizer}>Crea nuova organizzazione</button>
						</div>
						<div>
							<h4>Organizzatori evento</h4>
							<div>
								{
									eventOrganizers.map(eo => {
										const className = eo.admins.map(a => a.id).includes(selecteduser?.id ?? 0) ?
											'administration-entity included' : 'administration-entity'
										return (
											<div key={eo.id} className={className}>
												<div onClick={() => eoClick(eo)}>{eo.name}</div>
												<button onClick={() => deleteEventOrganizer(eo.id)}>Cancella</button>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
					<div className='administration-entity-container'>
						<div>
							<InputWithAlert onChange={e => setNewAssociationName(e.target.value)} value={newAssociationName} />
							<button onClick={createAssociation}>Crea nuova società</button>
						</div>
						<div>
							<h4>Società</h4>
							<div>
								{
									associations.map(as => {
										const className = as.admins.map(a => a.id).includes(selecteduser?.id ?? 0) ?
											'administration-entity included' : 'administration-entity'
										return (
											<div key={as.id} className={className}>
												<div onClick={() => asClick(as)}>{as.name}</div>
												<button onClick={() => deleteAssociation(as.id)}>Cancella</button>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrganizerAdministratorScreen;