import { useContext, useState } from 'react';
import CompetitionTurn from '../../components/Judge/CompetitionTurn';
import { Competition } from '../../models/models';

import { useLocation, useNavigate } from 'react-router-dom';
import HeaderWithBackButton from '../../components/HeaderWithBackButton';
import JuryContext from '../../contexts/JuryContext';
import './CompetitionsScreen.scss';

function CompetitionsScreen() {
	const context = useContext(JuryContext);
	const navigate = useNavigate();
	const location = useLocation();

	let [searchCompetition, setSearchCompetition] = useState("");
	let [searchTurn, setSearchTurn] = useState("");
	let [turnSelected, setTurnSelected] = useState(false);

	let competitionSort = (a: Competition, b: Competition) => {
		return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
	};

	let competitionsToShow = context.competitions.filter(x => x.name.toLowerCase().includes(searchCompetition.toLowerCase()));

	let turnsToShow = context.executionturns.filter(x => x.name.toLowerCase().includes(searchTurn.toLowerCase()));

	const goJuriesSelection = () => {
		const previousDirectory = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

		return previousDirectory
	}

	return (
		<div className='events-container'>
			{
				context.juries.length > 1 ? (
					// <div className='judge-competition-juries-selection-container'>
					// 	<button onClick={goJuriesSelection}>Torna a seleziona giuria</button>
					// </div>
					<HeaderWithBackButton navigateTo={goJuriesSelection() + "/JurySelection"} />
				) : (null)
			}
			<div className='judge-competition-turn-responsive-nav'>
				<div className={`judge-competition-turn-responsive-nav-button${!turnSelected ? " selected" : ""}`} onClick={() => setTurnSelected(false)}>Gare</div>
				<div className={`judge-competition-turn-responsive-nav-button${turnSelected ? " selected" : ""}`} onClick={() => setTurnSelected(true)}>Turni</div>
			</div>
			<div className='judge-competition-turn-container'>
				<div className={`judge-competition-container${!turnSelected ? " selected" : ""}`}>
					<div className={`judge-competition-title`}>Gare</div>
					<input
						placeholder='Cerca gara'
						className='event-search-competition'
						type="text"
						value={searchCompetition}
						onChange={(e) => setSearchCompetition(e.target.value)} />
					<div className='judge-competition-container-list'>
						{competitionsToShow.length ? (
							competitionsToShow.sort(competitionSort).map((competition) => {
								return (
									<CompetitionTurn key={competition.id} competition={competition} />
								);
							})
						) : (
							<p>Non ci sono competizioni disponibili</p>
						)}
					</div>

				</div>
				<div className={`judge-turn-container${turnSelected ? " selected" : ""}`}>
					<div className={`judge-competition-title`}>Turni</div>
					<input
						placeholder='Cerca turno'
						className='event-search-competition'
						type="text"
						value={searchTurn}
						onChange={(e) => setSearchTurn(e.target.value)} />
					<div className='judge-turn-container-list'>
						{turnsToShow.length ? (
							turnsToShow.sort((a, b) => -(Date.parse(b.start) - Date.parse(a.start))).map((executionturn) => {
								return (
									<CompetitionTurn key={executionturn.id} competition={executionturn} />
								);
							})
						) : (
							<p>Non ci sono turni disponibili</p>
						)}
					</div>

				</div>
			</div>

		</div>
	);
}

export default CompetitionsScreen;