import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Jury } from '../../models/models';

import './OrganaizerJuryCom.scss';

import TableNameCell from './TableComponents/TableNameCell';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';

interface props {
	jury: Jury;
	del: (jury: Jury) => void;
	edi: (jury: Jury) => void;
	usr: (jury: Jury) => void;
}

function OrganaizerEventuserCom({ jury, del, edi, usr }: props) {
	let editReq = () => {
		edi(jury);
	}

	let deleteReq = () => {
		del(jury);
	}

	return (
		<tr>
			<TableNameCell text={jury.name} />
			<td className='organizer-jury-col-users row' onClick={() => usr(jury)}>
				{jury.judges?.length ?? 0}
				<FontAwesomeIcon icon={faUserPlus} />
			</td>
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />
		</tr>
	);
}

export default OrganaizerEventuserCom;