import { faCalendarDays, faCheck, faClock, faEllipsis, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDate, getDay } from '../models/dateAndTime';
import { Event } from '../models/models';


import { useNavigate } from 'react-router-dom';
import { USER_TYPE, useAuth } from '../models/auth/AuthProvider';
import { getSubscriptionsStat } from '../utility/UtilityFunctions';
import './EventCom.scss';


interface props {
	event: Event;
}

function EventCom({ event }: props) {
	const auth = useAuth();
	const navigate = useNavigate();

	let eventState = " inactive";
	let eventIcon = faClock;
	let eventStateName = "In attesa";

	if (getSubscriptionsStat(event) === 1) {
		eventState = " subscriptionsOn"
		eventIcon = faIdCard;
		eventStateName = "Iscrizioni aperte";
	}
	if (getSubscriptionsStat(event) === 2) {
		eventState = " subscriptionsOff"
		eventIcon = faIdCard;
		eventStateName = "Iscrizioni chiuse";
	}
	if (getDay() >= getDay(event.startDate)) {
		eventState = " active"
		eventIcon = faEllipsis;
		eventStateName = "In corso";
	}
	if (getDay() > getDay(event.endDate)) {
		eventState = " finished"
		eventIcon = faCheck;
		eventStateName = "Completato";
	}

	const goToEvents = () => {
		if (auth.user.type !== USER_TYPE.USER)
			return

		navigate("/User/Events/Info", { state: { event } })
	}

	const goToJury = () => {
		if (auth.user.isOrganizer || auth.user.juries?.length! > 0)
			navigate(`${event.id}/Jury/Login`);
	}

	return (
		<div className='event-header' onClick={goToEvents}>
			<div className='event-header-data'>
				<div className='event-info'>
					<div className={'event-state' + eventState}>
						<FontAwesomeIcon icon={eventIcon} />
					</div>
					<div className='event-name' >{event.name}</div>
				</div>
				<div className={'event-date-container'}>
					<FontAwesomeIcon icon={faCalendarDays} />
					<div className='event-date'>
						{convertDate(event.startDate, undefined, true)}
					</div>
					<div className='event-date'>
						{convertDate(event.endDate, undefined, true)}
					</div>
				</div>

			</div>
		</div>
	);
}

export default EventCom;