import React from 'react'
import './HomeScreen.scss'
import { useTranslation } from 'react-i18next';

function HomeScreen() {
	const { t, i18n } = useTranslation();

	return (
		<div id='Home'>
			<h2>{t('homeScreen.welcome')}</h2>
			<p>{t('homeScreen.description')}</p>
		</div>
	)
}

export default HomeScreen