import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { Category, Competition, Event, handleResponse, initCategory, initCompetition } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerCompetitionsEditRequest.scss';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';

interface props {
	competition?: Competition;
	event: Event;
	editCompleted: (competition: Competition) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerCompetitionsEditRequest({ competition, event, show, editCompleted }: props) {
	const configurationContext = useContext(OrganizerConfigurationsContext);

	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per la competizione" };
	const inputLevelError: ErrorType = { id: "level", text: "Inserire un livello per la competizione" };
	const inputCategoryNameError: ErrorType = { id: "categoryName", text: "Inserire un nome per la categoria della competizione" };
	const inputCategoryDateMinError: ErrorType = { id: "categoryDateMin", text: "Inserire una data minima per la categoria della competizione" };
	const inputCategoryDateMaxError: ErrorType = { id: "categoryDateMax", text: "Inserire una data massima per la categoria della competizione" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [competitionToPut, setCompetitionToPut] = useState<Competition>(competition ? competition : initCompetition);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (competition)
			setCompetitionToPut(competition);
	}, [competition]);

	let editCompetition = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (!competitionToPut.name || competitionToPut.name === "") {
			_errors.push(inputNameError);
		}

		if (!competitionToPut.levelId || competitionToPut.levelId === 0) {
			_errors.push(inputLevelError);
		}

		if (!competitionToPut.category || competitionToPut.category.name === "") {
			_errors.push(inputCategoryNameError);
		}
		if (!competitionToPut.category || competitionToPut.category.minBirthDate === "") {
			_errors.push(inputCategoryDateMinError);
		}
		if (!competitionToPut.category || competitionToPut.category.maxBirthDate === "") {
			_errors.push(inputCategoryDateMaxError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await put.competition(competitionToPut.id, competitionToPut.name, competitionToPut.levelId, competitionToPut.category)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica della gara",
					"Gara modificata correttamente",
					() => editCompleted(competitionToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])


	let setCategory: Dispatch<SetStateAction<Category>> = useCallback(
		(input) => setCompetitionToPut(c => ({ ...c, category: (typeof input === 'function') ? input(c.category ?? initCategory) : input })),
		[setCompetitionToPut]
	);

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica la competizione" submit={editCompetition}>
			<p className='organizer-competition-edit-request-form-old-name'>{competition?.name}</p>

			<p className='organizer-competition-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-competition-edit-request-form-input'
				style={{ resize: 'vertical' }}
				value={competitionToPut.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setCompetitionToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-competition-edit-request-form-input-label'>Livello</p>
			<InputWithAlert
				errorId="level"
				type='select'
				errors={inputErrors}
				className='organizer-competition-edit-request-form-input'
				style={{ resize: 'vertical' }}
				value={competitionToPut.levelId}
				name="levelId"
				onSelectChange={(e) => {
					const { name, value } = e.target;

					setCompetitionToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			>
				<option value={0}>---</option>
				{configurationContext.competitionLevels.value.filter(l => l.ownerId === event.ownerId).map(competionlevel =>
					<option key={competionlevel.id} value={competionlevel.id}>{competionlevel.name}</option>)}
			</InputWithAlert>
			<p className='organizer-competition-edit-request-form-input-label'>Categoria</p>
			<InputWithAlert
				categoryNameErrorId={inputCategoryNameError.id}
				categoryDateMaxErrorId={inputCategoryDateMaxError.id}
				categoryDateMinErrorId={inputCategoryDateMinError.id}
				errors={inputErrors}
				className='organizer-competition-add-request-form-input'
				style={{ resize: 'vertical' }}
				categoryValue={competitionToPut.category}
				type='category'
				name="category"
				onCategoryChange={setCategory}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerCompetitionsEditRequest;