import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { Competition, handleResponse } from '../../../models/models';
import './OrganizerCompetitionsDeleteRequest.scss';

interface props {
	competition?: Competition;
	deletionCompleted: (competition: Competition) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerCompetitionsDeleteRequest({ competition, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteCompetition = async (e: React.FormEvent) => {
		if (!competition) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.competition(competition.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione della gara",
					"Gara cancellata",
					() => deletionCompleted(competition)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE LA COMPETIZIONE" submit={deleteCompetition}>
			<p className='organizer-competition-delete-request-form-competition-name'>{competition?.name}</p>
			<div className='organizer-competition-massive-delete-warning'>
				Azione irreversibile, verranno eliminati tutti gli atleti, attrezzi ed esercizi associati alla gara
			</div>
		</CrudForm>
	);
}

export default OrganizerCompetitionsDeleteRequest;