import { useContext, useState } from 'react';
import { FloorMusicMetadata, getMusicUrl } from '../../models/models';

import './OrganaizerFloorMusicCom.scss';

import TableNameCell from './TableComponents/TableNameCell';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../contexts/EventEoContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { get } from '../../models/backendReq';
import { saveAs } from 'file-saver';
import AudioComponent from '../AudioComponent';

interface props {
	floorMusicMetadata: FloorMusicMetadata;
	del: (eventuser: FloorMusicMetadata) => void;
}

function OrganaizerFloorMusicCom({ floorMusicMetadata, del }: props) {
	let deleteReq = () => {
		del(floorMusicMetadata);
	}

	return (
		<tr>
			<TableNameCell text={floorMusicMetadata.fileName} />
			<TableNameCell text={floorMusicMetadata.association.name} />
			<td>
				<AudioComponent floorMusicId={floorMusicMetadata.id} />
			</td>
			<TableRemoveEditButtons deletReq={deleteReq} hideEdit />
		</tr>
	);
}

export default OrganaizerFloorMusicCom;