import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalWindow from '../../../components/ModalWindow';
import OrganizerCompetitionAthleteSetupExeCom from '../../../components/Organizer/CompetitionSetup/OrganizerCompetitionAthleteSetupExeCom';
import { useAuth } from '../../../models/auth/AuthProvider';
import { COMP_TYPE, del, post } from '../../../models/backendReq';
import { AthleteClass, Competition, Exercise, TeamClass, handleResponse } from '../../../models/models';
import '../../../utility/prototype';
import './SubscriptionEditExercises.scss';
import EventEoContext from '../../../contexts/EventEoContext';

interface props {
	competition: Competition;
	athlete: (AthleteClass | TeamClass);
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	team?: TeamClass;
	okSubscriptionStat: number;
}

function SubscriptionEditExercises(props: props) {
	const eventContext = useContext(EventEoContext);
	const auth = useAuth();
	const navigate = useNavigate();

	const disable = props.okSubscriptionStat !== 1 && !auth.user.isOrganizer;

	const addExercise = async (exerciseTypeId: number, n: number) => {
		if (disable)
			return;

		for (let y = 0; y < n; y++) {
			let res;
			if (props.competition.type === COMP_TYPE.Collective)
				res = await post.exercise(exerciseTypeId, props.competition.id, undefined, props.athlete.id);
			else
				res = await post.exercise(exerciseTypeId, props.competition.id, props.athlete.id);

			await handleResponse(
				res, auth, alert, navigate,
				"Errore durante l'inserimento dell'esercizio",
				undefined,
				(data) => eventContext.exercises.crud.add({ ...data, competition: props.competition })
			)
		};
	};


	const deleteExercise = async (exercise?: Exercise) => {
		if (disable)
			return;

		if (!exercise)
			return
		await del.exercise(exercise.id).then(res => {
			handleResponse(
				res, auth, alert, navigate,
				"Errore durante la cancellazione",
				undefined,
				() => eventContext.exercises.crud.delete({ ...exercise, competition: props.competition })
			)
		});
	};

	return (
		<ModalWindow show={props.show} closeButton>
			<div className='subscription-edit-exericeses'>
				<h3>Diachiarazione esercizi</h3>
				<div className='subscription-edit-exericeses-header'>
					<strong>{"firstName" in props.athlete ? props.athlete.getName() : props.athlete.name}</strong>
					<div>{props.competition.name}</div>
				</div>
				<div className='subscription-edit-exericeses-container'>
					{
						props.competition.exerciseTypes?.sortExerciseTypes().map(et => {
							const exercises = eventContext.exercises.value.filter(exe => {
								if (props.competition.type === COMP_TYPE.Collective)
									return exe.teamId === props.athlete.id && exe.competitionId === props.competition.id;
								else
									return exe.athleteId === props.athlete.id && exe.competitionId === props.competition.id;
							})
							return (
								<OrganizerCompetitionAthleteSetupExeCom
									key={et.id}
									addExercise={addExercise}
									deleteExercise={deleteExercise}
									exerciseType={et}
									exercises={exercises.filter(x => et.id === x.typeId)}
									isMono
									disableAdd={disable} disableDelete={disable} />
							)
						})}
				</div>
			</div>
		</ModalWindow>
	)
}

export default SubscriptionEditExercises