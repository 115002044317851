import { faAdd, faEllipsisVertical, faGripVertical, faLayerGroup, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { NavLink, useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import ConditionalListComponent from '../../../../components/ConditionalListComponent';
import CrudForm from '../../../../components/Organizer/TableComponents/CrudForm';
import ToolTip from '../../../../components/ToolTip';
import EventEoContext, { ExerciseWithCompetition } from '../../../../contexts/EventEoContext';
import { useAlert } from '../../../../models/AlertProvider';
import { useStateLocation } from '../../../../models/StateLocationProvider';
import { useAuth } from '../../../../models/auth/AuthProvider';
import { get, post, put } from '../../../../models/backendReq';
import useOnClickOutside from '../../../../models/hooks/useOnClickOutside';
import { Association, Athlete, Competition, ExecutionGroup, ExecutionTurn, Exercise, handleResponse, initAssociation, initAthlete, responseGetJson } from '../../../../models/models';
import { changeObjInAry, getUnique } from '../../../../utility/UtilityFunctions';
import '../../../../utility/prototype';
import OrganizerExecutionGroupsDeleteRequest from '../../ExecutionGroups/OrganizerExecutionGroupsDeleteRequest';
import OrganizerExecutionTurnsSVAthletesSelection from './OrganizerExecutionTurnsSVAthletesSelection';
import './OrganizerExecutionTurnsSVScreen.scss';


function GroupAthleteComponent({
	athlete,
	group,
	competitions,
	odlFixRequest,
	selectedExecutionTurn,
	selectedCompetitionShow,
	selectionOn,
	setSelectedCompetitionShow,
	missingExe,
	athletesToMove,
	setAthleteToRemove,
	moveExerciseRequest
}: {
	athlete: Athlete;
	group: ExecutionGroup
	competitions: Competition[];
	odlFixRequest?: (group: ExecutionGroup, exercises: Exercise[]) => void;
	selectedExecutionTurn?: ExecutionTurn;
	selectedCompetitionShow?: number;
	selectionOn?: boolean;
	setSelectedCompetitionShow?: React.Dispatch<React.SetStateAction<number>>;
	missingExe: ExerciseWithCompetition[];
	athletesToMove?: { athlete: Athlete, group: ExecutionGroup }[];
	setAthleteToRemove: React.Dispatch<React.SetStateAction<Athlete | undefined>>;
	moveExerciseRequest: (athlete: Athlete) => void;
}) {
	const eventContext = useContext(EventEoContext);

	const contextRef = useRef<HTMLDivElement>(null);
	const [showMenu, setShowMenu] = useState(false);

	useOnClickOutside(contextRef, () => setShowMenu(false));

	const uniqueExesByCompetitions = getUnique(group?.exercises?.filter(x => x.athleteId === athlete.id) ?? [], "competitionId");

	return <Draggable isDragDisabled={selectionOn} key={athlete.id} draggableId={athlete.id.toString()} index={athlete.id}>
		{(provided) => (
			<div
				key={athlete.id}
				className={`organizer-executionturns-simplifiedview-group-athlete${uniqueExesByCompetitions.some(e => e.competitionId === selectedCompetitionShow) ? " selected" : ""}`}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				ref={provided.innerRef}>
				{selectionOn ? <input
					type='checkbox'
					checked={athletesToMove?.find(x => x.athlete.id === athlete.id) ? true : false}
					onChange={() => moveExerciseRequest(athlete)} />
					:
					<FontAwesomeIcon icon={faGripVertical} />
				}
				{missingExe.filter(e => e.athleteId === athlete.id).length > 0 &&
					<div className='organizer-executionturns-simplifiedview-group-athlete-warning'>
						<FontAwesomeIcon icon={faWarning} />
						<div className='organizer-executionturns-simplifiedview-group-athlete-tooltip'>
							<span>ODL non allineato con la dichiarazione</span>
							<button onClick={() => odlFixRequest &&
								odlFixRequest(group, missingExe.filter(e => e.athleteId === athlete.id))
							}>Riallinea</button>
						</div>
					</div>
				}
				<div className='organizer-executionturns-simplifiedview-group-athlete-name'>	{`${athlete.firstName} ${athlete.lastName}`}</div>
				<div >
					{
						uniqueExesByCompetitions.map((e: Exercise) => {
							const competition = competitions.find(c => e.competitionId === c.id);
							const linkState = { competition, event: eventContext.event, set: selectedExecutionTurn, backReq: window.location.pathname };

							return <ToolTip key={e.id} timeOnLeave={100} timeOnEnter={200}
								text={
									<NavLink to={`../Classifications/${competition?.id}/Athletes`} className='organizer-executionturns-simplifiedview-group-athlete-cat-link' state={linkState}>
										{competition?.name}
									</NavLink>}>
								<FontAwesomeIcon
									icon={faLayerGroup}
									onMouseEnter={() => setSelectedCompetitionShow && setSelectedCompetitionShow(e.competitionId)}
									onMouseLeave={() => setSelectedCompetitionShow && setSelectedCompetitionShow(0)}
									// style={{ color: competitionColors && competitionColors[competition?.name ?? ""] }}
									className='organizer-executionturns-simplifiedview-group-athlete-category'
								/>
							</ToolTip>
						})
					}
				</div>
				<div className='organizer-executionturns-simplifiedview-group-athlete-menu-container'>
					<button className='organizer-executionturns-simplifiedview-group-athlete-menu-button' onClick={() => {
						setShowMenu(!showMenu);
					}}>
						<FontAwesomeIcon icon={faEllipsisVertical} />
					</button>
					{showMenu && <div className='organizer-executionturns-simplifiedview-group-athlete-menu' ref={contextRef}>
						<button onClick={() => setAthleteToRemove(athlete)}>Elimina</button>
					</div>}
				</div>
			</div>
		)}
	</Draggable>
}

function GroupComponent({
	competitions,
	group,
	addRequest,
	editRequest,
	deleteRequest,
	addExercisesRequest,
	removeExercisesRequest,
	moveExercisesRequest,
	odlFixRequest,
	provided,
	snapshot,
	selectionOn,
	athletesToMove,
	selectedExecutionTurn,
	selectedCompetitionShow,
	setSelectedCompetitionShow }: {
		competitions: Competition[];
		group?: ExecutionGroup,
		addRequest?: VoidFunction,
		deleteRequest?: (group: ExecutionGroup) => void,
		editRequest?: (group: ExecutionGroup) => void,
		addExercisesRequest?: (group: ExecutionGroup) => void,
		removeExercisesRequest?: (group: ExecutionGroup, athlete: Athlete) => void,
		moveExercisesRequest?: (group: ExecutionGroup, athlete: Athlete) => void,
		odlFixRequest?: (group: ExecutionGroup, exercises: Exercise[]) => void,
		provided?: DroppableProvided;
		snapshot?: DroppableStateSnapshot;
		selectionOn?: boolean;
		athletesToMove?: { athlete: Athlete, group: ExecutionGroup }[];
		selectedExecutionTurn?: ExecutionTurn;
		selectedCompetitionShow?: number;
		setSelectedCompetitionShow?: React.Dispatch<React.SetStateAction<number>>;
	}) {
	const eventContext = useContext(EventEoContext);

	const [editActive, setEditActive] = useState(false);
	const [newName, setNewName] = useState(group?.name ?? "");
	const [athleteToRemove, setAthleteToRemove] = useState<Athlete>();
	const showRemove = useState(false);

	const newNameRef = useRef<HTMLInputElement>(null);

	const _editRequest = () => {
		if (!editRequest || !group)
			return

		setEditActive(false);
		if (group.name !== newName)
			editRequest({ ...group, name: newName });
	}

	useOnClickOutside(newNameRef, _editRequest);


	useEffect(() => {
		if (!editActive || !newNameRef)
			return;

		newNameRef.current?.focus();
	}, [editActive]);

	useEffect(() => {
		if (athleteToRemove)
			showRemove[1](true);
	}, [athleteToRemove]);

	const athletes: Athlete[] = getUnique(group?.exercises?.map(e => e.athlete ?? initAthlete) ?? []).filter(a => a !== undefined);
	const owners: Association[] = getUnique(athletes.map(ath => ath.owner ?? initAssociation)).filter(a => a !== undefined);
	const missingExe = eventContext.exercises.value.filter(exercise => !group?.exercises?.map(e => e.id).includes(exercise.id));

	return (
		group ? (
			<div
				ref={provided?.innerRef}
				className={`organizer-executionturns-simplifiedview-group-container ${snapshot?.isDraggingOver ? "dragActive" : ""}`}
				{...provided?.droppableProps}>
				{!editActive ?
					<div className='organizer-executionturns-simplifiedview-group-header' onClick={() => setEditActive(!editActive)}>
						{group.name}
					</div>
					:
					<input className='organizer-executionturns-simplifiedview-group-name'
						onKeyDown={e => e.key === "Enter" && _editRequest()}
						ref={newNameRef}
						value={newName}
						onChange={e => setNewName(e.target.value)} />
				}
				<div className='organizer-executionturns-simplifiedview-group-header-tool-container'>
					<div className='organizer-executionturns-simplifiedview-group-header-tool-buttons'>
						<button className='organizer-executionturns-simplifiedview-group-header-tool-add' onClick={() => addExercisesRequest && addExercisesRequest(group)}>
							Aggiungi atleta
						</button>
						<button className='organizer-executionturns-simplifiedview-group-header-tool-delete' onClick={() => deleteRequest && deleteRequest(group)}>
							Elimina gruppo
						</button>
					</div>
					<span>{athletes.length}</span>
				</div>
				<div className='organizer-executionturns-simplifiedview-group-athletes-container'>
					<ConditionalListComponent items={owners.associationFilter()}
						emptyMesssage={<div className='organizer-executionturns-simplifiedview-group-no-athletes'>
							Non ci sono atleti in questo gruppo <button onClick={() => addExercisesRequest && addExercisesRequest(group)}>Aggiungi</button>
						</div>}
						renderList={(associations => associations.map(association =>
							<div key={association.id}>
								<div className='organizer-executionturns-simplifiedview-group-association'>{association.name}</div>
								<div>
									{athletes.filter(a => a.ownerId === association.id).athleteFilter().map(athlete =>
										<GroupAthleteComponent athlete={athlete}
											competitions={competitions}
											group={group}
											missingExe={missingExe}
											selectionOn={selectionOn}
											odlFixRequest={odlFixRequest}
											athletesToMove={athletesToMove}
											selectedCompetitionShow={selectedCompetitionShow}
											selectedExecutionTurn={selectedExecutionTurn}
											setSelectedCompetitionShow={setSelectedCompetitionShow}
											setAthleteToRemove={setAthleteToRemove}
											moveExerciseRequest={athlete => moveExercisesRequest && moveExercisesRequest(group, athlete)} />
									)}
								</div>
							</div>))} />
				</div>
				{provided?.placeholder}
				<CrudForm
					headerText='Cancellazione atleta'
					back={() => showRemove[1](false)}
					denied={() => showRemove[1](false)}
					show={showRemove} confirm={() => {
						removeExercisesRequest && athleteToRemove && removeExercisesRequest(group, athleteToRemove);
						showRemove[1](false);
					}}>
					<p style={{ margin: "10px" }}>{`Sicuro di voler eliminare ${athleteToRemove?.firstName} ${athleteToRemove?.lastName} da ${group.name}?`}</p>
				</CrudForm>
			</div>
		) : (
			<div className='organizer-executionturns-simplifiedview-group-container addGroup'>
				<button onClick={addRequest}>
					<FontAwesomeIcon icon={faAdd} />
					<div>Aggiungi gruppo</div>
				</button>
			</div>

		)
	)
}

function OrganizerExecutionTurnsSVScreen({ selectionOn }: { selectionOn: boolean }) {
	const auth = useAuth();
	const navigate = useNavigate();
	const alert = useAlert();
	const location = useStateLocation();

	const eventContext = useContext(EventEoContext);

	const executionTurn = location.read<ExecutionTurn>("set");

	const [selectedExecutionTurn, setSelectedExecutionTurn] = useState(executionTurn);
	const [executionGroups, setExecutionGroups] = useState<ExecutionGroup[]>();
	const [otherGroups, setOtherGroups] = useState<ExecutionGroup[]>();
	const [loadingGroups, setLoadingGroups] = useState(false);
	const [executionGroupToDelete, setExecutionGroupToDelete] = useState<ExecutionGroup>();
	const [executionGroupToEdit, setExecutionGroupToEdit] = useState<ExecutionGroup>();
	const [selecetedCompetitionShow, setSelectedCompetitionShow] = useState(0);

	const [athletesToMove, setAthletesToMove] = useState<{ athlete: Athlete, group: ExecutionGroup }[]>([]);
	const [athleteToMoveDest, setAthleteToMoveDest] = useState<{ turnId: number, groupId: number }>()

	const showDelete = useState(false);
	const showAddExercises = useState(false);
	const showMoveExercises = useState(false);



	useEffect(() => {
		if (showDelete[0])
			return
		setExecutionGroupToDelete(undefined);
	}, showDelete);

	useEffect(() => {
		if (executionGroupToDelete)
			showDelete[1](true);
	}, [executionGroupToDelete]);

	useEffect(() => {
		if (!selectedExecutionTurn)
			return

		let getExecutionGroups = async () => {
			const executiongroupsFromServer = await get.executionGroupsByTurn(selectedExecutionTurn.id, ["Exercises.Athlete.Owner"])
				.then(r => responseGetJson(r, []));

			setExecutionGroups(executiongroupsFromServer);

			const otherGroupsPormise = eventContext.executionturns.value.filter(et => et.id !== selectedExecutionTurn.id).map(async (executionTurn) => {
				return await get.executionGroupsByTurn(executionTurn.id, ["Exercises.Athlete.Owner"]).then(r => responseGetJson(r, [])) as ExecutionGroup[];
			});

			const othersExecutionGroupsFromServer = await Promise.all(otherGroupsPormise);
			setOtherGroups(othersExecutionGroupsFromServer.flat());
			setLoadingGroups(false);
		};
		setLoadingGroups(true);
		getExecutionGroups();
	}, [selectedExecutionTurn])

	useMemo(() => {
		if (executionGroups && selectedExecutionTurn)
			eventContext.executionturns.crud.edit({ ...selectedExecutionTurn, groups: executionGroups });
	}, [executionGroups]);

	const addRequest = async () => {
		if (!selectedExecutionTurn)
			return

		const res = await post.executionGroup(selectedExecutionTurn.id, "GRUPPO " + ((executionGroups?.length ?? 0) + 1));
		handleResponse(res, auth, alert, navigate, "Errore durante l'inserimento del gruppo",
			"Gruppo inserito correttamente", (data) => setExecutionGroups(p => [...p ?? [], data]));
	}

	const editRequest = async (group: ExecutionGroup) => {
		if (!selectedExecutionTurn)
			return

		const res = await put.executionGroup(group.id, group.name);
		handleResponse(res, auth, alert, navigate, "Errore durante la modifica del gruppo",
			undefined, () => setExecutionGroups(p => changeObjInAry(p ?? [], group)));
	}

	const deleteRequest = (group: ExecutionGroup) => {
		setExecutionGroupToDelete(group);
	}

	const deletionCompleted = (group: ExecutionGroup) => {
		setExecutionGroups(p => p?.filter(g => g.id !== group.id));
		setExecutionGroupToDelete(undefined);
		showDelete[1](false);
	}

	const addExercisesRequest = (executionGroup: ExecutionGroup) => {
		setExecutionGroupToEdit(executionGroup);
		showAddExercises[1](true);
	}

	const addExercisesCompleted = (executionGroup: ExecutionGroup, exercises: Exercise[]) => {
		if (!executionGroups)
			return

		setExecutionGroups(p => changeObjInAry(p ?? [], { ...executionGroup, exercises }));
		const newExes = eventContext.exercises.value.filter(x => exercises.some(y => y.id === x.id));
		eventContext.exercises.crud.edit(newExes.map(x => ({ ...x, executionGroup })));
		showAddExercises[1](false);
	}

	const removeExercisesRequest = async (executionGroup: ExecutionGroup, athlete: Athlete | Athlete[]) => {
		if (!Array.isArray(athlete)) {
			const newExercises = executionGroup.exercises?.filter(exe => exe.athleteId !== athlete.id) ?? [];
			const toRemove = executionGroup.exercises?.filter(exe => exe.athleteId === athlete.id) ?? [];
			const res = await put.executionGroupAddExercises(executionGroup.id, newExercises?.map(exe => exe.id));
			handleResponse(res, auth, alert, navigate, "Errore durante la modifica del gruppo", undefined, () =>
				setExecutionGroups(p => changeObjInAry(p ?? [], { ...executionGroup, exercises: newExercises }))
			)

			const newExes = eventContext.exercises.value.filter(x => toRemove.some(y => y.id === x.id));
			eventContext.exercises.crud.edit(newExes.map(x => ({ ...x, executionGroup: undefined })));
		} else {
			const newExercises = executionGroup.exercises?.filter(exe => !athlete.some(a => a.id === exe.athleteId)) ?? [];
			const toRemove = executionGroup.exercises?.filter(exe => athlete.some(a => a.id === exe.athleteId)) ?? [];
			const res = await put.executionGroupAddExercises(executionGroup.id, newExercises?.map(exe => exe.id));
			handleResponse(res, auth, alert, navigate, "Errore durante la modifica del gruppo", undefined, () =>
				setExecutionGroups(p => changeObjInAry(p ?? [], { ...executionGroup, exercises: newExercises }))
			)

			const newExes = eventContext.exercises.value.filter(x => toRemove.some(y => y.id === x.id));
			eventContext.exercises.crud.edit(newExes.map(x => ({ ...x, executionGroup: undefined })));
		}
	}

	const moveExercisesRequest = (executionGroup: ExecutionGroup, athlete: Athlete) => {
		setAthletesToMove(p => {
			if (p.find(x => x.athlete.id === athlete.id))
				return p.filter(x => x.athlete.id !== athlete.id);
			else
				return [...p, { athlete, group: executionGroup }]
		});
	}
	const denieMoveExercisesRequest = () => {
		setAthletesToMove([]);
		setAthleteToMoveDest(undefined);
		showMoveExercises[1](false);
	}
	const acceptMoveExercisesRequest = async () => {
		if (athleteToMoveDest?.groupId === 0 || athleteToMoveDest?.turnId === 0)
			return;

		const groups: ExecutionGroup[] = getUnique(athletesToMove.map(x => x.group));
		const groupsWithAthletes = groups
			.map(g => ({ ...g, athletes: athletesToMove.filter(y => y.group.id === g.id).map(a => a.athlete) }))
		const promise = groupsWithAthletes.map(async x => await removeExercisesRequest(x, x.athletes));
		await Promise.all(promise);
		const destGroup = otherGroups?.find(og => og.id === athleteToMoveDest?.groupId);
		const exercises = athletesToMove.map(x => x.group.exercises?.filter(y => y.athleteId === x.athlete.id) ?? []).flat();

		if (!destGroup || !destGroup.exercises)
			return;
		const exes = [...destGroup.exercises, ...exercises]
		await put.executionGroupAddExercises(destGroup.id, exes.map(({ id }) => id));

		const newExes = eventContext.exercises.value.filter(x => exes.some(y => y.id === x.id));
		eventContext.exercises.crud.edit(newExes.map(x => ({ ...x, executionGroup: destGroup })));

		setAthleteToMoveDest(undefined);
		setAthletesToMove([]);
		showMoveExercises[1](false);
	}

	const etOptions = eventContext.executionturns.value
		.sort((a, b) => -(Date.parse(b.start) - Date.parse(a.start))).map(et => ({ value: et, label: et.name }));


	const usedExercices = [
		...executionGroups?.map(eg => eg.exercises!).flat().filter(exe => exe) ?? [],
		...otherGroups?.map(eg => eg.exercises!).flat().filter(exe => exe) ?? []
	]

	const addNewExercisesRequest = async (executionGroup: ExecutionGroup, athlete: Athlete) => {
		const newExercises = [...executionGroup.exercises ?? [], ...usedExercices.filter(exe => athlete.id === exe.athleteId)];
		const res = await put.executionGroupAddExercises(executionGroup.id, newExercises?.map(exe => exe.id));
		handleResponse(res, auth, alert, navigate, "Errore durante la modifica del gruppo", undefined, () =>
			setExecutionGroups(p => changeObjInAry(p ?? [], { ...executionGroup, exercises: newExercises }))
		)
		const newExes = eventContext.exercises.value.filter(x => newExercises.some(y => y.id === x.id));
		eventContext.exercises.crud.edit(newExes.map(x => ({ ...x, executionGroup })));
	}

	const odlFixRequest = async (executionGroup: ExecutionGroup, exercises: Exercise[]) => {
		const newExercises = [...executionGroup.exercises ?? [], ...exercises];
		const res = await put.executionGroupAddExercises(executionGroup.id, newExercises?.map(exe => exe.id));
		handleResponse(res, auth, alert, navigate, "Errore durante la modifica del gruppo", undefined, () =>
			setExecutionGroups(p => changeObjInAry(p ?? [], { ...executionGroup, exercises: newExercises }))
		)
		const newExes = eventContext.exercises.value.filter(x => newExercises.some(y => y.id === x.id));
		eventContext.exercises.crud.edit(newExes.map(x => ({ ...x, executionGroup })));
	}

	const odlFixAllRequest = async () => {
		if (!executionGroups)
			return
		let newExecutionGroups: ExecutionGroup[] = [...executionGroups];
		const changesPromise = executionGroups.map(async (executiongroup, index, array) => {
			const exercisesToAdd: Exercise[] = [...executiongroup.exercises ?? []];
			eventContext.exercises.value.forEach(exercise => {
				const otherExercises = array.filter(w => w.id !== executiongroup.id).map(x => x.exercises ?? []).flat();
				const thisExercises = executiongroup.exercises ?? [];

				if (!otherExercises.map(x => x.athleteId).includes(exercise.athleteId) &&
					!thisExercises.map(x => x.id).includes(exercise.id) &&
					thisExercises.map(x => x.athleteId).includes(exercise.athleteId))
					exercisesToAdd.push(exercise);
			})

			const res = await put.executionGroupAddExercises(executiongroup.id, exercisesToAdd?.map(exe => exe.id));
			await handleResponse(res, auth, alert, navigate, "Errore durante la modifica del gruppo", undefined, () =>
				newExecutionGroups = changeObjInAry(newExecutionGroups, { ...executiongroup, exercises: exercisesToAdd }));

			const newExes = eventContext.exercises.value.filter(x => exercisesToAdd.some(y => y.id === x.id));
			eventContext.exercises.crud.edit(newExes.map(x => ({ ...x, executionGroup: executiongroup })));
		});
		await Promise.all(changesPromise);
		setExecutionGroups(newExecutionGroups);
	}

	const onDragEnd = async (result: DropResult) => {
		const { source, destination } = result;

		if (!destination || source.droppableId === destination.droppableId) return;

		const sourceEG = executionGroups?.find(eg => eg.id === parseInt(source.droppableId));
		const sourceAT = await get.athlete(source.index).then(r => responseGetJson(r)) as Athlete;

		const destinationEG = executionGroups?.find(eg => eg.id === parseInt(destination.droppableId));

		if (!sourceEG || !sourceAT || !destinationEG) return;

		await removeExercisesRequest(sourceEG, sourceAT);
		await addNewExercisesRequest(destinationEG, sourceAT);
	}

	const missingExe = eventContext.exercises.value
		.filter(exercise => !executionGroups?.some(g => g.exercises?.map(e => e.id).includes(exercise.id)))
		.filter(exercise => executionGroups?.some(g => g.exercises?.map(e => e.athleteId).includes(exercise.athleteId)));

	return (
		<div className='organizer-executionturns-simplifiedview-container'>
			<ReactSelect
				value={{ value: selectedExecutionTurn, label: selectedExecutionTurn?.name }}
				isSearchable={false}
				className='organizer-executionturns-simplifiedview-turn-selection'
				options={etOptions}
				isLoading={loadingGroups}
				onChange={nv => setSelectedExecutionTurn(nv?.value)}
				placeholder="Seleziona un turno" />
			{selectionOn && athletesToMove.length > 0 &&
				<div className='organizer-executionturns-simplifiedview-move-container'>
					<button
						className='organizer-executionturns-simplifiedview-move-button'
						onClick={() => showMoveExercises[1](true)}>Sposta atleti selezionati</button>
				</div>}
			{missingExe.length > 0 && <div className='organizer-executionturns-simplifiedview-fixodl-container'>
				<button className='organizer-executionturns-simplifiedview-fixodl-button' onClick={odlFixAllRequest}>
					<FontAwesomeIcon icon={faWarning} />
					<span>Allinea incongruenze</span>
				</button>
			</div>}
			<DragDropContext onDragEnd={onDragEnd} >
				<div className='organizer-executionturns-simplifiedview-groups-container'>
					{
						!loadingGroups && executionGroups?.sort((a, b) => b.id - a.id).map(group => {


							return <Droppable key={group.id} droppableId={`${group.id}`}>
								{(provided, snapshot) => (
									<GroupComponent
										selectionOn={selectionOn}
										selectedCompetitionShow={selecetedCompetitionShow}
										setSelectedCompetitionShow={setSelectedCompetitionShow}
										competitions={eventContext.competitions.value}
										key={group.id}
										group={group}
										editRequest={editRequest}
										deleteRequest={deleteRequest}
										addExercisesRequest={addExercisesRequest}
										removeExercisesRequest={removeExercisesRequest}
										moveExercisesRequest={moveExercisesRequest}
										odlFixRequest={odlFixRequest}
										athletesToMove={athletesToMove}
										provided={provided}
										snapshot={snapshot}
										selectedExecutionTurn={selectedExecutionTurn} />
								)}
							</Droppable>
						})
					}
					{selectedExecutionTurn && !loadingGroups &&
						<GroupComponent competitions={eventContext.competitions.value} addRequest={addRequest} />}
				</div>
			</DragDropContext>
			<OrganizerExecutionGroupsDeleteRequest
				deletionCompleted={deletionCompleted}
				show={showDelete}
				executiongroup={executionGroupToDelete} />
			<OrganizerExecutionTurnsSVAthletesSelection
				addRequest={addExercisesCompleted}
				executionGroup={executionGroupToEdit}
				show={showAddExercises}
				usedExercises={usedExercices} />
			<CrudForm
				headerText='Sposta atleti'
				back={denieMoveExercisesRequest}
				denied={denieMoveExercisesRequest}
				show={showMoveExercises} confirm={acceptMoveExercisesRequest}>
				<div className='organizer-executionturns-simplifiedview-move-popup-container'>
					<p>Seleziona turno di destinazione</p>
					<select onChange={(e) => setAthleteToMoveDest(p =>
						p ? { ...p, turnId: +e.target.value } : { turnId: +e.target.value, groupId: 0 })}>
						<option value={0}>---</option>
						{eventContext.executionturns.value.filter(et => et.id !== selectedExecutionTurn?.id).map(et =>
							<option key={et.id} value={et.id}>{et.name}</option>
						)}
					</select>
					{(athleteToMoveDest?.turnId ?? 0) > 0 && <>
						<p>Seleziona gruppo</p>
						<select onChange={(e) => setAthleteToMoveDest(p =>
							p ? { ...p, groupId: +e.target.value } : { groupId: +e.target.value, turnId: 0 })}>
							<option value={0}>---</option>
							{otherGroups?.filter(og => og.executionTurnId === athleteToMoveDest?.turnId).map(eg =>
								<option key={eg.id} value={eg.id}>{eg.name}</option>
							)}
						</select>
					</>}
				</div>
			</CrudForm>
		</div>
	)
}

export default OrganizerExecutionTurnsSVScreen