import React, { useContext, useEffect, useState } from 'react'
import ModalWindow from '../../ModalWindow';
import { Athlete, Competition, Exercise, ExerciseType, Team, responseGetJson } from '../../../models/models';
import { changeObjInAry, getUnique } from '../../../utility/UtilityFunctions';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import { COMP_TYPE, del, post } from '../../../models/backendReq';
import './OrganizerCompetitionMassiveExercises.scss';
import EventEoContext, { ExerciseWithCompetition } from '../../../contexts/EventEoContext';
import FullWindowLoading from '../../FullWindowLoading';

interface ExerciseTypeConfig extends ExerciseType {
	competition: Competition;
	active: boolean;
	quantity: number;
}

interface props {
	selectedAthletes: { athletes: (Athlete | Team)[], competition: Competition }[];
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerCompetitionMassiveExercises(props: props) {
	const eventContext = useContext(EventEoContext);

	const [etsConfig, setEtsConfig] = useState<ExerciseTypeConfig[]>([]);
	const [progress, setProgress] = useState({ text: "", value: 0 });

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setEtsConfig(props.selectedAthletes.map(sa => eventContext.associatedexercisetypes.value
			.filter(aet => aet.competition.id === sa.competition.id)
			.map(x => ({ ...x, active: false, quantity: 1, competition: sa.competition }))).flat())
	}, [props, eventContext])

	const qtyChanged = (e: React.ChangeEvent<HTMLInputElement>, exerciseType: ExerciseTypeConfig) => {
		setEtsConfig(prev => prev.map(x => {
			if (x.competition.id !== exerciseType.competition.id || x.id !== exerciseType.id)
				return x;
			else
				return { ...x, quantity: e.target.valueAsNumber };
		}));
	}

	const etSelection = (exerciseType: ExerciseTypeConfig) => {
		setEtsConfig(prev => prev.map(x => {
			if (x.competition.id !== exerciseType.competition.id || x.id !== exerciseType.id)
				return x;
			else
				return { ...x, active: !x.active };
		}));
	}


	const execute = async () => {
		if (etsConfig.every(et => et.active === false))
			return

		setLoading(true);
		const toDel: ExerciseWithCompetition[] = [];
		const toAdd: ExerciseWithCompetition[] = [];
		props.selectedAthletes.forEach(athlete => {
			athlete.athletes.forEach(ath => {
				etsConfig.filter(x => x.active && x.competition.id === athlete.competition.id).map(et => {
					const _exercises = eventContext.exercises.value
						.filter(e => e.typeId === et.id && e.athleteId === ath?.id && e.competitionId === athlete.competition.id);

					const actQty = _exercises.length;

					if (actQty > et.quantity)
						for (let index = 0; index < actQty - et.quantity; index++)
							toDel.push(_exercises[index]);

					if (actQty < et.quantity)
						for (let index = 0; index < et.quantity - actQty; index++)
							toAdd.push({
								athleteId: ath?.id,
								competitionId: athlete.competition.id,
								typeId: et.id,
								competition: athlete.competition,
								id: 0,
								scores: [],
								teamId: 0
							});
				});
			});
		})
		const total = toDel.length + toAdd.length;
		const delPromise = toDel.map(exercise => del.exercise(exercise.id).then(() => setProgress(prev =>
			({ text: `${(prev.value / 100) * total} / ${total}`, value: prev.value + (100 / total) })
		)));
		eventContext.exercises.crud.delete(toDel);
		await Promise.all(delPromise);
		const addPromise = toAdd.map(async (exercise) => {
			if (exercise.competition.type === COMP_TYPE.Individual) {
				const res = await post.exercise(exercise.typeId, exercise.competitionId, exercise.athleteId);
				setProgress(prev => ({ text: `${(prev.value / 100) * total} / ${total}`, value: prev.value + (100 / total) }));
				return { ...await responseGetJson(res), competition: exercise.competition };
			} else {
				const res = await post.exercise(exercise.typeId, exercise.competitionId, undefined, exercise.athleteId);
				setProgress(prev => ({ text: `${(prev.value / 100) * total} / ${total}`, value: prev.value + (100 / total) }));
				return { ...await responseGetJson(res), competition: exercise.competition };
			}
		});
		const exesToAdd = await Promise.all(addPromise);
		eventContext.exercises.crud.add(exesToAdd);
		props.show[1](false);
		setProgress({ text: "", value: 0 });
		setLoading(false);
	}

	const etsConfigUnique: ExerciseTypeConfig[] = getUnique(etsConfig, "competition");
	return (
		<>
			<ModalWindow show={props.show}>
				<h3>Dichiarazione massiva esercizi</h3>
				<div className='organizer-competition-massive-exercises-container'>
					<div>Selezionare gli attrezzi da utilizzare</div>
					<div>Gli attrezzi non selezionati resteranno invariati</div>
					<div className='organizer-competition-massive-ets-container'>
						{etsConfigUnique.map((etc, index, array) => <div key={index}>
							{array.length > 1 && <h4>{etc.competition.name}</h4>}
							{etsConfig.filter(x => x.competition.id === etc.competition.id).map(et =>
								<div key={et.id} className='organizer-competition-massive-ets'>
									<label className='organizer-competition-massive-exercises-ets-displayName'>
										<input type='checkbox' checked={et.active} onChange={() => etSelection(et)} />
										{et.displayName}
									</label>
									{et.active &&
										<input
											className='organizer-competition-massive-ets-quantity'
											value={et.quantity}
											min={0}
											type='number'
											onChange={e => qtyChanged(e, et)} />}
								</div>
							)}
						</div>)}
					</div>

					<div className='organizer-competition-massive-buttons'>
						<button className='organizer-competition-massive-button' onClick={() => props.show[1](false)}>Annulla</button>
						<button className='organizer-competition-massive-button execute' onClick={execute}>Esegui</button>
					</div>
				</div>
			</ModalWindow>
			<FullWindowLoading active={loading} progress={progress.value} progressText={progress.text} />
		</>
	)
}

export default OrganizerCompetitionMassiveExercises