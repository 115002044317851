import { faChevronDown, faChevronUp, faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Athlete, Exercise, AthleteClass, TeamClass, Score } from '../../models/models';
import AthleteCom from './AthleteCom';
import './TeamCom.scss';
import '../../utility/prototype'
import { CompetitionContextData } from '../../contexts/CompetitionContext';
import { TurnContextData } from '../../contexts/TurnContext';
import { COMP_TYPE } from '../../models/backendReq';
import ManageScoreCom from './ManageScoreCom';
import JuryContext from '../../contexts/JuryContext';

interface TeamWithAthletes extends TeamClass {
	athletes: AthleteClass[];
}
interface props {
	athleteFilter: string;
	context: CompetitionContextData | TurnContextData;
	filterShowWithScore: boolean;
	filterShowWithoutScore: boolean;
	newScoreAssigned: () => void;
	team: TeamWithAthletes;
	collective: boolean
	// exercises: Exercise[];
}
function TeamCom({ athleteFilter, context, newScoreAssigned, filterShowWithScore, filterShowWithoutScore, team, collective }: props) {
	const jurycontext = useContext(JuryContext);
	let [expandTeam, setExpandTeam] = useState(false);

	let ExtendButtonClick = () => {
		if (!collective)
			setExpandTeam(!expandTeam);
		else
			setShowScore(true);
	};

	let teamContainerClassName = expandTeam ? 'team-athletes-container expanded' : 'team-athletes-container';

	const filterCompetitors = (competitor: AthleteClass, index: number, array: Athlete[]) => {
		let retVal;
		retVal = (competitor.exercises?.length ?? 1) > 0;
		if (!filterShowWithScore)
			retVal = retVal && !competitor.hasAllScore;

		if (!filterShowWithoutScore)
			retVal = retVal && competitor.hasAllScore;

		return retVal
	}

	//################################################################ 
	//                            COLLECTIVE 
	//################################################################
	let [cachedExercises, setCachedExercises] = useState<Exercise[]>(team.exercises ?? []);
	const [exerciseToChange, setExerciseToChange] = useState<Exercise>();
	const [showScore, setShowScore] = useState(false);

	const addAnimation = () => {
		const animationKeyFrames = {
			backgroundColor: ["#00FF0055", "#FFFFFF"],
			boxShadow: ["0 0 5px #00FF00", "none"]
		};

		const animationOption: KeyframeAnimationOptions = {
			duration: 700,
			iterations: 1,
			// iterationStart: 2000
		}

		document.getElementById(`judgeTeam${team.id}`)?.animate(animationKeyFrames, animationOption);
	};

	const deleteAnimation = () => {
		const animationKeyFrames = {
			backgroundColor: ["#AA000055", "#FFFFFF"],
			boxShadow: ["0 0 5px #AA0000", "none"]
		};

		const animationOption: KeyframeAnimationOptions = {
			duration: 700,
			iterations: 1,
		}

		document.getElementById(`judgeTeam${team.id}`)?.animate(animationKeyFrames, animationOption);
	};

	const editAnimation = () => {
		const animationKeyFrames = {
			backgroundColor: ["#ffa50055", "#FFFFFF"],
			boxShadow: ["0 0 5px #ffa500", "none"]
		};

		const animationOption: KeyframeAnimationOptions = {
			duration: 700,
			iterations: 1,
		}

		document.getElementById(`judgeTeam${team.id}`)?.animate(animationKeyFrames, animationOption);
	};

	let addScore = async (score: Score) => {
		const index = cachedExercises.findIndex(exe => exe.id === score.exerciseId);
		const newExercises = [...cachedExercises];
		newExercises[index].scores.push(score);
		setCachedExercises(newExercises);
		addAnimation();
		newScoreAssigned();
		jurycontext.scoresHandler.add([score]);
	}

	let deleteScore = async (score?: Score) => {
		if (!score)
			return
		const index = cachedExercises.findIndex(x => x.id === score.exerciseId);
		const newExercises = [...cachedExercises];
		newExercises[index].scores = newExercises[index].scores.filter(x => x.id !== score.id);
		setCachedExercises(newExercises);
		deleteAnimation();
		jurycontext.scoresHandler.delete([score]);
	};

	let editScores = async (scores: Score[]) => {
		const newExercises = [...cachedExercises];
		scores.forEach(score => {
			const exeIndex = newExercises.findIndex(x => x.id === score.exerciseId);
			const scoreIndex = newExercises[exeIndex].scores.findIndex(x => x.id === score.id);
			newExercises[exeIndex].scores[scoreIndex] = score;
			setCachedExercises(newExercises);
		})
		editAnimation();
		newScoreAssigned();
		jurycontext.scoresHandler.edit(scores);
	}

	let assignRequest = (e: Exercise) => {
		setExerciseToChange(e);
	}


	// const exerType = context.exerciseTypes.find(et => et.id === exerciseToChange?.typeId);
	let exeStatClassName = 'team-exercises-container';
	const atLeastOneScore = cachedExercises.some(exe => exe.scores.length > 0);
	const allScoreDone = cachedExercises.every(exe => exe.scores.length > 0);
	if (atLeastOneScore)
		exeStatClassName += ' some';
	if (allScoreDone)
		exeStatClassName += ' every';

	return (
		<div id={`judgeTeam${team.id}`} className='team-container'>
			<div onClick={ExtendButtonClick} className='team-name-container'>
				{collective && <div className={exeStatClassName}>
					<FontAwesomeIcon icon={atLeastOneScore ? faCircleCheck : faCircle} />
				</div>}
				<div className='team-name'>{team.name}</div>
				{
					!collective && (
						<div className='team-name-icon'>
							<FontAwesomeIcon icon={faChevronUp} style={{ transform: `rotate(${expandTeam ? '0' : '180'}deg)` }} />
						</div>
					)
				}
			</div>
			<div className={teamContainerClassName}>
				{
					team.athletes.length ?
						team.athletes.athleteFilter(athleteFilter)
							.filter(filterCompetitors)
							.map((athlete) => <AthleteCom newScoreAssigned={newScoreAssigned} key={athlete.id} athlete={athlete} context={context} exercises={athlete.exercises?.filter(exe => exe.athleteId === athlete.id) ?? []} />) :
						<p>Non ci sono atleti in questa squadra</p>
				}
			</div>
			{
				collective && showScore && (
					<ManageScoreCom
						addCompleted={addScore}
						assignments={(context as CompetitionContextData).assignments}
						athlete={team}
						deleteCompleted={deleteScore}
						editCompleted={editScores}
						exercises={cachedExercises}
						exerciseTypes={context.exerciseTypes}
						juries={(context as CompetitionContextData).juries}
						setShowScore={setShowScore}
						show={showScore}
						userJury={(context as CompetitionContextData).userJury}
					/>
				)
			}
		</div>
	);
}

export default TeamCom;