import { useContext, useState } from 'react';
import OrganaizerEventuserCom from '../../../components/Organizer/OrganaizerEventuserCom';
import OrganizerEventusersAddRequest from './OrganizerEventusersAddRequest';
import OrganizerEventusersDeleteRequest from './OrganizerEventusersDeleteRequest';
import OrganizerEventusersEditRequest from './OrganizerEventusersEditRequest';


import { User } from '../../../models/models';


import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableNameCell from '../../../components/Organizer/TableComponents/TableNameCell';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../../contexts/EventEoContext';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerEventusersScreen.scss';
import ConditionalListComponent from '../../../components/ConditionalListComponent';

function OrganizerEventusersScreen() {
	const context = useContext(EventEoContext);

	let [eventuserToDel, setUserToDel] = useState<User>();
	let [eventuserToPut, setUserToPut] = useState<User>();

	let showDel = useState(false);
	let showAdd = useState(false);
	let showEdi = useState(false);

	let deleteRequest = (eventuser: User) => {
		showDel[1](true);
		setUserToDel(eventuser);
	}
	let deletionCompleted = (eventuser: User) => {
		context.eventusers.crud.delete(eventuser);
		showDel[1](false)
	}
	let addCompleted = (eventuser: User) => {
		context.eventusers.crud.add(eventuser);
		showAdd[1](false)
	}
	let editRequest = (eventuser: User) => {
		showEdi[1](true);
		setUserToPut(eventuser);
	}
	let editCompleted = (eventuser: User) => {
		context.eventusers.crud.edit(eventuser);
		showEdi[1](false)
	}

	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi utente' }
	];

	return (
		<OrganizerEventTable text='UTENTI EVENTO'>
			<EoToolBar elements={_toolBarElements} />
			<Table>
				<thead>
					<tr>
						<TableNameCell text='Nome' isHeader />
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={context.eventusers.value}
						emptyMesssage='Nessun utente creato'
						renderList={eventusers => eventusers.map(eventuser =>
							<OrganaizerEventuserCom key={eventuser.id} eventuser={eventuser} del={deleteRequest} edi={editRequest} />
						)} />
				</tbody>
			</Table>
			<OrganizerEventusersDeleteRequest eventuser={eventuserToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerEventusersAddRequest event={context.event} show={showAdd} addCompleted={addCompleted} />
			<OrganizerEventusersEditRequest eventuser={eventuserToPut} show={showEdi} editCompleted={editCompleted} />
		</OrganizerEventTable>
	);
}

export default OrganizerEventusersScreen;