import React from 'react';
import { Association, ExecutionGroup } from '../../models/models';

import './AssociationExeGroupCom.scss';

interface props {
	association: Association | ExecutionGroup;
	setAssociation: React.Dispatch<React.SetStateAction<Association | ExecutionGroup | undefined | string>>
}

function AssociationExeGroupCom({ association, setAssociation }: props) {


	return (
		<div
			onClick={() => setAssociation(association)}
			className='company-container'>
			<p>{association.name}</p>
		</div>
	);
}

export default AssociationExeGroupCom;