import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TableLinkRow.scss';
import { NavLink } from 'react-router-dom';

interface props {
	text: string;
	id?: number;
	navigate?: { path: string, state?: any };
	isHeader?: boolean;
	selectable?: boolean;
	fullArray?: any[];
	selectAll?: React.ChangeEventHandler<HTMLInputElement>;
	selectElement?: any;
	selectArray?: any[];
	setSelectArray?: React.Dispatch<React.SetStateAction<any[]>>
}


function TableLinkRow(props: props) {
	const isChecked = props.selectArray?.map(({ id }) => id).includes(props.id);

	const clicked = () => {
		if (props.selectable) {
			if (!props.setSelectArray || !props.selectArray || !props.fullArray)
				return
			if (isChecked)
				props.setSelectArray(props.selectArray.filter(c => c.id !== props.id));
			else
				props.setSelectArray(previous => [...previous, props.selectElement]);
		}
	}

	const isHead = props.isHeader || !props.navigate || !props.id
	return (
		isHead ? (
			<th className='organizer-link-col'>
				{
					props.selectable ? (
						<label>
							<input
								type='checkbox'
								checked={props.fullArray?.length === props.selectArray?.length}
								onChange={props.selectAll}
							/>{props.text}
						</label>
					) : (
						props.text
					)
				}
			</th>
		) : (
			<td className={`organizer-link-col rows${props.selectable ? " selectable" : ""}`} onClick={clicked}>
				{!props.selectable ? <NavLink
					className='organizer-link-col-container'
					to={props.navigate?.path ?? ""}
					state={props.navigate?.state}>{props.text}</NavLink> :
					<div className='organizer-link-col-container'>
						<input
							style={{ height: "15px", width: "15px", marginRight: "10px" }}
							type='checkbox'
							checked={isChecked} readOnly />
						<div>{props.text}</div>
					</div>}
			</td>
		)
	)
}



export default TableLinkRow