import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { get } from './backendReq';

interface ServerStatusContextProps {
	isServerOnline: boolean;
}

const ServerStatusContext = createContext<ServerStatusContextProps | undefined>(undefined);

export const useServerStatus = (): ServerStatusContextProps => {
	const context = useContext(ServerStatusContext);
	if (context === undefined) {
		throw new Error('useServerStatus must be used within a ServerStatusProvider');
	}
	return context;
};


interface ServerStatusProviderProps {
	children: React.ReactNode;
	checkInterval?: number; // Intervallo di tempo tra i controlli in millisecondi, default 5000 ms
	onOnline?: () => void;  // Callback chiamata quando il server diventa online
	onOffline?: () => void; // Callback chiamata quando il server diventa offline
}

export const ServerStatusProvider: React.FC<ServerStatusProviderProps> = ({ children, checkInterval = 5000, onOnline, onOffline }) => {
	const [isServerOnline, setIsServerOnline] = useState<boolean>(true);

	const checkServerStatus = useCallback(async () => {
		try {
			const response = await get.accountSelf();
			if (response.ok) {
				setIsServerOnline(prevStatus => {
					if (!prevStatus && onOnline) onOnline();
					return true;
				});
			} else {
				setIsServerOnline(prevStatus => {
					if (prevStatus && onOffline) onOffline();
					return false;
				});
			}
		} catch (error) {
			setIsServerOnline(prevStatus => {
				if (prevStatus && onOffline) onOffline();
				return false;
			});
		}
	}, [onOnline, onOffline]);

	useEffect(() => {
		checkServerStatus(); // Controllo iniziale

		const intervalId = setInterval(checkServerStatus, checkInterval);

		return () => clearInterval(intervalId); // Pulizia dell'intervallo al dismontaggio del componente
	}, [checkServerStatus, checkInterval]);

	return (
		<ServerStatusContext.Provider value={{ isServerOnline }}>
			{children}
		</ServerStatusContext.Provider>
	);
};
