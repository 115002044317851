import { RankingConfiguration } from "./models";

export enum COMP_TYPE {
	Individual = "Individual",
	Cumulative = "Cumulative",
	Collective = "Collective"
}

export enum ATHLETE_GENDER {
	Female = "Female",
	Male = "Male",
	Mixed = "Mixed"
}

enum REQ_METHOD {
	GET = 'GET',
	POST = 'POST',
	DELETE = 'DELETE',
	PUT = 'PUT'
}

export const serverIp = process.env.REACT_APP_HOST ?? "";

const requestOptions = (method: REQ_METHOD, body?: any, noheaders?: boolean, notJson?: boolean) => {
	let credentialsInclude: RequestCredentials = 'include';
	const reqOption = {
		credentials: credentialsInclude,
		method: method,
		body: notJson ? body : JSON.stringify(body)
	}

	return noheaders ? reqOption : { ...reqOption, headers: { 'Content-Type': 'application/json' } }
};


function createUrl(route: string, queryParams?: { [id: string]: any; }): string {
	let url = new URL(route, serverIp);

	if (queryParams) {
		const params = new URLSearchParams()
		Object.entries(queryParams).filter(([_, value]) => value !== null && value !== undefined).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach(v => params.append(key, v))
			} else
				params.append(key, value)
		});

		url.search = params.toString();
	}

	return url.toString();
}

let get = {
	/**ACCOUNTS */
	async accountSelf() {
		return await fetch(createUrl(`accounts/self`), requestOptions(REQ_METHOD.GET));
	},

	/**ASSOCIATIONS */
	async association(id: Number) {
		return await fetch(createUrl(`Associations/${id}`), requestOptions(REQ_METHOD.GET));
	},
	async associations() {
		return await fetch(createUrl(`Associations`), requestOptions(REQ_METHOD.GET));
	},
	async associationsByCompetition(CompetitionId: Number) {
		return await fetch(createUrl(`/competitions/${CompetitionId}/associations`), requestOptions(REQ_METHOD.GET));
	},
	async associationAdmins(associationId: number) {
		return await fetch(createUrl(`/associations/${associationId}/admins`), requestOptions(REQ_METHOD.GET));
	},
	async selfAssociations() {
		return await fetch(createUrl(`/self/associations`), requestOptions(REQ_METHOD.GET));
	},
	async associationEventOrganizers(associationId: number) {
		return await fetch(createUrl(`/associations/${associationId}/EventOrganizers`), requestOptions(REQ_METHOD.GET));
	},
	async associationEvents(associationId: number) {
		return await fetch(createUrl(`/associations/${associationId}/Events`), requestOptions(REQ_METHOD.GET));
	},

	/**ATHLETES */
	async athletesByAssociation(associationId: number, includes?: "Owner"[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`associations/${associationId}/athletes`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async athlete(id: number, includes?: "Owner"[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`athletes/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async athletesByTeam(TeamId: number, competitionId: number, associationId?: number, includes?: "Owner"[]) {
		let queryParam = { "associationId": associationId, "include": includes };

		return await fetch(createUrl(`competitions/${competitionId}/teams/${TeamId}/athletes`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async athletesByCompetition(CompetitionId: number, associationId?: number, includes?: "Owner"[]) {
		let queryParam = { "associationId": associationId, "include": includes };

		return await fetch(createUrl(`competitions/${CompetitionId}/athletes`, queryParam), requestOptions(REQ_METHOD.GET))
	},

	/**CHAMPIONSHIPS */
	async championships(organizerId?: number) {
		let queryParam = { "organizerId": organizerId };

		return await fetch(createUrl(`championships`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async championship(id: number) {
		return await fetch(createUrl(`Championships/${id}`), requestOptions(REQ_METHOD.GET));
	},
	/**COMPETITION LEVELS */
	async competitionLevelByEventOrganizer(eventOrganizerId: number) {
		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/Competition/Levels`), requestOptions(REQ_METHOD.GET));
	},
	async competitionLevel(eventOrganizerId: number, id: number) {
		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/Competition/Levels/${id}`), requestOptions(REQ_METHOD.GET));
	},
	/**COMPETITIONS */
	async competitionsByEvent(eventId: number, includes?: "Level"[]) {
		const queryParam = { "include": includes };
		return await fetch(createUrl(`events/${eventId}/competitions`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async competition(id: number, includes?: "Level"[]) {
		const queryParam = { "include": includes };
		return await fetch(createUrl(`competitions/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async competitions(type?: COMP_TYPE, includes?: "Level"[]) {
		const queryParam = { "type": type, "include": includes };
		return await fetch(createUrl(`competitions`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async competitionsByJudge(judgeId: number, includes?: "Level"[]) {
		const queryParam = { "include": includes };
		return await fetch(createUrl(`accounts/judges/${judgeId}/competitions`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async competitionsByChampionship(championshipId: number, includes?: "Level"[]) {
		const queryParam = { "include": includes };
		return await fetch(createUrl(`championships/${championshipId}/competitions`, queryParam), requestOptions(REQ_METHOD.GET));
	},

	/**EVENT ORGANIZER */
	async eventOrganizers() {
		return await fetch(createUrl(`EventOrganizers`), requestOptions(REQ_METHOD.GET));
	},
	async eventOrganizer(id: number) {
		return await fetch(createUrl(`EventOrganizers/${id}`), requestOptions(REQ_METHOD.GET));
	},
	async eventOrganizerAdmins(eventOrganizerId: number) {
		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/admins`), requestOptions(REQ_METHOD.GET));
	},
	async selfEventOrganizers() {
		return await fetch(createUrl(`/self/EventOrganizers`), requestOptions(REQ_METHOD.GET));
	},
	async eventOrganizerAssociations(eventOrganizerId: number) {
		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/associations`), requestOptions(REQ_METHOD.GET));
	},

	/**EVENTS */
	async events(organizerId?: number) {
		let queryParam = { "organizerId": organizerId };
		return await fetch(createUrl(`events`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async event(id: number) {
		return await fetch(createUrl(`events/${id}`), requestOptions(REQ_METHOD.GET));
	},

	/**EVENT USERS */
	async usersInEvent(eventId: number) {
		return await fetch(createUrl(`events/${eventId}/users`), requestOptions(REQ_METHOD.GET));
	},
	async userInEvent(id: number) {
		return await fetch(createUrl(`events/users/${id}`), requestOptions(REQ_METHOD.GET));
	},

	/**EXECUTION GROUPS */
	async executionGroupsByTurn(turnId: number, includes?: ("Exercises" | "Exercises.Athlete" | "Exercises.Athlete.Owner" | "Exercises.Roster")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`Execution/Turns/${turnId}/Groups`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async executionGroup(id: number, includes?: ("Exercises" | "Exercises.Athlete" | "Exercises.Athlete.Owner" | "Exercises.Roster")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`Execution/Groups/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	/**EXECUTION TURNS */
	async executionTurn(id: number, includes?: ("Groups.Exercises" | "Groups.Exercises.Athlete" | "Groups.Exercises.Athlete.Owner" | "Groups.Exercises.Roster" | "Groups")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`Execution/Turns/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async executionTurnsByEvent(eventId: number, includes?: ("Groups.Exercises" | "Groups.Exercises.Athlete" | "Groups.Exercises.Athlete.Owner" | "Groups.Exercises.Roster" | "Groups")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`events/${eventId}/Execution/Turns`, queryParam), requestOptions(REQ_METHOD.GET));
	},

	/**EXERCISES */
	async excercisesByCompetition(competitionId: number, athleteId?: number, typeId?: number, teamId?: number, includes?: ("ExecutionGroup" | "Athlete" | "Roster")[]) {
		let queryParam = { "athleteId": athleteId, "typeId": typeId, "teamId": teamId, "include": includes };

		return await fetch(createUrl(`competitions/${competitionId}/exercises`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async excercise(id: number, includes?: ("ExecutionGroup" | "Athlete" | "Roster")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`excercises/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},

	/**EXERCISE TYPES */
	async excerciseTypes(includes?: "ScoreComposition"[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`exercisetypes`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async excerciseTypesByCompetition(competitionId: number, includes?: "ScoreComposition"[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`competitions/${competitionId}/exercisetypes`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async excerciseType(id: number, includes?: string[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`exercisetypes/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},

	/**FLOOR MUSIC */
	async getFloormusicMedia(floorMusicId: number) {
		return await fetch(createUrl(`floormusic/${floorMusicId}/media`), requestOptions(REQ_METHOD.GET));
	},
	async getFloormusicMetadata(floorMusicId: number, includes?: ("Association")[]) {
		let queryParam = { "include": includes };
		return await fetch(createUrl(`floormusic/${floorMusicId}/metadata`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async getFloormusicEventMetadata(eventId: number, includes?: ("Association")[]) {
		let queryParam = { "include": includes };
		return await fetch(createUrl(`events/${eventId}/floormusic/metadata`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async getFloormusicEventMedia(eventId: number) {
		return await fetch(createUrl(`events/${eventId}/media`), requestOptions(REQ_METHOD.GET));
	},

	/**JURIES */
	async juries(eventId: number, includes?: ("Assignments" | "Judges" | "Event")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`events/${eventId}/juries`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async jury(id: number, includes?: ("Assignments" | "Judges" | "Event")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`juries/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async selfJury(includes?: ("Assignments" | "Judges" | "Event")[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`self/juries`, queryParam), requestOptions(REQ_METHOD.GET));
	},

	/**JURY ASSIGNMENTS */
	async juriesAssignment(id: number) {
		return await fetch(createUrl(`juries/assignments/${id}`), requestOptions(REQ_METHOD.GET));
	},
	async juriesAssignments(competitionId?: number, judgeId?: number, exerciseTypeId?: number) {
		let queryParam = { "competitionId": competitionId, "judgeId": judgeId, "exerciseTypeId": exerciseTypeId };

		return await fetch(createUrl("juries/assignments", queryParam), requestOptions(REQ_METHOD.GET));
	},
	/** RANKING CONFIGURATIONS */
	async rankingConfigurations(competitionId: number) {
		return await fetch(createUrl(`competitions/${competitionId}/ranking/configuration`), requestOptions(REQ_METHOD.GET));
	},
	/**ROSTERS */
	async rostersByTeam(id: number) {
		return await fetch(createUrl(`teams/${id}/rosters`), requestOptions(REQ_METHOD.GET));
	},
	async rostersByCompetition(id: number) {
		return await fetch(createUrl(`competitions/${id}/rosters`), requestOptions(REQ_METHOD.GET));
	},
	async rosters(competitionId: number, teamId: number) {
		return await fetch(createUrl(`rosters/${competitionId}-${teamId}`), requestOptions(REQ_METHOD.GET));
	},

	/**SCORE FRGMENT TYPES */

	/**SCORES */
	async scoresByExerciseId(exerciseId: number) {
		return await fetch(createUrl(`exercises/${exerciseId}/scores`), requestOptions(REQ_METHOD.GET));
	},
	async score(id: number) {
		return await fetch(createUrl(`scores/${id}`), requestOptions(REQ_METHOD.GET));
	},

	/**TEAMS */
	async teamsByAssociation(associationId: number, includes?: "Owner"[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`associations/${associationId}/teams`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async team(id: number, includes?: "Owner"[]) {
		let queryParam = { "include": includes };

		return await fetch(createUrl(`teams/${id}`, queryParam), requestOptions(REQ_METHOD.GET));
	},
	async teamsByCompetition(competitionId: number, associationId?: number, includes?: "Owner"[]) {
		let queryParam = { "associationId": associationId, "include": includes };

		return await fetch(createUrl(`competitions/${competitionId}/teams`, queryParam), requestOptions(REQ_METHOD.GET));
	},

	/**USERS */
	async users() {
		return await fetch(createUrl(`users`), requestOptions(REQ_METHOD.GET));
	},
	async user(id: number) {
		return await fetch(createUrl(`users/${id}`), requestOptions(REQ_METHOD.GET));
	},
};

let post = {
	/**ACCOUNTS */
	async login(userName: string, password: string) {
		let body = {
			userName: userName,
			password: password
		};
		return await fetch(createUrl(`accounts/login`), requestOptions(REQ_METHOD.POST, body));
	},
	async logout() {
		let body = {};
		return await fetch(createUrl(`accounts/logout`), requestOptions(REQ_METHOD.POST, body));
	},

	/**ASSOCIATIONS */
	async association(name: string) {
		let body = { name: name };

		return await fetch(createUrl(`associations`), requestOptions(REQ_METHOD.POST, body));
	},

	/**ATHLETES */
	async athlete(associationId: number, firstName: string, lastName: string, code: string, birthDate: string, gender: string) {
		let body = {
			firstName: firstName,
			lastName: lastName,
			code: code,
			birthDate: birthDate,
			gender: gender
		};

		return await fetch(createUrl(`associations/${associationId}/athletes`), requestOptions(REQ_METHOD.POST, body));
	},

	/**CHAMPIONSHIPS */
	async championship(name: string) {
		let body = {
			name: name
		};

		return await fetch(createUrl(`Championships`), requestOptions(REQ_METHOD.POST, body));
	},
	/**COMPETITION LEVELS */
	async competitionLevel(eventOrganizerId: number, name: string, description?: string) {
		let body = { name, description };

		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/Competition/Levels`), requestOptions(REQ_METHOD.POST, body));
	},

	/**COMPETITIONS */
	async competition(eventId: number, type: string, name: string, exerciseTypeIds: number[], levelId?: number, category?: any) {
		let body = {
			type: type,
			name: name,
			levelId: levelId,
			category: category,
			exerciseTypeIds: exerciseTypeIds
		};

		return await fetch(createUrl(`events/${eventId}/competitions`), requestOptions(REQ_METHOD.POST, body));
	},
	async competitionAddBluk(eventId: number, build: any[]) {
		let body = build;

		return await fetch(createUrl(`events/${eventId}/competitions/bulk`), requestOptions(REQ_METHOD.POST, body));
	},
	async competitionAddInAGroup(competitionId: number, groupId: number) {
		let body = {};

		return await fetch(createUrl(`championships/${groupId}/competitions/${competitionId}`), requestOptions(REQ_METHOD.POST, body));
	},

	/**EVENT ORGANIZER */
	async eventOrganizer(name: string) {
		let body = { name: name };

		return await fetch(createUrl(`EventOrganizers`), requestOptions(REQ_METHOD.POST, body));
	},

	/**EVENTS */
	async event(ownerId: number, name: string, startDate: string, endDate: string, subscriptionEndDate: string, subscriptionStartDate?: string) {
		let body = {
			ownerId: ownerId,
			name: name,
			startDate: startDate,
			endDate: endDate,
			subscriptionStartDate,
			subscriptionEndDate
		};

		return await fetch(createUrl(`events`), requestOptions(REQ_METHOD.POST, body));
	},

	/**EVENT USERS */
	async eventUserLogin(eventId: number, accessCode: string) {
		let body = { accessCode: accessCode };

		return await fetch(createUrl(`events/${eventId}/users/login`), requestOptions(REQ_METHOD.POST, body));
	},
	async eventUser(eventId: number, accessCode: string, displayName: string) {
		let body = { accessCode: accessCode, displayName: displayName };

		return await fetch(createUrl(`events/${eventId}/users`), requestOptions(REQ_METHOD.POST, body));
	},

	/**EXECUTION GROUPS */
	async executionGroup(turnId: number, name: string) {
		let body = {
			name: name
		};

		return await fetch(createUrl(`Execution/Turns/${turnId}/groups`), requestOptions(REQ_METHOD.POST, body));
	},
	/**EXECUTION TURNS */
	async executionTurn(eventId: number, name: string, start: string, end: string) {
		let body = {
			name: name,
			start: start,
			end: end
		};

		return await fetch(createUrl(`events/${eventId}/Execution/Turns	`), requestOptions(REQ_METHOD.POST, body));
	},

	/**EXERCISES */
	async exercise(typeId: number, competitionId: number, athleteId?: number, teamId?: number) {
		let body = {
			typeId: typeId,
			athleteId: athleteId,
			teamId: teamId
		};

		return await fetch(createUrl(`competitions/${competitionId}/exercises`), requestOptions(REQ_METHOD.POST, body));
	},

	/**EXERCISE TYPES */
	async exerciseType(name: string, displayName: string, scoreComposition: any[]) {
		let body = { name, displayName, scoreComposition };

		return await fetch(createUrl(`exercisetypes`), requestOptions(REQ_METHOD.POST, body));
	},

	/**FLOOR MUSIC */
	async floorMusic(eventId: number, associationId: number, file: FormData) {
		let body = file;

		return await fetch(
			createUrl(`events/${eventId}/association/${associationId}/floormusic`),
			requestOptions(REQ_METHOD.POST, body, true, true)
		);
	},

	/**INDIVIDUAL COMPETITIONS */
	async individualCompetitionTransferAthlete(athletes: { sourceCompetitionId: number, targetCompetitionId: number, athleteId: number }[]) {
		let body = athletes;

		return await fetch(createUrl(`competitions/subscriptions/transfers/bulk`), requestOptions(REQ_METHOD.POST, body));
	},

	/**JURIES */
	async jury(eventId: number, name: string) {
		let body = { name: name };

		return await fetch(createUrl(`events/${eventId}/juries`), requestOptions(REQ_METHOD.POST, body));
	},

	/**JURIES ASSIGNMENTS */
	async jurieAssignments(juryId: number, competitionId: number, exerciseTypeId: number, assignedScoreFragmentTypes: number[]) {
		let body = {
			juryId: juryId,
			competitionId: competitionId,
			exerciseTypeId: exerciseTypeId,
			assignedScoreFragmentTypesIds: assignedScoreFragmentTypes
		};

		return await fetch(createUrl(`juries/assignments`), requestOptions(REQ_METHOD.POST, body));
	},

	/**ROSTERS */

	/**SCORES */
	async scores(exerciseId: number, juryId: number, scoreFragments: {}) {
		let body = {
			juryId,
			scoring: scoreFragments
		};

		return await fetch(createUrl(`exercises/${exerciseId}/scores`), requestOptions(REQ_METHOD.POST, body));
	},

	/**TEAMS */
	async team(associationId: number, name: string) {
		let body = {
			name: name
		};

		return await fetch(createUrl(`associations/${associationId}/teams`), requestOptions(REQ_METHOD.POST, body));
	},

	/**USERS */
	async user(userName: string, password: string, displayName: string) {
		let body = {
			userName: userName,
			password: password,
			displayName: displayName
		};

		return await fetch(createUrl(`users`), requestOptions(REQ_METHOD.POST, body));
	},
};

let del = {
	/**ACCOUNTS */

	/**ASSOCIATIONS */
	async association(id: number) {
		let body = {};

		return await fetch(createUrl(`associations/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},
	async userInAssociation(associationId: number, userId: number) {
		let body = {};

		return await fetch(createUrl(`associations/${associationId}/admins/${userId}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**ATHLETES */
	async athlete(id: number) {
		let body = {};

		return await fetch(createUrl(`athletes/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**CHAMPIONSHIPS */
	async championship(id: number) {
		let body = {};

		return await fetch(createUrl(`Championships/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**COMPETITIONS */
	async competition(id: number) {
		let body = {};

		return await fetch(createUrl(`competitions/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},
	async competitionExerciseType(idCompetition: number, idExerciseType: number) {
		let body = {};

		return await fetch(createUrl(`competitions/${idCompetition}/exercisetypes/${idExerciseType}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**EVENT ORGANIZERS */
	async eventOrganizer(id: number) {
		let body = {};

		return await fetch(createUrl(`EventOrganizers/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},
	async userInEventOrganizer(eventOrganizerId: number, userId: number) {
		let body = {};

		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/admins/${userId}`), requestOptions(REQ_METHOD.DELETE, body));
	},
	async associationInEventOrganizer(eventOrganizerId: number, associationId: number) {
		let body = {};

		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/associations/${associationId}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**EVENTS */
	async event(id: number) {
		let body = {};

		return await fetch(createUrl(`events/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**EVENT USER */
	async eventUser(id: number) {
		let body = {};

		return await fetch(createUrl(`events/users/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**EXECUTION GROUPS */
	async executionGroup(id: number) {
		let body = {};

		return await fetch(createUrl(`Execution/Groups/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**EXECUTION TURNS */
	async executionTurn(id: number) {
		let body = {};

		return await fetch(createUrl(`Execution/Turns/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**EXERCISES */
	async exercise(id: number) {
		let body = {};

		return await fetch(createUrl(`exercises/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**EXERCISE TYPES */
	async exerciseType(id: number) {
		let body = {};

		return await fetch(createUrl(`exercisetypes/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**FLOOR MUSIC */
	async floorMusicExercise(exerciseId: number) {
		let body = {};

		return await fetch(createUrl(`exercises/${exerciseId}/floormusic`), requestOptions(REQ_METHOD.DELETE, body));
	},
	async floorMusic(floorMusicId: number) {
		let body = {};

		return await fetch(createUrl(`floormusic/${floorMusicId}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**INDIVIDUAL COMPETITIONS */
	async individualCompetitionRemoveAthlete(competitionId: number, athleteId: number) {
		let body = {};

		return await fetch(createUrl(`competitions/${competitionId}/athletes/${athleteId}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**JURIES */
	async jury(id: number) {
		let body = {};

		return await fetch(createUrl(`juries/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},
	async judgeInJury(juryId: number, judgeId: number) {
		let body = {};

		return await fetch(createUrl(`juries/${juryId}/judges/${judgeId}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**JURIES ASSIGNMENTS */
	async juryAssignments(id: number) {
		let body = {};

		return await fetch(createUrl(`juries/assignments/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**ROSTERS */
	async roster(competitionId: number, teamId: number) {
		let body = {};

		return await fetch(createUrl(`rosters/${competitionId}-${teamId}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**SCORES */
	async score(id: number) {
		let body = {};

		return await fetch(createUrl(`scores/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**TEAMS */
	async team(id: number) {
		let body = {};

		return await fetch(createUrl(`teams/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},

	/**USERS */
	async user(id: number) {
		let body = {};

		return await fetch(createUrl(`users/${id}`), requestOptions(REQ_METHOD.DELETE, body));
	},
};

let put = {
	/**ACCOUNTS */

	/**ASSOCIATIONS */
	async association(id: number, name: string) {
		let body = { name: name };

		return await fetch(createUrl(`associations/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async adminInAssociation(associationId: number, userId: number) {
		let body = {};

		return await fetch(createUrl(`associations/${associationId}/admins/${userId}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**ATHLETES */
	async athlete(id: number, firstName: string, lastName: string, code: string, birthDate: string, gender: string) {
		let body = {
			firstName: firstName,
			lastName: lastName,
			code: code,
			birthDate: birthDate,
			gender: gender
		};

		return await fetch(createUrl(`athletes/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**CHAMPIONSHIPS */
	async championships(id: number, name: string) {
		let body = { name: name };

		return await fetch(createUrl(`Championships/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async championshipsAddCompetitions(championshipId: number, competitionsIds: number[]) {
		let body = competitionsIds;

		return await fetch(createUrl(`Championships/${championshipId}/competitions`), requestOptions(REQ_METHOD.PUT, body));
	},
	/**COMPETITION LEVELS */
	async competitionLevel(eventOrganizerId: number, levelId: number, name: string, description?: string) {
		let body = { name, description };

		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/Competition/Levels/${levelId}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**COMPETITIONS */
	async competition(id: number, name: string, levelId?: number, category?: any) {
		let body = {
			name: name,
			levelId: levelId,
			category: category,
		};

		return await fetch(createUrl(`competitions/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async competitionAddExerciseType(competitionId: number, exerciseTypeId: number) {
		let body = {};

		return await fetch(createUrl(`competitions/${competitionId}/exercisetypes/${exerciseTypeId}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**EVENT ORGANIZER */
	async eventOrganizer(id: number, name: string) {
		let body = { name: name };

		return await fetch(createUrl(`EventOrganzers/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async adminInEventOrganizer(eventOrganizerId: number, userId: number) {
		let body = {};

		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/admins/${userId}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async associationInEventOrganizer(eventOrganizerId: number, associationId: number) {
		let body = {};

		return await fetch(createUrl(`EventOrganizers/${eventOrganizerId}/associations/${associationId}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**EVENTS */
	async event(id: number, name: string, startDate: string, endDate: string, subscriptionEndDate: string, subscriptionStartDate?: string) {
		let body = {
			name,
			startDate,
			endDate,
			subscriptionStartDate,
			subscriptionEndDate
		};

		return await fetch(createUrl(`events/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**EVENT USERS */
	async eventUser(id: number, displayName: string) {
		let body = { displayName: displayName };

		return await fetch(createUrl(`events/users/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async eventUserPassword(id: number, password: string) {
		let body = password;

		return await fetch(createUrl(`events/users/${id}/password`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**EXECUTION GROUPS */
	async executionGroup(id: number, name: string) {
		let body = {
			name: name
		};

		return await fetch(createUrl(`Execution/Groups/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async executionGroupAddExercises(id: number, exerciseIds: number[]) {
		let body = exerciseIds;

		return await fetch(createUrl(`Execution/Groups/${id}/exercises`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**EXECUTION TURNS */
	async executionTurn(id: number, name: string, start: string, end: string) {
		let body = {
			name: name,
			start: start,
			end: end
		};

		return await fetch(createUrl(`Execution/Turns/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**EXERCISES */
	async exercise(id: number, athleteId: number, typeId: number) {
		let body = {
			athleteId: athleteId,
			typeId: typeId
		};

		return await fetch(createUrl(`exercises/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**EXERCISE TYPES */
	async exerciseType(id: number, name: string, displayName: string, scorecomposition: any[]) {
		let body = { name, displayName, scorecomposition };

		return await fetch(createUrl(`exercisetypes/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**FLOOR MUSIC */
	async floormusic(exerciseId: number, floorMusicId: number) {
		let body = { floorMusicId };

		return await fetch(createUrl(`exercises/${exerciseId}/floormusic`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**INDIVIDUAL COMPETITIONS */
	async individualCompetitionAddAthlete(competitionId: number, athleteId: number) {
		let body = {};

		return await fetch(createUrl(`competitions/${competitionId}/athletes/${athleteId}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**JURIES */
	async jury(id: number, name: string) {
		let body = { name: name };

		return await fetch(createUrl(`juries/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async judgeInJury(juryId: number, judgeId: number) {
		let body = {};

		return await fetch(createUrl(`juries/${juryId}/judges/${judgeId}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**JURIES ASSIGNMENTS */
	async judgeAssignments(id: number, assignedScoreFragmentTypesIds: number[]) {
		let body = assignedScoreFragmentTypesIds;

		return await fetch(createUrl(`juries/assignments/${id}/scorefragmenttypes`), requestOptions(REQ_METHOD.PUT, body));
	},

	/** RANKING CONFIGURATIONS */
	async rankingConfigurations(competitionId: number, rankingConfigurations: RankingConfiguration) {
		let body = rankingConfigurations;

		return await fetch(createUrl(`competitions/${competitionId}/ranking/configuration`), requestOptions(REQ_METHOD.PUT, body));
	},
	/**ROSTERS */
	async rosterAddAthlete(competitionId: number, teamId: number, athleteIds: number[]) {
		let body = {
			competitionId: competitionId,
			teamId: teamId,
			athleteIds: athleteIds
		};

		return await fetch(createUrl(`rosters/${competitionId}-${teamId}`), requestOptions(REQ_METHOD.PUT, body));
	},
	/**SCORE FRGMENT TYPES */

	/**SCORES */
	async scores(id: number, scoreFragments: {}) {
		let body = scoreFragments;

		return await fetch(createUrl(`scores/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**TEAMS */
	async team(id: number, name: string) {
		let body = {
			name: name
		};

		return await fetch(createUrl(`teams/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},

	/**USERS */
	async user(id: number, displayName: string) {
		let body = { displayName: displayName };

		return await fetch(createUrl(`users/${id}`), requestOptions(REQ_METHOD.PUT, body));
	},
	async userPassword(id: number, oldPassword: string, newPassword: string) {
		let body = {
			oldPassword: oldPassword,
			newPassword: newPassword
		};

		return await fetch(createUrl(`users/${id}/password`), requestOptions(REQ_METHOD.PUT, body));
	},
};

export { get, post, del, put };