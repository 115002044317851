import { Athlete, Exercise, ExerciseType, getSum, round, Team } from '../../models/models';
import ConditionalListComponent from '../ConditionalListComponent';

import ClassificationExercise from './ClassificationExercise';

import './ClassificationExerciseType.scss';

export interface extData {
	id: number;
	data: {
		exercise: Exercise;
		athlete?: Athlete | Team;
		team?: Team;
		hide?: boolean;
	}[];
	exerciseType: ExerciseType;
}

interface exerciseTypeClassification {
	exercises: Exercise[];
	athlete: Athlete | Team;
	team?: Team;
	hide?: boolean;
	sum: number;
}

interface props {
	data: extData;
	competitionRule: number;
	hideReq: (id: number) => void;
}

export let seedExerciseTypeClassification = (data: extData, competitionRule: number) => {
	let result: exerciseTypeClassification[] = [];
	let blIds: number[] = [];
	data.data.forEach(x => {
		let AthsFiltered = data.data.filter(y => y.athlete?.id === x.athlete?.id);
		let sum = 0;
		let exes = AthsFiltered.map(af => af.exercise);

		if (!x.athlete)
			return

		if (!blIds.includes(x.athlete.id)) {
			blIds.push(x.athlete.id);

			let rule = competitionRule;
			switch (rule) {
				case 0: //Media
					sum = AthsFiltered.reduce((pv, cv) => getSum(cv.exercise.scores, data.exerciseType.scoreComposition) + pv, 0);
					sum = sum / AthsFiltered.length || 0;
					break;
				case 1: //Max
					sum = Math.max(...AthsFiltered.map(x => getSum(x.exercise.scores, data.exerciseType.scoreComposition)))
					break;
				case 2: //Somma
					sum = AthsFiltered.reduce((pv, cv) => getSum(cv.exercise.scores, data.exerciseType.scoreComposition) + pv, 0);
					break;
			}
			result.push({ exercises: exes, athlete: x.athlete, team: x.team, sum: !x.hide ? round(sum, 3) : Number.NEGATIVE_INFINITY, hide: x.hide })
		}
	})

	return result.sort((a, b) => b.sum - a.sum)
}

function ClassificationExerciseType({ data, competitionRule, hideReq }: props) {
	return (
		<div className='classification-exercise-type-container'>
			<div className='classification-exercise-type-name'>{data.exerciseType.displayName}</div>
			<ConditionalListComponent items={seedExerciseTypeClassification(data, competitionRule)}
				emptyMesssage='Non ci sono atleti per questo attrezzo'
				renderList={items => items.map((info, index, array) => {
					let isPM = false;
					if (array.length > 1) {
						if (index > 0 && array[index - 1].sum === info.sum) {
							isPM = true;
						}
					}
					return (
						<ClassificationExercise
							key={index}
							athlete={info.athlete}
							sum={info.sum}
							isPM={isPM}
							position={index + 1}
							team={info.team}
							hideReq={hideReq}
							hide={info.hide} />
					)
				})} />
		</div>
	)
}

export default ClassificationExerciseType