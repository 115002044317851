import { useContext, useState } from 'react';

import OrganaizerTeamCom from '../../../components/Organizer/OrganaizerTeamCom';


import { Team } from '../../../models/models';


import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableNameCell from '../../../components/Organizer/TableComponents/TableNameCell';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import AssociationEoContext from '../../../contexts/AssociationEoContext';
import '../../../utility/prototype';
import OrganizerCompetitionTeamsAddRequest from '../CompetitionSettings/OrganizerCompetitionTeamsAddRequest';
import OrganizerCompetitionTeamsDeleteRequest from '../CompetitionSettings/OrganizerCompetitionTeamsDeleteRequest';
import OrganizerCompetitionTeamsEditRequest from '../CompetitionSettings/OrganizerCompetitionTeamsEditRequest';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerTeamsScreen.scss';
import OrganizerAssociationTable from '../../../components/Organizer/TableComponents/OrganizerAssociationTable';
import ConditionalListComponent from '../../../components/ConditionalListComponent';

function OrganizerTeamsScreen() {
	const context = useContext(AssociationEoContext);

	let [teamsFilterName, setTeamsFilterName] = useState("");

	let [teamToDel, setTeamToDel] = useState<Team>();
	let [teamToPut, setTeamToPut] = useState<Team>();

	let showDel = useState(false);
	let showAdd = useState(false);
	let showEdi = useState(false);
	let showFilters = useState(false);

	let deleteRequest = (team: Team) => {
		showDel[1](true);
		setTeamToDel(team);
	}
	let deletionCompleted = (team: Team) => {
		context.teams.crud.delete(team);
		showDel[1](false)
	}
	let addCompleted = (team: Team) => {
		context.teams.crud.add(team);
		showAdd[1](false)
	}
	let editRequest = (team: Team) => {
		showEdi[1](true);
		setTeamToPut(team);
	}
	let editCompleted = (team: Team) => {
		context.teams.crud.edit(team);
		showEdi[1](false)
	}

	const _filters = [
		{ name: 'Squadra', set: setTeamsFilterName, value: teamsFilterName }
	];
	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi squadra' },
		{ ...toolBarElements.filterElement, callback: () => showFilters[1](!showFilters[0]), notNumber: _filters.filter(f => f.value !== "").length }
	];

	return (
		<OrganizerAssociationTable text='SQUADRE'>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar show={showFilters} filters={_filters} found={_toolBarElements[1].notNumber! > 0 ? context.teams.value.teamFilter(teamsFilterName).length : undefined} />
			<Table>
				<thead>
					<tr>
						<TableNameCell text='Nome' isHeader />
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={context.teams.value.teamFilter(teamsFilterName)}
						emptyMesssage='Nessuna squadra creata'
						renderList={teams => teams.map(team =>
							<OrganaizerTeamCom key={team.id} team={team} del={deleteRequest} edi={editRequest} />
						)} />
				</tbody>
			</Table>
			<OrganizerCompetitionTeamsDeleteRequest team={teamToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerCompetitionTeamsAddRequest association={context.association} show={showAdd} addCompleted={addCompleted} />
			<OrganizerCompetitionTeamsEditRequest team={teamToPut} show={showEdi} editCompleted={editCompleted} />
		</OrganizerAssociationTable>
	);
}

export default OrganizerTeamsScreen;