import { useContext, useState } from 'react';
import ModalWindow from '../../../components/ModalWindow';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import OrganaizerEventCom from '../../../components/Organizer/OrganaizerEventCom';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableContainer from '../../../components/Organizer/TableComponents/TableContainer';
import TableDateCell from '../../../components/Organizer/TableComponents/TableDateCell';
import TableLinkRow from '../../../components/Organizer/TableComponents/TableLinkRow';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import EventsEoContext from '../../../contexts/EventsEoContext';
import { useAuth } from '../../../models/auth/AuthProvider';
import { Event } from '../../../models/models';
import { toolBarElements } from '../SubNavBarLinks';
import OrganizerEventsAddRequest from './OrganizerEventsAddRequest';
import OrganizerEventsDeleteRequest from './OrganizerEventsDeleteRequest';
import OrganizerEventsEditRequest from './OrganizerEventsEditRequest';
import './OrganizerEventsScreen.scss';
import ConditionalListComponent from '../../../components/ConditionalListComponent';
import { getSubscriptionsStat } from '../../../utility/UtilityFunctions';

function OrganizerEventsScreen() {
	const context = useContext(EventsEoContext);

	let auth = useAuth();

	let [eventsFilterName, setEventsFilterName] = useState("");

	let [eventToDel, setEventToDel] = useState<Event>();
	let [eventToPut, setEventToPut] = useState<Event>();

	let showDel = useState(false);
	let showAdd = useState(false);
	let showEdi = useState(false);
	let showFilters = useState(false);

	let deleteRequest = (event: Event) => {
		showDel[1](true);
		setEventToDel(event);
	}

	let deletionCompleted = (event: Event) => {
		context.events.crud.delete(event);
		showDel[1](false)
	}

	let addCompleted = (event: Event) => {
		context.events.crud.add(event);
		showAdd[1](false)
	}
	let editRequest = (event: Event) => {
		showEdi[1](true);
		setEventToPut(event);
	}

	let editCompleted = (event: Event) => {
		context.events.crud.edit(event);
		showEdi[1](false)
	}

	let eventsToShow = context.events.value.filter(x => x.name.toLowerCase().includes(eventsFilterName.toLowerCase()));

	const _filters = [
		{ name: 'Evento', set: setEventsFilterName, value: eventsFilterName }
	];

	const _toolBarElements: eoToolbarProp[] = [];

	if (auth.user.isOrganizer)
		_toolBarElements.push({ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Crea evento' });
	_toolBarElements.push({ ...toolBarElements.filterElement, callback: () => showFilters[1](!showFilters[0]), notNumber: _filters.filter(f => f.value !== "").length })

	return (
		<div>
			<TableContainer headerText='EVENTI' showLoading={context.loading}>
				<EoToolBar elements={_toolBarElements} />
				<FiltersBar show={showFilters} filters={_filters} found={_filters.filter(f => f.value !== "").length > 0 ? eventsToShow.length : undefined} />
				<Table>
					<thead>
						<tr>
							<TableLinkRow text='Nome' isHeader />
							<th></th>
							<TableDateCell headerText='Inizio' isHeader />
							<TableDateCell headerText='Fine' isHeader />
							{auth.user.isOrganizer && <TableRemoveEditButtons isHeader />}
						</tr>
					</thead>
					<tbody>
						<ConditionalListComponent
							items={eventsToShow.sort((a, b) => -(Date.parse(a.startDate) - Date.parse(b.startDate)))}
							emptyMesssage={auth.user.isAssociation && !auth.user.isOrganizer ? 'Non ci sono iscrizioni aperte' : 'Nessun evento creato'}
							renderList={items =>
								items.map(item =>
									<OrganaizerEventCom
										del={deleteRequest}
										edi={editRequest}
										event={item}
										key={item.id}
										lastId={context.lastEvent?.id}
									/>)}
						/>
					</tbody>
				</Table>
				<OrganizerEventsDeleteRequest deletionCompleted={deletionCompleted} show={showDel} event={eventToDel} />
				<OrganizerEventsAddRequest addCompleted={addCompleted} show={showAdd} />
				<OrganizerEventsEditRequest editCompleted={editCompleted} show={showEdi} event={eventToPut} />
			</TableContainer>
		</div>
	);
}

export default OrganizerEventsScreen;