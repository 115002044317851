import { faHourglass, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import OrganizerEventTable from '../../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../../contexts/EventEoContext';
import { Association, Athlete, Competition, Exercise, ExerciseType, Team, getSum, initExecutionGroup } from '../../../../models/models';
import '../../../../utility/prototype';
import './EventDetailsLScoreLive.scss';
import { useStateLocation } from '../../../../models/StateLocationProvider';
import HeaderWithBackButton from '../../../../components/HeaderWithBackButton';
import ToolTip from '../../../../components/ToolTip';
import { compareCompetitorExercise, getUnique } from '../../../../utility/UtilityFunctions';

interface CompetitionWithAthletes extends Competition {
	athletes: (Athlete | Team)[];
}

interface props {
	athlete: (Athlete | Team);
	exercises: Exercise[];
	ets: ExerciseType[];
	fragmentsRule: number;
	etId: number;
	association?: Association;
}
export function AthleteLiveCom({ athlete, exercises, ets, association, fragmentsRule, etId }: props) {
	const eventContext = useContext(EventEoContext);

	const name = ("firstName" in athlete) ? athlete.firstName + ' ' + athlete.lastName : athlete.name

	const renderFragments = (s: {
		value: string;
		fragments: [string, number][];
	}) => {
		switch (fragmentsRule) {
			case 0:
				return null;
			case 1:
				if (s.fragments.length > 0) {
					let key = "";
					let value = "";
					key = s.fragments.sort((a, b) => b[1] - a[1])[0][0];
					value = s.fragments.sort((a, b) => b[1] - a[1])[0][1].toFixed(3);
					return (
						<div className='eventLives-tableETColumns-fragments'>
							<div className='eventLives-tableETColumns-fragment'>
								<span className='eventLives-tableETColumns-fragment-key'>{key}</span>
								<span className='eventLives-tableETColumns-fragment-value'>{value}</span>
							</div>
						</div>
					)
				} else return null;
			case 2:
				return (
					<div className='eventLives-tableETColumns-fragments'>
						{s.fragments.map(([key, value], index) =>
							<div key={index} className='eventLives-tableETColumns-fragment'>
								<span className='eventLives-tableETColumns-fragment-key'>{key}</span>
								<span className='eventLives-tableETColumns-fragment-value'>{value.toFixed(3)}</span>
							</div>
						)}
					</div>
				)
		}
	}

	const getTTCompetitions = () => {
		const athletes = [
			...eventContext.athletes.value.filter(a => "firstName" in athlete && athlete.id === a.id),
			...eventContext.teams.value.filter(t => "name" in athlete && athlete.id === t.id)
		];
		const competitions = getUnique(athletes, "competitionId").map(a => a.competition);
		return <div>
			{competitions.map(c => <div key={c.id}>{c.name}</div>)}
		</div>
	}

	const getTTOdl = (exerciseType: ExerciseType) => {
		const _exercises = exercises.filter(e => e.typeId === exerciseType.id)
			.filter(e => e.executionGroup);
		const groups = getUnique(_exercises.map(e => e.executionGroup ?? initExecutionGroup));

		const strings = groups.map(g => {
			const executionTurn = eventContext.executionturns.value.find(et => et.id === g.executionTurnId);
			return `${executionTurn?.name} / ${g.name}`;
		})

		return <div>
			{strings.map((s, index) => <div key={index}>{s}</div>)}
		</div>
	}
	return (
		<div className='eventLives-table-row body'>
			<ToolTip text={getTTCompetitions()} timeOnEnter={1000}>
				<div className='eventLives-tableAthleteColumn'>
					<div className='eventLives-tableAthleteColumn-association'>{association ? association.name : athlete.owner?.name}</div>
					<div>{name}</div>
				</div>
			</ToolTip>
			{ets.map(et => {
				let scoreFound = 0
				let sums: ({ value: string, fragments: [string, number][] } | JSX.Element)[] = [];

				let exercisesFound = exercises.filter(x => x.typeId === et.id);
				if (exercisesFound.length > 0) {
					sums = exercisesFound.map(exercise => {
						let score = getSum(exercise.scores, et.scoreComposition);
						if (exercise.scores.length > 0) {
							scoreFound++;
							return { value: score.toFixed(3), fragments: exercise.scores.map(score => Object.entries(score.scoring)).flat() };
						} else {
							return <FontAwesomeIcon icon={faHourglass} />
						}
					})
				}

				let classNameString = 'eventLives-tableETColumns';
				if (exercisesFound.length === 0)
					classNameString += ' noExercise'
				else
					classNameString += scoreFound > 0 ? ' exerciseWithScores' : ' exerciseWithoutScores'

				if (etId === et.id)
					classNameString += " active"

				return (
					<div key={et.id} className={classNameString}>
						{
							sums.length > 0 ?
								<ToolTip timeOnEnter={1000} text={getTTOdl(et)}>
									{sums.map((s, index) => (
										<div key={index}>{"value" in s ? (
											<div>
												<div>{s.value}</div>
												{renderFragments(s)}
											</div>
										) : s}</div>
									))}
								</ToolTip>
								: <FontAwesomeIcon icon={faMinus} />
						}
					</div>
				)
			})}
		</div>
	)
}

function EventDetailsScoreCompetitionLive() {
	const eventContext = useContext(EventEoContext);
	const params = useParams();
	let location = useStateLocation();

	const [fragmentsRule, setFragmentsRule] = useState(0);


	const competitionId = parseInt(params.competitionId ?? "");
	let competition = eventContext.competitions.value.find(c => c.id === competitionId);

	const exTypes = eventContext.associatedexercisetypes.value.filter(et => et.competition.id === competition?.id);
	const [etToShow, setEtToShow] = useState(exTypes.length > 0 ? exTypes[0].id : 0);
	const exercises = eventContext.exercises.value.filter(e => e.competitionId === competitionId);

	const competitors = [
		...eventContext.athletes.value.filter(x => x.competitionId === competitionId),
		...eventContext.teams.value.filter(x => x.competitionId === competitionId)
	];

	return (
		<OrganizerEventTable text='LIVE'>
			<HeaderWithBackButton navigateTo={'..'} state={location.state} />
			<div className='eventLives-visualization-mode-selection'>
				<span>Visualizzazione punteggio:</span>
				<select onChange={e => setFragmentsRule(parseInt(e.target.value))}>
					<option value={0}>Solo risultato</option>
					<option value={1}>Valore massimo</option>
					<option value={2}>Tutto</option>
				</select>
			</div>
			<h4>{competition?.name}</h4>
			<div className='evenLives-container'>
				<div className='eventLives-table'>
					<div className='eventLives-table-row header'>
						<div className='eventLives-tableAthleteColumn header'>Atleta</div>
						{exTypes.map(ets => {
							return (
								<div key={ets.id} className='eventLives-tableETColumns header'>{ets.displayName}</div>
							)
						})}
						<div className='eventLives-tableETColumns header responsive'>
							<select value={etToShow} onChange={(e) => setEtToShow(+e.target.value)}>
								{exTypes.map(et => <option key={et.id} value={et.id}>{et.displayName}</option>)}
							</select>
						</div>
					</div>
					<div className='eventLives-tableBody'>
						{competitors.athleteFilter(undefined, true).map(athlete =>
							<AthleteLiveCom
								key={athlete.id}
								fragmentsRule={fragmentsRule}
								athlete={athlete}
								exercises={exercises.filter(e => compareCompetitorExercise(athlete, e))}
								ets={exTypes}
								etId={etToShow} />)}
					</div>
				</div>
			</div>
		</OrganizerEventTable>
	)
}

export default EventDetailsScoreCompetitionLive