import { useContext, useState } from 'react';
import OrganaizerExecutionTurnCom from '../../../components/Organizer/OrganaizerExecutionTurnCom';
import OrganizerExecutionTurnsAddRequest from './OrganizerExecutionTurnsAddRequest';
import OrganizerExecutionTurnsDeleteRequest from './OrganizerExecutionTurnsDeleteRequest';
import OrganizerExecutionTurnsEditRequest from './OrganizerExecutionTurnsEditRequest';


import { ExecutionTurn } from '../../../models/models';


import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableDateCell from '../../../components/Organizer/TableComponents/TableDateCell';
import TableLinkRow from '../../../components/Organizer/TableComponents/TableLinkRow';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../../contexts/EventEoContext';
import { downloadPDFInWorker } from '../../../models/PDF/Worker/downloadPDFInWorker';
import { eventDetailsBuild } from '../Events/OrganizerEventInfo';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerExecutionTurnsScreen.scss';
import OrganizerExecutionTurnsSVScreen from './SimplifiedView/OrganizerExecutionTurnsSVScreen';
import { usePDFDownload } from '../../../models/DownloadPDFProvider';


function OrganizerExecutionTurnsScreen() {
	const eventContext = useContext(EventEoContext);
	const pdf = usePDFDownload();

	let [executionturnsFilterName, setExecutionTurnsFilterName] = useState("");

	let [executionturnsToDel, setExecutionTurnToDel] = useState<ExecutionTurn>();
	let [executionturnsToPut, setExecutionTurnToPut] = useState<ExecutionTurn>();

	let [hideSVView, setHideSView] = useState(false);
	let [svActiveSelection, setSvActiveSelection] = useState(false);

	let showDel = useState(false);
	let showAdd = useState(false);
	let showFilters = useState(false);
	let showEdi = useState(false);


	let deleteRequest = (executionturns: ExecutionTurn) => {
		showDel[1](true);
		setExecutionTurnToDel(executionturns);
	}
	let deletionCompleted = (executionturn: ExecutionTurn) => {
		eventContext.executionturns.crud.delete(executionturn);
		showDel[1](false)
	}
	let addCompleted = (executionturn: ExecutionTurn) => {
		eventContext.executionturns.crud.add(executionturn);
		showAdd[1](false)
	}
	let editRequest = (executionturn: ExecutionTurn) => {
		showEdi[1](true);
		setExecutionTurnToPut(executionturn);
	}
	let editCompleted = (executionturn: ExecutionTurn) => {
		eventContext.executionturns.crud.edit(executionturn);
		showEdi[1](false)
	}

	const PDFGeneration = () => {
		const pdfProps = {
			eventDetailsScreenData: eventDetailsBuild(eventContext),
			onlyODL: true
		}
		const fileName = "ODL evento - " + eventContext.event.name.trim() + ".pdf";
		pdf.download("EventDetails", pdfProps, fileName);
	}
	let executionturnsToShow = eventContext.executionturns.value.filter(x => x.name.toLowerCase().includes(executionturnsFilterName.toLowerCase()));

	const _filters = [
		{ name: 'Turno', set: setExecutionTurnsFilterName, value: executionturnsFilterName }
	];

	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi turno' },
		{ ...toolBarElements.simplifiedViewElement, callback: () => setHideSView(!hideSVView), text: hideSVView ? 'Config. semplificata' : 'Config. estesa', selected: hideSVView },
		{ ...toolBarElements.downloadPDFElement, callback: PDFGeneration },
	];
	if (hideSVView)
		_toolBarElements.push({ ...toolBarElements.filterElement, callback: () => showFilters[1](!showFilters[0]), notNumber: _filters.filter(f => f.value !== "").length })
	else
		_toolBarElements.push({
			...toolBarElements.selectElement,
			text: "Seleziona",
			callback: () => setSvActiveSelection(!svActiveSelection),
			selected: svActiveSelection
		})
	return (
		<OrganizerEventTable text='TURNI'>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar show={showFilters} filters={_filters} found={_toolBarElements[2]?.notNumber! > 0 ? executionturnsToShow.length : undefined} />
			{
				hideSVView ? (
					<Table>
						<thead>
							<tr>
								<TableLinkRow text='Nome' isHeader />
								<TableDateCell headerText='Inizio' isHeader />
								<TableDateCell headerText='Fine' isHeader />
								<TableRemoveEditButtons isHeader />
							</tr>
						</thead>
						<tbody>
							<ConditionalListComponent
								items={executionturnsToShow.sort((a, b) => -(Date.parse(b.start) - Date.parse(a.start)))}
								emptyMesssage='Nessun turno creato'
								renderList={executionturns => executionturns.map(executionturn =>
									<OrganaizerExecutionTurnCom
										executionturn={executionturn}
										del={deleteRequest}
										edi={editRequest}
										key={executionturn.id}
										lastId={eventContext.lastExecutionTurn?.id}
									/>
								)} />
						</tbody>
					</Table>
				) : (
					<OrganizerExecutionTurnsSVScreen selectionOn={svActiveSelection} />
				)
			}
			<OrganizerExecutionTurnsDeleteRequest executionturns={executionturnsToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerExecutionTurnsAddRequest event={eventContext.event} show={showAdd} addCompleted={addCompleted} />
			<OrganizerExecutionTurnsEditRequest event={eventContext.event} executionturns={executionturnsToPut} show={showEdi} editCompleted={editCompleted} />
		</OrganizerEventTable>
	);
}

export default OrganizerExecutionTurnsScreen;