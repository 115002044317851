import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import OrganizerCompetitionTeamSetupCom from '../../../../components/Organizer/CompetitionSetup/OrganizerCompetitionTeamSetupCom';
import { Association, Competition, ExerciseType, Team } from '../../../../models/models';
import './OrganizerCompetitionSetupSectionTeams.scss'

interface associationProps extends props {
	association: Association;
}
function AssociationCom({ association, teams, associatedExerciseTypes, competition }: associationProps) {
	let [showTeams, setShowTeams] = useState(false);

	let teamSort = (a: Team, b: Team) => {
		return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
	};

	let teamsToShow = teams.filter(x => x.owner?.id === association.id);

	return (
		<div className='organizer-competition-team-association-container'>
			<div onClick={() => setShowTeams(!showTeams)} className='organizer-competition-team-association-name'>
				<FontAwesomeIcon icon={showTeams ? faChevronUp : faChevronDown} />
				<span>{association.name}</span>
				<span>{teamsToShow.length === 1 ? "1 iscritta" : teamsToShow.length + ' iscritte'}</span>
			</div>
			<div key={association.id} className={"organizer-competition-team-teams-container" + (showTeams ? " show" : "")}>
				{(teamsToShow.sort(teamSort).map((team) => {
					return (
						<OrganizerCompetitionTeamSetupCom key={team.id} team={team} competition={competition} associatedExerciseTypes={associatedExerciseTypes} />
					);
				}))}
			</div>
		</div>

	)
}

interface props {
	associatedExerciseTypes: ExerciseType[];
	teams: Team[];
	competition: Competition;
}

function OrganizerCompetitionSetupSectionTeams({ teams, associatedExerciseTypes, competition }: props) {

	let associations = teams.map(x => x.owner as Association);
	const ids = associations.map(o => o?.id);
	const filtered = associations.filter(({ id }, index) => !ids.includes(id, index + 1));

	let associationSort = (a: Association, b: Association) => {
		return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
	};

	return (
		<div>
			{
				filtered.sort(associationSort).map(association => {
					return (
						<AssociationCom
							key={association.id}
							associatedExerciseTypes={associatedExerciseTypes}
							association={association}
							teams={teams}
							competition={competition}
						/>
					)
				})}
		</div>
	)
}

export default OrganizerCompetitionSetupSectionTeams