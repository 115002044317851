import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';
import { useAuth } from '../../../models/auth/AuthProvider';
import './OrganizerConfigurationsLevels.scss'
import { CompetitionLevel, handleResponse, initCompetitionLevel } from '../../../models/models';
import { post, put, del } from '../../../models/backendReq';
import { useAlert } from '../../../models/AlertProvider';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import ModalWindow from '../../../components/ModalWindow';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';

function CompetitionLevelComponent({ competitionlevel }: { competitionlevel: CompetitionLevel }) {
	const configurationsContext = useContext(OrganizerConfigurationsContext);

	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const delreq = useState(false);
	const [edit, setEdit] = useState(false);
	const [editlevel, setEditlevel] = useState(competitionlevel);

	const ref = useRef<HTMLFormElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	useOnClickOutside(ref, () => setEdit(false));

	const _edit = async (e: FormEvent) => {
		e.preventDefault();
		if (competitionlevel.name === editlevel.name && competitionlevel.description === editlevel.description) {
			setEdit(false)
			return;
		}
		const res = await put.competitionLevel(competitionlevel.ownerId, competitionlevel.id, editlevel.name, editlevel.description);
		handleResponse(res, auth, alert, navigate, "Errore durante la modifica del livello",
			"Livello modificato correttamente", () => {
				configurationsContext.competitionLevels.crud.edit(editlevel);
				setEdit(false);
			});
	}

	useEffect(() => {
		if (edit)
			inputRef.current?.select();
	}, [edit])
	// const _delete = async (e: FormEvent)=>{
	// 	e.preventDefault();
	// 	const res = await del.competitionLevel(competitionlevel.id);
	// 	handleResponse(res, auth, alert, navigate, "Errore durante la cancellazione del livello",
	// 		"Livello cancellato correttamente", () => {
	// 			configurationsContext.competitionLevels.crud.delete(competitionlevel);
	// 		});
	// }
	return (
		edit ? (
			<form ref={ref} onSubmit={_edit} className='organizer-configurations-level-container editactive'>
				<input
					ref={inputRef}
					className='organizer-configurations-level-input-name'
					placeholder='Nome'
					name='name'
					value={editlevel.name}
					onChange={e => setEditlevel(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
				<input
					className='organizer-configurations-level-input-description'
					placeholder='Descrizione'
					name='description'
					value={editlevel.description}
					onChange={e => setEditlevel(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
				<button type='button' onClick={() => setEdit(false)}>
					<FontAwesomeIcon icon={faClose} />
				</button>
				<button >
					<FontAwesomeIcon icon={faCheck} />
				</button>
			</form>
		) : (
			<div className='organizer-configurations-level-container' onClick={() => setEdit(true)}>
				<span className='organizer-configurations-level-name'>{competitionlevel.name}</span>
				<span className='organizer-configurations-level-description'>{competitionlevel.description ?? "---"}</span>
				{/* <button onClick={() => delreq[1](true)}>
							<FontAwesomeIcon icon={faClose} />
						</button> */}
				{/* {del && <CrudForm submit={_delete} show={delreq} back={() => delreq[1](false)} denied={() => delreq[1](false)} headerText='Cancellare il livello'>
							<p>{competitionlevel.name}</p>
						</CrudForm>} */}
			</div>
		)
	)
}

function OrganizerConfigurationsLevels() {
	const configurationsContext = useContext(OrganizerConfigurationsContext);
	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const [newLevel, setNewLevel] = useState<CompetitionLevel>(initCompetitionLevel);

	const createNewLevel = async (e: React.FormEvent) => {
		e.preventDefault();

		if (newLevel.name === "")
			return

		if (newLevel.ownerId === 0)
			return

		const res = await post.competitionLevel(newLevel.ownerId, newLevel.name, newLevel.description);
		handleResponse(res, auth, alert, navigate, "Errore durante la creazione del livello",
			"Livello creato correttamente", (newlevel) => {
				configurationsContext.competitionLevels.crud.add(newlevel);
				setNewLevel(initCompetitionLevel);
			});

	}

	return (
		<div>
			<form onSubmit={createNewLevel} className='organizer-configurations-levels-add-new-container'>
				<input
					placeholder='Nome'
					name='name'
					value={newLevel.name}
					onChange={e => setNewLevel(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
				<select
					value={newLevel.ownerId}
					onChange={e => setNewLevel(prev => ({ ...prev, "ownerId": parseInt(e.target.value) }))}>
					<option value={0}>---</option>
					{auth.user.eventOrganizers.map(eventorganizer =>
						<option key={eventorganizer.id} value={eventorganizer.id}>{eventorganizer.name}</option>
					)}
				</select>
				<input
					placeholder='Descrizione'
					name='description'
					value={newLevel.description}
					onChange={e => setNewLevel(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
				<button >Crea</button>
			</form>
			<div className='organizer-configurations-levels-container'>
				{auth.user.eventOrganizers
					.filter(eo => configurationsContext.competitionLevels.value.map(lvl => lvl.ownerId).includes(eo.id))
					.map(eventorganizer => <div key={eventorganizer.id}>
						<div className='organizer-configurations-levels-eo-name'>{eventorganizer.name}</div>
						{configurationsContext.competitionLevels.value
							.filter(cl => cl.ownerId === eventorganizer.id)
							.map(competitionlevel => <CompetitionLevelComponent key={competitionlevel.id} competitionlevel={competitionlevel} />)}
					</div>)}
			</div>
		</div>
	)
}

export default OrganizerConfigurationsLevels