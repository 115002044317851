import React, { useEffect, useRef } from 'react';
import './FiltersBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import { useLocation, useNavigate } from 'react-router-dom';
import { camelize } from '../../utility/UtilityFunctions';
import { useStateLocation } from '../../models/StateLocationProvider';

export interface FilterInterface {
	name: string,
	set?: (value: React.SetStateAction<string>) => void,
	setNum?: (value: React.SetStateAction<number>) => void,
	setBool?: (value: React.SetStateAction<boolean>) => void,
	value?: string | number,
	placeHolder?: string,
	type?: React.HTMLInputTypeAttribute,
	checked?: boolean,
	options?: { label: string, value: number | string }[];
}

interface props {
	children?: React.ReactNode;
	filters?: FilterInterface[];
	history?: string;
	show?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	bShow?: boolean;
	found?: number;
	isStatic?: boolean;
}

function FilterBar(props: props) {
	const location = useStateLocation();
	const navigate = useNavigate();

	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => !props.isStatic && props.show && props.show[1](false));

	useEffect(() => {
		if (!props.show || !props.show[0])
			return

		document.getElementById('filters_bar_element')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}, props.show);

	useEffect(() => {
		if (!props.history || !location.read() || !Object.hasOwn(location.read() ?? {}, props.history))
			return;

		props.filters?.map(filter => {
			if (typeof (filter.value) === "string") {
				if (!props.history)
					return;
				const state = location.read()[props.history];
				const filterId = camelize(filter.name);
				Object.hasOwn(state, filterId) && filter.set && filter.set(state[filterId]);
			};
			if (typeof (filter.value) === "number") {
				if (!props.history)
					return;
				const state = location.read()[props.history];
				const filterId = camelize(filter.name);
				Object.hasOwn(state, filterId) && filter.setNum && filter.setNum(state[filterId]);
			};
			if (filter.checked !== undefined) {
				if (!props.history)
					return;
				const state = location.read()[props.history];
				const filterId = camelize(filter.name);
				Object.hasOwn(state, filterId) && filter.setBool && filter.setBool(state[filterId]);
			};
		});
	}, [navigate]);

	function _edit<T>(set: ((value: React.SetStateAction<T>) => void), value: T, filterName: string) {
		set(value)
		if (!props.history)
			return;

		const filterId = camelize(filterName);
		const stateValue = location.read() && Object.hasOwn(location.read() ?? {}, props.history) ? location.read()[props.history] : {};
		stateValue[filterId] = value;
		const state = { ...location.read(), [props.history]: stateValue };
		navigate(".", { state });
	}


	const show = props.bShow || (props.show && props.show[0]);
	return (
		<div ref={ref} id='filters_bar_element' className={`filters-bar-parent${show ? " show" : ""}${props.isStatic ? " static" : ""}`}>
			<div className={`filters-bar-container`}>
				{props.filters?.map((of, index) => (
					<div key={index} className='filters-bar-others-container'>
						<div className='filters-bar-other-name'>{of.name}</div>
						{
							of.type === "select" ?
								<select
									className='filters-bar-other-input'
									onChange={(e) => {
										of.set && _edit(of.set, e.target.value, of.name);
										of.setNum && _edit(of.setNum, + e.target.value, of.name);
									}}
									value={of.value}
								>
									<option value={0 || ""}>---</option>
									{of.options?.map((opt, index) => <option value={opt.value} key={index}>{opt.label}</option>)}
								</select>
								: of.type === "checkbox" ?
									<input
										type={of.type}
										className='filters-bar-other-input'
										onChange={(e) => of.setBool && _edit(of.setBool, !of.checked, of.name)}
										checked={of.checked}
									/> :
									<input
										type={of.type}
										className='filters-bar-other-input'
										placeholder={of.placeHolder}
										onChange={(e) => {
											of.set && _edit(of.set, e.target.value, of.name);
											of.setNum && _edit(of.setNum, + e.target.value, of.name);
										}}
										value={of.value ? of.value : ""}
									/>
						}
						<button onClick={() => {
							of.set && typeof (of.value) === "string" && _edit<string>(of.set, "", of.name);
							of.setNum && typeof (of.value) === "number" && _edit<number>(of.setNum, 0, of.name);
							of.setBool && _edit<boolean>(of.setBool, false, of.name);
						}}
							className='filters-bar-other-default-button'>
							<FontAwesomeIcon icon={faClose} />
						</button>
					</div>
				))}
				{props.children}

			</div>
			{props.show && <button className='filters-bar-close' onClick={() => props.show && props.show[1](false)}>Chiudi</button>}
			{props.found !== undefined && <div>{`Elementi trovati: ${props.found}`}</div>}
		</div>
	)
}

export default FilterBar