import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { ATHLETE_GENDER, post } from '../../../models/backendReq';
import { Association, Athlete, handleResponse, initAthlete } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { getAthleteGenderString } from '../../../utility/UtilityFunctions';
import '../../../utility/prototype'
import './OrganizerAthletesAddRequest.scss';

interface props {
	association: Association;
	addCompleted: (association: Athlete) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerAthletesAddRequest({ association, show, addCompleted }: props) {
	const inputFirstNameError: ErrorType = { id: "firstName", text: "Inerire un nome per l'atleta" };
	const inputLastNameError: ErrorType = { id: "lastName", text: "Inerire un cognome per l'atleta" };
	const inputCodeError: ErrorType = { id: "code", text: "Inerire codice tessera atleta" };
	const inputBirthDateError: ErrorType = { id: "birthDate", text: "Inserire una data di nascita" };
	const inputGenderError: ErrorType = { id: "gender", text: "Selezionare il genere" };

	let [athleteToAdd, setAthleteToAdd] = useState<Athlete>(initAthlete);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const firstFocusRef = useRef<HTMLInputElement>(null);

	let denied = () => {
		setAthleteToAdd(initAthlete);
		show[1](false);
	}

	let addAthlete = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (athleteToAdd.firstName === "") {
			_errors.push(inputFirstNameError);
		}

		if (athleteToAdd.lastName === "") {
			_errors.push(inputLastNameError);
		}

		if (athleteToAdd.code === "") {
			_errors.push(inputCodeError);
		}

		if (athleteToAdd.birthDate === "") {
			_errors.push(inputBirthDateError);
		}

		if (athleteToAdd.gender === "") {
			_errors.push(inputGenderError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await post.athlete(association.id, athleteToAdd.firstName.noMultiSpace(), athleteToAdd.lastName.noMultiSpace(), athleteToAdd.code.noMultiSpace(), athleteToAdd.birthDate, athleteToAdd.gender)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante l'inserimento dell'atleta",
					"Atleta creato correttamente",
					(data) => {
						setAthleteToAdd(initAthlete);
						addCompleted(data);
					},
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci nuovo atleta' submit={addAthlete}>
			<p className='organizer-athlete-add-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="firstName"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-athlete-add-request-form-input'
				type='text'
				value={athleteToAdd.firstName}
				name="firstName"
				onChange={(e) => {
					const { name, value } = e.target;

					setAthleteToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-athlete-add-request-form-input-label'>Cognome</p>
			<InputWithAlert
				errorId="lastName"
				errors={inputErrors}
				className='organizer-athlete-add-request-form-input'
				type='text'
				value={athleteToAdd.lastName}
				name="lastName"
				onChange={(e) => {
					const { name, value } = e.target;

					setAthleteToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-athlete-add-request-form-input-label'>Genere</p>
			<InputWithAlert
				errorId="gender"
				errors={inputErrors}
				className='organizer-athlete-add-request-form-input'
				value={athleteToAdd.gender}
				name='gender'
				onSelectChange={(e) => {
					const { name, value } = e.target;

					setAthleteToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				type='select'
				setErrors={setInputErrors}>
				<option value="">---</option>
				<option value={ATHLETE_GENDER.Female}>{getAthleteGenderString(ATHLETE_GENDER.Female)}</option>
				<option value={ATHLETE_GENDER.Male}>{getAthleteGenderString(ATHLETE_GENDER.Male)}</option>
			</InputWithAlert>
			<p className='organizer-athlete-add-request-form-input-label'>Codice</p>
			<InputWithAlert
				errorId="code"
				errors={inputErrors}
				className='organizer-athlete-add-request-form-input'
				type='text'
				value={athleteToAdd.code}
				name="code"
				onChange={(e) => {
					const { name, value } = e.target;

					setAthleteToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-athlete-add-request-form-input-label'>Data di nascita</p>
			<InputWithAlert
				errorId="birthDate"
				errors={inputErrors}
				className='organizer-athlete-add-request-form-input'
				dateSelected={athleteToAdd.birthDate}
				onDateChange={(e) => { setAthleteToAdd(prevState => ({ ...prevState, birthDate: e })); }}
				setErrors={setInputErrors}
				type='date'
			/>
		</CrudForm>
	);
}

export default OrganizerAthletesAddRequest;