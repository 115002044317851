import { useContext, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import AssociationEoContext from '../../../contexts/AssociationEoContext';
import AssociationsEoContext from '../../../contexts/AssociationsEoContext';
import { Athlete, Team, initAssociation } from '../../../models/models';
import { changeObjInAry, deleteObjInAry } from '../../../utility/UtilityFunctions';

function OrganizerAssociationLayout() {
	const params = useParams();
	const associationsContext = useContext(AssociationsEoContext);

	const associationId = parseInt(params.associationId ?? "");
	const association = associationsContext.associations.value.find(a => a.id === associationId) ?? { ...initAssociation, athletes: [], teams: [] };

	const crudAthlete = {
		add: (athlete: Athlete) => {
			associationsContext.associations.crud.edit({ ...association, athletes: [...association.athletes, athlete] });
		},
		edit: (athlete: Athlete) => {
			associationsContext.associations.crud.edit({ ...association, athletes: changeObjInAry(association.athletes, athlete) });
		},
		delete: (athlete: Athlete) => {
			associationsContext.associations.crud.edit({ ...association, athletes: deleteObjInAry(association.athletes, athlete) });
		}
	};

	const crudTeam = {
		add: (team: Team) => {
			associationsContext.associations.crud.edit({ ...association, teams: [...association.teams, team] });
		},
		edit: (team: Team) => {
			associationsContext.associations.crud.edit({ ...association, teams: changeObjInAry(association.teams, team) });
		},
		delete: (team: Team) => {
			associationsContext.associations.crud.edit({ ...association, teams: deleteObjInAry(association.teams, team) });
		}
	};

	const contextVal = {
		association,
		athletes: { value: association.athletes, crud: crudAthlete },
		teams: { value: association.teams, crud: crudTeam }
	};

	return (
		<AssociationEoContext.Provider value={contextVal}>
			<Outlet />
		</AssociationEoContext.Provider>
	)
}

export default OrganizerAssociationLayout