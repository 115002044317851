// src/idb.ts
import { DBSchema, StoreNames, openDB } from 'idb';

interface MyDB extends DBSchema {
	'scoresToAdd': {
		key: string | number;
		value: any;
	};
	'scoresToDel': {
		key: string | number;
		value: any;
	};
	'scoresToEdit': {
		key: string | number;
		value: any;
	};
}

const dbPromise = openDB<MyDB>('my-database', 1, {
	upgrade(db) {
		if (!db.objectStoreNames.contains('scoresToAdd')) {
			db.createObjectStore('scoresToAdd', { keyPath: 'id' });
		}
		if (!db.objectStoreNames.contains('scoresToDel')) {
			db.createObjectStore('scoresToDel', { keyPath: 'id', });
		}
		if (!db.objectStoreNames.contains('scoresToEdit')) {
			db.createObjectStore('scoresToEdit', { keyPath: 'id', });
		}
	},
});

export const idbKeyval = {
	async get<T>(storeName: StoreNames<MyDB>, key: string | number | IDBKeyRange): Promise<T | undefined> {
		return (await dbPromise).get(storeName, key);
	},
	async getAll<T>(storeName: StoreNames<MyDB>, key?: string | number | IDBKeyRange): Promise<T[] | undefined> {
		return (await dbPromise).getAll(storeName, key);
	},
	async set<T>(storeName: StoreNames<MyDB>, value: T): Promise<string | number> {
		return (await dbPromise).put(storeName, value);
	},
	async delete(storeName: StoreNames<MyDB>, key: string | number | IDBKeyRange): Promise<void> {
		return (await dbPromise).delete(storeName, key);
	},
	async clear(storeName: StoreNames<MyDB>): Promise<void> {
		return (await dbPromise).clear(storeName);
	},
	async keys(storeName: StoreNames<MyDB>): Promise<(string | number)[]> {
		return (await dbPromise).getAllKeys(storeName);
	},
	async key(storeName: StoreNames<MyDB>, key: string | number | IDBKeyRange): Promise<string | number | undefined> {
		return (await dbPromise).getKey(storeName, key);
	},
};
