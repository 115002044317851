import { faBug, faChevronDown, faChevronUp, faMars, faMarsAndVenus, faSquare, faSquareCheck, faVenus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputCategory from '../../../components/Input/InputCategory';
import InputWithAlert from '../../../components/Input/InputWithAlert';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { ATHLETE_GENDER, COMP_TYPE, get, post } from '../../../models/backendReq';
import { getAge } from '../../../models/dateAndTime';
import { Category, Competition, CompetitionLevel, Event, ExerciseType, handleResponse, initCategory } from '../../../models/models';
import { getCompTypeString, getGenderIcon } from '../../../utility/UtilityFunctions';
import '../../../utility/prototype';
import './OrganizerCompetitionsAddBulk.scss';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';

function ExerciseTypeComponent({ exerciseType, selectedEts, setSelectedEts }: {
	exerciseType: ExerciseType;
	selectedEts: ExerciseType[];
	setSelectedEts: React.Dispatch<React.SetStateAction<ExerciseType[]>>;
}) {

	const isSelected = selectedEts.map(({ id }) => id).includes(exerciseType.id);
	const _onClick = () => {
		if (isSelected)
			setSelectedEts(prev => prev.filter(et => et.id !== exerciseType.id));
		else
			setSelectedEts(prev => [...prev, exerciseType]);

	};

	return (
		<div onClick={_onClick} className={`organizer-competitions-bulk-exercise-type-container${isSelected ? " selected" : ""}`}>
			<FontAwesomeIcon icon={!isSelected ? faSquare : faSquareCheck} />
			<span>{exerciseType.name}</span>
		</div>
	);
}

function LevelComponent({ level, setLevels }: {
	level: CompetitionLevel;
	setLevels: React.Dispatch<React.SetStateAction<CompetitionLevel[]>>;
}) {

	const _onClick = () => {
		setLevels(prev => prev.filter(l => l !== level));
	};
	return (
		<div className='organizer-competitions-bulk-level-container'>
			<span>{level.name}</span>
			<button type="button" onClick={_onClick}>RIMUOVI</button>
		</div>
	);
}

function CategoryComponent({ category, event, setCategories }: {
	category: Category;
	event: Event;
	setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
}) {

	const _onClick = () => {
		setCategories(prev => prev.filter(c => c.name !== category.name));
	};

	return (
		<div className='organizer-competitions-bulk-category-container'>
			<span>{category.name}</span>
			{category.allowedGenders && category.allowedGenders.map(ag => (
				<div key={ag} className='organizer-competitions-bulk-category-age-container gen'>
					<FontAwesomeIcon icon={getGenderIcon(ag)} />
				</div>
			))
			}
			{category.maxBirthDate &&
				<div className='organizer-competitions-bulk-category-age-container max'>
					<FontAwesomeIcon icon={faChevronUp} />
					<span>{getAge(category.maxBirthDate, event.startDate)}</span>
				</div>
			}
			{category.minBirthDate &&
				<div className='organizer-competitions-bulk-category-age-container min'>
					<FontAwesomeIcon icon={faChevronDown} />
					<span>{getAge(category.minBirthDate, event.startDate)}</span>
				</div>
			}
			<button type="button" onClick={_onClick}>RIMUOVI</button>
		</div>
	);
}

interface props {
	event: Event;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	created: (competitions: Competition[], exercisetypes: ExerciseType[]) => void;
}

function OrganizerCompetitionsAddBulk(props: props) {
	const configurationContext = useContext(OrganizerConfigurationsContext);

	const auth = useAuth();
	const navigate = useNavigate();
	const alert = useAlert();

	const [type, setType] = useState("");
	const [exerciseTypes, setExerciseTypes] = useState<ExerciseType[]>([]);
	const [selectedEts, setSelectedEts] = useState<ExerciseType[]>([]);
	const [levelToAdd, setLevelToAdd] = useState<number>();
	const [levels, setLevels] = useState<CompetitionLevel[]>([]);
	const [categoryToAdd, setCategoryToAdd] = useState<Category>(initCategory);
	const [categories, setCategories] = useState<Category[]>([]);
	const [toShow, setToShow] = useState(0);
	const [competitionsPrefix, setCompetitionsPrefix] = useState("");

	useEffect(() => {
		const getEts = async () => {
			const res = await get.excerciseTypes();
			handleResponse(res, auth, alert, navigate, "Errore durante il caricamento degli attrezzi",
				undefined, (data => setExerciseTypes(data)));
		};
		getEts();
	}, []);

	const levelValid = levelToAdd && !levels.map(l => l.id).includes(levelToAdd);
	const categoryValid = categoryToAdd.name !== "" &&
		!categories.map(({ name }) => name).includes(categoryToAdd.name) &&
		categoryToAdd.allowedGenders && categoryToAdd.allowedGenders.length > 0;

	const addLevel = () => {
		if (levelValid) {
			const newLevel = configurationContext.competitionLevels.value.find(l => l.id === levelToAdd);

			if (newLevel)
				setLevels(prev => [...prev, newLevel]);
			setLevelToAdd(undefined);
		}
	};

	const addCategory = () => {
		if (categoryValid) {
			setCategories(prev => [...prev, categoryToAdd]);
			setCategoryToAdd({ name: "" } as Category);
		}
	};

	const close = () => {
		props.show[1](false);
	};

	useEffect(() => { setToShow(0); }, [props.show[0]]);

	const create = async () => {
		let competitionsCreated: Competition[] = [];

		for (let indexL = 0; indexL < levels.length; indexL++) {
			for (let indexC = 0; indexC < categories.length; indexC++) {
				let nameBuild = competitionsPrefix;
				nameBuild += " " + levels[indexL].name.toUpperCase();
				nameBuild += " " + categories[indexC].name.toUpperCase();

				competitionsCreated.push({
					category: categories[indexC],
					eventId: props.event.id,
					id: 0,
					level: levels[indexL],
					levelId: levels[indexL].id,
					name: nameBuild,
					type: type,
					exerciseTypeIds: selectedEts.map(({ id }) => id)
				});
			}
		}
		const res = await post.competitionAddBluk(props.event.id, competitionsCreated);
		handleResponse(res, auth, alert, navigate, "Errore durante la creazione delle competizioni",
			"Creazione avvenuta correttamente", data => props.created(data, selectedEts));
	};

	const renderToShow = () => {
		switch (toShow) {
			case 0:
				return (//** TYPE SELECTION */)
					<div className='organizer-competitions-bulk-columns'>
						<p>Seleziona tipologia gara</p>
						<InputWithAlert
							errorId="type"
							className='organizer-competition-add-request-form-input'
							name='type'
							value={type}
							onSelectChange={(e) => { setType(e.target.value); }}
							type='select'>
							<option value="">---</option>
							<option value={COMP_TYPE.Individual}>{getCompTypeString(COMP_TYPE.Individual)}</option>
							<option value={COMP_TYPE.Cumulative}>{getCompTypeString(COMP_TYPE.Cumulative)}</option>
							<option value={COMP_TYPE.Collective}>{getCompTypeString(COMP_TYPE.Collective)}</option>
						</InputWithAlert>
						<p>Definisci prefisso gara</p>
						<input
							className='organizer-competition-add-request-form-input'
							type='text'
							value={competitionsPrefix}
							onChange={(e) => setCompetitionsPrefix(e.target.value)} />
					</div>
				);
			case 1:
				return (/** EXERCISE TYPES DEFINITION */
					<div className='organizer-competitions-bulk-columns'>
						<p>Seleziona attrezzi</p>
						<div className='organizer-competitions-bulk-column'>
							{
								exerciseTypes.sortExerciseTypes("name").map(et => (
									<ExerciseTypeComponent key={et.id} exerciseType={et} selectedEts={selectedEts} setSelectedEts={setSelectedEts} />
								))
							}
						</div>
					</div>);
			case 2:
				return (/** LEVELS DEFINITION */
					<div className='organizer-competitions-bulk-columns'>
						<p>Definizione livelli</p>
						<div className={`organizer-competitions-bulk-add-new-container${levelValid ? " valid" : ""}`}>
							<select
								value={levelToAdd}
								onChange={e => setLevelToAdd(parseInt(e.target.value))}
								onKeyUp={(e) => {
									e.preventDefault();
									if (e.key === "Enter") {
										addLevel();
										setLevelToAdd(undefined);
									}
								}}
							>
								<option value={undefined}>---</option>
								{configurationContext.competitionLevels.value
									.filter(l => l.ownerId === props.event.ownerId)
									.filter(l => !levels.map(x => x.id).includes(l.id))
									.map(competionlevel =>
										<option key={competionlevel.id} value={competionlevel.id}>{competionlevel.name}</option>)}
							</select>
							<button type='button' onClick={addLevel}>+</button>
						</div>
						<div className='organizer-competitions-bulk-column'>
							{
								levels.map(l => (
									<LevelComponent key={l.id} level={l} setLevels={setLevels} />
								))
							}
						</div>
					</div>);
			case 3:
				return (/** CATEGORIES DEFINITION */
					<div className='organizer-competitions-bulk-columns'>
						<p>Definizione categorie</p>
						<div className={`organizer-competitions-bulk-add-new-container${categoryValid ? " valid" : ""}`}>
							<InputCategory
								className='organizer-competitions-bulk-column-category-input'
								category={categoryToAdd}
								setCategory={setCategoryToAdd} />
							<button type='button' onClick={addCategory}>+</button>
						</div>
						<div className='organizer-competitions-bulk-column'>
							{
								categories.map(c => (
									<CategoryComponent key={c.name} category={c} event={props.event} setCategories={setCategories} />
								))
							}
						</div>
					</div>);
		}
	};
	return (
		<CrudForm
			buttonType='button'
			showArrows={toShow !== 3} showArrowsNext showArrowsPrevious
			previous={() => setToShow(toShow - 1)}
			next={() => setToShow(toShow + 1)}
			disableArrowNext={!((toShow === 0 && type !== "") || (toShow === 1 && selectedEts.length > 0) || (toShow === 2 && levels.length > 0))}
			disableArrowPrevious={!(toShow > 0)}
			confirm={create}
			denied={close}
			back={() => setToShow(toShow - 1)}
			show={props.show}
			headerText='COMPOSIZIONE GARE'>
			<div className='organizer-competitions-bulk-container'>
				{renderToShow()}
			</div>
		</CrudForm>
	);
}

export default OrganizerCompetitionsAddBulk;