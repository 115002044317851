import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import './HeaderWithBackButton.scss';

interface props {
	header?: string;
	navigateTo: string | number;
	state?: any;
}
function HeaderWithBackButton(props: props) {
	const navigate = useNavigate();

	const goTo = () => {
		if (typeof (props.navigateTo) === "string")
			navigate(props.navigateTo, { state: props.state });
		else
			navigate(props.navigateTo)
	}

	return (
		<div className='header-with-back-button-container'>
			<button className='header-with-back-button-button' onClick={goTo}>
				<FontAwesomeIcon icon={faChevronLeft} />
			</button>
			<div className='header-with-back-button-name-container'>
				<div className='header-with-back-button-name'>
					{props.header}
				</div>
			</div>
		</div>
	)
}

export default HeaderWithBackButton