import React, { useContext } from 'react';
import OrganizerAssociationTable from '../../../components/Organizer/TableComponents/OrganizerAssociationTable';
import { useAuth } from '../../../models/auth/AuthProvider';
import './OrganizerMembershipsScreen.scss';
import AssociationEoContext from '../../../contexts/AssociationEoContext';
import { del, put } from '../../../models/backendReq';
import { EventOrganizer, handleResponse } from '../../../models/models';
import { useAlert } from '../../../models/AlertProvider';
import { useNavigate } from 'react-router-dom';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';

function OrganizerMembershipsScreen() {
	const configurationContext = useContext(OrganizerConfigurationsContext);
	const context = useContext(AssociationEoContext);

	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const configuratedEo = configurationContext.associations.value.filter(a => a.id === context.association.id).map(x => x.eventorganizer);

	return (
		<OrganizerAssociationTable text='MEMBRI'>
			<div>
				<h3>Membri</h3>
				<div>
					{auth.user.eventOrganizers.map(eventorganizer => {
						const checked = configuratedEo.find(x => x.id === eventorganizer.id) ? true : false;
						const _addEo = async (eventorganizer: EventOrganizer) => {
							const res = await put.associationInEventOrganizer(eventorganizer.id, context.association.id);
							handleResponse(res, auth, alert, navigate, "Errore durante l'operazione", undefined,
								() => configurationContext.associations.crud.add({ ...context.association, eventorganizer, eventorganizerId: eventorganizer.id }));
						};
						const _removeEo = async (eventorganizer: EventOrganizer) => {
							const res = await del.associationInEventOrganizer(eventorganizer.id, context.association.id);
							handleResponse(res, auth, alert, navigate, "Errore durante l'operazione", undefined,
								() => configurationContext.associations.crud.delete({ ...context.association, eventorganizer, eventorganizerId: eventorganizer.id }));
						};

						return <div key={eventorganizer.id}>
							<label >
								<input checked={checked}
									onChange={e => checked ? _removeEo(eventorganizer) : _addEo(eventorganizer)}
									type='checkbox' />
								{eventorganizer.name}
							</label>
						</div>
					})}
				</div>
			</div>
		</OrganizerAssociationTable>
	)
}

export default OrganizerMembershipsScreen