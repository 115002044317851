import { useEffect, useState } from 'react';
import EventCom from '../components/EventCom';
import { get } from '../models/backendReq';
import { getDay, publicMonths } from '../models/dateAndTime';
import { Event, responseGetJson } from '../models/models';

import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth, USER_TYPE } from '../models/auth/AuthProvider';
import './EventsScreen.scss';

function EventsScreen() {
	let [events, setEvents] = useState<Event[]>([]);
	let [year, setYear] = useState(new Date().getFullYear())
	let [monthToExpand, setMonthToExpand] = useState("");
	const auth = useAuth();

	useEffect(() => {

		let getEvents = async () => {
			let eventsFromServer: Event[] = [];
			if (auth.user.auth) {
				if (auth.user.type === USER_TYPE.EVENTUSER)
					eventsFromServer = [await get.event(auth.user.info.eventId).then(r => responseGetJson(r, []))]
				else {
					eventsFromServer = await get.events().then(r => responseGetJson(r, []));
					// eventsFromServer = eventsFromServer.filter(e =>
					// 	auth.user.eventOrganizers?.map(eo => eo.id).includes(e.ownerId));
				}
			} else
				eventsFromServer = await get.events().then(r => responseGetJson(r, []));

			setEvents(eventsFromServer);
		};

		getEvents();

	}, [auth]);

	const eventToShow = events.filter(event => parseInt(event.startDate.split("-")[0]) === year);

	const months = eventToShow.map(event => event.startDate.split("-")[1])
		.filter((m, index, array) => array.indexOf(m) === index).sort((a, b) => parseInt(b) - parseInt(a));

	const todaysEvents = events.filter(event => {
		if (auth.user.type !== USER_TYPE.EVENTUSER) {
			return getDay() >= getDay(event.startDate) && getDay() <= getDay(event.endDate);
		} else
			return event.id === auth.user.info.eventId;
	})

	const eventSort = (a: Event, b: Event) => {
		return Date.parse(a.startDate) - Date.parse(b.startDate);
	}

	const updateMonth = (month: string) => {
		if (monthToExpand === month)
			setMonthToExpand("");
		else {
			setMonthToExpand(month);
			const element = document.getElementById(month + 'EventsList');
			if (element) {
				setTimeout(() => element.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'nearest',
				}), 10);
			}
		}

	}
	return (
		<div id='events'>
			<div className='year-selection'>
				<button className='year-selection-button' onClick={() => setYear(year - 1)}>
					<FontAwesomeIcon icon={faChevronLeft} />
				</button>
				<div className='year-selection-current'>{year}</div>
				<button className='year-selection-button' onClick={() => setYear(year + 1)}>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
			</div>
			<div className='today-events'>
				<p className='events-table-header'>Eventi in corso</p>
				<div className='today-events-list'>
					{todaysEvents.length > 0 ? (
						todaysEvents.map(event => {
							return (
								<EventCom key={event.id} event={event} />
							)
						})

					) : (
						<p>Non sono in programma eventi per oggi</p>
					)}
				</div>
			</div>
			<div className='events-table'>
				<p className='events-table-header'>Eventi</p>
				{
					months.map(month => {
						const listClassName = `month-events-container-list${monthToExpand === month ? " show" : ""}`
						return (
							<div key={month} className={`months-events-container${monthToExpand === month ? " isExpanded" : ""}`}>
								<div className='month-events-container' onClick={() => updateMonth(month)}>
									<div>{publicMonths[parseInt(month) - 1]}</div>
									<FontAwesomeIcon icon={monthToExpand === month ? faChevronUp : faChevronDown} />
								</div>
								<div id={month + 'EventsList'} className={listClassName}>
									{eventToShow.sort(eventSort).filter(e => e.startDate.split("-")[1] === month).map((event) => {
										return (
											<EventCom key={event.id} event={event} />
										);
									})}
								</div>
							</div>
						)
					})
				}
			</div>

			{/* <iframe src="https://calendar.google.com/calendar/embed?src=ab97f5ca7888c52fd306864c09ca21f80be99ea06b2c67098a908b7a06f53e45%40group.calendar.google.com&ctz=Europe%2FRome" style={{ border: 0, width: "800", height: "600", overflow: "hidden" }}></iframe> */}
			{/* <EventSetupCom event={event} setEvent={setEvent} /> */}
		</div>
	);
}

export default EventsScreen;