import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Athlete, Competition, handleResponse, initCompetition } from '../../../models/models';

import { COMP_TYPE, del, post, put } from '../../../models/backendReq';

import { useAuth } from '../../../models/auth/AuthProvider';
import './OrganizerCompetitionAthletesScreen.scss';

import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganizerCompetitionTable from '../../../components/Organizer/TableComponents/OrganizerCompetitionTable';
import TransferAthleteWindow from '../../../components/Organizer/TransferAthleteWindow';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import EventEoContext from '../../../contexts/EventEoContext';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import { toolBarElements } from '../SubNavBarLinks';
import OrganizerCompetitionAddAthleteRequest from './Sections/OrganizerCompetitionAddAthleteRequest';
import OrganizerCompetitionSetupSectionAthletes from './Sections/OrganizerCompetitionSetupSectionAthletes';
import OrganizerCompetitionSetupSectionTeams from './Sections/OrganizerCompetitionSetupSectionTeams';
import OrganizerCompetitionMassiveExercises from '../../../components/Organizer/CompetitionSetup/OrganizerCompetitionMassiveExercises';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';


function OrganizerCompetitionAthletesScreen() {
	const competitionContext = useContext(CompetitionEoContext);
	const eventContext = useContext(EventEoContext);

	let alert = useAlert();
	let auth = useAuth();
	let navigate = useNavigate();

	const [athletesToTransfer, setAthletesToTransfer] = useState<Athlete[]>([]);
	const [enableSelect, setEnableselect] = useState(false);
	const [expandRequest, setExpandRequest] = useState(0);
	const [athleteToDelete, setAthleteToDelete] = useState<Athlete>();

	const showAdd = useState(false);
	const showTransfer = useState(false);
	const showExercises = useState(false);
	const showDelete = useState(false);

	let addNewAthlete = async (athletes?: Athlete[]) => {
		showAdd[1](false);
		if (!athletes) {
			alert.show("Errore durante l'inserimento dell'atleta", MESSAGE_TYPE.ERROR, 2000, true, true);
			return;
		}

		const athletesToAdd: Athlete[] = [];
		let newAthletesPromise = athletes.map(async (athlete) => {
			let res = await put.individualCompetitionAddAthlete(competitionContext.competition.id, athlete.id);
			await handleResponse(
				res, auth, alert, navigate,
				"Errore durante l'inserimento dell'atleta",
				undefined, () => athletesToAdd.push(athlete)
			)
		});

		await Promise.all(newAthletesPromise);
		eventContext.athletes.crud.add(athletesToAdd.map(a => ({ ...a, competition: competitionContext.competition, competitionId: competitionContext.competition.id })));
	};

	let deleteAthlete = async () => {
		if (!athleteToDelete)
			return;

		const exercises = competitionContext.exercises.filter(exe => exe.athleteId === athleteToDelete.id);
		if (exercises.length > 0) {
			const delExePromise = exercises.map(exercise => del.exercise(exercise.id));
			await Promise.all(delExePromise);
			eventContext.exercises.crud.delete(exercises.map(exercise => ({ ...exercise, competition: competitionContext.competition })));
		}
		await del.individualCompetitionRemoveAthlete(competitionContext.competition.id, athleteToDelete.id)
			.then(async (res) => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione dell'atleta",
					"Atleta cancellato",
					() => eventContext.athletes.crud.delete({ ...athleteToDelete, competition: competitionContext.competition, competitionId: competitionContext.competition.id })
				)
			});
		showDelete[1](false);
	};

	let transferAthlete = (sourceCompetition: Competition, athlete: Athlete) => {
		setAthletesToTransfer([athlete]);
		showTransfer[1](true);
	}

	const transferCompleted = (sourceCompetition: Competition, targetCompetition: Competition, athletes: Athlete[]) => {
		eventContext.athletes.crud.delete(athletes.map(athlete => ({ ...athlete, competition: sourceCompetition, competitionId: sourceCompetition.id })));
		eventContext.athletes.crud.add(athletes.map(athlete => ({ ...athlete, competition: targetCompetition, competitionId: targetCompetition.id })));

		const exercises = eventContext.exercises.value.filter(exe => exe.competitionId === sourceCompetition.id && athletes.some(x => x.id === exe.athleteId));
		eventContext.exercises.crud.delete(exercises);
		eventContext.exercises.crud.add(exercises.map(x => ({ ...x, competition: targetCompetition, competitionId: targetCompetition.id })));
		setAthletesToTransfer([]);
		showTransfer[1](false);
	}

	const _toolBarElements: eoToolbarProp[] = [];

	if (competitionContext.competition.type === COMP_TYPE.Individual) {
		_toolBarElements.push({ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Iscrivi atleta' });
		_toolBarElements.push({ ...toolBarElements.selectElement, callback: () => setEnableselect(!enableSelect), text: 'Seleziona', selected: enableSelect });
	}
	if (enableSelect) {
		_toolBarElements.push({ ...toolBarElements.transferElement, callback: () => showTransfer[1](true), text: 'Sposta atleti', notNumber: athletesToTransfer.length });
		_toolBarElements.push({ ...toolBarElements.athleteElement, callback: () => showExercises[1](true), text: 'Dichiara esercizi', notNumber: athletesToTransfer.length });
	}

	useEffect(() => {
		if (!enableSelect)
			setAthletesToTransfer([]);
	}, [enableSelect])
	return (
		<OrganizerCompetitionTable text='ATLETI'>
			<EoToolBar elements={_toolBarElements} />
			<div className='organizer-competition-selection-buttons'>
				<button onClick={() => setExpandRequest(expandRequest < 0 ? 1 : expandRequest + 1)}>Espandi tutti</button>
				<button onClick={() => setExpandRequest(expandRequest > 0 ? -1 : expandRequest - 1)}>Comprimi tutti</button>
				{enableSelect && <>
					<button onClick={() => setAthletesToTransfer(competitionContext.athletes)}>
						Seleziona tutti
					</button>
					<button onClick={() => setAthletesToTransfer([])}>Deseleziona tutti</button>
				</>}
			</div>
			<div className='organizer-competition-athletes-body'>
				{
					competitionContext.competition.type === COMP_TYPE.Individual ? (
						<>
							<OrganizerCompetitionSetupSectionAthletes
								associatedExerciseTypes={eventContext.associatedexercisetypes.value.filter(x => x.competition.id === competitionContext.competition.id)}
								athletes={competitionContext.athletes}
								competition={competitionContext.competition}
								deleteAthlete={(athlete) => {
									setAthleteToDelete(athlete);
									showDelete[1](true)
								}}
								transferAthlete={transferAthlete}
								enableSelect={enableSelect}
								athletesSelected={athletesToTransfer}
								setAthletesSelected={setAthletesToTransfer}
								expandRequest={expandRequest}
							/>
							<OrganizerCompetitionAddAthleteRequest
								addCompleted={addNewAthlete}
								associations={eventContext.associations}
								athletes={competitionContext.athletes}
								eventAthletes={eventContext.athletes.value}
								show={showAdd} />
							<TransferAthleteWindow
								show={showTransfer}
								athletes={athletesToTransfer}
								competitions={eventContext.competitions.value}
								sourceCompetition={competitionContext.competition}
								transferCompleted={transferCompleted} />
							<CrudForm
								headerText='Elimina atleta'
								back={() => showDelete[1](false)}
								denied={() => showDelete[1](false)}
								show={showDelete}
								submitButtonText='ELIMINA DEFINITIVAMENTE'
								confirm={deleteAthlete}>
								<p className='organizer-competition-athletes-delete-message'>
									Attenzione: cancellando questo atleta si elimineranno tutti gli esercizi ad esso associati, compresi i punteggi se già ha gareggiato, e non sarà possibile in alcun modo recuperarli. Sei sicuro di voler eliminare tutto di questo atleta?
								</p>
							</CrudForm>
						</>
					) : (
						<>
							<OrganizerCompetitionSetupSectionTeams
								associatedExerciseTypes={competitionContext.associatedexercisetypes}
								teams={competitionContext.teams}
								competition={competitionContext.competition} />

						</>
					)
				}
				<OrganizerCompetitionMassiveExercises selectedAthletes={[{ athletes: athletesToTransfer, competition: competitionContext.competition }]} show={showExercises} />
			</div>
		</OrganizerCompetitionTable>
	);
}

export default OrganizerCompetitionAthletesScreen;