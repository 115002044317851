import { Championship } from '../models/models';
import './ChampionshipCom.scss';


interface props {
	championship: Championship;
}

function ChampionshipCom({ championship }: props) {
	return (
		<div>
			<div className='championship-header'>
				<div className='championship-name'>{championship.name}</div>
			</div>
		</div>
	);
}

export default ChampionshipCom;