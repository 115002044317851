import { faArrowRight, faCheck, faChevronDown, faChevronUp, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConditionalListComponent from '../../../components/ConditionalListComponent';
import AthletesSubscribedWindow from '../../../components/Organizer/AthletesSubscribedWindow';
import OrganizerCompetitionMassiveExercises from '../../../components/Organizer/CompetitionSetup/OrganizerCompetitionMassiveExercises';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import TransferAthleteWindow from '../../../components/Organizer/TransferAthleteWindow';
import EventEoContext, { AthleteWithCompetition, TeamWithCompetition } from '../../../contexts/EventEoContext';
import { useAlert } from '../../../models/AlertProvider';
import { downloadPDFInWorker } from '../../../models/PDF/Worker/downloadPDFInWorker';
import { useAuth } from '../../../models/auth/AuthProvider';
import { COMP_TYPE, del } from '../../../models/backendReq';
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';
import { Association, Athlete, AthleteClass, Competition, ExerciseType, Team, TeamClass, handleResponse, initAthlete, initCategory, initCompetition, initTeam } from '../../../models/models';
import { getSubscriptionsStat, getUnique } from '../../../utility/UtilityFunctions';
import '../../../utility/prototype';
import { toolBarElements } from '../SubNavBarLinks';
import SubscriptionAdd from './SubscriptionAdd';
import SubscriptionEditExercises from './SubscriptionEditExercises';
import './SubscriptionScreen.scss';
import { usePDFDownload } from '../../../models/DownloadPDFProvider';


interface CompetitionWithCompetitors extends Competition {
	competitors: (Athlete | Team)[]
}
interface AssociationExtended extends Association {
	competitions: CompetitionWithCompetitors[];
}

function CompetitionComp(props: {
	competition: CompetitionWIthAthletes;
	showExercisesModal: (athlete: (AthleteClass | TeamClass), competition: Competition, team?: TeamClass) => void;
	okSubscriptionStat: number;
	deleteAthlete: (athlete: (Athlete | Team), competition?: Competition) => void;
	transferRequest: (sourceCompetition: Competition, athlete: AthleteClass) => void,
	showAssociation?: boolean;
	athletesSelected?: { athletes: AthleteClass[], competition: Competition }[];
	selectionEnabled?: boolean;
	setAthletesSelected?: React.Dispatch<React.SetStateAction<{ athletes: AthleteClass[]; competition: Competition; }[]>>;
	expandRequest?: number;
}) {
	const [showAthletes, setShowAthletes] = useState(false);
	const checkboxRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (props.expandRequest && props.expandRequest > 0)
			setShowAthletes(true);
		else
			setShowAthletes(false);
	}, [props.expandRequest]);

	const athletesSelected = props.athletesSelected?.find(x => x.competition.id === props.competition.id);
	const allAthletesSelected = props.competition?.athletes?.every(a => athletesSelected?.athletes.some(y => y.id === a.id));

	const handleExpand = (e: React.MouseEvent) => {
		if (!checkboxRef.current?.contains(e.target as Node))
			setShowAthletes(!showAthletes)
	}
	const handleSelection = () => {
		if (allAthletesSelected)
			props.setAthletesSelected && props.setAthletesSelected(p => p.filter(x => x.competition.id !== props.competition.id));
		else
			if (athletesSelected)
				props.setAthletesSelected && props.competition.type === COMP_TYPE.Individual && props.setAthletesSelected(p =>
					p.map(x => {
						if (x.competition !== props.competition)
							return x;
						else
							return {
								...x, athletes: [...x.athletes,
								...props.competition.athletes?.filter(a => !athletesSelected.athletes.some(x => x.id === a.id)) as AthleteClass[] ?? []]
							}
					}))
			else
				props.setAthletesSelected && props.competition.type === COMP_TYPE.Individual && props.setAthletesSelected(p =>
					[...p, {
						competition: props.competition,
						athletes: props.competition.athletes as AthleteClass[] ?? []
					}])
	}
	return (
		<div className='subscription-subscribed-competition-container'>
			<div className={`subscription-subscribed-competition-name${showAthletes ? " show" : ""}`} onClick={handleExpand}>
				{props.selectionEnabled && props.competition.type === COMP_TYPE.Individual && <input
					type='checkbox'
					checked={allAthletesSelected}
					onChange={handleSelection}
					ref={checkboxRef}
				/>}
				<div className='subscription-subscribed-competition-lenght'>{props.competition.athletes?.length}</div>
				<div className='subscription-subscribed-competition-name-child'>{props.competition.name}</div>
			</div>
			<div className={`subscription-subscribed-competition-athletes-container${showAthletes ? " show" : ""}`}>
				{
					showAthletes && props.competition.athletes?.map(ath => {
						return (
							<AthleteComp
								ath={ath}
								c={props.competition}
								deleteAthlete={props.deleteAthlete}
								transferRequest={props.transferRequest}
								okSubscriptionStat={props.okSubscriptionStat}
								showExercisesModal={props.showExercisesModal}
								key={props.competition.id + " " + ath.id}
								showAssociation={props.showAssociation}
								athletesSelected={props.athletesSelected}
								selectionEnabled={props.selectionEnabled}
								setAthletesSelected={props.setAthletesSelected} />
						)
					}
					)}
			</div>
		</div>
	)
}

function AthleteComp(props: {
	ath: AthleteClass | TeamClass;
	c: Competition;
	showExercisesModal: (athlete: (AthleteClass | TeamClass), competition: Competition, team?: TeamClass) => void;
	okSubscriptionStat: number;
	deleteAthlete: (athlete: (Athlete | Team), competition?: Competition) => void;
	transferRequest: (sourceCompetition: Competition, athlete: AthleteClass) => void,
	showAssociation?: boolean;
	athletesSelected?: { athletes: AthleteClass[], competition: Competition }[];
	selectionEnabled?: boolean;
	setAthletesSelected?: React.Dispatch<React.SetStateAction<{ athletes: AthleteClass[], competition: Competition }[]>>
}) {
	const auth = useAuth();
	const isCum = props.c.type === COMP_TYPE.Cumulative;
	const athClassName = `subscription-subscribed-athlete-container${isCum ? " isCum" : ""}${props.selectionEnabled ? " selectable" : ""}`

	const isSelected = props.athletesSelected?.some(as => as.athletes.map(x => x.id).includes(props.ath.id) && as.competition.id === props.c.id);

	const handleSelection = () => {
		if (props.selectionEnabled && props.c.type === COMP_TYPE.Individual) {
			props.setAthletesSelected && props.setAthletesSelected(prev => {
				if (isSelected)
					if (prev.some(y => y.competition.id === props.c.id && y.athletes.filter(a => a.id !== props.ath.id).length === 0))
						return prev.filter(y => y.competition.id !== props.c.id);
					else
						return prev.map(x => {
							if (x.competition.id !== props.c.id)
								return x;
							else
								return { ...x, athletes: x.athletes.filter(a => a.id !== props.ath.id) }
						})
				else
					if (prev.find(y => y.competition.id === props.c.id))
						return prev.map(x => {
							if (x.competition.id !== props.c.id)
								return x;
							else
								return { ...x, athletes: [...x.athletes, props.ath as AthleteClass] }
						})
					else
						return [...prev, { competition: props.c, athletes: [props.ath as AthleteClass] }]
			})
		}
	}

	return (
		<>
			<div className={athClassName} onClick={handleSelection}>
				{props.selectionEnabled && props.c.type === COMP_TYPE.Individual && <input
					type='checkbox'
					checked={isSelected}
					style={{ marginRight: '10px' }}
					readOnly
				/>}
				<span className='subscription-subscribed-athlete-name'>
					{props.showAssociation && <div className='subscription-subscribed-athlete-association'>{props.ath.owner?.name}</div>}
					<div>{"firstName" in props.ath ? props.ath.getName("LF") : props.ath.name}</div>
				</span>
				{!isCum &&
					<button
						onClick={() => props.showExercisesModal(props.ath, props.c)}
						className={`subscription-subscribed-athlete-has-exe${props.ath.hasExercises ? " valid" : ""}`}>
						<FontAwesomeIcon icon={props.ath.hasExercises ? faCheck : faArrowRight} />
					</button>}
				{(props.okSubscriptionStat === 1 || auth.user.isOrganizer) && !props.selectionEnabled &&
					<RemoveAthletePopup
						disabled={props.ath.hasExercises}
						transferRequest={props.transferRequest}
						athlete={props.ath}
						competition={props.c}
						deleteAthlete={props.deleteAthlete} />
				}
			</div>
			{isCum && (props.ath as TeamClass).athletes?.map(a => {
				return (
					<div key={a.id} className='subscription-subscribed-athlete-container isTeam'>
						<span className='subscription-subscribed-athlete-name isTeam'>
							<div>{a.getName("LF")}</div>
						</span>
						<button
							onClick={() => props.showExercisesModal(a, props.c, (props.ath as TeamClass))}
							className={`subscription-subscribed-athlete-has-exe${a.hasExercises ? " valid" : ""}`}>
							<FontAwesomeIcon icon={a.hasExercises ? faCheck : faArrowRight} />
						</button>
					</div>
				)
			})}
		</>
	)
}

function RemoveAthletePopup({ athlete, competition, deleteAthlete, transferRequest, disabled }: {
	athlete: (AthleteClass | TeamClass),
	competition: Competition,
	deleteAthlete: (athlete: (Athlete | Team), competition: Competition) => void,
	transferRequest: (sourceCompetition: Competition, athlete: AthleteClass) => void,
	disabled?: boolean;
}) {
	const auth = useAuth();
	const [showRemove, setShowRemove] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const eleRef = useRef<HTMLButtonElement>(null);

	useOnClickOutside(ref, () => setShowRemove(false));

	const _setShowClick = (e: React.MouseEvent) => {
		if (!eleRef.current?.contains(e.target as Node))
			setShowRemove(true)
	}
	const _transferRequest = () => {
		transferRequest(competition, athlete as AthleteClass)
		setShowRemove(false);
	}

	return (
		<button onClick={_setShowClick} className='subscription-subscribed-athlete-ellipsis-button'>
			<FontAwesomeIcon icon={faEllipsisVertical} />
			{showRemove &&
				<div ref={ref} className={`subscription-subscribed-athlete-remove-popup`}>
					<button
						className={disabled ? " disabled" : ""}
						onClick={() => { if (!disabled) deleteAthlete(athlete, competition) }}>
						Cancella
					</button>
					{
						competition.type === COMP_TYPE.Individual && auth.user.isOrganizer &&
						<button ref={eleRef} onClick={_transferRequest}>Sposta</button>
					}
				</div>}
		</button>
	)
}

interface CompetitionWIthAthletes extends Competition {
	athletes?: (AthleteClass | TeamClass)[];
}

function SubscriptionsScreen() {
	const context = useContext(EventEoContext);
	const pdf = usePDFDownload();

	const auth = useAuth();
	const alert = useAlert();

	const navigate = useNavigate();

	const [athletesFilter, setAthletesFilter] = useState("");
	const [competitionsFilter, setCompetitionsFilter] = useState("");
	const [categoryFilter, setCategoryFilter] = useState("");
	const [levelFilter, setlevelFilter] = useState(0);
	const [associationFilter, setAssociationFilter] = useState(0);
	const [exerciseTypesFilter, setExerciseTypesFilter] = useState(0);

	const [showAssociation, setShowAssociation] = useState(false);
	const [competitions, setCompetitions] = useState<CompetitionWIthAthletes[]>([]);
	const [athleteToEdit, setAthleteToEdit] = useState<AthleteClass | TeamClass>(new AthleteClass(initAthlete));
	const [competitionToEdit, setCompetitionToEdit] = useState<Competition>(initCompetition);
	const [athletesToTransfer, setAthletesToTransfer] = useState<{ athletes: AthleteClass[], competition: Competition }[]>([]);
	const [teamToEdit, setTeamToEdit] = useState<TeamClass>(new TeamClass(initTeam));
	const [selectionEnabled, setSelectionEnabled] = useState(false);
	const [associationToShow, setAssociationToShow] = useState<AssociationExtended>();
	const [expandAllRequest, setExpandAllRequest] = useState(0);

	const showAdd = useState(false);
	const showExercises = useState(false);
	const showMassiveExercises = useState(false);
	const showTransfer = useState(false);
	const showAssociationDetails = useState(false);
	const showFilters = useState(false);

	useEffect(() => {
		if (context.loading)
			return

		const getCompetitions = async () => {
			const compBuild = context.competitions.value.map(c => {
				const athletes: (Athlete | Team)[] = c.type === COMP_TYPE.Individual ?
					context.athletes.value.filter(x => x.competition.id === c.id) : context.teams.value.filter(x => x.competition.id === c.id);
				const exerciseTypes: ExerciseType[] = context.associatedexercisetypes.value.filter(et => et.competition.id === c.id);

				const athletesFiltered = athletes.filter(a1 => auth.user.associations?.map(a => a.id).includes(a1.ownerId)).map(a2 => {
					if ("firstName" in a2) {
						const exercises = context.exercises.value.filter(exe => exe.athleteId === a2.id && exe.competitionId === c.id);
						return new AthleteClass(a2, exercises)
					} else {
						const roster = context.rosters.value.find(r => r.teamId === a2.id && r.competitionId === c.id);
						const teamExercises = context.exercises.value.filter(exe => exe.teamId === a2.id);

						return new TeamClass(a2, teamExercises, undefined, roster?.athletes.map(athlete => {
							const exercises = context.exercises.value.filter(exe => exe.athleteId === athlete.id && exe.competitionId === c.id);
							return new AthleteClass(athlete, exercises);
						}));
					}
				})

				return {
					...c,
					athletes: athletesFiltered.athleteFilter(athletesFilter, true)
						.filter(a => a.ownerId === associationFilter || !associationFilter)
						.filter(a => getUnique(a.exercises ?? [], "typeId").length === exerciseTypesFilter || !exerciseTypesFilter),
					exerciseTypes: exerciseTypes
				}
			})
			setCompetitions(compBuild);
		}

		getCompetitions();
	}, [context, athletesFilter, associationFilter]);

	const athletesSubscribed: { athlete: (AthleteClass | TeamClass), competition: Competition }[] = [];
	competitions.forEach(c => {
		c.athletes?.forEach(a => athletesSubscribed.push({ athlete: a, competition: c }))
	})

	const deleteAthlete = async (athlete: (Athlete | Team), competition?: Competition) => {
		if (!competition)
			return

		if (competition.type === COMP_TYPE.Individual)
			await del.individualCompetitionRemoveAthlete(competition.id, athlete.id)
				.then(res => {
					handleResponse(
						res, auth, alert, navigate,
						"Errore durante la cancellazione dell'atleta",
						"Atleta cancellato",
						() => context.athletes.crud.delete({ ...athlete as Athlete, competition, competitionId: competition.id })
					)
				});
		else
			await del.roster(competition.id, athlete.id)
				.then(res => {
					handleResponse(
						res, auth, alert, navigate,
						"Errore durante la cancellazione della squadra",
						"Atleta cancellato",
						() => {
							context.teams.crud.delete({ ...athlete as Team, competition, competitionId: competition.id });
							context.rosters.crud.delete(context.rosters.value.find(r => r.competitionId === competition.id && r.teamId === athlete.id)!);
						})
				});
	}

	const newAthletes = (athletes: Athlete[], competition: Competition, team?: Team) => {
		if (competition.type !== COMP_TYPE.Individual && !team)
			return

		if (competition.type === COMP_TYPE.Individual)
			context.athletes.crud.add(athletes.map(a => ({ ...a, competition, competitionId: competition.id })))
		else {
			context.teams.crud.add({ ...team!, competition, competitionId: competition.id });
			if (team)
				context.rosters.crud.add({
					athletes,
					competition,
					competitionId: competition.id,
					team: team,
					teamId: team.id
				})
		}
	}

	const showExercisesModal = (athlete: (AthleteClass | TeamClass), competition: Competition, team?: TeamClass) => {
		setAthleteToEdit(athlete);
		setCompetitionToEdit(competition);
		team && setTeamToEdit(team);
		showExercises[1](true);
	}

	const okSubscriptionStat = getSubscriptionsStat(context.event);

	const subscriptionStat = () => {
		switch (okSubscriptionStat) {
			case 0: return "Attesa apertura iscrizioni";
			case 1: return "Iscrizioni aperte";
			case 2: return "Iscrizioni chiuse";
		}
	}

	const subscriptionStatCN = () => {
		switch (okSubscriptionStat) {
			case 0: return "waiting";
			case 1: return "open";
			case 2: return "closed";
		}
	}

	const athletesToTransferLenght = athletesToTransfer?.map(att => att.athletes).flat().length;

	const _catFilter = [];
	const _lvlFilter = [];
	if (categoryFilter !== "")
		_catFilter.push(categoryFilter);

	if (levelFilter !== 0)
		_lvlFilter.push(levelFilter);

	const _filters = [
		{ name: 'Competizione', set: setCompetitionsFilter, value: competitionsFilter },
		{
			name: 'Categorie',
			set: setCategoryFilter,
			value: categoryFilter,
			type: "select",
			options: getUnique(competitions.map(c => c.category ?? initCategory), "name").filter(w => w && w.name !== "")
				.map(x => ({ value: x.name, label: x.name }))
		},
		{
			name: 'Livelli',
			setNum: setlevelFilter,
			value: levelFilter,
			type: "select",
			options: getUnique(competitions, "levelId").filter(w => w.levelId)
				.map(x => ({ value: x.levelId ?? 0, label: x.level?.name ?? "" }))
		},
		{
			name: 'Società',
			setNum: setAssociationFilter,
			value: associationFilter,
			type: "select",
			options: auth.user.associations
				.filter(a => context.athletes.value.map(x => x.ownerId).includes(a.id) || context.teams.value.map(x => x.ownerId).includes(a.id))
				.map(a => ({ label: a.name, value: a.id }))
		},
		{ name: 'Atleta', set: setAthletesFilter, value: athletesFilter },
		{ name: 'Attrezzi dichiarati', setNum: setExerciseTypesFilter, value: exerciseTypesFilter, type: "number" },
	];


	let subscribedText = () => {
		if (auth.user.associations.length > 1) {
			const associationFilter = (a: Association) => {
				return competitions.reduce((prev, curr) => prev + curr.athletes?.filter(ath => ath.ownerId === a.id).length!, 0) > 0;
			}

			const associations = auth.user.associations.filter(associationFilter);
			return <div className='subscription-show-hide-associations-container'>
				{associations.length > 0 && <button className='subscription-show-hide-association-button' onClick={() => setShowAssociation(!showAssociation)}>
					<div>{!showAssociation ? "Visualizza società" : "Nascondi società"}</div>
					<FontAwesomeIcon icon={showAssociation ? faChevronUp : faChevronDown} />
				</button>}
				{showAssociation && associations.map(association =>
					<div key={association.id} onClick={() => associationDetailsRequest(association)} className='subscription-subscribed-athletes-by-association'>
						<div className='subscription-subscribed-athletes-by-association-name'>{association.name}</div>
						<div className='subscription-subscribed-athletes-by-association-count'>{competitions.reduce((prev, curr) => prev + curr.athletes?.filter(ath => ath.ownerId === association.id).length!, 0)}</div>
					</div>
				)}
			</div>

		} else
			return `Atleti da te iscritti: ${competitions.reduce((prev, curr) => prev + curr.athletes?.length!, 0)}`;

	}

	const transferRequest = (sourceCompetition: Competition, athlete: AthleteClass) => {
		setAthletesToTransfer([{ athletes: [athlete], competition: sourceCompetition }]);
		showTransfer[1](true);
	}

	const transferCompleted = (sourceCompetition: Competition, targetCompetition: Competition, athletes: Athlete[]) => {
		context.athletes.crud.delete(athletes.map(athlete => ({ ...athlete, competition: sourceCompetition, competitionId: sourceCompetition.id })));
		context.athletes.crud.add(athletes.map(athlete => ({ ...athlete, competition: targetCompetition, competitionId: targetCompetition.id })));
		const exercises = context.exercises.value.filter(exe => exe.competitionId === sourceCompetition.id && athletes.some(x => x.id === exe.athleteId));
		context.exercises.crud.delete(exercises);
		context.exercises.crud.add(exercises.map(x => ({ ...x, competition: targetCompetition, competitionId: targetCompetition.id })));

		setAthletesToTransfer([]);
		showTransfer[1](false);
	}

	const associationDetailsRequest = (association: Association) => {
		const competitionsFiltered = competitions.filter(competition => competition.athletes?.some(ath => ath.ownerId === association.id));
		const competitionWithAthletes = competitionsFiltered.map(cf => {
			const competitors = cf.athletes?.filter(a => a.ownerId === association.id) ?? [];
			return { ...cf, competitors }
		});

		setAssociationToShow({
			...association,
			competitions: competitionWithAthletes
		});
		showAssociationDetails[1](true);
	};

	const competitionsToShow = competitions.sortCompetitions()
		.filter(comp => comp.athletes?.length && comp.athletes?.length > 0)
		.filter(comp => comp.athletes?.map(x => x.ownerId).includes(associationFilter) || associationFilter === 0)
		.competitionFilter(competitionsFilter, _catFilter, _lvlFilter);

	const PDFGeneration = () => {
		const pdfProps = {
			data: {
				associations: auth.user.associations,
				athletes: competitionsToShow.map(c => {
					const competitiors = c.athletes ?? [];
					let toRet = [] as (AthleteWithCompetition | TeamWithCompetition)[]
					competitiors.forEach(comp => toRet.push({ ...comp, competition: c, competitionId: c.id }));
					return toRet
				}).flat(),
				event: context.event,
				exercises: context.exercises.value,
				exerciseTypes: context.associatedexercisetypes.value
			}
		};

		const fileName = "Iscrizioni evento - " + context.event.name.trim() + ".pdf";
		pdf.download("Subscriptions", pdfProps, fileName);
	}

	const _toolBarElements: eoToolbarProp[] = [];
	if (okSubscriptionStat === 1 || auth.user.isOrganizer)
		_toolBarElements.push({ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Iscrivi atleti' })

	_toolBarElements.push({
		...toolBarElements.filterElement,
		callback: () => showFilters[1](!showFilters[0]),
		notNumber: _filters.filter(f => (f.value && f.value !== "" && f.value !== 0)).length
	});
	_toolBarElements.push({
		...toolBarElements.downloadPDFElement,
		callback: PDFGeneration,
	});

	if (okSubscriptionStat === 1 || auth.user.isOrganizer) {
		_toolBarElements.push({
			...toolBarElements.selectElement,
			callback: () => setSelectionEnabled(!selectionEnabled), text: 'Seleziona', selected: selectionEnabled
		})
	}
	if (selectionEnabled) {
		if (auth.user.isOrganizer)
			_toolBarElements.push({ ...toolBarElements.transferElement, callback: () => showTransfer[1](true), text: 'Sposta', notNumber: athletesToTransferLenght, disabled: athletesToTransfer.length > 1 });
		_toolBarElements.push({ ...toolBarElements.athleteElement, callback: () => showMassiveExercises[1](true), text: 'Dichiara esercizi', notNumber: athletesToTransferLenght });
	}



	return (
		<OrganizerEventTable text='ISCRIZIONI'>
			<AthletesSubscribedWindow show={showAssociationDetails} association={associationToShow} />
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar history='subscriptionsCompetitionsFilters' show={showFilters} filters={_filters} found={_toolBarElements[0].notNumber! > 0 ? competitionsToShow.length : undefined} />
			<div className='subscription-subscribed-athletes-container'>
				<div className={`subscription-stat ${subscriptionStatCN()}`}>{subscriptionStat()}</div>
				<div className='subscription-subscribed-athlete-header'>{subscribedText()}</div>
				{competitionsToShow.length > 0 && <div className='subscription-subscribed-expand-manager-container'>
					<button onClick={() => setExpandAllRequest(expandAllRequest < 0 ? 1 : expandAllRequest + 1)}>Espandi tutto</button>
					<button onClick={() => setExpandAllRequest(expandAllRequest > 0 ? - 1 : expandAllRequest - 1)}>Comprimi tutto</button>
					{selectionEnabled && <>
						<button onClick={() => setAthletesToTransfer(
							competitionsToShow.filter(x => x.type === COMP_TYPE.Individual).map(c => ({ competition: c, athletes: c.athletes as AthleteClass[] })))}>
							Seleziona tutti
						</button>
						<button onClick={() => setAthletesToTransfer([])}>Deseleziona tutti</button>
					</>}
				</div>}
				<div className='subscription-subscribed-athlete-header-container'>
					<ConditionalListComponent
						items={competitionsToShow}
						emptyMesssage={<>
							<div>Non hai iscritto ancora nessun atleta a questo evento</div>
							{okSubscriptionStat === 1 &&
								<button className='subscription-no-athlete-add-button' onClick={() => showAdd[1](true)}>AGGIUNGI</button>}
						</>}
						renderList={competitions => competitions.map(c =>
							<CompetitionComp
								key={c.id}
								competition={c}
								deleteAthlete={deleteAthlete}
								transferRequest={transferRequest}
								okSubscriptionStat={okSubscriptionStat}
								showExercisesModal={showExercisesModal}
								showAssociation={auth.user.associations?.length! > 1}
								athletesSelected={athletesToTransfer}
								selectionEnabled={selectionEnabled}
								setAthletesSelected={setAthletesToTransfer}
								expandRequest={expandAllRequest}
							/>
						)} />
				</div>
			</div>
			<SubscriptionAdd competitions={competitions} show={showAdd} newAthletes={newAthletes} />
			<SubscriptionEditExercises
				athlete={athleteToEdit}
				competition={competitionToEdit}
				show={showExercises}
				team={teamToEdit}
				okSubscriptionStat={okSubscriptionStat} />
			<TransferAthleteWindow
				competitions={competitions}
				athletes={athletesToTransferLenght > 0 ? athletesToTransfer[0].athletes : []}
				sourceCompetition={athletesToTransferLenght > 0 ? athletesToTransfer[0].competition : initCompetition}
				show={showTransfer}
				transferCompleted={transferCompleted} />
			<OrganizerCompetitionMassiveExercises
				selectedAthletes={athletesToTransfer}
				show={showMassiveExercises} />
		</OrganizerEventTable >
	)
}

export default SubscriptionsScreen