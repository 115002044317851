import { useState } from 'react';
import { ExerciseType, Jury, JuryAssignment, ScoreFragmentType } from '../../../models/models';

import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './OrganizerCompetitionJudgeCom.scss';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';

interface JuryWithAssignments extends Jury {
	judgeAssignment?: JuryAssignment[];
}

interface props {
	jury: JuryWithAssignments;
	exerciseTypes: ExerciseType[];
	setJurys: React.Dispatch<React.SetStateAction<JuryWithAssignments[] | undefined>>
}

function OrganizerCompetitionJuryCom({ jury, exerciseTypes, setJurys }: props) {
	const alert = useAlert();
	let [exerciseIdToAdd, setExerciseIdToAdd] = useState(0);

	let setExeTypeToJury = async () => {
		if (exerciseIdToAdd !== 0) {
			let exeType = exerciseTypes.find(x => x.id === exerciseIdToAdd);

			if (!exeType) {
				alert.show("Errore durante l'associazion", MESSAGE_TYPE.ERROR, 2000, true, true);
				return;
			}

			let newAssignment: JuryAssignment = {
				assignedScoreFragmentTypes: exeType.scoreComposition.map(x => x.label),
				competitionId: 0,
				exerciseTypeId: exerciseIdToAdd,
				id: (jury.judgeAssignment?.length ?? 0) + 1,
				juryId: jury.id
			}
			if (exeType)
				setJurys(prev => {
					const indexFound = prev?.findIndex(j => j.id === jury.id);
					if (indexFound === undefined || indexFound === -1 || !prev)
						return prev

					prev[indexFound].judgeAssignment = [...prev[indexFound].judgeAssignment ?? [], newAssignment];

					return prev
				})
			setExerciseIdToAdd(0);
		}
	};

	let resetExeTypeToJury = async (id: number) => {
		if (id !== 0) {
			setJurys(prev => {
				const indexFound = prev?.findIndex(j => j.id === jury.id);
				if (indexFound === undefined || indexFound === -1 || !prev)
					return prev

				prev[indexFound].judgeAssignment = prev[indexFound].judgeAssignment?.filter(ja => ja.id !== id);

				return [...prev]
			})

		}
	};

	let updateFragment = async (judgeAssignmentAssociated: JuryAssignment, sc: ScoreFragmentType, type?: ExerciseType) => {
		if (!judgeAssignmentAssociated)
			return

		let ids = type?.scoreComposition.filter(x => judgeAssignmentAssociated?.assignedScoreFragmentTypes.includes(x.label)).map(y => y.id);
		if (!ids)
			return

		if (ids.includes(sc.id)) {
			ids = ids.filter(x => x !== sc.id)
		} else {
			ids.push(sc.id)
		}

		let jaCopy = judgeAssignmentAssociated;
		let labelList = type?.scoreComposition.filter(x => ids?.includes(x.id)).map(y => y.label);

		if (labelList && jaCopy)
			jaCopy = { ...jaCopy, assignedScoreFragmentTypes: [...labelList] };

		let assignments = [...jury.judgeAssignment ?? []];
		let assignmentIndex = assignments.findIndex(x => x.id === jaCopy.id);
		assignments[assignmentIndex] = jaCopy

		setJurys(prev => {
			const indexFound = prev?.findIndex(j => j.id === jury.id);
			if (indexFound === undefined || indexFound === -1 || !prev)
				return prev

			prev[indexFound].judgeAssignment = assignments;

			return [...prev]
		})

	}

	let filteredExeTypes = exerciseTypes.filter(x => !jury.judgeAssignment?.map(y => y.exerciseTypeId).includes(x.id));

	return (
		<div className='competition-setup-judge-container'>
			<div className='competition-setup-judge-header'>
				<p className='competition-setup-judge-header-name'>{jury.name}</p>
				<div className='competition-setup-judge-header-addrow'>
					{/*<Select className='competition-setup-judge-header-select' value={exerciseIdToAdd} onChange={(e) => setExerciseIdToAdd(e)} children={selectOptions} />*/}

					<select className='competition-setup-judge-header-select' value={exerciseIdToAdd} onChange={(e) => setExerciseIdToAdd(parseInt(e.target.value))}>
						<option value={0}>---</option>
						{filteredExeTypes.map(x => {
							return (
								<option key={x.id} value={x.id}>{x.name}</option>
							)
						})}
					</select>
					<button onClick={setExeTypeToJury}>
						<FontAwesomeIcon icon={faAdd} />
						<span>Aggiungi</span>
					</button>

				</div>

			</div>
			<div className='competition-setup-judge-exesassociated-container'>
				{jury.judgeAssignment?.length ?? 0 > 0 ? (jury.judgeAssignment?.map(judgeAssignmentAssociated => {
					let typeFound = exerciseTypes.find(x => x.id === judgeAssignmentAssociated.exerciseTypeId);
					// if (exeName) {
					// 	let matches = exeName.match(/\b(\w)/g);

					// 	if (matches && matches?.length > 1) {
					// 		exeName = matches.join('').toUpperCase();
					// 	} else {
					// 		exeName = exeName.substring(0, 2);
					// 	}
					// }

					return (
						<div key={judgeAssignmentAssociated.id} className='competition-setup-judge-exeassociated-container' >
							<div className='competition-setup-judge-exeassociated-name-and-delete'>
								<div className='competition-setup-judge-exeassociated-name'>{typeFound?.displayName}</div>
								<button className='competition-setup-judge-exeassociated-button' onClick={() => resetExeTypeToJury(judgeAssignmentAssociated.id)}>
									<FontAwesomeIcon icon={faTrash} />
								</button>
							</div>
							<div className='competition-setup-judge-exeassociated-fragments-container'>
								{typeFound?.scoreComposition.map(scoreFragment => {
									return (
										<label key={scoreFragment.id} className='competition-setup-judge-exeassociated-fragment'>
											<input
												type={'checkbox'}
												checked={judgeAssignmentAssociated.assignedScoreFragmentTypes.includes(scoreFragment.label)}
												onChange={() => updateFragment(judgeAssignmentAssociated, scoreFragment, typeFound)}
											/>
											<span>{scoreFragment.label}</span>
										</label>
									)
								})}
							</div>
						</div>
					);
				})) : (
					<label style={{ width: '100%' }}>...</label>
				)}
			</div>
		</div>
	);
}

export default OrganizerCompetitionJuryCom;