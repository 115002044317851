import { faBug, faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
import { ATHLETE_GENDER, COMP_TYPE } from "../models/backendReq";
import { Athlete, Category, Event, Exercise, initCategory, RankingConfiguration, Team } from "../models/models";
import { EventEoContextData } from "../contexts/EventEoContext";
import { OrganizerConfigurationsContext } from "../contexts/OrganizerConfigurationsContext";
import { Location } from "react-router-dom";
import { FilterInterface } from "../components/Organizer/FiltersBar";

export function ShrinkString(text?: string) {
	let label = text;
	if (!label)
		return "";
	if (label.length > 6) {
		const labelSplit = label.split(" ");
		if (labelSplit.length > 1) {
			const labels = labelSplit.filter(label => label.length > 2).slice(0, 3);
			label = labels.map(l => l.slice(0, 1).toUpperCase() + ".").join(" ");
		} else
			label = label.slice(0, 5) + "."
	}

	return label
}

export function getPropertyName<T extends object>(o: T, expression: (x: { [Property in keyof T]: string }) => string) {
	const res = {} as { [Property in keyof T]: string };
	Object.keys(o).map(k => res[k as keyof T] = k);
	return expression(res);
}

export function getCompTypeString(type: COMP_TYPE | string) {
	switch (type) {
		case COMP_TYPE.Collective, "Collective":
			return "Collettivo"

		case COMP_TYPE.Cumulative, "Cumulative":
			return "Squadre"

		case COMP_TYPE.Individual, "Individual":
			return "Individuale"
	}
}

export function getAthleteGenderString(type: ATHLETE_GENDER | string) {
	switch (type) {
		case ATHLETE_GENDER.Female, "Female":
			return "F"

		case ATHLETE_GENDER.Male, "Male":
			return "M"
	}
}

export function getRankingConfiguration(position: number, rankingConfiguration: RankingConfiguration, arrayLength: number) {
	let counter = 0;
	for (let index = 0; index < rankingConfiguration.chartSections.length; index++) {
		counter += rankingConfiguration.chartSections[index].size ?? arrayLength;
		if (position <= counter)
			return rankingConfiguration.chartSections[index].name;
	}
	return '---'
}

export function checkCategory(birthDate: string, gender: string, category?: Category, checkSelection?: "all" | "max" | "min" | "gender") {
	let toRet = true;
	const birthTime = new Date(birthDate).getTime();

	if (!category)
		return true;

	if (category.maxBirthDate && (checkSelection === "all" || checkSelection === "max" || !checkSelection)) {
		const maxBirthTime = new Date(category.maxBirthDate).getTime();
		if (birthTime > maxBirthTime) toRet = false;
	}
	if (category.minBirthDate && (checkSelection === "all" || checkSelection === "min" || !checkSelection)) {
		const minBirthTime = new Date(category.minBirthDate).getTime();
		if (birthTime < minBirthTime) toRet = false;
	}

	if (category.allowedGenders && (checkSelection === "all" || checkSelection === "gender" || !checkSelection)) {
		if (!category.allowedGenders.includes(gender)) toRet = false;
	}

	return toRet
}

export function getSubscriptionsStat(event: Event) {
	let toRet = 0;
	if (Date.now() > new Date(event.subscriptionEndDate).getTime()) {
		toRet = 2;
	} else if (!event.subscriptionStartDate || Date.now() >= new Date(event.subscriptionStartDate).getTime() && Date.now() <= new Date(event.subscriptionEndDate).getTime()) {
		toRet = 1;
	}

	return toRet;
}

export const getGenderIcon = (gender: string) => {
	if (gender === "")
		return faBug;

	switch (gender) {
		case ATHLETE_GENDER.Female: return faVenus;
		case ATHLETE_GENDER.Male: return faMars;
		default: return faBug;
	}
}


export const changeObjInAry = <T extends object, K extends keyof T>(array: T[], newValue: T | T[], property?: K | K[], extCmp?: K) => {
	return array.map(obj => {
		if (property) {
			if (Array.isArray(property))
				if (!Array.isArray(newValue))
					return property.every(p => obj[p] === newValue[p] && (!extCmp || obj[extCmp] === newValue[extCmp])) ? newValue : obj;
				else {
					const find = newValue.find(nv => property.every(p => obj[p] === nv[p] && (!extCmp || obj[extCmp] === nv[extCmp])));
					return find ? find : obj;
				}
			else
				if (!Array.isArray(newValue))
					return obj[property] === newValue[property] && (!extCmp || obj[extCmp] === newValue[extCmp]) ? newValue : obj;
				else {
					const find = newValue.find(nv => obj[property] === nv[property] && (!extCmp || obj[extCmp] === nv[extCmp]));
					return find ? find : obj;
				}
		} else {
			if (!Array.isArray(newValue)) {
				if ("id" in obj && "id" in newValue)
					return obj.id === newValue.id && (!extCmp || obj[extCmp] === newValue[extCmp]) ? newValue : obj;
				else
					return obj
			} else {
				const find = newValue.find(nv => "id" in nv && "id" in obj && nv.id === obj.id && (!extCmp || obj[extCmp] === nv[extCmp]));
				return find ? find : obj;
			}
		}
	})
}

export const deleteObjInAry = <T extends object, K extends keyof T>(array: T[], toDelete: T | T[], property?: K | K[], extCmp?: K) => {
	return array.filter(obj => {
		if (property) {
			if (!Array.isArray(property)) {
				if (!Array.isArray(toDelete))
					return obj[property] !== toDelete[property] || (extCmp && obj[extCmp] !== toDelete[extCmp]);
				else
					return !toDelete.map(x => x[property]).includes(obj[property]) || (extCmp && !toDelete.map(x => x[extCmp]).includes(obj[extCmp]));
			} else {
				if (!Array.isArray(toDelete))
					return property.some(p => obj[p] !== toDelete[p] || (extCmp && obj[extCmp] !== toDelete[extCmp]));
				else
					return toDelete.every(td => property.some(p => obj[p] !== td[p] || (extCmp && obj[extCmp] !== td[extCmp])));
			}
		} else {
			if (!Array.isArray(toDelete))
				return "id" in obj && "id" in toDelete && obj.id !== toDelete.id || (extCmp && obj[extCmp] !== toDelete[extCmp]);
			else
				return !toDelete.map(x => "id" in x ? x.id : x).includes("id" in obj ? obj.id : obj) || (extCmp && !toDelete.map(x => x[extCmp]).includes(obj[extCmp]));
		}
	})
}

export const crudBuilder = <T extends object, K extends keyof T>(
	setState: (value: React.SetStateAction<T[]>) => void,
	refreshCallBack: (property?: any) => void,
	property?: K | K[],
	extCmp?: K,
	addCallBack?: (value: T | T[]) => void,
	removeCallBack?: (value: T | T[]) => void
) => {
	return {
		add: (value: T | T[]) => {
			if (!Array.isArray(value))
				setState(prev => [...prev, value]);
			else
				setState(prev => [...prev, ...value]);
			addCallBack && addCallBack(value);
		},
		edit: (value: T | T[]) => {
			setState(prev => changeObjInAry(prev, value, property, extCmp));
		},
		delete: (value: T | T[]) => {
			setState(prev => deleteObjInAry(prev, value, property, extCmp))
			removeCallBack && removeCallBack(value);
		},
		refresh: refreshCallBack,
	};
};

export const stringSort = (a: string, b: string) => {
	if (a === undefined || b === undefined)
		return 0
	return a.localeCompare(b)
}

export function permutator<T>(items: T[], size: number = items.length): T[][] {
	if (!size) {
		return [[]];
	}

	// If size is greater than items.length, reset size. 
	size = Math.min(items.length, size);


	return items.flatMap((item) => {
		return permutator(
			items.filter((it) => it !== item),
			size - 1
		).map((permutation) => [item, ...permutation]);
	});
}

export const stringFilter = (input: string, filter: string) => {
	return input.trim().toLowerCase().includes(filter.trim().toLowerCase());
}

export const stringFilterWithPermutation = (input: string, filter: string) => {
	const intFilter = filter.trim().replace(" ", "").toLowerCase();
	const inputArray = input.trim().split(" ");

	return permutator(inputArray).map(x => x.join("")).some(comp => comp.toLowerCase().includes(intFilter.toLowerCase()))
}

export function getUnique<T extends object, K extends keyof T>(input: Array<T>, property?: K): Array<T> {
	return input
		.filter(b => b !== null && b)
		.filter((any, index, array) => !array.map(a => property && property in a ? a[property] : "id" in a && a.id)
			.includes(property && property in any ? any[property] : "id" in any && any.id, index + 1));
}

export function moveElement(array: any[], from: number, to: number) {
	if (to < 0 || to > array.length)
		return array;

	const _array = [...array];
	const f = _array.splice(from, 1)[0];
	_array.splice(to, 0, f);

	return _array;
}

export function slideElement(array: any[], from: number, direction?: boolean) {
	if (direction)
		return moveElement(array, from, from - 1);
	else
		return moveElement(array, from, from + 1)
}

export async function copyToClipboard(text: string) {
	navigator.clipboard.writeText(text);
}

export function leadingZeros(input: string | number, zeros: number) {
	if (typeof (input) === "string")
		return input.padStart(zeros, "0");
	else
		return String(input).padStart(zeros, "0");
}


export const getCompetitorId = (competitor: Athlete | Team) => {
	if ("firstName" in competitor)
		return competitor.id;

	return competitor.id * -1;
}

export const getCompetitorName = (competitor: Athlete | Team) => {
	if ("firstName" in competitor)
		return competitor.firstName + " " + competitor.lastName;

	return competitor.name
}

export const compareCompetitorExercise = (competitor: Athlete | Team, exercise: Exercise) => {
	if ("firstName" in competitor)
		return competitor.id === exercise.athleteId;

	return competitor.id === exercise.teamId;
}

export const competitionsFilterBuilder = (
	eventContext?: EventEoContextData,
	configurationsContext?: OrganizerConfigurationsContext,
	setCompetitionsFilterName?: React.Dispatch<React.SetStateAction<string>>,
	competitionsFilterName?: string,
	setCategoryFilter?: React.Dispatch<React.SetStateAction<string>>,
	categoryFilter?: string,
	setlevelFilter?: React.Dispatch<React.SetStateAction<number>>,
	levelFilter?: number,
	setAthleteFilter?: React.Dispatch<React.SetStateAction<string>>,
	athleteFilter?: string,
	setTypeFilter?: React.Dispatch<React.SetStateAction<string>>,
	typeFilter?: string,
	setGenderFilter?: React.Dispatch<React.SetStateAction<string>>,
	genderFilter?: string,
) => {
	const filters: FilterInterface[] = [];

	if (eventContext && setCompetitionsFilterName && competitionsFilterName !== undefined)
		filters.push({ name: 'Competizione', set: setCompetitionsFilterName, value: competitionsFilterName });

	if (setCategoryFilter && categoryFilter !== undefined && eventContext)
		filters.push({
			name: 'Categorie',
			set: setCategoryFilter,
			value: categoryFilter,
			type: "select",
			options: getUnique(eventContext.competitions.value.map(c => c.category ?? initCategory), "name")
				.filter(w => w.name)
				.map(x => ({ value: x.name, label: x.name }))
		});

	if (configurationsContext && eventContext && setlevelFilter && levelFilter !== undefined)
		filters.push({
			name: 'Livelli',
			setNum: setlevelFilter,
			value: levelFilter,
			type: "select",
			options: configurationsContext.competitionLevels.value
				.filter(x => eventContext.competitions.value.map(c => c.levelId).includes(x.id))
				.map(l => ({ label: l.name, value: l.id }))
		});

	if (setTypeFilter && typeFilter !== undefined)
		filters.push({
			name: 'Tipo',
			set: setTypeFilter,
			value: typeFilter,
			type: "select",
			options: [
				{ label: getCompTypeString(COMP_TYPE.Collective) ?? "", value: COMP_TYPE.Collective },
				{ label: getCompTypeString(COMP_TYPE.Cumulative) ?? "", value: COMP_TYPE.Cumulative },
				{ label: getCompTypeString(COMP_TYPE.Individual) ?? "", value: COMP_TYPE.Individual }
			]
		});

	if (setGenderFilter && genderFilter !== undefined)
		filters.push({
			name: 'Genere',
			set: setGenderFilter,
			value: genderFilter,
			type: "select",
			options: [
				{ label: getAthleteGenderString(ATHLETE_GENDER.Female) ?? "", value: ATHLETE_GENDER.Female },
				{ label: getAthleteGenderString(ATHLETE_GENDER.Male) ?? "", value: ATHLETE_GENDER.Male },
			]
		});

	if (setAthleteFilter && athleteFilter !== undefined)
		filters.push({ name: 'Atleta', set: setAthleteFilter, value: athleteFilter });


	return filters;
}

export function camelize(str: string) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
		return index === 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

export function downloadFromLink(url: string, filename: string) {
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute(
		'download',
		filename,
	);

	// Append to html link element page
	document.body.appendChild(link);

	// Start download
	link.click();

	// Clean up and remove the link
	link.parentNode?.removeChild(link);
}