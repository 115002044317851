import { faChevronLeft, faChevronRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ModalWindow from '../../../components/ModalWindow';
import AthleteSelection from '../../../components/Organizer/AthleteSelection';
import ConfirmCancelButtons from '../../../components/Organizer/ConfirmCancelButtons';
import EventEoContext from '../../../contexts/EventEoContext';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { COMP_TYPE, get, put } from '../../../models/backendReq';
import { Association, Athlete, AthleteClass, Competition, CompetitionLevel, Team, TeamClass, handleResponse } from '../../../models/models';
import { checkCategory, getAthleteGenderString, getCompTypeString, getUnique } from '../../../utility/UtilityFunctions';
import './SubscriptionAdd.scss';
import '../../../utility/prototype';

interface NameValueType {
	name: string;
	value: string | number;
}

function SelectionFilter({ show, title, stat, set, elements, fullDesc }: {
	show: boolean;
	title: string;
	stat: NameValueType;
	set: React.Dispatch<React.SetStateAction<string>> | React.Dispatch<React.SetStateAction<number>>;
	elements: NameValueType[];
	fullDesc?: string;
}) {

	const _set = (value: string | number) => {
		if (typeof (value) === "string")
			(set as React.Dispatch<React.SetStateAction<string>>)(value);
		else
			(set as React.Dispatch<React.SetStateAction<number>>)(value);
	}

	const buttonClassName = (name: string | number, selected: string | number) => {
		if (typeof name === "number" && typeof selected === "number")
			return "subscription-filter-container-button" + (name === selected ? " active" : "") + (name === -1 ? " isAll" : "");
		else
			return "subscription-filter-container-button" + (name === selected ? " active" : "") + (name === "ALL" ? " isAll" : "");
	}

	const allStat = typeof (stat.value) === "string" ? "ALL" : -1;

	const setPreviousCat = () => {
		if (stat.value === allStat) {
			if (elements.length > 0)
				_set(elements[elements.length - 1].value);
			return
		}

		const id = elements.findIndex(c => c.value === stat.value);
		if (id === -1 || id === 0) {
			_set(allStat);
			return
		}
		_set(elements[id - 1].value);

	}
	const setNextCat = () => {
		if (stat.value === allStat) {
			if (elements.length > 0)
				_set(elements[0].value);
			return
		}

		const id = elements.findIndex(c => c.value === stat.value);
		if (id === -1 || id === elements.length - 1) {
			_set(allStat);
			return
		}

		_set(elements[id + 1].value);
	}

	return show ? (
		<div className='subscription-filter-container'>
			<div className='subscription-filter-container-title'>{title}</div>
			<div className='subscription-filter-container-buttons'>
				<button className={buttonClassName(allStat, stat.value)} onClick={() => _set(allStat)}>{fullDesc ?? "TUTTO"}</button>
				{elements.map((c, index) => (
					<button className={buttonClassName(c.value, stat.value)} key={index} onClick={() => _set(c.value)}>{c.name}</button>
				))}
			</div>
			<div className='subscription-filter-container-buttons responsive'>
				<button className='subscription-filter-container-button' onClick={setPreviousCat}>
					<FontAwesomeIcon icon={faChevronLeft} />
				</button>
				<p>{stat.value === allStat ? "Tutte" : stat.name}</p>
				<button className='subscription-filter-container-button' onClick={setNextCat}>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
			</div>
		</div>
	) : null;
}
function SelectionHeader({ text, back, hideBack }: { text: string, back: VoidFunction, hideBack?: boolean }) {
	return (
		<div className='subscription-table-header'>
			<span>{text}</span>
			{!hideBack && <button onClick={back}>Cambia</button>}
		</div>)
}

interface CompetitionWIthAthletes extends Competition {
	athletes?: (AthleteClass | TeamClass)[];
}

interface props {
	newAthletes: (athlete: Athlete[], competition: Competition, team?: Team) => void
	competitions: CompetitionWIthAthletes[];
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function SubscriptionAdd(props: props) {
	const eventContext = useContext(EventEoContext);
	const configurationsContext = useContext(OrganizerConfigurationsContext);

	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const [showFilters, setShowFilters] = useState(true);

	const [levels, setLevels] = useState<CompetitionLevel[]>([])
	const [filterType, setFilterType] = useState("");
	const [filterGender, setFilterGender] = useState("");
	const [filterCategory, setFilterCategory] = useState("");
	const [filterLevel, setFilterLevel] = useState(0);
	const [competitionSelected, setCompetitionSelected] = useState<CompetitionWIthAthletes>();
	const [AthletesToInsert, setAthletesToInsert] = useState<Athlete[]>([]);
	const [teamToInsert, setTeamToInsert] = useState<Team>();
	const [associationFilter, setAssociationFilter] = useState<Association>();

	const [athletes, setAthletes] = useState<Athlete[]>();
	const [teams, setTeams] = useState<Team[]>();

	useEffect(() => {
		const fetchLevels = async () => {
			const levelsToAdd: CompetitionLevel[] = [];
			const promiseLevels = getUnique(props.competitions, "levelId").map(async (c) => {
				if (!c.levelId)
					return

				const res = await get.competitionLevel(eventContext.event.ownerId, c.levelId);
				return handleResponse(res, auth, alert, navigate,
					"Errore durante il caricamento dei livelli",
					undefined, data => levelsToAdd.push(data))
			})
			await Promise.all(promiseLevels);
			setLevels(levelsToAdd);
		}

		if (!auth.user.eventOrganizers.some(eo => eo.id === eventContext.event.ownerId))
			fetchLevels();
		else
			setLevels(configurationsContext.competitionLevels.value
				.filter(l => l.ownerId === eventContext.event.ownerId && props.competitions.map(c => c.levelId).includes(l.id)));
	}, [, configurationsContext, props.competitions])
	useEffect(() => {

		if (props.competitions.length === 1) {
			setFilterLevel(props.competitions[0].levelId ?? 0);
			setFilterCategory(props.competitions[0].category?.name ?? "");
			setShowFilters(false);
			setCompetitionSelected(props.competitions[0]);
		}

	}, [props.competitions])

	useEffect(() => {
		const getAthletes = async () => {
			const promiseAth = eventContext.associations.map(async (association) => {
				const res = await get.athletesByAssociation(association.id, ["Owner"]);
				return handleResponse(res, auth, alert, navigate,
					"Errore durante il caricamento degli atleti",
					undefined,
					(data) => { return data })
			});

			const promiseTeam = eventContext.associations.map(async (association) => {
				const res = await get.teamsByAssociation(association.id, ["Owner"]);
				return handleResponse(res, auth, alert, navigate,
					"Errore durante il caricamento delle squadre",
					undefined,
					(data) => { return data })
			});


			const _athletes: Athlete[][] = await Promise.all(promiseAth);
			const _teams: Team[][] = await Promise.all(promiseTeam);

			setAthletes(_athletes.flat());
			setTeams(_teams.flat());
		};

		getAthletes();
	}, [competitionSelected]);

	const categories = props.competitions.map(c => c.category).filter((c, index, array) => !array.map(a => a?.name).includes(c?.name!, index + 1));

	const close = () => {
		if (props.competitions.length !== 1)
			setCompetitionSelected(undefined);
		setTeamToInsert(undefined);
		setAthletesToInsert([]);
		props.show[1](false);
	}

	const closeFilter = () => {
		setShowFilters(true);
		setTeamToInsert(undefined);
		setCompetitionSelected(undefined);
		setAssociationFilter(undefined);
		setAthletesToInsert([]);
	}

	const closeCompetition = () => {
		setTeamToInsert(undefined);
		if (props.competitions.length === 1)
			return
		setCompetitionSelected(undefined);
		setAssociationFilter(undefined);
		setAthletesToInsert([]);
	}

	const showCompetitions = () => {
		setShowFilters(false);
		setAssociationFilter(undefined);
	}

	const addAthletes = async () => {
		if (AthletesToInsert.length === 0) {
			alert.show("Nessun atleta selezionato", MESSAGE_TYPE.INFO, 2000, true, true);
			return;
		}

		if (!competitionSelected)
			return

		if (competitionSelected.type !== COMP_TYPE.Individual && !teamToInsert) {
			alert.show("Nessuna squadra selezionata", MESSAGE_TYPE.INFO, 2000, true, true);
			return;
		}

		let _athletesToInsert: Athlete[] = [];
		;

		if (competitionSelected.type === COMP_TYPE.Individual) {
			let newAthletesPromise = AthletesToInsert.map(async (athlete) => {
				const res = await put.individualCompetitionAddAthlete(competitionSelected.id, athlete.id);
				handleResponse(res, auth, alert, navigate, "Errore durante l'inserimento dell'atleta");
				if (res.ok)
					_athletesToInsert.push(athlete);
			});

			await Promise.all(newAthletesPromise);
		} else {
			if (!teamToInsert) {
				alert.show("Errore durante l'iscrizione della squadra", MESSAGE_TYPE.ERROR, 3000, true, true);
				return;
			}
			const res = await put.rosterAddAthlete(competitionSelected.id, teamToInsert.id, AthletesToInsert.map(({ id }) => id));
			handleResponse(res, auth, alert, navigate, "Errore durante l'iscrizione della squadra");
			if (res.ok)
				_athletesToInsert = [...AthletesToInsert];
		}

		props.newAthletes(_athletesToInsert, competitionSelected, teamToInsert);
		alert.show("Atleti inseriti correttamente", MESSAGE_TYPE.VALID, 3000, true, true);
		closeFilter();
	};

	const addRequest = () => {
		addAthletes();
	}

	const types = props.competitions.map(c => c.type).filter((x, i, a) => a.indexOf(x) === i);
	const genders = props.competitions.map(c => c.category?.allowedGenders).flat().filter((x, i, a) => a.indexOf(x) === i);
	const hasMixedType = types.length > 1;
	const levelFilterName = levels.find(l => l.id === filterLevel)?.name ?? "";

	useEffect(() => {
		setFilterLevel(0);
	}, [filterCategory]);
	useEffect(() => {
		setFilterCategory("");
	}, [filterGender]);
	useEffect(() => {
		setFilterGender("");
	}, [filterType]);

	const competitionslevelsFilter = (cl: CompetitionLevel) => {
		return props.competitions
			.filter(c => c.category?.name === filterCategory || filterCategory === "ALL")
			.filter(c => c.levelId === cl.id)
			.filter(c => c.category?.allowedGenders?.includes(filterGender) || filterGender === "ALL")
			.filter(c => c.type === filterType || filterType === "ALL" || !hasMixedType).length > 0;
	}
	const competitionsToShow = props.competitions
		.filter(c => c.category?.name === filterCategory || filterCategory === "ALL")
		.filter(c => c.levelId === filterLevel || filterLevel === -1)
		.filter(c => c.category?.allowedGenders?.includes(filterGender) || filterGender === "ALL")
		.filter(c => c.type === filterType || filterType === "ALL" || !hasMixedType);

	const headerFiltersText = `
		Tipologia ${filterType === "ALL" ? "Tutte" : getCompTypeString(filterType) ?? getCompTypeString(types[0])}, 
		Genere ${filterGender === "ALL" ? "Tutti" : getAthleteGenderString(filterGender)}, 
		Categoria ${filterCategory === "ALL" ? "Tutte" : filterCategory}, 
		Livello ${filterLevel === -1 ? "Tutti" : levelFilterName}
		`;

	return (
		<ModalWindow show={props.show}>
			<div className='subscription-container'>
				<div className='subscription-container-header'>
					<div>ISCRIZIONE ATLETI</div>
					<button onClick={close}>
						<FontAwesomeIcon icon={faClose} />
					</button>
				</div>
				{
					showFilters ? (
						<div className='subscription-filters-container'>
							<SelectionFilter
								show={hasMixedType}
								elements={types.map(t => ({ name: getCompTypeString(t) ?? "", value: t }))}
								set={setFilterType}
								stat={{ name: getCompTypeString(filterType) ?? "", value: filterType }}
								title='Seleziona tipologia gara'
								fullDesc='TUTTE' />
							<SelectionFilter
								show={!hasMixedType || filterType !== ""}
								elements={genders.map(g => ({ name: getAthleteGenderString(g ?? "") ?? "", value: g ?? "" }))}
								set={setFilterGender}
								stat={{ name: getAthleteGenderString(filterGender) ?? "", value: filterGender }}
								title='Seleziona genere gara'
								fullDesc='TUTTE' />
							<SelectionFilter
								show={filterGender !== ""}
								elements={categories.filter(x => !x?.allowedGenders || x.allowedGenders.some(y => y === filterGender || filterGender === "ALL"))
									.map(c => ({ name: c?.name ?? "", value: c?.name ?? "" }))}
								set={setFilterCategory}
								stat={{ name: filterCategory, value: filterCategory }}
								title='Seleziona categoria gara'
								fullDesc='TUTTE' />
							<SelectionFilter
								show={filterCategory !== ""}
								elements={levels.filter(competitionslevelsFilter).map(l => ({ name: l.name, value: l.id }))}
								set={setFilterLevel}
								stat={{ name: levelFilterName, value: filterLevel }}
								title='Seleziona livello gara'
								fullDesc='TUTTI' />
							{filterLevel !== 0 &&
								<div className='subscription-filter-container-button-container'>
									<button className='subscription-filter-container-show-button' onClick={showCompetitions}>VISUALIZZA GARE</button>
								</div>
							}
						</div>
					) : (
						<div className='subscription-filters-container'>
							<SelectionHeader
								text={headerFiltersText}
								back={closeFilter} hideBack={props.competitions.length === 1} />
							{
								!competitionSelected ? (
									<div className='subscription-competitions-to-show-container'>
										{competitionsToShow.map(c => (
											<div onClick={() => setCompetitionSelected(c)} className='subscription-competition-to-show' key={c.id}>
												{c.name}
											</div>
										))}
									</div>
								) : (competitionSelected.type !== COMP_TYPE.Individual && !teamToInsert ? (
									(teams?.length ?? 0) > 0 ? <div>
										<div>Dichiarazione squadra</div>
										<div className='subscription-athletes-teams-selection'>
											<select onChange={e => setAssociationFilter(eventContext.associations.find(a => a.id === parseInt(e.target.value)))}>
												<option value={0}></option>
												{eventContext.associations.map(association =>
													<option key={association.id} value={association.id}>{association.name}</option>)}
											</select>
											{teams?.filter(t => !competitionSelected.athletes?.map(({ id }) => id).includes(t.id))
												.filter(t => !associationFilter || t.ownerId === associationFilter.id)
												.map(t => <div>
													<button onClick={() => setTeamToInsert(t)}>
														{!associationFilter && <div>{eventContext.associations.find(a => a.id === t.ownerId)?.name}</div>}
														<strong>{t.name}</strong>
													</button>
												</div>)}
										</div>

									</div> : <div className='subscription-athletes-no-team-found'>
										Nessuna squadra dichiarata, dichiarare la propria squadra in <NavLink to={'/User/Association'}>Società</NavLink>
									</div>
								) : (
									<div>
										<SelectionHeader
											text={`${competitionSelected.name}${teamToInsert ? "/" + teamToInsert.name : ""}`}
											back={closeCompetition}
											hideBack={competitionSelected.type === COMP_TYPE.Individual && props.competitions.length === 1} />
										<div className='subscription-athletes-add-selection'>
											{competitionSelected.type === COMP_TYPE.Individual && eventContext.associations.length > 1 &&
												<div className='subscription-athletes-add-association-filter'>
													<span>Filtra per società:</span>
													<select onChange={e => setAssociationFilter(eventContext.associations.find(a => a.id === parseInt(e.target.value)))}>
														<option value={0}></option>
														{eventContext.associations.map(association =>
															<option key={association.id} value={association.id}>{association.name}</option>)}
													</select>
												</div>}
											{
												eventContext.associations
													.filter(a => competitionSelected.type === COMP_TYPE.Individual || a.id === teamToInsert?.ownerId)
													.filter(a => !associationFilter || a.id === associationFilter.id)
													.map(association => (
														<div key={association.id}>
															{eventContext.associations?.length! > 1 && <strong>{association.name}</strong>}
															<div>
																{athletes?.filter(ath => ath.ownerId === association.id).athleteFilter().map(athlete => {
																	const isInThisCompetition = competitionSelected.athletes?.map(({ id }) => id).includes(athlete.id);
																	const selected = AthletesToInsert.includes(athlete) || isInThisCompetition;
																	const isInOtherCompetitions = props.competitions.some(c => c.athletes?.map(a => a.id).includes(athlete.id)) && !isInThisCompetition;

																	return (
																		<AthleteSelection key={athlete.id} athlete={athlete}
																			setAthletesToInsert={setAthletesToInsert}
																			isInOtherCompetitions={isInOtherCompetitions}
																			isInThisCompetition={isInThisCompetition || !checkCategory(athlete.birthDate, athlete.gender, competitionSelected.category)}
																			selected={selected}
																			okGen={checkCategory(athlete.birthDate, athlete.gender, competitionSelected.category, "gender")}
																			okMaxAge={checkCategory(athlete.birthDate, athlete.gender, competitionSelected.category, "max")}
																			okMinAge={checkCategory(athlete.birthDate, athlete.gender, competitionSelected.category, "min")}
																		/>
																	)
																})}
															</div>
														</div>
													))
											}
										</div>
									</div>
								)
								)
							}
							{
								competitionSelected &&
								<div className='subscription-filter-container-button-container'>
									<ConfirmCancelButtons no={closeCompetition} yes={addRequest} buttonType='button' />
								</div>}
						</div>
					)
				}
			</div>
		</ModalWindow >
	)
}

export default SubscriptionAdd