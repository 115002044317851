import { createContext } from "react";
import { AthleteClass, Event, ExecutionGroup, ExecutionTurn, ExerciseType, Jury, JuryAssignment, TeamClass } from "../models/models";

export interface TurnContextData {
	assignments: JuryAssignment[];
	executiongroups: ExecutionGroup[];
	turn: ExecutionTurn;
	event: Event;
	exerciseTypes: ExerciseType[];
	juries: Jury[];
	userJury?: Jury;
	competitors?: (AthleteClass | TeamClass)[];
}

export default createContext<TurnContextData>(null!);