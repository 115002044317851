import { useContext, useEffect, useRef, useState } from 'react';
import { COMP_TYPE, del, get, post } from '../../../models/backendReq';
import { Athlete, Competition, Exercise, ExerciseType, HTTP_STATUS_CODES, handleResponse, responseGetJson } from '../../../models/models';

import './OrganizerCompetitionAthleteSetupCom.scss';
import OrganizerCompetitionAthleteSetupExeCom from './OrganizerCompetitionAthleteSetupExeCom';

import { faArrowUpFromBracket, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import EventEoContext from '../../../contexts/EventEoContext';

interface props {
	athlete: Athlete;
	associatedExerciseTypes: ExerciseType[];
	competition: Competition;
	isIndividual: boolean;
	deleteAthlete: (athlete: Athlete, competitionId?: number) => void;
	transferAthlete: (sourceCompetition: Competition, athlete: Athlete) => void;
	showCompetition?: boolean;
	enableSelect?: boolean;
	athletesSelected?: Athlete[];
	setAthletesSelected?: React.Dispatch<React.SetStateAction<Athlete[]>>;
}

function OrganizerCompetitionAthleteSetupCom({
	athlete,
	associatedExerciseTypes,
	competition,
	isIndividual,
	deleteAthlete,
	transferAthlete,
	showCompetition,
	enableSelect,
	athletesSelected,
	setAthletesSelected
}: props) {
	const eventContext = useContext(EventEoContext);
	const competitionContext = useContext(CompetitionEoContext);
	const exercises = competitionContext.exercises.filter(exe => exe.athleteId === athlete.id);

	let [showExercises, setShowExercises] = useState(false);
	let [showContextMenu, setShowContextMenu] = useState(false);


	let ref = useRef<HTMLDivElement>(null);
	let menuRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(ref, () => setShowExercises(false));
	useOnClickOutside(menuRef, () => setShowContextMenu(false));

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let addNewExercise = async (exerciseTypeId: number, n: number) => {
		let toAdd: Exercise[] = [];
		for (let y = 0; y < n; y++) {
			let res = await post.exercise(exerciseTypeId, competition.id, athlete.id);
			await handleResponse(
				res, auth, alert, navigate,
				"Errore durante l'inserimento dell'esercizio",
				undefined,
				(data) => toAdd.push(data)
			)
		};
		eventContext.exercises.crud.add(toAdd.map(x => ({ ...x, competition })));
	};


	let deleteExercise = async (exercise?: Exercise) => {
		if (!exercise)
			return
		await del.exercise(exercise.id).then(res => {
			handleResponse(
				res, auth, alert, navigate,
				"Errore durante la cancellazione",
				undefined,
				() => eventContext.exercises.crud.delete({ ...exercise, competition })
			)
		});
	};

	let handleSelection = () => {
		if (!enableSelect || !setAthletesSelected)
			return

		if (athletesSelected?.map(({ id }) => id).includes(athlete.id))
			setAthletesSelected(p => p.filter(x => x.id !== athlete.id));
		else
			setAthletesSelected(p => [...p, athlete]);
	}

	let filteredExerciseTypes = competition.type !== COMP_TYPE.Collective ? associatedExerciseTypes : [];//.filter(x => !exercises?.map(y => y.typeId).includes(x.id));


	return (
		<div className='organizer-competition-setup-athlete-name-cont'>
			<div onClick={handleSelection} className={`organizer-competition-setup-athlete-name${enableSelect ? " checkable" : ""}`}>
				{isIndividual && (
					enableSelect ? (
						<input
							style={{ width: '40px' }}
							type='checkbox'
							checked={athletesSelected?.map(({ id }) => id).includes(athlete.id)}
							readOnly />
					) : (
						<div ref={menuRef} className='organizer-competition-setup-athlete-name-cont-button-container'>
							<button className='organizer-competition-setup-athlete-name-cont-button-menu' onClick={() => setShowContextMenu(!showContextMenu)}>
								<FontAwesomeIcon icon={faEllipsisV} />
							</button>
							<div className={`organizer-competition-setup-athlete-name-cont-menu-container${showContextMenu ? " show" : ""}`}>
								<button className='organizer-competition-setup-athlete-name-cont-button' onClick={() => {
									setShowContextMenu(false);
									transferAthlete(competition, athlete);
								}}>
									Sposta
								</button>
								<button className='organizer-competition-setup-athlete-name-cont-button' onClick={() => {
									setShowContextMenu(false);
									deleteAthlete(athlete, competition.id);
								}}>
									Cancella
								</button>
							</div>
						</div>
					)
				)}
				{showCompetition && <div>{competition.name}</div>}
				<div className='organizer-competition-setup-athlete-name-clear'>
					<strong>{athlete.code + " - "}</strong>{athlete.firstName + " " + athlete.lastName}
				</div>
				<button className='organizer-competition-setup-athlete-show-exercises' onClick={() => setShowExercises(!showExercises)}>ESERCIZI</button>
			</div>
			<div ref={ref} className={`organizer-competition-setup-athlete-exercise-container${showExercises ? ' show' : ''}`}>
				{
					filteredExerciseTypes?.sort((a, b) => a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0).map((et) => {
						return (
							<OrganizerCompetitionAthleteSetupExeCom
								key={et.id}
								exercises={exercises.filter(x => et.id === x.typeId)}
								exerciseType={et}
								deleteExercise={deleteExercise}
								addExercise={addNewExercise}
							/>
						);
					})
				}

				<button className='organizer-competition-setup-athlete-hide-exercises' onClick={() => setShowExercises(false)}>Chiudi</button>
			</div>
		</div>
	);
}

export default OrganizerCompetitionAthleteSetupCom;