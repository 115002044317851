import { createContext } from "react";
import { Athlete, Competition, Crud, Exercise, ExerciseType, Jury, Roster, Team } from "../models/models";

export interface CompetitionEoContextData {
	loading: boolean;
	competition: Competition;
	juries: { value: Jury[], crud: Crud<Jury | Jury[]> };
	associatedexercisetypes: ExerciseType[];
	rosters: Roster[];
	athletes: Athlete[];
	teams: Team[];
	exercises: Exercise[];
}

export default createContext<CompetitionEoContextData>(null!);