import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { Jury, handleResponse, initJury } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerJuriesEditRequest.scss';

interface props {
	jury?: Jury;
	editCompleted: (juries: Jury) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerEventusersEditRequest({ jury, show, editCompleted }: props) {
	const alert = useAlert();
	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome" };

	let navigate = useNavigate();
	let auth = useAuth();

	let [juriesToPut, setEventuserToPut] = useState<Jury>(jury ? jury : initJury);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (jury)
			setEventuserToPut(jury);
	}, [jury]);

	let editJury = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (juriesToPut.name === "") {
			_errors.push(inputNameError);
		}
		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await put.jury(juriesToPut.id, juriesToPut.name)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica della giuria",
					`Giuria modificata correttamente`,
					() => editCompleted(juriesToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica giuria" submit={editJury}>
			<p className='organizer-juries-edit-request-form-old-name'>{jury?.name}</p>
			<p className='organizer-juries-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-juries-edit-request-form-input'
				type='text'
				value={juriesToPut.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setEventuserToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
		</CrudForm>

	);
}

export default OrganizerEventusersEditRequest;