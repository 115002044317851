import { useLocation } from 'react-router-dom';
import TableContainer from '../../../components/Organizer/TableComponents/TableContainer';
import { associationLinks } from '../SubNavBarLinks';
import './OrganizerAssociationInfo.scss';
import { useStateLocation } from '../../../models/StateLocationProvider';
import OrganizerAssociationTable from '../../../components/Organizer/TableComponents/OrganizerAssociationTable';

function OrganizerAssociationInfo() {
	const location = useStateLocation();

	const { association, monoAssociation } = location.read();

	return (
		<div>
			<OrganizerAssociationTable text='INFO'>
				<div className='organizer-association-info-container'>
					<h3>{association.name}</h3>
				</div>
			</OrganizerAssociationTable>
		</div>
	)
}

export default OrganizerAssociationInfo