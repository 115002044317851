import { useLocation } from 'react-router-dom';
import CompetitionContext from "../../contexts/CompetitionContext";
import JuryCompetitionContext from "../../contexts/JuryCompetitionContext";
import { COMP_TYPE } from '../../models/backendReq';
import { Association, AthleteClass, Competition, Event, ExerciseType, Jury, JuryAssignment, TeamClass, initAthlete, initCompetition, initTeam } from '../../models/models';

import { useContext } from 'react';
import HeaderWithBackButton from '../../components/HeaderWithBackButton';
import JuryContext from '../../contexts/JuryContext';
import { useAuth } from '../../models/auth/AuthProvider';
import { getUnique } from '../../utility/UtilityFunctions';
import './CompetitionLayout.scss';
import CompetitionsNested from './CompetitionNested';
import { useStateLocation } from '../../models/StateLocationProvider';

interface TeamWithAthletes extends TeamClass {
	athletes: AthleteClass[];
}

interface CompetitionLayoutData {
	assignments: JuryAssignment[];
	associations: Association[];
	competition: Competition;
	event: Event;
	// exercises: Exercise[];
	exerciseTypes: ExerciseType[];
	juries: Jury[];
	userJury?: Jury;
	competitors?: AthleteClass[] | TeamWithAthletes[];
}

function CompetitionLayout() {
	const auth = useAuth();
	const location = useStateLocation();
	const competition = location.read<Competition>("competition") ?? initCompetition;

	const context = useContext(JuryContext);
	const assignments = context.assignments.filter(x => x.competitionId === competition.id);

	let competitors;
	if (competition.type === COMP_TYPE.Cumulative) {
		const teams = getUnique(context.exercises.filter(x => x.competitionId === competition.id), "teamId")
			.map(x => new TeamClass(x.roster?.team ?? initTeam));

		const _competitors = teams.map(team => {
			const _athletes = getUnique(context.exercises.filter(x => x.competitionId === competition.id && x.teamId === team.id), "athleteId")
				.map(x => x.athlete ?? initAthlete);
			const _exercises = context.exercises.filter(e => _athletes.map(m => m.id).includes(e.athleteId))
				.filter(x => x.competitionId === competition.id)
				.filter(exe =>
					context.assignments.filter(a => a.competitionId === exe.competitionId)
						.map(a => a.exerciseTypeId)
						.includes(exe.typeId) || auth.user.eventOrganizers.map(eo => eo.id).includes(context.event.ownerId)
				);

			return {
				...(new TeamClass(team)),
				athletes: _athletes.map(ath => new AthleteClass(ath, _exercises))
			}
		});
		competitors = _competitors;
	} else if (competition.type === COMP_TYPE.Individual) {
		const _athletes = getUnique(context.exercises.filter(x => x.competitionId === competition.id), "athleteId")
			.map(x => x.athlete ?? initAthlete);

		const _competitors = _athletes.map(ath => {
			const _exercises = context.exercises.filter(exe => exe.athleteId === ath.id)
				.filter(x => x.competitionId === competition.id)
				.filter(exe =>
					assignments.filter(a => a.competitionId === exe.competitionId)
						.map(a => a.exerciseTypeId)
						.includes(exe.typeId) || auth.user.eventOrganizers.map(eo => eo.id).includes(context.event.ownerId)
				);

			return new AthleteClass(ath, _exercises)
		});
		competitors = _competitors;
	} else if (competition.type === COMP_TYPE.Collective) {
		const teams = getUnique(context.exercises.filter(x => x.competitionId === competition.id), "teamId")
			.map(x => new TeamClass(x.roster?.team ?? initTeam));

		const _competitors = teams.map(team => {
			const _exercises = context.exercises.filter(e => e.teamId === team.id)
				.filter(x => x.competitionId === competition.id)
				.filter(exe =>
					assignments.filter(a => a.competitionId === exe.competitionId)
						.map(a => a.exerciseTypeId)
						.includes(exe.typeId) || auth.user.eventOrganizers.map(eo => eo.id).includes(context.event.ownerId)
				);

			return {
				...(new TeamClass(team, _exercises)),
				athletes: []
			}
		});
		competitors = _competitors;
	}

	const contextData: CompetitionLayoutData = {
		competition,
		assignments,
		associations: context.competitions.find(c => c.id === competition.id)?.associations ?? [],
		event: context.event,
		exerciseTypes: context.exercisetypes.filter(et => et.competitionId === competition.id),
		juries: context.juries,
		competitors,
		userJury: context.userJury
	};

	return (
		<div className='competition-content'>
			<div>
				<HeaderWithBackButton navigateTo={-1} header={competition.name} />
				<CompetitionContext.Provider value={contextData}>
					<JuryCompetitionContext.Provider value={{ assignments: contextData.assignments }}>
						<CompetitionsNested />
					</JuryCompetitionContext.Provider>
				</CompetitionContext.Provider>
			</div>
		</div>
	);
}

export default CompetitionLayout;