import { useEffect, useState } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { syncScores } from '../../OfflineFirst/sync';
import FullWindowLoading from '../../components/FullWindowLoading';
import { ExerciseTypeWithCompetition, ExerciseWithCompetition } from '../../contexts/EventEoContext';
import JuryContext, { CompetitionWithAssociations } from '../../contexts/JuryContext';
import { ServerStatusProvider } from '../../models/ServerStatusProvider';
import { useAuth } from '../../models/auth/AuthProvider';
import { get } from '../../models/backendReq';
import { Competition, Event, ExecutionTurn, Exercise, ExerciseType, Jury, JuryAssignment, Score, initEvent, responseGetJson } from '../../models/models';
import { changeObjInAry, deleteObjInAry } from '../../utility/UtilityFunctions';
import { ScoresHubConnection } from '../../models/hubs/ScoresHubConnection';

function JudgeLayout() {
	const auth = useAuth();

	//Get event Id from url
	const params = useParams();
	const [searchParams, _] = useSearchParams();
	const eventId = parseInt(params.eventId ?? "");
	const juryId = parseInt(searchParams.get('juryId') ?? "");

	const [loading, setLoading] = useState(true);
	const [progress, setProgress] = useState(0);
	const [competitions, setCompetitions] = useState<CompetitionWithAssociations[]>([]);
	const [executionturns, setExecutionturns] = useState<ExecutionTurn[]>([]);
	const [juries, setJuries] = useState<Jury[]>([]);
	const [exercises, setExercises] = useState<ExerciseWithCompetition[]>([]);
	const [exercisetypes, setExercisetypes] = useState<ExerciseTypeWithCompetition[]>([]);
	const [assignments, setAssignments] = useState<JuryAssignment[]>([]);
	const [event, setEvent] = useState<Event>(initEvent);

	let getExercises = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseExercises = competition
				.map(async (_competition) => await get.excercisesByCompetition(_competition.id, undefined, undefined, undefined, ["ExecutionGroup", "Athlete", "Roster"])
					.then(async (r) => {
						const exercises = await responseGetJson(r, []) as Exercise[];
						return exercises.map(e => ({ ...e, competitionId: _competition.id, competition: _competition }))
					}));
			const allExercisesFromServer = (await Promise.all(promiseExercises)).flat();
			setExercises(allExercisesFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const exercisesFromServer = await get.excercisesByCompetition(competition.id, undefined, undefined, undefined, ["ExecutionGroup", "Athlete", "Roster"])
				.then(r => responseGetJson(r, [])) as Exercise[];
			setExercises(p => [
				...p.filter(e => e.competitionId !== competition.id),
				...exercisesFromServer.map(e => ({ ...e, competitionId: competition.id, competition }))]);
		}
	}

	let getExercisetypes = async (competition: Competition | Competition[]) => {
		if (Array.isArray(competition)) {
			const promiseExerciseTypes = competition
				.map(async (_competition) => await get.excerciseTypesByCompetition(_competition.id, ["ScoreComposition"])
					.then(async (r) => (await responseGetJson(r, []) as ExerciseType[])
						.map(data => ({ ...data, competition: _competition, competitionId: _competition.id }))) as ExerciseTypeWithCompetition[]);
			const allExerciseTypesFromServer = (await Promise.all(promiseExerciseTypes)).flat();
			setExercisetypes(allExerciseTypesFromServer);
		} else {
			if (!competition)
				return getCompetitions;
			const exerciseTypesFromServer = await get.excerciseTypesByCompetition(competition.id, ["ScoreComposition"])
				.then(r => responseGetJson(r, [])) as ExerciseType[];
			setExercisetypes(p => [
				...p.filter(e => e.competition.id !== competition.id),
				...exerciseTypesFromServer.map(e => ({ ...e, competition, competitionId: competition.id }))
			]);
		}
	}

	let getAssignments = async (competition: Competition | Competition[]) => {
		const ujInUser = auth.user.juries?.find(x => x.id === juryId);
		if (ujInUser) {
			if (Array.isArray(competition)) {
				const assignmentsFromServer = ujInUser.assignments?.filter(x => competition.some(y => y.id === x.competitionId)) ?? [];
				setAssignments(assignmentsFromServer);
			} else {
				if (!competition)
					return getCompetitions;

				const assignmentsFromServer = ujInUser.assignments?.filter(x => x.competitionId === competition.id) ?? [];
				setAssignments(p => [
					...p.filter(e => e.competitionId !== competition.id),
					...assignmentsFromServer
				]);
			}
		} else {
			if (Array.isArray(competition)) {
				const promiseAssignments = competition
					.map(async (_competition) => await get.juriesAssignments(_competition.id)
						.then(async (r) => (await responseGetJson(r, []) as JuryAssignment[])));
				const allAssignmetnsFromServer = (await Promise.all(promiseAssignments)).flat();
				setAssignments(allAssignmetnsFromServer);
			} else {
				if (!competition)
					return getCompetitions;
				const assignmentsFromServer = await get.juriesAssignments(competition.id)
					.then(r => responseGetJson(r, [])) as JuryAssignment[];
				setAssignments(p => [
					...p.filter(e => e.competitionId !== competition.id),
					...assignmentsFromServer
				]);
			}
		}
	}

	let getCompetitions = async () => {
		let competitionsFromServer = await get.competitionsByEvent(eventId, ["Level"])
			.then(r => responseGetJson(r, [])) as Competition[];
		const promise = competitionsFromServer.map(async (competition) => {
			const res = await get.associationsByCompetition(competition.id);
			const associations = await responseGetJson(res, []);
			return { ...competition, associations }
		});
		const cwa = await Promise.all(promise);
		setCompetitions(cwa);
		await getExercises(competitionsFromServer).then(() => setProgress(20));
		await getExercisetypes(competitionsFromServer).then(() => setProgress(40));
		await getAssignments(competitionsFromServer).then(() => setProgress(60));
	};

	let getExecutionTurns = async () => {
		let executionTurnsFromServer: ExecutionTurn[] = await get.executionTurnsByEvent(eventId, ["Groups"])
			.then(r => responseGetJson(r, []));
		setExecutionturns(executionTurnsFromServer);
	};

	let getEvent = async () => {
		let eventFromServer: Event = await get.event(eventId)
			.then(r => responseGetJson(r));
		setEvent(eventFromServer);
	};

	let getJuries = async () => {
		let juriesFromServer = await get.juries(eventId, ["Judges"])
			.then(r => responseGetJson(r, []));
		setJuries(juriesFromServer);
	};

	const addScores = (scores: Score[]) => {
		setExercises(p => {
			const newExercises: ExerciseWithCompetition[] = [];
			scores.forEach(s => {
				const exercise = exercises.find(x => x.id === s.exerciseId);
				if (exercise) {
					newExercises.push({ ...exercise, scores: [...exercise.scores, s] });
				}
			});
			return changeObjInAry(p, newExercises)
		});
	};

	const editScores = (scores: Score[]) => {
		setExercises(p => {
			const newExercises: ExerciseWithCompetition[] = [];
			scores.forEach(s => {
				const exercise = exercises.find(x => x.id === s.exerciseId);
				if (exercise) {
					newExercises.push({ ...exercise, scores: changeObjInAry(exercise.scores, s) });
				}
			});
			return changeObjInAry(p, newExercises)
		});
	};


	const deleteScores = (scores: Score[]) => {
		setExercises(p => {
			const newExercises: ExerciseWithCompetition[] = [];
			scores.forEach(s => {
				const exercise = exercises.find(x => x.id === s.exerciseId);
				if (exercise) {
					newExercises.push({ ...exercise, scores: deleteObjInAry(exercise.scores, s) });
				}
			});
			return changeObjInAry(p, newExercises)
		});
	};

	const scoresHandler = {
		add: addScores,
		edit: editScores,
		delete: deleteScores
	}

	useEffect(() => {
		const fetchData = async () => {
			await getCompetitions().then(() => setProgress(70));
			await getExecutionTurns().then(() => setProgress(80));
			await getEvent().then(() => setProgress(90));
			await getJuries().then(() => setProgress(100));
			setLoading(false);
			handleOnline();
		}
		fetchData();
	}, []);

	const handleOnline = async () => {
		if (loading) return;

		const [scoresAdded, scoresEdited, scoresDeleted] = await syncScores(auth.user.juries?.map(j => j.id) ?? []) ?? [[], [], []];
		scoresHandler.delete(scoresDeleted);
		scoresHandler.add(scoresAdded);
		scoresHandler.edit(scoresEdited);
	};


	useEffect(() => {
		const connect = async () => !loading && await ScoresHubConnection.connect(competitions, setExercises);
		connect();
		return () => {
			const disconnect = async () => !loading && await ScoresHubConnection.disconnect(competitions);
			disconnect();
		}
	}, [competitions, loading]);


	const contextVal = {
		loading, event, progress,
		competitions,
		executionturns,
		juries,
		exercises,
		assignments,
		exercisetypes,
		scoresHandler,
		userJury: auth.user.juries?.find(x => x.id === juryId)
	};

	return (
		<ServerStatusProvider checkInterval={2000} onOnline={handleOnline}>
			<JuryContext.Provider value={contextVal}>
				<Outlet />
				<FullWindowLoading active={loading} progress={progress} />
			</JuryContext.Provider>
		</ServerStatusProvider>
	)
}

export default JudgeLayout