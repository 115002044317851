import React from 'react'
import './Table.scss'

interface props {
	children: React.ReactNode;
}

function Table(props: props) {
	return (
		<table cellSpacing={0} className='organizer-table'>
			{props.children}
		</table>
	)
}

export default Table