import { faCheck, faChevronCircleUp, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { put } from '../../models/backendReq';
import { ChartSection, Competition, HTTP_STATUS_CODES, RankingConfiguration, handleResponse } from '../../models/models';
import { changeObjInAry, slideElement } from '../../utility/UtilityFunctions';
import ModalWindow from '../ModalWindow';
import './ClassificationSetupComRanking.scss';
import { useAuth } from '../../models/auth/AuthProvider';
import { useAlert } from '../../models/AlertProvider';
import { useNavigate } from 'react-router-dom';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import EventEoContext from '../../contexts/EventEoContext';


function RankingConfigurationComponent({ chartsection, editRequest, moveup, index }: {
	chartsection: ChartSection,
	editRequest: (charsection: ChartSection, type: number, oldname?: string) => void
	index: number,
	moveup: (index: number) => void
}) {
	const [edit, setEdit] = useState(0);
	const [newCharSection, setNewCharSection] = useState(chartsection);


	const ref = useRef<HTMLFormElement>(null);
	const inputRefName = useRef<HTMLInputElement>(null);
	const inputRefSize = useRef<HTMLInputElement>(null);

	useEffect(() => {
		switch (edit) {
			case 1: inputRefName.current?.select(); break;
			case 2: inputRefSize.current?.select(); break;
		}

	}, [edit]);

	useOnClickOutside(ref, () => {
		setNewCharSection(chartsection);
		setEdit(0);
	});

	const _sizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const _value = parseInt(e.target.value);
		if (isNaN(_value) || _value === 0) {
			setNewCharSection(p => ({ ...p, [e.target.name]: undefined }));
			return;
		}
		setNewCharSection(p => ({ ...p, [e.target.name]: _value }));
	}

	const _onSubmit = (e: FormEvent) => {
		e.preventDefault();
		editRequest({ ...newCharSection, name: newCharSection.name.trim() }, 1, chartsection.name);
		setEdit(0);
	}

	const _onAbort = () => {
		setEdit(0);
		setNewCharSection(chartsection)
	}
	return (
		edit > 0 ? (
			<form ref={ref} onSubmit={_onSubmit} className='classification-com-ranking-chartsection'>
				<input
					ref={inputRefName}
					className='classification-com-ranking-chartsection-name input'
					name='name'
					value={newCharSection.name}
					onChange={(e) => setNewCharSection(p => ({ ...p, [e.target.name]: e.target.value }))}
				/>
				<input
					ref={inputRefSize}
					className='classification-com-ranking-chartsection-size input'
					name='size'
					value={newCharSection.size}
					onChange={_sizeChange}
				/>

				<button type='button' className='classification-com-ranking-chartsection-button' onClick={_onAbort}>
					<FontAwesomeIcon icon={faClose} />
				</button>
				<button className='classification-com-ranking-chartsection-button'>
					<FontAwesomeIcon icon={faCheck} />
				</button>
			</form>
		) : (
			<div className='classification-com-ranking-chartsection'>
				<button className='classification-com-ranking-chartsection-button' onClick={() => moveup(index)}>
					<FontAwesomeIcon icon={faChevronCircleUp} />
				</button>
				<span onClick={() => setEdit(1)} className='classification-com-ranking-chartsection-name'>{chartsection.name}</span>
				<span onClick={() => setEdit(2)} className='classification-com-ranking-chartsection-size'>{chartsection.size ?? 0 > 0 ? chartsection.size : "---"}</span>

				<button className='classification-com-ranking-chartsection-button' onClick={() => editRequest(chartsection, 0)}>
					<FontAwesomeIcon icon={faClose} />
				</button>
			</div>
		)
	)
}

interface props {
	competition: Competition;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function ClassificationSetupComRanking(props: props) {
	const eventContext = useContext(EventEoContext);

	const rankingConfiguration = eventContext.rankingconfigurations.value.find(rc => rc.competitionId === props.competition.id);

	const auth = useAuth()
	const alert = useAlert();
	const navigate = useNavigate();

	const [nameNewChartSection, setNameNewChartSection] = useState("");
	const [sizeNewChartSection, setSizeNewChartSection] = useState<number>();

	const _AddNew = async () => {
		if (!rankingConfiguration)
			return;

		const _rcToAdd = rankingConfiguration;
		let csToAdd: ChartSection = { name: nameNewChartSection.trim() };
		if (sizeNewChartSection && !isNaN(sizeNewChartSection) && sizeNewChartSection > 0)
			csToAdd.size = sizeNewChartSection;

		if (rankingConfiguration.chartSections.some(cs => cs.name === csToAdd.name)) {
			setNameNewChartSection("");
			setSizeNewChartSection(undefined);
			return
		}

		_rcToAdd.chartSections.push(csToAdd);

		const res = await put.rankingConfigurations(props.competition.id, _rcToAdd);
		handleResponse(res, auth, alert, navigate, "Errore durante l'inserimento del ranking", undefined,
			() => {
				eventContext.rankingconfigurations.crud.edit({ ...rankingConfiguration, chartSections: _rcToAdd.chartSections });
			})

	}

	const _editrequest = async (chartsection: ChartSection, type: number, oldname?: string) => {
		if (!rankingConfiguration)
			return;
		const _rcToUpdate = rankingConfiguration;
		switch (type) {
			case 0:
				_rcToUpdate.chartSections = _rcToUpdate.chartSections.filter(cs => cs.name !== chartsection.name);

				const resD = await put.rankingConfigurations(props.competition.id, _rcToUpdate);
				handleResponse(resD, auth, alert, navigate, "Errore durante l'eliminazione del ranking", undefined,
					() => {
						eventContext.rankingconfigurations.crud.edit({ ...rankingConfiguration, chartSections: _rcToUpdate.chartSections });
					})
				break;

			case 1:
				_rcToUpdate.chartSections = _rcToUpdate.chartSections.map(cs => cs.name !== oldname ? cs : chartsection);
				const resE = await put.rankingConfigurations(props.competition.id, _rcToUpdate);
				handleResponse(resE, auth, alert, navigate, "Errore durante la modifica del ranking", undefined,
					() => {
						eventContext.rankingconfigurations.crud.edit({ ...rankingConfiguration, chartSections: _rcToUpdate.chartSections });
					})
				break;
		}
	}

	const _sizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const _value = parseInt(e.target.value);
		if (isNaN(_value) || _value === 0) {
			setSizeNewChartSection(undefined);
			return;
		}
		setSizeNewChartSection(_value);
	}

	const _moveUp = async (index: number) => {
		if (!rankingConfiguration)
			return;

		const _rcToUpdate = rankingConfiguration;
		_rcToUpdate.chartSections = slideElement(_rcToUpdate.chartSections, index, true)

		const res = await put.rankingConfigurations(props.competition.id, _rcToUpdate);
		handleResponse(res, auth, alert, navigate, "Errore durante lo spostamento del ranking", undefined,
			() => {
				eventContext.rankingconfigurations.crud.edit({ ...rankingConfiguration, chartSections: _rcToUpdate.chartSections });
			})
	};

	return (
		<ModalWindow show={props.show} closeButton>
			<div className='classification-com-ranking-container'>
				<div className='classification-com-ranking-div'>FASCE</div>
				<div className='classification-com-ranking-add-container'>
					<input
						className='classification-com-ranking-add-input-name'
						value={nameNewChartSection}
						onChange={(e) => setNameNewChartSection(e.target.value)}
						placeholder='Nome nuova fascia' />
					<input
						className='classification-com-ranking-add-input-size'
						value={sizeNewChartSection ?? ""}
						onChange={_sizeChange}
						placeholder='Posti' />
					<button className='classification-com-ranking-add-button' onClick={_AddNew}>Aggiungi nuova</button>
				</div>
				<div className='classification-com-ranking-chartsections-container'>
					{rankingConfiguration?.chartSections.map((cs, index) =>
						<RankingConfigurationComponent key={index} editRequest={_editrequest} chartsection={cs} index={index} moveup={_moveUp} />)}
				</div>
			</div>
		</ModalWindow>
	)
}

export default ClassificationSetupComRanking