import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBug, faCheck, faCircle, faClose, faInfo, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import './AlertProvider.scss';

export enum MESSAGE_TYPE {
	NONE = 0,
	INFO = 1,
	WARNING = 2,
	ERROR = 3,
	VALID
}
interface AlertStateType {
	id: number;
	backgroundColor: string;
	canClose: boolean;
	color: string;
	icon: IconProp;
	isModal: boolean;
	text: string | string[];
	time?: number;
	timeout?: NodeJS.Timeout;
}

interface AlertContextType {
	show: (text?: string | string[], type?: MESSAGE_TYPE, time?: number, canClose?: boolean, isModal?: boolean) => void;
}

let AlertContext = React.createContext<AlertContextType>(null!);


export function AlertProvider({ children }: { children: React.ReactNode; }) {
	const [alertState, setAlertState] = useState<AlertStateType[]>([]);

	const clearState = (id: number) => {
		setAlertState(prev => prev.filter(x => x.id !== id));
		const asFind = alertState.find(x => x.id === id);

		if (asFind && asFind.timeout) clearTimer(asFind.timeout);
	}

	const setTimer = (id: number, time: number | undefined) => {
		return setTimeout(() => clearState(id), time);
	}
	const clearTimer = (timer: NodeJS.Timeout) => {
		clearTimeout(timer);
	}

	const findId = (ass: AlertStateType[], id: number) => {
		let ret = id;
		if (ass.find(as => as.id === id))
			ret = findId(ass, id + 1);

		return ret;
	}
	let show = (text?: string | string[], type?: MESSAGE_TYPE, time?: number, canClose?: boolean, isModal?: boolean) => {
		const icons: IconProp[] = [faCircle, faInfo, faWarning, faBug, faCheck];
		const colorsBg = ["#aaa", "#2A4F87", "#C73E1D", "#3B1F2B", "#428959"];
		const colors = ["#fff", "#fff", "#fff", "#fff", "#fff"]



		if (time && time > 0) {
			setAlertState(prev => {
				const newId = findId(prev, Date.now());
				const timeout = setTimer(newId, time);

				return [...prev, {
					id: newId,
					backgroundColor: colorsBg[type ?? 0],
					canClose: canClose ?? false,
					color: colors[type ?? 0],
					icon: icons[type ?? 0],
					isModal: isModal ?? false,
					text: text ?? "",
					time,
					timeout
				}]
			});

		} else {
			setAlertState(prev => {
				const newId = findId(prev, Date.now());

				return [...prev, {
					id: newId,
					backgroundColor: colorsBg[type ?? 0],
					canClose: true,
					color: colors[type ?? 0],
					icon: icons[type ?? 0],
					isModal: isModal ?? false,
					text: text ?? ""
				}]
			})
		}
	};


	let value = { show };

	const over = (as: AlertStateType) => {
		if (as.timeout) {
			clearTimer(as.timeout);
			const newArray = alertState.map(x => {
				if (x.id === as.id) {
					return { ...x, timeout: undefined }
				}
				return x
			})
			setAlertState(newArray);
		}
	}

	const leave = (as: AlertStateType) => {
		if (!as.timeout) {
			const timeout = setTimer(as.id, as.time);
			const newArray = alertState.map(x => {
				if (x.id === as.id) {
					return { ...as, timeout }
				}
				return x
			})
			setAlertState(newArray);
		}
	}

	return (
		<AlertContext.Provider value={value}>
			{
				alertState.map(as => (
					<div
						onMouseOver={() => over(as)}
						onMouseLeave={() => leave(as)}
						key={as.id}
						style={{ backgroundColor: as.backgroundColor, color: as.color }}
						className={`alert-provider-container${as.isModal ? " modal" : ""}`}
					>
						<FontAwesomeIcon icon={as.icon} />
						<div>{Array.isArray(as.text) ? (
							as.text.map(t => <div>{t}</div>)
						) : (
							as.text
						)}</div>
						{as.canClose ? (
							<button onClick={() => clearState(as.id)}>
								<FontAwesomeIcon icon={faClose} />
							</button>
						) : (null)}
					</div>
				))
			}
			{children}
		</AlertContext.Provider>
	)
}

export const useAlert = () => {
	return useContext(AlertContext);
};