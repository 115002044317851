import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import EventsEoContext from '../../../contexts/EventsEoContext';
import { useAuth } from '../../../models/auth/AuthProvider';
import { get } from '../../../models/backendReq';
import { Event, responseGetJson } from '../../../models/models';
import { crudBuilder, getUnique } from '../../../utility/UtilityFunctions';
import { useStateLocation } from '../../../models/StateLocationProvider';

function OrganizerEventsLayout() {
	const auth = useAuth();
	const location = useStateLocation();

	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState<Event[]>([]);

	const event = location.read<Event>("event");

	let getEvents = async () => {
		const getFromOrganizers = async () => {
			if (!auth.user.isOrganizer)
				return [];

			const promise = auth.user.eventOrganizers.map(async (eventorganizer) => {
				const res = await get.events(eventorganizer.id);
				return await responseGetJson(res, []) as Event[];
			})
			const events = await Promise.all(promise);
			return events.flat();
		}

		const getFromAssociations = async () => {
			if (!auth.user.isAssociation)
				return [];

			const promise = auth.user.associations.map(async (association) => {
				const res = await get.associationEvents(association.id);
				return await responseGetJson(res, []) as Event[];
			})
			const events = await Promise.all(promise);
			return events.flat();
		}

		const eventsFromServer: Event[] = [...await getFromOrganizers(), ...await getFromAssociations()];
		setEvents(getUnique(eventsFromServer));
	};

	useEffect(() => {
		const fetchData = async () => {
			if (auth.user.info)
				await getEvents();
			setLoading(false)
		}
		fetchData();
	}, [auth]);

	const contextVal = {
		loading, events: { value: events, crud: crudBuilder(setEvents, getEvents) },
		lastEvent: event,
	};

	return (
		<EventsEoContext.Provider value={contextVal}>
			<Outlet />
		</EventsEoContext.Provider>
	)
}

export default OrganizerEventsLayout