import { useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import EventEoContext from '../../../contexts/EventEoContext';
import { get } from '../../../models/backendReq';
import { Competition, initCompetition, Jury, responseGetJson } from '../../../models/models';
import { useStateLocation } from '../../../models/StateLocationProvider';
import { crudBuilder } from '../../../utility/UtilityFunctions';


function OrganizerCompetitionLayout() {
	const params = useParams();
	const eventContext = useContext(EventEoContext);

	const competitionId = parseInt(params.competitionId ?? "");

	const [competition, setCompetition] = useState(initCompetition);
	const [juries, setJuries] = useState<Jury[]>([]);
	const [loading, setLoading] = useState(true);

	let getJuries = async () => {
		let juriesFromServer = await get.juries(competitionId).then(r => responseGetJson(r, []));
		setJuries(juriesFromServer);
	};


	useEffect(() => {
		const fetchData = async () => {
			const competitionFromServer = await get.competition(competitionId).then(r => responseGetJson(r)) as Competition;
			setCompetition(competitionFromServer);
			await getJuries();
			setLoading(false);
		}

		fetchData();
	}, []);

	const associatedexercisetypes = eventContext.associatedexercisetypes.value.filter(x => x.competition.id === competition.id);
	const rosters = eventContext.rosters.value.filter(x => x.competition.id === competition.id);
	const athletes = eventContext.athletes.value.filter(x => x.competition.id === competition.id);
	const teams = eventContext.teams.value.filter(x => x.competition.id === competition.id);
	const exercises = eventContext.exercises.value.filter(x => x.competition.id === competition.id);

	const contextVal = {
		loading, competition,
		juries: { value: juries, crud: crudBuilder(setJuries, getJuries) },
		associatedexercisetypes, rosters, athletes, teams, exercises
	};

	return (
		<CompetitionEoContext.Provider value={contextVal}>
			<Outlet />
		</CompetitionEoContext.Provider>
	)
}

export default OrganizerCompetitionLayout