import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../models/AlertProvider';
import { useAuth } from '../../models/auth/AuthProvider';
import { post } from '../../models/backendReq';
import { Athlete, Competition, handleResponse } from '../../models/models';
import '../../utility/prototype';
import CrudForm from './TableComponents/CrudForm';
import './TransferAthleteWindow.scss';

interface props {
	sourceCompetition: Competition;
	competitions: Competition[];
	athletes: Athlete[];
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	transferCompleted: (sourceCompetition: Competition, targetCompetition: Competition, athletes: Athlete[]) => void;
}

function TransferAthleteWindow(props: props) {
	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const [targetCompetition, setTargetCompetition] = useState<Competition>();

	const transfer = async () => {
		if (!targetCompetition)
			return

		const athletesToTransfer = props.athletes.map(athlete => ({
			sourceCompetitionId: props.sourceCompetition.id,
			targetCompetitionId: targetCompetition.id,
			athleteId: athlete.id,
		}));
		const res = await post.individualCompetitionTransferAthlete(athletesToTransfer);
		handleResponse(res, auth, alert, navigate, "Errore durante lo spostamento dell'atleta", undefined,
			() => props.transferCompleted(props.sourceCompetition, targetCompetition, props.athletes));

		setTargetCompetition(undefined);
	}

	const filterCompetition = (c: Competition) => {
		if (auth.user.isOrganizer)
			return c.id !== props.sourceCompetition.id;

		return c.id !== props.sourceCompetition.id &&
			props.sourceCompetition.category?.allowedGenders?.every(ag => c.category?.allowedGenders?.includes(ag)) &&
			props.sourceCompetition.category.maxBirthDate === c.category?.maxBirthDate &&
			props.sourceCompetition.category.minBirthDate === c.category?.minBirthDate;
	}

	return (
		<CrudForm
			submit={transfer}
			headerText='SPOSTA ATLETA'
			back={() => props.show[1](false)}
			denied={() => props.show[1](false)}
			show={props.show}>
			<div className='transfer-athlete-container'>
				<div className='transfer-athlete-info-container'>
					<div className='transfer-athlete-info'>{`Competizione: ${props.sourceCompetition.name}`}</div>
				</div>
				<div>Seleziona competizione di destinazione</div>
				<select className='transfer-athlete-select' value={targetCompetition?.id ?? 0} onChange={e => setTargetCompetition(props.competitions.find(c => parseInt(e.target.value) === c.id))}>
					<option value={0}>...</option>
					{props.competitions.filter(filterCompetition).sortCompetitions().map(competition => {
						let className = "";
						if (competition.category?.maxBirthDate === props.sourceCompetition.category?.maxBirthDate &&
							competition.category?.minBirthDate === props.sourceCompetition.category?.minBirthDate &&
							competition.category?.allowedGenders?.every(c => props.sourceCompetition.category?.allowedGenders?.includes(c)) &&
							competition.level === props.sourceCompetition.level)
							className = 'same-category'
						return <option className={className} key={competition.id} value={competition.id}>{competition.name}</option>
					})}
				</select>
			</div>
		</CrudForm>
	)
}

export default TransferAthleteWindow