import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderWithBackButton from '../../../../components/HeaderWithBackButton';
import OrganizerEventTable from '../../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../../contexts/EventEoContext';
import { useStateLocation } from '../../../../models/StateLocationProvider';
import { compareCompetitorExercise, getUnique } from '../../../../utility/UtilityFunctions';
import '../../../../utility/prototype';
import './EventDetailsLScoreLive.scss';
import { AthleteLiveCom } from './EventDetailsScoreCompetitionLive';
import { initAthlete, initTeam } from '../../../../models/models';

function EventDetailsScoreTurnLive() {
	const eventContext = useContext(EventEoContext);
	const params = useParams();
	let location = useStateLocation();

	const turnId = parseInt(params.turnId ?? "");
	const executionTurn = eventContext.executionturns.value.find(et => et.id === turnId);

	const [fragmentsRule, setFragmentsRule] = useState(0);
	const [competitionFilter, setCompetitionFilter] = useState("");

	const exercises = eventContext.exercises.value
		.filter(exe => exe.executionGroup?.executionTurnId === turnId)
		.filter(exe => exe.competition.name === competitionFilter || !competitionFilter);
	const competitions = getUnique(eventContext.exercises.value
		.filter(exe => exe.executionGroup?.executionTurnId === turnId).map(e => e.competition));
	const exerciseTypes = getUnique(eventContext.associatedexercisetypes.value
		.filter(et => competitions.some(c => c.id === et.competitionId)));
	const [etToShow, setEtToShow] = useState(exerciseTypes.length > 0 ? exerciseTypes[0].id : 0);

	const athletesToShow = [
		...getUnique(exercises.filter(x => x.athleteId > 0), 'athleteId'),
		...getUnique(exercises.filter(x => x.teamId > 0), 'teamId')
	].map(exe => {
		if (exe.athleteId)
			return eventContext.athletes.value.find(a => a.id === exe.athleteId) ?? initAthlete;

		return eventContext.teams.value.find(a => a.id === exe.teamId) ?? initTeam;
	});

	return (
		<OrganizerEventTable text='LIVE'>
			<HeaderWithBackButton navigateTo={'..'} state={location.state} />
			<div className='eventLives-visualization-mode-selection'>
				<div>
					<span>Visualizzazione punteggio:</span>
					<select onChange={e => setFragmentsRule(parseInt(e.target.value))}>
						<option value={0}>Solo risultato</option>
						<option value={1}>Valore massimo</option>
						<option value={2}>Tutto</option>
					</select>
					<span>Visualizza solo gara:</span>
					<select onChange={e => setCompetitionFilter(e.target.value)}>
						<option value={""}>---</option>
						{competitions.map((x, index) => <option key={index} value={x.name}>{x.name}</option>)}
					</select>
				</div>
				<div>
				</div>
			</div>
			<h4>{executionTurn?.name}</h4>
			<div className='evenLives-container'>
				<div className='eventLives-table'>
					<div className='eventLives-table-row header'>
						<div className='eventLives-tableAthleteColumn header'>Atleta</div>
						{exerciseTypes.map(ets => {
							return (
								<div key={ets.id} className='eventLives-tableETColumns header'>
									{ets.displayName}
								</div>
							)
						})}
						<div className='eventLives-tableETColumns header responsive'>
							<select value={etToShow} onChange={(e) => setEtToShow(+e.target.value)}>
								{exerciseTypes.map(et => <option key={et.id} value={et.id}>{et.displayName}</option>)}
							</select>
						</div>
					</div>
					<div className='eventLives-tableBody'>
						{
							executionTurn?.groups?.map(group => {
								const _exes = exercises.filter(x => x.executionGroup?.id === group.id);
								return (
									<div className='eventLives-tableBody-group' key={group.id}>
										<div className='eventLives-tableBody-group-name'>{"Guppo: " + group.name}</div>
										{
											athletesToShow
												.filter(a => _exes.some(x => compareCompetitorExercise(a, x)))
												.athleteFilter(undefined, true).map((athlete) => {
													return (
														<AthleteLiveCom
															fragmentsRule={fragmentsRule}
															key={athlete.id}
															athlete={athlete}
															exercises={_exes.filter(x => compareCompetitorExercise(athlete, x))}
															ets={exerciseTypes}
															association={eventContext.associations.find(a => a.id === athlete.ownerId)}
															etId={etToShow} />
													);
												})
										}
									</div>

								)
							})
						}
					</div>
				</div>
			</div>
		</OrganizerEventTable>
	)
}

export default EventDetailsScoreTurnLive