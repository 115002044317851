import { useContext } from 'react';
import OrganizerExecutionTurnTable from '../../../components/Organizer/TableComponents/OrganizerExecutionTurnTable';
import ExecutionTurnEoContext from '../../../contexts/ExecutionTurnEoContext';
import './OrganizerExecutionTurnInfo.scss';

function OrganizerExecutionTurnInfo() {
	const context = useContext(ExecutionTurnEoContext);

	return (
		<OrganizerExecutionTurnTable text='TURNO'>
			<div className='organizer-execution-turn-info-container'>
				<h3>{context.executionturn.name}</h3>
			</div>
		</OrganizerExecutionTurnTable>
	)
}

export default OrganizerExecutionTurnInfo