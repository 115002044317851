import { createContext } from "react";
import { Association, AthleteClass, Competition, Event, ExerciseType, Jury, JuryAssignment, TeamClass } from "../models/models";

interface TeamWithAthletes extends TeamClass {
	athletes: AthleteClass[];
}

export interface CompetitionContextData {
	assignments: JuryAssignment[];
	associations: Association[];
	competition: Competition;
	event: Event;
	// exercises: Exercise[];
	exerciseTypes: ExerciseType[];
	juries: Jury[];
	userJury?: Jury;
	competitors?: AthleteClass[] | TeamWithAthletes[];
}

export default createContext<CompetitionContextData>(null!);