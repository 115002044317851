import { faAdd, faCheck, faClose, faGear, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del, post, put } from '../../../models/backendReq';
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';
import { ExerciseType, ScoreFragmentType, handleResponse, initExerciseType, initScoreFragmentType } from '../../../models/models';
import './OrganizerConfigurationsExerciseTypes.scss';
import { changeObjInAry } from '../../../utility/UtilityFunctions';

function ScoreFragmentTypeTd({ scorefragmenttype, prop, editScorefragmenttype, className }: {
	scorefragmenttype: ScoreFragmentType,
	prop: string,
	editScorefragmenttype: (scorefragmenttype: ScoreFragmentType) => void;
	className?: string;
}) {
	const [edit, setEdit] = useState(false);
	const [newVal, setNewVal] = useState(scorefragmenttype[prop as keyof ScoreFragmentType]);

	const abortEdit = () => {
		setNewVal(scorefragmenttype[prop as keyof ScoreFragmentType]);
		setEdit(false);
	};
	const confirmEdit = () => {
		editScorefragmenttype({ ...scorefragmenttype, [prop]: newVal });
		setEdit(false);
	};

	const inRef = useRef<HTMLInputElement>(null);
	useOnClickOutside(inRef, abortEdit);

	useEffect(() => {
		if (edit)
			inRef.current?.select();
	}, [edit]);

	const keyPressedEvent = (ev: KeyboardEvent) => {
		switch (ev.key) {
			case "Enter": confirmEdit();
				break;
			case "Escape": abortEdit();
				break;
		}
	}
	return (
		< td className={`organizer-configurations-exercisetypes-scorefragment-td${edit ? " editActive" : ""} ${className}`}>
			{!edit ?
				<div onClick={() => setEdit(true)}>{scorefragmenttype[prop as keyof ScoreFragmentType]}</div> : <input
					ref={inRef}
					value={newVal}
					onChange={e => setNewVal(e.target.value)}
					onKeyDown={keyPressedEvent}
				/>
			}
		</td>
	)
}


function ExerciseTypeComponent({ exercisetype, configurationReq }: {
	exercisetype: ExerciseType,
	configurationReq: (exercisetype: ExerciseType) => void
}) {
	const configurationsContext = useContext(OrganizerConfigurationsContext);

	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const delreq = useState(false);
	const [edit, setEdit] = useState(false);
	const [editExercisetype, setEditExercisetype] = useState(exercisetype);

	const ref = useRef<HTMLFormElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const configRef = useRef<HTMLButtonElement>(null);
	const deleteRef = useRef<HTMLButtonElement>(null);

	useOnClickOutside(ref, () => setEdit(false));

	const _edit = async (e: FormEvent) => {
		e.preventDefault();
		if (exercisetype.name === editExercisetype.name && exercisetype.displayName === editExercisetype.displayName) {
			setEdit(false)
			return;
		}
		const res = await put.exerciseType(exercisetype.id, editExercisetype.name, editExercisetype.displayName, []);
		handleResponse(res, auth, alert, navigate, "Errore durante la modifica dell'attrezzo",
			"Attrezzo modificato correttamente", () => {
				configurationsContext.exercisetypes.crud.edit(editExercisetype);
				setEdit(false);
			});
	}

	useEffect(() => {
		if (edit)
			inputRef.current?.select();
	}, [edit]);

	const _delete = async (e: FormEvent) => {
		e.preventDefault();
		const res = await del.exerciseType(exercisetype.id);
		handleResponse(res, auth, alert, navigate, "Errore durante la cancellazione dell'attrezzo",
			"Livello cancellato correttamente", () => {
				configurationsContext.exercisetypes.crud.delete(exercisetype);
			});
	}
	return (
		edit ? (
			<form ref={ref} onSubmit={_edit} className='organizer-configurations-exercisetype-container editactive'>
				<input
					ref={inputRef}
					className='organizer-configurations-exercisetype-input-name'
					placeholder='Nome'
					name='name'
					value={editExercisetype.name}
					onChange={e => setEditExercisetype(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
				<input
					className='organizer-configurations-exercisetype-input-displayname'
					placeholder='Display name'
					name='displayName'
					value={editExercisetype.displayName}
					onChange={e => setEditExercisetype(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
				<button type='button' onClick={() => setEdit(false)}>
					<FontAwesomeIcon icon={faClose} />
				</button>
				<button >
					<FontAwesomeIcon icon={faCheck} />
				</button>
			</form>
		) : (
			<>
				<div className='organizer-configurations-exercisetype-container' onClick={(e) => {
					if (!configRef.current?.contains(e.target as Node) && !deleteRef.current?.contains(e.target as Node))
						setEdit(true)
				}}>
					<span className='organizer-configurations-exercisetype-name'>{exercisetype.name}</span>
					<span className='organizer-configurations-exercisetype-displayname'>{exercisetype.displayName}</span>
					<button ref={configRef} onClick={() => configurationReq(exercisetype)}>
						<FontAwesomeIcon icon={faGear} />
					</button>
					<button ref={deleteRef} onClick={() => delreq[1](true)}>
						<FontAwesomeIcon icon={faClose} />
					</button>
				</div>
				{del && <CrudForm submit={_delete} show={delreq} back={() => delreq[1](false)} denied={() => delreq[1](false)} headerText="Cancellare l'attrezzo">
					<p>{exercisetype.name}</p>
				</CrudForm>}
			</>
		)
	)
}

function OrganizerConfigurationsExerciseTypes() {
	const configurationsContext = useContext(OrganizerConfigurationsContext);
	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const [newExercisetype, setNewExercisetype] = useState<ExerciseType>(initExerciseType);
	const [showConfiguration, setShowConfiguration] = useState(false);
	const [exercisetypeConfig, setExerciseTypeConfig] = useState<ExerciseType>();
	const [newScoreFragmentType, setNewScoreFragmentType] = useState<ScoreFragmentType>(initScoreFragmentType);
	const [fragmentToDeleteSelected, setFragmentToDeleteSelected] = useState<ScoreFragmentType>()

	const showScoreSimulation = useState(false);
	useEffect(() => {
		const findET = configurationsContext.exercisetypes.value.find(et => et.id === exercisetypeConfig?.id);

		if (findET) {
			setExerciseTypeConfig(findET);
			return;
		}

		setShowConfiguration(false);
		setExerciseTypeConfig(undefined);

	}, [configurationsContext]);

	const createNewLevel = async (e: React.FormEvent) => {
		e.preventDefault();

		if (newExercisetype.name === "")
			return

		if (newExercisetype.displayName === "")
			return

		const res = await post.exerciseType(newExercisetype.name, newExercisetype.displayName, []);
		handleResponse(res, auth, alert, navigate, "Errore durante la creazione dell'attrezzo",
			"Attrezzo creato correttamente", (newExercisetype) => {
				configurationsContext.exercisetypes.crud.add(newExercisetype);
				setNewExercisetype(initExerciseType);
			});
	}
	const configurationReq = (exercisetype: ExerciseType) => {
		setShowConfiguration(true);
		setExerciseTypeConfig(exercisetype);
	}

	const addScorefragmenttype = async () => {

		if (!exercisetypeConfig)
			return;

		const newScoreComposition = [...exercisetypeConfig?.scoreComposition ?? [], newScoreFragmentType];

		const res = await put.exerciseType(exercisetypeConfig.id, exercisetypeConfig.name, exercisetypeConfig.displayName, newScoreComposition);
		await handleResponse(res, auth, alert, navigate, "Errore durante la modifica dell'attrezzo", undefined,
			(newExerciseType) => {
				configurationsContext.exercisetypes.crud.edit(newExerciseType);
				setExerciseTypeConfig(newExerciseType);
			});

		setNewScoreFragmentType(initScoreFragmentType);
	}
	const delScorefragmenttype = async () => {
		if (!exercisetypeConfig || !fragmentToDeleteSelected)
			return;

		const newScoreComposition = [...exercisetypeConfig?.scoreComposition.filter(sc => sc.id !== fragmentToDeleteSelected.id) ?? []];

		const res = await put.exerciseType(exercisetypeConfig.id, exercisetypeConfig.name, exercisetypeConfig.displayName, newScoreComposition);
		await handleResponse(res, auth, alert, navigate, "Errore durante la modifica dell'attrezzo", undefined,
			() => {
				const newExerciseType = { ...exercisetypeConfig, scoreComposition: newScoreComposition };
				configurationsContext.exercisetypes.crud.edit(newExerciseType);
				setExerciseTypeConfig(newExerciseType);
			});

		setNewScoreFragmentType(initScoreFragmentType);
	}
	const editScorefragmenttype = async (scorefragmenttype: ScoreFragmentType) => {
		if (!exercisetypeConfig)
			return;

		const newScoreComposition = [...changeObjInAry(exercisetypeConfig.scoreComposition, scorefragmenttype)];

		const res = await put.exerciseType(exercisetypeConfig.id, exercisetypeConfig.name, exercisetypeConfig.displayName, newScoreComposition);
		await handleResponse(res, auth, alert, navigate, "Errore durante la modifica dell'attrezzo", undefined,
			() => {
				const newExerciseType = { ...exercisetypeConfig, scoreComposition: newScoreComposition };
				configurationsContext.exercisetypes.crud.edit(newExerciseType);
				setExerciseTypeConfig(newExerciseType);
			});

		setNewScoreFragmentType(initScoreFragmentType);
	}

	const valueAsNumber = (val: string | number) => {
		if (typeof (val) === "string")
			return val;
		return val !== 0 && !isNaN(val) ? val : "";
	};

	const fragments = ["label", "min", "max", "order", "baseValue", "defaultValue", "weight"];

	return (
		<div className='organizer-configurations-exercisetypes-parent'>
			<div className='organizer-configurations-exercisetypes'>
				<form onSubmit={createNewLevel} className='organizer-configurations-exercisetypes-add-new-container'>
					<input
						placeholder='Nome'
						name='name'
						value={newExercisetype.name}
						onChange={e => setNewExercisetype(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
					<input
						placeholder='Display name'
						name='displayName'
						value={newExercisetype.displayName}
						onChange={e => setNewExercisetype(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
					<button >Crea</button>
				</form>
				<div className='organizer-configurations-exercisetypes-container'>
					{configurationsContext.exercisetypes.value
						.map(exercisetype =>
							<ExerciseTypeComponent key={exercisetype.id} exercisetype={exercisetype} configurationReq={configurationReq} />)}
				</div>
			</div>
			<div className={`organizer-configurations-exercisetypes-configurations${showConfiguration ? " show" : ""}`}>
				<div className='organizer-configurations-exercisetypes-configurations-header'>
					<span className='organizer-configurations-exercisetypes-configurations-name'>{exercisetypeConfig?.name}</span>
					<button className='organizer-configurations-exercisetypes-configurations-close' onClick={() => setShowConfiguration(false)}>
						<FontAwesomeIcon icon={faClose} />
					</button>
				</div>
				<table>
					<thead>
						<tr>
							<th></th>
							{fragments.map((fr, index) => <th className={fr} key={index}>{fr}</th>)}
						</tr>
					</thead>
					<tbody>
						{exercisetypeConfig?.scoreComposition.sort((a, b) => a.order - b.order).map(sc => (
							<tr className={`organizer-configurations-exercisetypes-composition`} key={sc.id}>
								<td>
									<input
										type='radio'
										checked={sc.id === fragmentToDeleteSelected?.id}
										onClick={() => sc.id === fragmentToDeleteSelected?.id ? setFragmentToDeleteSelected(undefined) : setFragmentToDeleteSelected(sc)} />
								</td>
								{fragments.map((fr, index) =>
									<ScoreFragmentTypeTd className={fr} scorefragmenttype={sc} prop={fr} editScorefragmenttype={editScorefragmenttype} key={index} />)}
							</tr>
						))}
						<tr className='organizer-configurations-scorecomposition-table-separator' >
							<td colSpan={8}>AGGIUNGI NUOVO ELEMENTO</td>
						</tr>
						<tr>
							<td></td>
							{fragments.map((fr, index) =>
								<td key={index}>
									<input
										onKeyUp={(e) => e.key === 'Enter' && addScorefragmenttype()}
										className={fr}
										type={fr === 'label' ? 'text' : 'number'}
										value={valueAsNumber(newScoreFragmentType[fr as keyof ScoreFragmentType])}
										onChange={e => setNewScoreFragmentType(p => ({ ...p, [fr]: e.target.value }))}
									/>
								</td>)}</tr>
					</tbody>
				</table>
				<div className='organizer-configurations-exercisetypes-configurations-buttons'>
					<button onClick={addScorefragmenttype}>
						<span>Aggiungi configurazione</span>
						<FontAwesomeIcon icon={faAdd} />
					</button>
					<button onClick={delScorefragmenttype}>
						<span>Cancella selezione</span>
						<FontAwesomeIcon icon={faTrash} />
					</button>
					<button onClick={delScorefragmenttype}>
						<span>Simula voto</span>
						<FontAwesomeIcon icon={faTrash} />
					</button>
				</div>
			</div>
		</div>
	)
}

export default OrganizerConfigurationsExerciseTypes