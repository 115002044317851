import './FullWindowLoading.scss'
import LoadingSpinner from './LoadingSpinner'
import ProgressBar from '@ramonak/react-progress-bar';

interface props {
	active: boolean;
	progress?: number;
	progressText?: string;
}
function FullWindowLoading(props: props) {
	return (
		<div className={`fullWindowLoading${props.active ? " active" : ""}`}>
			<div className='fullWindowLoading-container'>
				{/* <img className='fullWindowLoading-logo' src={GalagoImg} alt="" /> */}
				<LoadingSpinner className='fullWindowLoading-spinner' />
				{props.progress !== undefined && <ProgressBar
					className='fullWindowLoading-progress'
					// completedClassName='fullWindowLoading-progress-bar'
					completed={props.progress}
					// labelColor='#fff'
					bgColor='#2F4858'
					transitionDuration='0.5s'
					isLabelVisible={props.progressText ? true : false}
					customLabel={props.progressText} />}
			</div>
		</div>
	)
}

export default FullWindowLoading