import { useLocation, useNavigate } from 'react-router-dom';
import { Athlete, Competition, Team } from '../../models/models';

import '../../utility/prototype';
import './CompetitionDetails.scss';
import { useStateLocation } from '../../models/StateLocationProvider';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

interface CompetitionWithAthletes extends Competition {
	athletes: (Athlete | Team)[];
}

interface props {
	competition: CompetitionWithAthletes;
}

function CompetitionDetails({ competition }: props) {
	const location = useStateLocation();

	const [showState, setShowState] = useState(false);

	const lastSelected = location.read<CompetitionWithAthletes>("competition")?.id === competition.id;

	return (
		<div className={'competitionDetails-container' + (lastSelected ? " ls" : "")}>
			<div className='competitionDetails-header'>
				<button className='competitionDetails-header-extend-button' onClick={() => setShowState(!showState)}>
					<FontAwesomeIcon icon={showState ? faChevronUp : faChevronDown} />
				</button>
				<div className='competitionDetails-header-name'>
					{competition.name}
				</div>
				<div className='competitionDetails-header-number'>
					<div className='competitionDetails-header-number-text'>atleti</div>
					<div className='competitionDetails-header-number-value'>{competition.athletes?.length}</div>
				</div>
				<NavLink
					className='competitionDetails-liveButton'
					to={`./Competition/${competition.id}`} state={{ ...location.state, competition }}>
					LIVE
					<FontAwesomeIcon icon={faChevronRight} />
				</NavLink>
			</div>
			{showState && <ul>{
				competition.athletes?.athleteFilter(undefined, true).map(athlete => {
					const name = ("firstName" in athlete) ? athlete.firstName + ' ' + athlete.lastName : athlete.name;
					return (
						<li key={athlete.id} className='competitionDetails-athlete'>
							<div className='competitionDetails-athlete-association'>{athlete.owner?.name}</div>
							<div className='competitionDetails-athlete-name'>{name}</div>
						</li>
					)
				})}
			</ul>}
		</div>
	)
}

export default CompetitionDetails