import { NavigateFunction } from "react-router-dom";
import { ErrorType } from "../components/Input/InputWithAlert";
import { MESSAGE_TYPE } from "./AlertProvider";
import { get } from "./backendReq";

export interface Association {
	id: number;
	name: string;
}

export interface Athlete {
	id: number;
	birthDate: string;
	ownerId: number;
	code: string;
	firstName: string;
	lastName: string;
	gender: string;
	owner?: Association;
}

export class AthleteClass implements Athlete {
	id: number;
	birthDate: string;
	ownerId: number;
	code: string;
	firstName: string;
	lastName: string;
	gender: string;
	owner?: Association;
	exercises?: Exercise[];
	groupId?: number;
	hasScore?: boolean;
	hasAllScore?: boolean;
	hasExercises?: boolean;
	competition?: Competition;

	constructor(athlete: Athlete, exercises?: Exercise[], groupId?: number, competition?: Competition) {

		this.id = athlete.id;
		this.birthDate = athlete.birthDate;
		this.ownerId = athlete.ownerId;
		this.code = athlete.code;
		this.firstName = athlete.firstName;
		this.lastName = athlete.lastName;
		this.gender = athlete.gender;
		this.owner = athlete.owner;
		this.exercises = exercises;
		this.competition = competition;
		if (exercises) {
			this.hasScore = exercises.some(exe => exe.scores.length > 0);
			this.hasAllScore = exercises.every(exe => exe.scores.length > 0);
			this.hasExercises = exercises.length > 0;
		}
		this.groupId = groupId;
	}

	public getName(selector?: string) {
		if (!selector || !/[FLC][FLC][FLC]/.test(selector.toUpperCase()))
			return `${this.firstName} ${this.lastName}`;

		const getFromChar = (char: string) => {
			switch (char.toUpperCase()) {
				case "F": return this.firstName;
				case "L": return this.lastName;
				case "C": return this.code;
			}
		};

		return `${getFromChar(selector[0])} ${getFromChar(selector[1])} ${getFromChar(selector[2])}`;
	}
}
export interface Category {
	name: string;
	maxBirthDate?: string;
	minBirthDate?: string;
	allowedGenders?: string[];
}

export interface Championship {
	id: number;
	ownerId: number;
	name: string;
	competitions?: Competition[];
}

export interface ChartSection {
	name: string;
	size?: number;
}

export interface Competition {
	id: number;
	type: string;
	eventId: number;
	level?: CompetitionLevel;
	levelId?: number;
	category?: Category;
	name: string;
	exerciseTypes?: ExerciseType[];
	exercises?: Exercise[];
	exerciseTypeIds?: number[];
}

export interface CompetitionLevel {
	id: number;
	name: string;
	description?: string;
	ownerId: number;
}

export interface Event {
	id: number;
	ownerId: number;
	name: string;
	startDate: string;
	endDate: string;
	subscriptionStartDate?: string;
	subscriptionEndDate: string;
}

export interface EventOrganizer {
	id: number;
	name: string;
}

export interface CreateEventUser {
	id: number;
	accessCode: string;
	displayName: string;
}

export interface ExecutionGroup {
	id: number;
	executionTurnId: number;
	name: string;
	exercises?: Exercise[];
}

export interface ExecutionTurn {
	id: number;
	eventId: number;
	start: string;
	end: string;
	name: string;
	groups?: ExecutionGroup[];
}

export interface Exercise {
	id: number;
	athleteId: number;
	teamId: number;
	typeId: number;
	competitionId: number;
	scores: Score[];
	executionGroup?: ExecutionGroup;
	athlete?: Athlete;
	roster?: Roster;
	floorMusicMetadataId?: number;
}

export interface ExerciseType {
	id: number;
	name: string;
	displayName: string;
	scoreComposition: ScoreFragmentType[];
}

export interface FloorMusicMetadata {
	id: number;
	fileId?: string;
	associationId: number;
	eventId: number;
	association: Association;
	event: Event;
	fileName?: string;
	mimeType?: string;
}

export interface JuryAssignment {
	id: number;
	juryId: number;
	competitionId: number;
	exerciseTypeId: number;
	assignedScoreFragmentTypes: string[];
}

export interface Jury {
	id: number;
	eventId: number;
	name: string;
	assignments?: JuryAssignment[];
	judges?: User[];
}

export interface Organizer {
	id: number;
	userName: string;
	displayName: string;
}

export interface RankingConfiguration {
	chartSections: ChartSection[];
}

export interface Roster {
	teamId: number;
	team: Team;
	competitionId: number;
	competition: Competition;
	athletes: Athlete[];
}

export interface Score {
	id: number;
	juryId: number;
	exerciseId: number;
	scoring: Record<string, number>;
}

export interface ScoreFragmentType {
	id: number;
	label: string;
	max: number;
	min: number;
	order: number;
	baseValue: number;
	defaultValue: number;
	weight: number;
}

export interface Team {
	id: number;
	ownerId: number;
	name: string;
	owner?: Association;
}

export class TeamClass implements Team {
	id: number;
	ownerId: number;
	name: string;
	owner?: Association;
	exercises?: Exercise[];
	groupId?: number;
	hasScore?: boolean;
	hasAllScore?: boolean;
	hasExercises?: boolean;
	athletes?: AthleteClass[];
	constructor(team: Team, exercises?: Exercise[], groupId?: number, athletes?: AthleteClass[]) {

		this.id = team.id;
		this.ownerId = team.ownerId;
		this.name = team.name;
		this.owner = team.owner;
		this.exercises = exercises;
		if (exercises) {
			this.hasScore = exercises.some(exe => exe.scores.length > 0);
			this.hasAllScore = exercises.every(exe => exe.scores.length > 0);
			this.hasExercises = exercises.length > 0;
		}
		this.groupId = groupId;
		this.athletes = athletes;
	}
}

export interface User {
	id: number;
	userName: string;
	displayName: string;
}

export interface Age {
	years: number;
	days: number;
}

export interface Crud<T, K extends any = undefined> {
	add: (prop: T, additionalProp?: K) => void;
	edit: (prop: T, additionalProp?: K) => void;
	delete: (prop: T, additionalProp?: K) => void;
	refresh: (property?: any) => void;
}

export const initAthlete: Athlete = { id: 0, firstName: "", birthDate: "", code: "", gender: "", lastName: "", ownerId: 0 };
export const initAssociation: Association = { id: 0, name: "" };
export const initCategory: Category = { name: "" };
export const initChampionship: Championship = { name: "", id: 0, ownerId: 0 };
export const initCompetitionLevel: CompetitionLevel = { description: "", id: 0, name: "", ownerId: 0 };
export const initCompetition: Competition = { name: "", category: initCategory, eventId: 0, id: 0, level: initCompetitionLevel, levelId: 0, type: "" };
export const initEvent: Event = { name: "", endDate: "", id: 0, ownerId: 0, startDate: "", subscriptionEndDate: "" };
export const initEventOrganizer: EventOrganizer = { id: 0, name: "" };
export const initCreateEventUser: CreateEventUser = { accessCode: "", displayName: "", id: 0 };
export const initExecutionGroup: ExecutionGroup = { executionTurnId: 0, id: 0, name: "" };
export const initExecutionTurn: ExecutionTurn = { end: "", eventId: 0, id: 0, name: "", start: "" };
export const initExercise: Exercise = { athleteId: 0, competitionId: 0, id: 0, scores: [], teamId: 0, typeId: 0 };
export const initFloorMusicMetaData: FloorMusicMetadata = { association: initAssociation, associationId: 0, event: initEvent, eventId: 0, id: 0 }
export const initOrganizer: Organizer = { displayName: "", id: 0, userName: "" };
export const initJuryAssignment: JuryAssignment = { assignedScoreFragmentTypes: [], competitionId: 0, exerciseTypeId: 0, id: 0, juryId: 0 };
export const initJury: Jury = { eventId: 0, id: 0, name: "" };
export const initScore: Score = { exerciseId: 0, id: 0, juryId: 0, scoring: {} };
export const initScoreFragmentType: ScoreFragmentType = { baseValue: 0, defaultValue: 0, id: 0, label: "", max: 0, min: 0, order: 0, weight: 0 };
export const initExerciseType: ExerciseType = { displayName: "", id: 0, name: "", scoreComposition: [] };
export const initTeam: Team = { name: "", id: 0, ownerId: 0 };
export const initRoster: Roster = { athletes: [], competition: initCompetition, competitionId: 0, team: initTeam, teamId: 0 };
export const initUser: User = { displayName: "", id: 0, userName: "" };
export const initChartSection: ChartSection = { name: "" };
export const initRankingConfiguration: RankingConfiguration = { chartSections: [] };

// ENUMERATOR
export enum HTTP_STATUS_CODES {
	OK = 200,
	NO_CONTENT = 204,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	NOT_FOUND = 404
}

export const round = (n: number, dp: number) => {
	const h = +('1'.padEnd(dp + 1, '0'));
	const _round = Math.round(n * h) / h;
	return parseFloat(_round.toFixed(3));
}

let privateSum = (score: Score, scoresComposition: ScoreFragmentType[]) => {
	let weights = Object.assign({}, ...scoresComposition.map((x) => ({ [x.label]: x.weight })));
	let bases = Object.assign({}, ...scoresComposition.map((x) => ({ [x.label]: x.baseValue })));

	let values: any = score.scoring;

	let sum = 0;
	for (let type in values) {
		sum = sum + ((weights[type] * values[type]) + bases[type]);
	}
	return round(sum, 3);
};

export let getSum = (scores: Score[], scoresComposition?: ScoreFragmentType[]) => {
	if (scoresComposition && scores) {
		let sums: number[] = scores.map(x => privateSum(x, scoresComposition));

		let sum = sums.reduce((a, b) => a + b, 0);
		return round(sum, 3);
	}
	return 0;
};

export let getAllSum = (exercises: Exercise[], exerciseTypes: ExerciseType[], rule: number, maxNum?: number) => {
	const exToMap = exercises.filter((ex, index, array) => !array.map(x => x.typeId).includes(ex.typeId, index + 1));
	let sums = exToMap.map(x => {

		let exeType = exerciseTypes.find(et => et.id === x.typeId);
		let exes = exercises.filter(x => x.typeId === exeType?.id);

		return getMultipleSum(exes, rule, exeType?.scoreComposition);
	}).sort((a, b) => b - a);

	if (maxNum)
		sums = sums.slice(0, maxNum);

	return round(sums.reduce((pv, cv) => pv + cv, 0), 3);
};

export let getTeamSum = (exercises: Exercise[], exerciseTypes: ExerciseType[], rule: number) => {

	let athSums = exerciseTypes.map((et) => {
		const etExes = exercises.filter(ex => ex.typeId === et.id);
		const exToMap = etExes.filter((ex, index, array) => !array.map(x => x.athleteId).includes(ex.athleteId, index + 1));

		let sums = exToMap.map((ex) => {
			let scoreComposition = exerciseTypes.find(x => x.id === ex.typeId)?.scoreComposition;
			let exes = etExes.filter(x => x.athleteId === ex.athleteId);

			return getMultipleSum(exes, rule, scoreComposition);
		}).sort((a, b) => b - a);

		return sums.slice(0, 3).reduce((prev, curr) => curr !== 0 ? prev + curr : prev, 0);
	});
	return round(athSums.reduce((prev, curr) => prev + curr, 0), 3);
};


export function getMultipleSum(exes: Exercise[], rule: number, scoreComposition: ScoreFragmentType[] | undefined) {
	let ret = 0;
	switch (rule) {
		case 0: //Media
			ret = exes.reduce((pv, cv) => getSum(cv.scores, scoreComposition) + pv, 0);
			ret = ret / exes.length || 0;
			break;
		case 1: //Max
			ret = Math.max(...exes.map(x => getSum(x.scores, scoreComposition)));
			break;

		case 2: //Somma
			ret = exes.reduce((pv, cv) => getSum(cv.scores, scoreComposition) + pv, 0);
			break;

		default:
			ret = 0;
			break;
	}

	return round(ret, 3);
}

export async function handleResponse(
	res: Response,
	auth?: any,
	alert?: any,
	navigate?: NavigateFunction,
	alertText?: string,
	okText?: string,
	okCallback?: (data: any) => any,
	setInputErrors?: (value: React.SetStateAction<ErrorType[]>) => void,
	nokCallback?: () => void
) {
	const textBuilder = (messages: any, text: string) => {
		let ret = text;
		if (!messages)
			return "Errore indefinito";

		if (messages.title) {
			ret += " ";
			ret += `"${messages.title}"`;
		}
		if (messages.detail) {
			ret += "\n";
			ret += messages.detail;
		}

		return ret;
	};

	let data = await responseGetJson(res);

	if (res.ok) {
		if (alert && okText) alert.show(okText, MESSAGE_TYPE.VALID, 2000, true, true);
		if (okCallback) return okCallback(data);
	} else {
		if (res.status !== HTTP_STATUS_CODES.UNAUTHORIZED) {
			const errorMessages = data;
			const keys = errorMessages?.errors ? Object.keys(errorMessages.errors) : [];
			const errorTypes: ErrorType[] = keys.map(k => { return { id: k, text: errorMessages.errors[k] }; });

			if (setInputErrors) setInputErrors(prev => [...prev, ...errorTypes]);
			if (alert && alertText !== undefined) alert.show(textBuilder(errorMessages, alertText), MESSAGE_TYPE.ERROR, 2000, true, true);
		} else {
			if (auth) auth.signout();
			if (navigate) navigate('/User/Login');
			if (alert) alert.show("Sessione utente scaduta", MESSAGE_TYPE.WARNING, 2000, true, true);
		}
		nokCallback && nokCallback();
	}
}

export const responseGetJson = async (response: Response, initValue?: any) => {
	const _return = response.headers.get('content-type')?.includes('json') && await response.json();
	return response.ok ? _return ?? initValue : initValue;
}

export const getMusicUrl = async (floorMusicMetadataId?: number) => {
	if (!floorMusicMetadataId || floorMusicMetadataId === 0) {
		return undefined;
	}
	const res = await get.getFloormusicMedia(floorMusicMetadataId);
	const blob = await res.blob();

	return URL.createObjectURL(blob);
}