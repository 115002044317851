import React from "react";
import '../utility/prototype';

export const publicMonths = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]

export let useDate = () => {
	let locale = 'it';
	let [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

	React.useEffect(() => {
		let timer = setInterval(() => { // Creates an interval which will update the current data every minute
			// This will trigger a rerender every component that uses the useDate hook.
			setDate(new Date());
		}, 60 * 1000);
		return () => {
			clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
		};
	}, []);

	let day = today.toLocaleDateString(locale, { weekday: 'long' });
	let date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

	let time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: false, minute: 'numeric' });

	return {
		date,
		time,
	};
};

export let convertDate = (inputDate?: string, includeTimes?: boolean, char?: boolean, hideDate?: boolean) => {
	let addZero = (value: number) => {
		return value < 10 ? "0" + value : value;
	};

	let retDate = '';

	let date = inputDate ? new Date(inputDate) : new Date(Date.now());
	let dd = date.getUTCDate();
	let MM = date.getUTCMonth() + 1;
	let YY = date.getUTCFullYear();
	let HH = inputDate ? date.getUTCHours() : date.getHours();
	let mm = inputDate ? date.getUTCMinutes() : date.getMinutes();

	if (!hideDate) {
		if (char)
			retDate = `${addZero(dd)} ${publicMonths[MM - 1]} ${addZero(YY)}`;
		else
			retDate = `${addZero(dd)}/${addZero(MM)}/${addZero(YY)}`;
	}
	if (includeTimes)
		retDate += ` ${addZero(HH)}:${addZero(mm)}`;

	return retDate
};

export let getAge = (dateString: string, to?: string) => {
	var today = to ? new Date(to) : new Date();
	var birthDate = new Date(dateString);
	var age = today.getUTCFullYear() - birthDate.getUTCFullYear();
	var m = today.getUTCMonth() - birthDate.getUTCMonth();
	if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
		age--;
	}
	return age;
};

export let getDay = (dateString?: string) => {
	if (dateString && dateString.isISOString())
		return Math.trunc(Date.parse(dateString) / (1000 * 3600 * 24));
	else
		return Math.trunc(Date.now() / (1000 * 3600 * 24));
}

export let getTime = (date?: string) => {
	if (date)
		return new Date(date).getTime();
	else
		return Date.now();
}

export let getDaysOfYear = (date: Date) => {
	return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000 - 1;
}