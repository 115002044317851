import moment, { Moment } from 'moment';
import { useMemo, Dispatch, SetStateAction, useCallback } from 'react';
import { Category, Event, Age } from '../../models/models';

import ReactSelect, { ActionMeta, MultiValue } from 'react-select';
import { ATHLETE_GENDER } from '../../models/backendReq';
import { getAthleteGenderString } from '../../utility/UtilityFunctions';
import '../../utility/prototype';
import { AgeInput } from "./AgeInput";
import './InputCategory.scss';
import DatePicker from './DatePicker';
import InputDate from './InputDate';
import InputWithAlert, { ErrorType } from './InputWithAlert';

interface props {
	className?: string;
	setCategory: Dispatch<SetStateAction<Category>>;
	category: Category;
	setErrors?: React.Dispatch<React.SetStateAction<ErrorType[]>>;
	nameErrorId?: string;
	dateMinErrorId?: string;
	dateMaxErrorId?: string;
	errors?: ErrorType[];
	disabled?: boolean;
}

function InputCategory({ className, setCategory, category, setErrors, errors, nameErrorId, dateMaxErrorId, dateMinErrorId, disabled }: props) {
	const minBirthDateToggled = typeof (category?.minBirthDate) === 'string';
	const maxBirthDateToggled = typeof (category?.maxBirthDate) === 'string';

	const toggleMinBirthDate = () => setCategory(prevState => ({
		...prevState,
		minBirthDate: minBirthDateToggled ? undefined : ""
	}));
	const toggleMaxBirthDate = () => setCategory(prevState => ({
		...prevState,
		maxBirthDate: maxBirthDateToggled ? undefined : ""
	}));

	const _onChangeGenders = (newValue: MultiValue<{
		value: ATHLETE_GENDER;
		label: string | undefined;
	}>, actionMeta: ActionMeta<{
		value: ATHLETE_GENDER;
		label: string | undefined;
	}>) => {
		if (!category)
			return;

		setCategory && setCategory(prevState => ({ ...prevState, allowedGenders: newValue.map(nv => nv.value) }));
	};

	const genderOptions = [
		{ value: ATHLETE_GENDER.Male, label: getAthleteGenderString(ATHLETE_GENDER.Male) },
		{ value: ATHLETE_GENDER.Female, label: getAthleteGenderString(ATHLETE_GENDER.Female) }
	];

	const actGenders = category ? category.allowedGenders?.map(ag => { return { value: ag as ATHLETE_GENDER, label: getAthleteGenderString(ag) }; }) ?? [] : [];

	return (
		<div className={'input-category-container ' + className}>
			<div className='input-category-name-gender-container'>
				<InputWithAlert
					type="text"
					value={category?.name}
					placeholder="Nome"
					name="name"
					onChange={e => setCategory(prevValue => ({ ...prevValue, name: e.target.value }))}
					errors={errors}
					setErrors={setErrors}
					errorId={nameErrorId}
					disabled={disabled}
				/>
			</div>

			<ReactSelect
				className='input-category-name-gender-select'
				placeholder='Genere'
				isMulti
				value={actGenders}
				isClearable={false}
				name='exerciseTypes'
				options={genderOptions}
				onChange={_onChangeGenders}
				isDisabled={disabled}
			/>
			<div className='input-category-dates'>
				<div className='input-category-date'>
					<label>
						<input type='checkbox' checked={minBirthDateToggled} onChange={toggleMinBirthDate} />
						Data di nascita minima
					</label>
					{minBirthDateToggled &&
						<InputWithAlert
							type='date'
							className='input-category-date-input'
							dateSelected={category.minBirthDate}
							onDateChange={(d) => setCategory(c => ({ ...category, minBirthDate: d }))}
							errors={errors}
							setErrors={setErrors}
							errorId={dateMinErrorId}
							disabled={disabled}
						/>}
				</div>
				<div className='input-category-date'	>
					<label>
						<input type='checkbox' checked={maxBirthDateToggled} onChange={toggleMaxBirthDate} />
						Data di nascita massima
					</label>
					{maxBirthDateToggled &&
						<InputWithAlert
							type='date'
							className='input-category-date-input'
							dateSelected={category.maxBirthDate}
							onDateChange={(d) => setCategory(c => ({ ...category, maxBirthDate: d }))}
							errors={errors}
							setErrors={setErrors}
							errorId={dateMaxErrorId}
							disabled={disabled}
						/>}
				</div>
			</div>
		</div>
	);
}

export default InputCategory;