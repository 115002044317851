import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { Association, HTTP_STATUS_CODES, handleResponse } from '../../../models/models';
import './OrganizerAssociationsDeleteRequest.scss';

interface props {
	association?: Association;
	deletionCompleted: (association: Association) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerAssociationsDeleteRequest({ association, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteAssociation = async (e: React.FormEvent) => {
		if (!association) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.association(association.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione della società",
					"Società cancellata",
					() => deletionCompleted(association)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE LA SOCIETA'" submit={deleteAssociation}>
			<p className='organizer-association-delete-request-form-association-name'>{association?.name}</p>
		</CrudForm>
	);
}

export default OrganizerAssociationsDeleteRequest;