import { createContext } from "react";
import { Association, Competition, Event, ExecutionTurn, Exercise, Jury, JuryAssignment, Score } from "../models/models";
import { AthleteWithCompetition, ExerciseTypeWithCompetition, TeamWithCompetition } from "./EventEoContext";

export interface CompetitionWithAssociations extends Competition {
	associations: Association[];
}

interface JuryContext {
	loading: boolean;
	progress: number;
	event: Event;
	assignments: JuryAssignment[],
	juries: Jury[],
	executionturns: ExecutionTurn[],
	competitions: CompetitionWithAssociations[],
	exercisetypes: ExerciseTypeWithCompetition[],
	exercises: Exercise[],
	scoresHandler: { add: (scores: Score[]) => void, edit: (scores: Score[]) => void, delete: (scores: Score[]) => void },
	userJury?: Jury;
}

export default createContext<JuryContext>(null!);