import React, { useEffect, useRef, useState } from 'react'
import { Athlete } from '../../models/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSquare, faSquareCheck, faWarning } from '@fortawesome/free-solid-svg-icons';
import './AthleteSelection.scss'
import { getGenderIcon } from '../../utility/UtilityFunctions';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';

interface props {
	athlete: Athlete;
	setAthletesToInsert: (value: React.SetStateAction<Athlete[]>) => void;
	selected?: boolean;
	isInThisCompetition?: boolean;
	isInOtherCompetitions?: boolean;
	okGen?: boolean;
	okMaxAge?: boolean;
	okMinAge?: boolean;
}

function AthleteSelection(props: props) {
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => setShowConfirmPopup(false));

	useEffect(() => {
		if (!showConfirmPopup)
			return

		setTimeout(() => {
			document.getElementById(`athleteSelection${props.athlete.id}`)?.scrollIntoView({ block: 'nearest' })
		}, 350);
	}, [showConfirmPopup])
	let containerClassName = "athlete-selection-header-athlete";
	if (props.selected)
		containerClassName += " selected"
	if (props.isInThisCompetition)
		containerClassName += " included"

	const _addAthlete = () => {
		props.setAthletesToInsert(current => [...current, props.athlete]);
		setShowConfirmPopup(false);
	}
	const _onClick = () => {
		if (!props.isInThisCompetition) {
			if (!props.selected)
				if (props.isInOtherCompetitions)
					setShowConfirmPopup(true);
				else
					_addAthlete()
			else
				props.setAthletesToInsert(current => current.filter(x => x.id !== props.athlete.id));
		}
	}
	return (
		<div className={containerClassName}>
			<div onClick={_onClick} className={`athlete-selection-header-athlete-info`}>
				<FontAwesomeIcon className='athlete-selection-header-athlete-icon' icon={!props.selected ? faSquare : faSquareCheck} />
				{/* <span className='athlete-selection-header-athlete-code'>{props.athlete.code}</span> */}
				<span className='athlete-selection-header-athlete-name'>{props.athlete.firstName + " " + props.athlete.lastName}</span>
				{props.okGen !== undefined &&
					<FontAwesomeIcon className={`athlete-selection-header-athlete-check${props.okGen ? " valid" : ""}`} icon={getGenderIcon(props.athlete.gender)} />}
				{props.okMinAge !== undefined &&
					<FontAwesomeIcon className={`athlete-selection-header-athlete-check${props.okMinAge ? " valid" : ""}`} icon={faChevronUp} />}
				{props.okMaxAge !== undefined &&
					<FontAwesomeIcon className={`athlete-selection-header-athlete-check${props.okMaxAge ? " valid" : ""}`} icon={faChevronDown} />}
				{props.isInOtherCompetitions && props.selected &&
					<FontAwesomeIcon className='athlete-selection-header-athlete-othercompetwarn' icon={faWarning} />}
			</div>
			{!props.isInThisCompetition && <div id={`athleteSelection${props.athlete.id}`} ref={ref} className={`athlete-selection-confirm-popup-container${showConfirmPopup ? " show" : ""}`}>
				<div>Atleta già presente in un'altra competizione, confermare la selezione?</div>
				<div>
					<button className='athlete-selection-confirm-popup-confirm-button' type='button' onClick={_addAthlete}>Conferma</button>
					<button className='athlete-selection-confirm-popup-cancel-button' type='button' onClick={() => setShowConfirmPopup(false)}>Annulla</button>
				</div>
			</div>}
		</div>
	)
}

export default AthleteSelection