import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AssociationCom from '../../components/Judge/AssociationExeGroupCom';
import AthleteCom from '../../components/Judge/AthleteCom';
import TeamCom from '../../components/Judge/TeamCom';
import CompetitionContext from "../../contexts/CompetitionContext";
import { Association, Athlete, AthleteClass, ExecutionGroup, Exercise, TeamClass } from '../../models/models';
import '../../utility/prototype'

import './CompetitionLayout.scss';
import { useLocalStorage } from '../../models/hooks/useLocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import SearchInput from '../../components/Input/SearchInput';
import { COMP_TYPE } from '../../models/backendReq';
import { useStateLocation } from '../../models/StateLocationProvider';

interface TeamWithAthletes extends TeamClass {
	athletes: AthleteClass[];
}

function CompetitionNested() {
	let location = useStateLocation();

	let context = useContext(CompetitionContext);

	let [association, setAssociation] = useState<Association | ExecutionGroup | undefined | string>(location.read<Association>("association"));
	let [filterShowWithScore, setFilterShowWithScore] = useLocalStorage("filterWithScoring", true);
	let [filterShowWithoutScore, setFilterShowWithoutScore] = useLocalStorage("filterWithoutScoring", true);
	let [expandFilters, setExpandFilters] = useState(false);
	let [athleteFilter, setAthleteFilter] = useState("");
	let [idToView, setIdToView] = useState(0);

	const refFilters = useRef<HTMLDivElement>(null);
	useOnClickOutside(refFilters, () => setExpandFilters(false))
	// let [competitorFilter, setCompetitorFilter] = useState("");

	useEffect(() => {
		const element = document.getElementById('judgeAssociationContainer');
		if (element) {
			element!.style.overflowY = 'hidden';
			let height = 190;
			if (context.associations?.length > 0)
				height = context.associations?.length * 45 + 135;

			if (association)
				height = 45;
			element!.style.height = `${height}px`;

			setTimeout(() => {
				if (association)
					element!.style.overflowY = 'hidden';
				else
					element!.style.overflowY = 'auto';
			}, 700)
		}
	})

	useEffect(() => {
		if (idToView !== 0)
			document.getElementById(`judgeAthlete${idToView}`)?.scrollIntoView({ block: 'center' });
	}, [idToView])

	const filterCompetitors = (competitor: AthleteClass | TeamWithAthletes, index: number, array: Athlete[] | TeamWithAthletes[]) => {
		// const exercises: Exercise[] = context.exercises;
		let retVal;
		if (context.competition.type === COMP_TYPE.Individual) {
			if (typeof (association) === "string")
				retVal = ((competitor as AthleteClass).exercises?.length ?? 1) > 0;
			else
				retVal = ((competitor as AthleteClass).exercises?.length ?? 1) > 0 && competitor.ownerId === association?.id;

			if (!filterShowWithScore)
				retVal = retVal && !(competitor as AthleteClass).hasAllScore;
			if (!filterShowWithoutScore)
				retVal = retVal && (competitor as AthleteClass).hasAllScore;

		} else if (context.competition.type === COMP_TYPE.Cumulative) {
			const _exercises = (competitor as TeamWithAthletes).athletes.map(x => x.exercises ?? []).flat();
			if (typeof (association) === "string")
				retVal = _exercises.length > 0;
			else
				retVal = _exercises.length > 0 && competitor.ownerId === association?.id;
		} else if (context.competition.type === COMP_TYPE.Collective) {
			const _exercises = (competitor as TeamWithAthletes).exercises ?? [];
			if (typeof (association) === "string")
				retVal = _exercises.length > 0;
			else
				retVal = _exercises.length > 0 && competitor.ownerId === association?.id;
		}


		return retVal
	}
	let competitors: AthleteClass[] | TeamWithAthletes[] = context.competitors ?? [];

	if (context.competitors) {
		if (context.competition.type === COMP_TYPE.Individual) {
			competitors = (context.competitors as AthleteClass[])?.filter(filterCompetitors).athleteFilter(athleteFilter);
		} else {
			competitors = (context.competitors as TeamWithAthletes[])?.filter(filterCompetitors);
		}
	}

	const associationName = typeof (association) === "string" ? "Società" : association?.name;

	return (
		<div className='company-table'>
			<div id='judgeAssociationContainer' className={`judge-associations-selection-container${association ? " selected" : ""}`}>
				{association ? (
					<div className='judge-association-selected' onClick={() => setAssociation(undefined)}>
						{associationName}
					</div>
				) : (
					<>
						<h3 style={{ flex: 1 }}>Seleziona una società</h3>
						{context.associations?.length > 0 ? (context.associations.associationFilter().map((association) => {
							return (
								<AssociationCom key={association.id} association={association} setAssociation={setAssociation} />
							);
						})) : (
							<p>Non ci sono società iscritte a questa gara</p>
						)}
						<div
							onClick={() => setAssociation("ALL")}
							// to={`Societa/${association.id}`}
							className='company-container'>
							<p>Tutte</p>
						</div>
					</>
				)}
			</div>
			<div className={`judge-competitors-selection-container${association ? " selected" : ""}`}>
				<div className={`judge-competitors-selection-container-competitor`}>
					{
						competitors.map(competitor => {
							let jsxElement: JSX.Element;

							if ('firstName' in competitor) {
								jsxElement = <AthleteCom newScoreAssigned={(id) => {
									setAthleteFilter("")
									setIdToView(id);
								}} athlete={competitor} context={context} exercises={competitor.exercises ?? []} key={competitor.id}
									additionalText={association === "ALL" ? context.associations.find(x => x.id === competitor.ownerId)?.name : undefined} />
							} else {
								jsxElement = <TeamCom
									athleteFilter={athleteFilter}
									context={context}
									newScoreAssigned={() => setAthleteFilter("")}
									team={competitor as TeamWithAthletes}
									key={competitor.id}
									filterShowWithScore={filterShowWithScore}
									filterShowWithoutScore={filterShowWithoutScore}
									collective={context.competition.type === COMP_TYPE.Collective}
								/>
							}
							return jsxElement
						})
					}
				</div>
				<div ref={refFilters} className={`judge-competitors-selection-container-filters${expandFilters ? " expand" : ""}`}>
					<button onClick={() => setExpandFilters(!expandFilters)}>
						<FontAwesomeIcon icon={expandFilters ? faClose : faFilter} />
					</button>
					<label>
						<input type='checkbox' checked={filterShowWithScore} onChange={() => setFilterShowWithScore(!filterShowWithScore)} />
						Mostra già assegnati
					</label>
					<label>
						<input type='checkbox' checked={filterShowWithoutScore} onChange={() => setFilterShowWithoutScore(!filterShowWithoutScore)} />
						Mostra da assegnare
					</label>
					<SearchInput className='judge-competitors-selection-container-filters-search' value={athleteFilter} onChange={(e) => setAthleteFilter(e.target.value)} placeholder='Cerca atleta' />
				</div>
			</div>
		</div>
	);
}

export default CompetitionNested;