import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../contexts/EventEoContext';
import { downloadPDFInWorker } from '../../../models/PDF/Worker/downloadPDFInWorker';
import { put } from '../../../models/backendReq';
import { ChartSection, Competition } from '../../../models/models';
import { getUnique, stringSort } from '../../../utility/UtilityFunctions';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerRankingConfigurations.scss';
import OrganizerRankingConfigurationsWizard from './OrganizerRankingConfigurationsWizard';
import { usePDFDownload } from '../../../models/DownloadPDFProvider';

function CsComponent({ cs }: {
	cs: {
		sum: number;
		competitions: Competition[];
		name: string;
		size?: number | undefined;
	}
}) {
	const context = useContext(EventEoContext);

	const inputRef = useRef<HTMLInputElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const [showCompetitions, setShowCompetitions] = useState(false);
	const [edit, setEdit] = useState(false);
	const [newName, setNewName] = useState(cs.name);

	const editName = async (e: React.FormEvent) => {
		e.preventDefault();
		const newRankingConfigurations = context.rankingconfigurations.value.map(rc => ({
			...rc,
			chartSections: rc.chartSections.map(x => x.name === cs.name ? { ...x, name: newName } : x)
		}));

		const promise = newRankingConfigurations.map(rc =>
			put.rankingConfigurations(rc.competitionId, rc)
		);

		await Promise.all(promise);

		context.rankingconfigurations.crud.edit(newRankingConfigurations);
		setEdit(false);
	}

	useEffect(() => {
		edit && inputRef.current?.select();
	}, [edit]);

	const showCompetitionsHandler = (e: React.MouseEvent) => {
		if (!buttonRef.current?.contains(e.target as Node) && !inputRef.current?.contains(e.target as Node)) {
			setShowCompetitions(!showCompetitions)
		}
	}
	return (
		<div>
			<div onClick={showCompetitionsHandler} className='organizer-rankingconfiguration-container'>
				<button ref={buttonRef} onClick={() => setEdit(!edit)}>
					<FontAwesomeIcon icon={edit ? faClose : faEdit} />
				</button>
				<span className='organizer-rankingconfiguration-number'>{cs.sum}</span>
				{!edit ? <span className='organizer-rankingconfiguration-name'>{cs.name}</span> :
					<form onSubmit={editName}>
						<input
							className='organizer-rankingconfiguration-edit-name-input'
							ref={inputRef}
							onChange={e => setNewName(e.target.value)}
							value={newName}
						/>
					</form>}
			</div>
			{showCompetitions &&
				<ul className='organizer-rankingconfiguration-competitions-container'>
					{cs.competitions.map(competition => <li key={competition.id}>
						<NavLink to={`../Classifications/${competition.id}/Athletes`} state={{ competition, event: context.event, backReq: window.location.pathname }}>{competition.name}</NavLink>
					</li>)}
				</ul>}
		</div>
	)
}
function OrganizerRankingConfigurations() {
	const context = useContext(EventEoContext);
	const pdf = usePDFDownload();

	const showWizard = useState(false);

	const chartSections = getUnique(context.rankingconfigurations.value.map(rc => rc.chartSections).flat(), "name") as ChartSection[];
	const csWithSum = chartSections.map(cs => {
		const allRcs = context.rankingconfigurations.value.map(rc => {
			const athletesNumber = context.athletes.value.filter(a => a.competitionId === rc.competitionId).length;
			const newCharSection: { name: string, size?: number, competitionId: number }[] = [];
			let actCount = 0;
			rc.chartSections.forEach(x => {
				if (actCount < athletesNumber)
					if (x.size) {
						if (actCount + x.size > athletesNumber) {
							newCharSection.push({ ...x, size: athletesNumber - actCount, competitionId: rc.competitionId });
							actCount = athletesNumber;
						} else {
							newCharSection.push({ ...x, competitionId: rc.competitionId });
							actCount += x.size;
						}
					} else {
						newCharSection.push({ ...x, size: athletesNumber - actCount, competitionId: rc.competitionId });
						actCount = athletesNumber;
					}
			})
			return newCharSection
		})
			.flat().filter(x => x.name === cs.name);
		const competitions = context.competitions.value
			.filter(c => allRcs.some(x => x.competitionId === c.id));
		const sum = allRcs.reduce((prev, curr) => prev + (curr.size ?? 0), 0);

		return { ...cs, sum, competitions }
	}).sort((a, b) => stringSort(a.name, b.name));

	const PDFGeneration = () => {
		const pdfProps = {
			data: {
				event: context.event,
				rankingconfigurations: csWithSum
			}
		};
		const fileName = "Premiazioni evento - " + context.event.name.trim() + ".pdf";
		pdf.download("RankingConfigurations", pdfProps, fileName);
	}

	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.wizardElement, callback: () => { showWizard[1](!showWizard[0]) }, text: 'Wizard creazione' },
		{ ...toolBarElements.downloadPDFElement, callback: PDFGeneration },
	];

	return (
		<div>
			<OrganizerEventTable text='PREMIAZIONI'>
				<EoToolBar elements={_toolBarElements} />
				<div className='organizer-rankingconfigurations-container'>
					<ConditionalListComponent items={csWithSum} emptyMesssage="Non ci sono premiazioni configurate in questo evento"
						renderList={(csWithSum) => csWithSum.map((cs, index) =>
							<CsComponent key={index} cs={cs} />
						)} />
				</div>
				<OrganizerRankingConfigurationsWizard show={showWizard} />
			</OrganizerEventTable>
		</div>
	);
}

export default OrganizerRankingConfigurations;