import React from 'react'
import './TableContainer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCirclePlus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner';
import SubNavBarCom from '../SubNavBarCom';
import FullWindowLoading from '../../FullWindowLoading';
import { useStateLocation } from '../../../models/StateLocationProvider';

interface props {
	children: React.ReactNode;
	showLoading?: boolean;
	hasSection?: boolean;
	headerText?: string;
	sectionName?: string;
	sectionBackPath?: string;
	subBarLinks?: { path: string, name: string }[];
	filters?: React.ReactNode;
	progress?: number;
}

function TableContainer(props: props) {
	const navigate = useNavigate();
	const location = useStateLocation();

	return (
		<div className='organizer-table-container'>
			{
				props.hasSection && (
					<div className='organizer-section'>
						<button className='organizer-section-button' onClick={() => navigate(props.sectionBackPath ?? "", { state: location.state })}>
							<FontAwesomeIcon icon={faArrowLeft} />
						</button>
						<div className='organizer-section-name'>{props.sectionName}</div>
					</div>
				)
			}
			<div className='organizer-table-header'>
				<div className='organizer-table-header-name'>{props.headerText}</div>
			</div>
			{props.filters}
			{props.subBarLinks && <SubNavBarCom elements={props.subBarLinks} />}
			{props.showLoading ? <FullWindowLoading active={true} progress={props.progress} /> : props.children}
		</div>
	)
}

export default TableContainer