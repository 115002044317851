import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { CreateEventUser, User, initCreateEventUser, initUser, handleResponse } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerEventusersEditRequest.scss';

interface props {
	eventuser?: User;
	editCompleted: (eventuser: User) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerEventusersEditRequest({ eventuser, show, editCompleted }: props) {
	const inputNameError: ErrorType = { id: "displayName", text: "Inserire il nome" };
	const inputCodeError: ErrorType = { id: "accessCode", text: "Inserire un codice" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [eventuserToPut, setEventuserToPut] = useState<CreateEventUser>(eventuser ? { id: eventuser.id, accessCode: "", displayName: eventuser.displayName } : initCreateEventUser);
	let [enableCodeEdit, setEnableCodeEdit] = useState(false);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (eventuser)
			setEventuserToPut({ id: eventuser.id, accessCode: "", displayName: eventuser.displayName });
	}, [eventuser]);

	let editUser = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (eventuserToPut.displayName === "") {
			_errors.push(inputNameError);
		}
		if (enableCodeEdit && eventuserToPut.accessCode === "") {
			_errors.push(inputCodeError);
		}
		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		const eventUserEdited = { ...eventuser ?? initUser, ["displayName"]: eventuserToPut.displayName };
		const euRes = await put.eventUser(eventuserToPut.id, eventuserToPut.displayName);
		handleResponse(
			euRes, auth, alert, navigate,
			"Errore durante l'inserimento del giudice",
			"Giudice creato correttamente",
			() => editCompleted(eventUserEdited),
			setInputErrors
		);
		if (enableCodeEdit) {
			const pwRes = await put.eventUserPassword(eventuserToPut.id, eventuserToPut.accessCode);
			handleResponse(
				pwRes, auth, alert, navigate,
				"Errore durante la modifica del codice",
				"Codice modificato correttamente",
				() => editCompleted(eventUserEdited),
				setInputErrors
			);
		}
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica gruppo" submit={editUser}>
			<p className='organizer-eventuser-edit-request-form-old-name'>{eventuser?.displayName}</p>
			<p className='organizer-eventuser-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="displayName"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-eventuser-edit-request-form-input'
				type='text'
				value={eventuserToPut.displayName}
				name="displayName"
				onChange={(e) => {
					const { name, value } = e.target;

					setEventuserToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<label className='organizer-eventuser-edit-request-form-checkbox'>
				<input
					type='checkbox'
					checked={enableCodeEdit}
					onChange={() => { setEnableCodeEdit(!enableCodeEdit); }}
				/>
				Modifica codice di accesso
			</label>

			{
				enableCodeEdit ? (
					<>
						<p className='organizer-eventuser-edit-request-form-input-label'>Nuovo codice</p>
						<InputWithAlert
							errorId="accessCode"
							errors={inputErrors}
							className='organizer-eventuser-edit-request-form-input'
							type='password'
							value={eventuserToPut.accessCode}
							name="accessCode"
							onChange={(e) => {
								const { name, value } = e.target;

								setEventuserToPut(prevState => ({ ...prevState, [name]: value }));
							}}
							setErrors={setInputErrors}
						/>
					</>
				) : (
					<> </>
				)
			}
		</CrudForm>
	);
}

export default OrganizerEventusersEditRequest;