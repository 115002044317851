// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';

// Configurazione delle risorse
const resources = {
	en: {
		translation: translationEN
	},
	it: {
		translation: translationIT
	}
};

i18n
	.use(initReactI18next) // passare instance di i18next a react-i18next
	.init({
		resources,
		lng: 'it', // lingua di default
		fallbackLng: 'it', // lingua di fallback se la traduzione non è disponibile

		interpolation: {
			escapeValue: false // react già fa escape dei valori per prevenire XSS
		}
	});

export default i18n;
