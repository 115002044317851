import React, { useEffect, useRef, useState } from 'react'

import '../../utility/prototype'
import './InputDate.scss'
import { leadingZeros } from '../../utility/UtilityFunctions';

interface DateAndTime {
	day: string;
	month: string;
	year: string;
	hour: string;
	minute: string;
}
interface props {
	onChange?: (date: string) => void | undefined;
	className?: string;
	value?: string;
	name?: string;
	time?: boolean;
	disabled?: boolean;
}

function InputDate(props: props) {
	const initValue = {
		day: "",
		hour: "",
		minute: "",
		month: "",
		year: ""
	};

	const valToDT = (val?: string) => {
		if (!val || !val.isISOString() || val === "")
			return initValue;

		const proValue = new Date(val ?? 0)

		const retDT: DateAndTime = {
			day: leadingZeros(proValue.getUTCDate(), 2),
			month: leadingZeros((proValue.getUTCMonth() + 1), 2),
			year: proValue.getUTCFullYear().toString(),
			hour: leadingZeros(proValue.getUTCHours(), 2),
			minute: leadingZeros(proValue.getUTCMinutes(), 2)
		}
		return retDT
	};

	const [dateAndTime, setDateAndTime] = useState<DateAndTime>(initValue);
	const [isFocusOn, setIsFocusOn] = useState(false);

	useEffect(() => {
		if (!props.value)
			return
		const newValue = valToDT(props.value);
		if (newValue !== dateAndTime && !isFocusOn)
			setDateAndTime(valToDT(props.value));
	}, [props.value]);

	const limit = (i: string, min?: number, max?: number, charNum?: number) => {
		let num = parseInt(i);
		if (min && num < min)
			num = min;
		if (max && num > max)
			num = max;

		if (Number.isNaN(num)) {
			return ""
		}
		if (charNum)
			return leadingZeros(num, charNum);
		else
			return String(num);
	}

	const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value !== "" && isNaN(parseInt(e.target.value)))
			return

		setDateAndTime(prev => ({ ...prev, [e.target.name]: e.target.value }))
	}

	const _onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsFocusOn(false);
		const dayLimit = () => {
			switch (parseInt(dateAndTime.month)) {
				case 1:
				case 3:
				case 5:
				case 7:
				case 8:
				case 10:
				case 12:
					return 31

				case 4:
				case 6:
				case 9:
				case 11:
					return 30

				case 2:
					if (dateAndTime.year && parseInt(dateAndTime.year) % 4 === 0)
						return 29;
					else return 28;

				default:
					return 31
			}
		}

		switch (e.target.name) {
			case "day":
				setDateAndTime(prev => ({ ...prev, day: limit(e.target.value, 1, dayLimit(), 2) }));
				break;
			case "month":
				setDateAndTime(prev => ({ ...prev, month: limit(e.target.value, 1, 12, 2) }));
				break;
			case "year":
				setDateAndTime(prev => ({ ...prev, year: e.target.value }));
				break;
			case "hour":
				setDateAndTime(prev => ({ ...prev, hour: limit(e.target.value, 1, 12, 2) }));
				break;
			case "minute":
				setDateAndTime(prev => ({ ...prev, minute: limit(e.target.value, 1, 12, 2) }));
				break;
		}
	}
	const _onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsFocusOn(true);
	}

	const checkValid = (i: string) => {
		return i && i !== "0";
	}

	useEffect(() => {
		const date = new Date();
		if (dateAndTime.day === "" ||
			dateAndTime.month === "" ||
			dateAndTime.year === "" ||
			(props.time && (dateAndTime.hour === "" || dateAndTime.minute === ""))) {
			props.onChange && props.onChange("")
			return
		}

		if (isNaN(parseInt(dateAndTime.day)) ||
			isNaN(parseInt(dateAndTime.month)) ||
			isNaN(parseInt(dateAndTime.year)) || (props.time &&
				(isNaN(parseInt(dateAndTime.hour)) || isNaN(parseInt(dateAndTime.minute))))) {
			props.onChange && props.onChange("")
			return
		}

		if (!checkValid(dateAndTime.day) || !checkValid(dateAndTime.month) || !checkValid(dateAndTime.year))
			return

		if (props.time && (!checkValid(dateAndTime.hour) || !checkValid(dateAndTime.minute)))
			return

		date.setUTCFullYear(parseInt(dateAndTime.year));
		date.setUTCMonth(parseInt(dateAndTime.month) - 1);
		date.setUTCDate(parseInt(dateAndTime.day));
		if (props.time) {
			date.setUTCHours(parseInt(dateAndTime.hour));
			date.setUTCMinutes(parseInt(dateAndTime.minute));
		} else {
			date.setUTCHours(0);
			date.setUTCMinutes(0);
		}
		date.setUTCSeconds(0, 0)

		if (props.onChange && date) props.onChange(date.toISOString());

	}, [dateAndTime]);

	return (
		<div className={`${props.className} input-date-container`}>
			<input
				type='text'
				pattern="\d*"
				value={dateAndTime.day}
				placeholder="GG"
				name="day"
				onChange={_onChange}
				onFocus={_onFocus}
				onBlur={_onBlur}
				disabled={props.disabled} />
			<div>/</div>
			<input
				type='text'
				pattern="\d*"
				value={dateAndTime.month}
				placeholder="MM"
				name="month"
				onChange={_onChange}
				onFocus={_onFocus}
				onBlur={_onBlur}
				disabled={props.disabled} />
			<div>/</div>
			<input
				type='text'
				pattern="\d*"
				value={dateAndTime.year}
				placeholder="YYYY"
				name="year"
				onChange={_onChange}
				onFocus={_onFocus}
				onBlur={_onBlur}
				disabled={props.disabled} />
			{
				props.time &&
				<>
					<input
						type='text'
						pattern="\d*"
						value={dateAndTime.hour}
						placeholder="HH"
						name="hour"
						onChange={_onChange}
						onFocus={_onFocus}
						onBlur={_onBlur}
						disabled={props.disabled} />
					<div>:</div>
					<input
						type='text'
						pattern="\d*"
						value={dateAndTime.minute}
						placeholder="mm"
						name="minute"
						onChange={_onChange}
						onFocus={_onFocus}
						onBlur={_onBlur}
						disabled={props.disabled} />
				</>
			}
		</div>
	)
}

export default InputDate