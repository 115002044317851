import { faBirthdayCake, faCaretDown, faCaretUp, faCopy, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ConditionalListComponent from '../../../components/ConditionalListComponent';
import ModalWindow from '../../../components/ModalWindow';
import AthletesSubscribedWindow from '../../../components/Organizer/AthletesSubscribedWindow';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar, { FilterInterface } from '../../../components/Organizer/FiltersBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import ToolTip from '../../../components/ToolTip';
import EventEoContext, { EventEoContextData } from '../../../contexts/EventEoContext';
import { downloadPDFInWorker } from '../../../models/PDF/Worker/downloadPDFInWorker';
import { USER_TYPE, useAuth } from '../../../models/auth/AuthProvider';
import { COMP_TYPE } from '../../../models/backendReq';
import { convertDate, getAge, getDaysOfYear } from '../../../models/dateAndTime';
import { useSortableData } from '../../../models/hooks/useSortableData';
import { Association, Athlete, Competition, Event, ExecutionGroup, ExecutionTurn, Exercise, Team, initAssociation, initAthlete, initExecutionGroup, initTeam } from '../../../models/models';
import { copyToClipboard, getUnique, stringFilterWithPermutation } from '../../../utility/UtilityFunctions';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerEventInfo.scss';
import { usePDFDownload } from '../../../models/DownloadPDFProvider';

interface AthleteWithGroupId extends Athlete {
	groupId?: number;
	competition?: Competition;
}

interface TeamWithGroupId extends Team {
	groupId?: number;
	competition?: Competition;
}

export interface CompetitionWithAthletes extends Competition {
	athletes: (Athlete | Team)[];
}

export interface ExecutionTurnWithAthletes extends ExecutionTurn {
	athletes: (AthleteWithGroupId | TeamWithGroupId)[];
	exercises: Exercise[];
}

export interface AssociationWithAthletes extends Association {
	athletes: (Athlete | Team)[];
}

export interface CompetitionWithCompetitors extends Competition {
	competitors: (Athlete | Team)[]
}
export interface AssociationExtended extends Association {
	competitions: CompetitionWithCompetitors[];
}

export interface EventDetailsScreenData {
	athletes?: (Athlete | Team)[];
	event: Event;
	competitions?: CompetitionWithAthletes[];
	executionTurns?: ExecutionTurnWithAthletes[];
	associations?: AssociationWithAthletes[];
}

export const eventDetailsBuild = (eventContext: EventEoContextData) => {
	let result: EventDetailsScreenData = {
		event: eventContext.event
	}

	result.competitions = eventContext.competitions.value.map(competition => {
		let ret;
		if (competition.type === COMP_TYPE.Collective)
			ret = eventContext.teams.value.filter(x => x.competition.id === competition.id);
		else
			ret = eventContext.athletes.value.filter(x => x.competition.id === competition.id);
		return {
			...competition,
			athletes: ret
		}
	});

	const athletesFromExercises = eventContext.exercises.value.map(exercise => {
		let res;
		if (exercise.competition.type !== COMP_TYPE.Collective)
			res = exercise.athlete ?? initAthlete
		else
			res = exercise.roster?.team ?? initTeam
		return { ...res, groupId: exercise.executionGroup?.id, competition: result.competitions?.find(c => c.id === exercise.competitionId) }
	});

	result.executionTurns = eventContext.executionturns.value.map(turn => {
		const athletes = athletesFromExercises.filter(athlete => turn.groups?.map(g => g.id).includes(athlete.groupId ?? 0))
			.filter((a, index, array) => !array.map(x => x.id).includes(a.id, index + 1));
		const exercises = eventContext.exercises.value.filter(exe => athletes.map((athlete) => ("firstName" in athlete) ? athlete.id : athlete.id * -1)
			.includes(exe.athleteId ?? (exe.teamId * -1)) && exe.executionGroup?.executionTurnId === turn.id)
			.filter((e, index, array) => !array.map(x => x.id).includes(e.id, index + 1));

		return { ...turn, athletes: athletes, exercises: exercises }
	})

	const AthletesInEvent = result.competitions.map(x => x.athletes).flat();
	const AssociationInEvent = AthletesInEvent.map(athlete => athlete.owner ?? initAssociation)
		.filter((a, index, array) => !array.map(({ id }) => id).includes(a.id, index + 1));

	result.associations = AssociationInEvent.map(association => {
		return { ...association, athletes: AthletesInEvent.filter(athlete => athlete.ownerId === association.id) }
	})

	result.athletes = AthletesInEvent;

	return result;
}

function OrganizerEventInfo() {
	const eventContext = useContext(EventEoContext);
	const pdf = usePDFDownload();

	const loaderData = eventDetailsBuild(eventContext);
	const auth = useAuth();

	const [associationToShow, setAssociationToShow] = useState<AssociationExtended>();
	const [filterAthleteName, setFilterAthleteName] = useState("");
	const [filterAssociation, setFilterAssociation] = useState(0);
	const [filterMinAge, setFilterMinAge] = useState(0);
	const [filterMaxAge, setFilterMaxAge] = useState(0);
	const [filterHasBirthday, setFilterHasBirthDay] = useState(false);
	const showFilters = useState(false);

	const showAssociationDetails = useState(false);

	const associationDetailsRequest = (association: Association) => {
		const competitionsToUse = loaderData.competitions?.filter(competition => competition.athletes.some(a => a.ownerId === association.id)) ?? [];
		const competitionsWithCompetitors = competitionsToUse.map(competition => {
			const competitors = competition.athletes.filter(a => a.ownerId === association.id);
			return { ...competition, competitors }
		});
		setAssociationToShow({ ...association, competitions: competitionsWithCompetitors });
		showAssociationDetails[1](true);
	}

	interface TableCompetitor {
		name: string;
		birthDate: string;
		age: number;
		association: string;
		associationId: number;
		competitions: Competition[];
		groups: ExecutionGroup[];
	}

	const athletesAndTeams = [...getUnique(eventContext.athletes.value), ...getUnique(eventContext.teams.value)];
	const competitors: TableCompetitor[] = athletesAndTeams.map(athlete => {
		if ("firstName" in athlete) {
			const name = athlete.firstName + " " + athlete.lastName;
			const birthDate = athlete.birthDate;
			const association = athlete.owner?.name ?? "";
			const associationId = athlete.ownerId;
			const age = getAge(athlete.birthDate);
			const competitions: Competition[] = eventContext.athletes.value.filter(x => x.id === athlete.id)
				.map(y => y.competition);
			const groups: ExecutionGroup[] = getUnique(eventContext.exercises.value.filter(x => x.athleteId === athlete.id)
				.map(ex => ex.executionGroup ?? initExecutionGroup));

			return { name, birthDate, age, association, associationId, competitions, groups }
		} else {
			const name = athlete.name;
			const birthDate = "";
			const association = athlete.owner?.name ?? "";
			const associationId = athlete.ownerId;
			const age = 0;
			const competitions: Competition[] = eventContext.teams.value.filter(x => x.id === athlete.id)
				.map(y => y.competition);
			const groups: ExecutionGroup[] = getUnique(eventContext.exercises.value.filter(x => x.teamId === athlete.id)
				.map(ex => ex.executionGroup ?? initExecutionGroup));

			return { name, birthDate, age, association, associationId, competitions, groups }
		}

	});

	const checkBirthDay = (birthday: string) => {
		const eventStartDate = new Date(eventContext.event.startDate);
		const eventEndDate = new Date(eventContext.event.endDate);
		const athleteBirthDate = new Date(birthday);

		const esD = getDaysOfYear(eventStartDate);
		const eeD = getDaysOfYear(eventEndDate);
		const abD = getDaysOfYear(athleteBirthDate);

		if (abD >= esD && abD <= eeD)
			return abD - esD + 1;

		return 0;
	};

	const { items, requestSort, sortConfig } = useSortableData(competitors
		.filter(c => stringFilterWithPermutation(c.name, filterAthleteName))
		.filter(c => c.associationId === filterAssociation || !filterAssociation)
		.filter(c => checkBirthDay(c.birthDate) || !filterHasBirthday)
		.filter(c => (c.age >= filterMinAge || !filterMinAge) && (c.age <= filterMaxAge || !filterMaxAge)));

	const getSortDirection = (key: string) => {
		if (!sortConfig || key !== sortConfig.key)
			return { icon: faSquare, className: "" };

		return sortConfig.direction === "ascending" ? { icon: faCaretUp, className: " asc" } : { icon: faCaretDown, className: " des" };
	};

	function HeaderSortButton<T extends keyof TableCompetitor>({ property, text }: { property: T, text: string }) {
		const { icon, className } = getSortDirection(property);
		return <button className={'event-details-subscriptions-sort-button' + className} onClick={() => requestSort(property)}>
			<span>{text}</span>
			<FontAwesomeIcon icon={icon} />
		</button>
	};


	const showLinks = useState(false);


	const juryLink = `${window.location.host}/Events/${eventContext.event.id}/Jury/Login`;

	const _filters: FilterInterface[] = [
		{ name: 'Atleta', set: setFilterAthleteName, value: filterAthleteName },
		{
			name: 'Società',
			setNum: setFilterAssociation,
			value: filterAssociation,
			options: getUnique(competitors, 'associationId').map(c => ({ label: c.association, value: c.associationId })),
			type: "select"
		},
		{ name: 'Età minima', setNum: setFilterMinAge, value: filterMinAge },
		{ name: 'Età massima', setNum: setFilterMaxAge, value: filterMaxAge },
		{ name: 'Festeggia il compleanno', setBool: setFilterHasBirthDay, checked: filterHasBirthday, type: "checkbox" },
	];

	const PDFGeneration = () => {
		const pdfProps = {
			eventDetailsScreenData: loaderData,
			onlyODL: false
		};
		const fileName = "Riepilogo evento - " + eventContext.event.name.trim() + ".pdf";
		pdf.download("EventDetails", pdfProps, fileName);
	}

	const _toolBarElements: eoToolbarProp[] = [
		{
			...toolBarElements.filterElement,
			callback: () => showFilters[1](!showFilters[0]),
			notNumber: _filters.filter(f => (f.value || f.checked)).length
		},
		{ ...toolBarElements.linkElement, callback: () => showLinks[1](true), text: "Link giuria" }
	];
	if (auth.user.type === USER_TYPE.USER)
		_toolBarElements.push(
			{
				...toolBarElements.downloadPDFElement,
				callback: PDFGeneration,
			});


	return (
		<OrganizerEventTable text='INFO'>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar
				history='eventInfoFilters'
				show={showFilters}
				filters={_filters}
				found={_toolBarElements[0].notNumber! > 0 ? items.length : undefined} />
			<div>
				<AthletesSubscribedWindow show={showAssociationDetails} association={associationToShow} />
				<div className='event-details-stat-element total'>
					<div className='event-details-stat-element-value total'>{loaderData?.athletes?.length}</div>
					<div className='event-details-stat-element-label total'>Totale iscritti</div>
				</div>
				<div className='event-details-stat-container'>
					{
						loaderData?.associations?.filter(x => x.athletes.length > 0).sort((a, b) => a.name.localeCompare(b.name)).map(association => {
							return (
								<div key={association.id} className='event-details-stat-element' onClick={() => associationDetailsRequest(association)}>
									<div className='event-details-stat-element-value'>{association.athletes?.length}</div>
									<div className='event-details-stat-element-label'>{association.name}</div>
								</div>
							)
						})
					}
				</div>
				<h4>Tabella atleti iscritti</h4>
				<table className='event-details-subscriptions-table'>
					<thead>
						<tr>
							<th className='event-details-subscriptions-th'>
								<HeaderSortButton property='name' text='Nome' />
							</th>
							<th className='event-details-subscriptions-th'>
								<HeaderSortButton property='association' text='Società' />
							</th>
							<th className='event-details-subscriptions-th'>
								<HeaderSortButton property='birthDate' text='Data di nascita' />
							</th>
							<th className='event-details-subscriptions-th canHide'>
								<HeaderSortButton property='age' text='Età' />
							</th>
							<th className='event-details-subscriptions-th canHide'>Gare</th>
							<th className='event-details-subscriptions-th canHide'>Turni</th>
						</tr>
					</thead>
					<tbody>
						<ConditionalListComponent items={items} emptyMesssage="Nessun iscritto presente"
							renderList={(items) => items.map((competitor, index) => <tr key={index}>
								<td className='event-details-subscriptions-td name'>
									<span className="cell-header">Nome</span>
									{checkBirthDay(competitor.birthDate) > 0 ?
										<div className='event-details-subscriptions-td-athlete-birth-div'>
											<span>{competitor.name}</span>
											<ToolTip timeOnEnter={1000} text={"Compleanno al " + checkBirthDay(competitor.birthDate) + "° giorno dell'evento"}>
												<FontAwesomeIcon icon={faBirthdayCake} />
											</ToolTip>
										</div>
										: competitor.name}</td>
								<td className='event-details-subscriptions-td'>
									<span className="cell-header">Società</span>
									{competitor.association}</td>
								<td className='event-details-subscriptions-td center'>
									<span className="cell-header">Data di nascita</span>
									{convertDate(competitor.birthDate)}</td>
								<td className='event-details-subscriptions-td canHide center'>
									<span className="cell-header">Età</span>
									{competitor.age}</td>
								<td className='event-details-subscriptions-td canHide turn-comp'>
									{competitor.competitions.map(c => <div key={c.id}>{c.name}</div>)}
								</td>
								<td className='event-details-subscriptions-td canHide turn-comp'>
									{competitor.groups.map(g =>
										<div key={g.id}>
											{eventContext.executionturns.value.find(x => x.id === g.executionTurnId)?.name + " / " + g.name}
										</div>
									)}
								</td>
							</tr>)} />
					</tbody>
				</table>
			</div>
			<ModalWindow show={showLinks} closeButton>
				<div className='event-details-jury-link-container'>
					<NavLink to={`/Events/${eventContext.event.id}/Jury/Login`}>{juryLink}</NavLink>
					<button onClick={() => copyToClipboard(juryLink)}>
						<FontAwesomeIcon icon={faCopy} />
					</button>
				</div>'
			</ModalWindow>
		</OrganizerEventTable>
	)
}

export default OrganizerEventInfo