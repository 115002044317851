import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { Competition, Score } from "../models";
import { SetStateAction } from "react";
import { ExerciseWithCompetition } from "../../contexts/EventEoContext";
import { changeObjInAry, deleteObjInAry } from "../../utility/UtilityFunctions";

const hubConnection = new HubConnectionBuilder()
	.withUrl(process.env.REACT_APP_NOTIFICATION_EXERCISE ? process.env.REACT_APP_NOTIFICATION_EXERCISE : '')
	.withAutomaticReconnect()
	.build();

export const ScoresHubConnection = {
	connect: async (
		competitions: Competition[],
		setExercises: (value: SetStateAction<ExerciseWithCompetition[]>) => void) => {
		if (!hubConnection || competitions.length === 0)
			return;

		if (hubConnection.state === HubConnectionState.Disconnected)
			await hubConnection.start();

		if (hubConnection.state !== HubConnectionState.Connected)
			return;

		const promiseSubscriptions = competitions.map(competition =>
			hubConnection.send("SubscribeToCompetition", competition.id))
		await Promise.all(promiseSubscriptions);

		hubConnection.on("ScoreDeleted", (scoresDeleted: Score) => {
			setExercises(prev => prev.map(exercise => {
				if (scoresDeleted.exerciseId === exercise.id)
					return { ...exercise, scores: [...deleteObjInAry(exercise.scores, scoresDeleted)] };
				return exercise;
			}));
		});
		hubConnection.on("ScoreUpdated", (scoresUpdated: Score) => {
			setExercises(prev => prev.map(exercise => {
				if (scoresUpdated.exerciseId === exercise.id) {
					return { ...exercise, scores: changeObjInAry(exercise.scores, scoresUpdated) };
				}
				return exercise;
			}));
		});
		hubConnection.on("ScoreCreated", (scoresCreated: Score) => {
			setExercises(prev => prev.map(exercise => {
				if (scoresCreated.exerciseId === exercise.id)
					if (exercise.scores.find(score => scoresCreated.id === score.id))
						return { ...exercise, scores: changeObjInAry(exercise.scores, scoresCreated) };
					else
						return { ...exercise, scores: [...exercise.scores, scoresCreated] }
				return exercise;
			}));
		});
	},
	disconnect: async (competitions: Competition[]) => {
		if (!hubConnection || competitions.length === 0)
			return;

		if (hubConnection.state === HubConnectionState.Disconnected)
			await hubConnection.start();

		if (hubConnection.state !== HubConnectionState.Connected)
			return;

		const promiseUnsubscriptions = competitions.map(competition =>
			hubConnection.send("UnsubscribeFromCompetition", competition.id))
		await Promise.all(promiseUnsubscriptions);

		if (hubConnection.state === HubConnectionState.Connected)
			await hubConnection.stop();
	}
}