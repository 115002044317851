import './TableNameCell.scss';

interface props {
	isHeader?: boolean;
	text?: string;
}
function TableNameCell(props: props) {
	return (
		props.isHeader ? (
			<th className='organizer-col-name'>{props.text}</th>
		) : (
			<td className='organizer-col-name rows'>{props.text}</td>
		)
	)
}

export default TableNameCell