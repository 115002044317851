import { useContext, useState } from 'react';

import OrganaizerAthleteCom from '../../../components/Organizer/OrganaizerAthleteCom';
import OrganizerAthletesAddRequest from './OrganizerAthletesAddRequest';
import OrganizerAthletesDeleteRequest from './OrganizerAthletesDeleteRequest';
import OrganizerAthletesEditRequest from './OrganizerAthletesEditRequest';


import { Athlete } from '../../../models/models';


import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableNameCell from '../../../components/Organizer/TableComponents/TableNameCell';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import AssociationEoContext from '../../../contexts/AssociationEoContext';
import '../../../utility/prototype';
import { toolBarElements } from '../SubNavBarLinks';
import './OrganizerAthletesScreen.scss';
import OrganizerAssociationTable from '../../../components/Organizer/TableComponents/OrganizerAssociationTable';
import ConditionalListComponent from '../../../components/ConditionalListComponent';

function OrganizerAthletesScreen() {
	const context = useContext(AssociationEoContext);

	let [athletesFilterName, setAthletesFilterName] = useState("");

	let [athleteToDel, setAthleteToDel] = useState<Athlete>();
	let [athleteToPut, setAthleteToPut] = useState<Athlete>();

	let showDel = useState(false);
	let showAdd = useState(false);
	let showEdi = useState(false);
	let showFilters = useState(false);

	let deleteRequest = (athlete: Athlete) => {
		showDel[1](true);
		setAthleteToDel(athlete);
	}
	let deletionCompleted = (athlete: Athlete) => {
		context.athletes.crud.delete(athlete);
		showDel[1](false)
	}
	let addCompleted = (athlete: Athlete) => {
		context.athletes.crud.add(athlete);
		showAdd[1](false)
	}
	let editRequest = (athlete: Athlete) => {
		showEdi[1](true);
		setAthleteToPut(athlete);
	}
	let editCompleted = (athlete: Athlete) => {
		context.athletes.crud.edit(athlete);
		showEdi[1](false)
	}
	const _filters = [
		{ name: 'Atleta', set: setAthletesFilterName, value: athletesFilterName }
	];
	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi atleta' },
		{ ...toolBarElements.filterElement, callback: () => showFilters[1](!showFilters[0]), notNumber: _filters.filter(f => f.value !== "").length }
	];

	return (
		<OrganizerAssociationTable text='ATLETI'>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar show={showFilters} filters={_filters} found={_toolBarElements[1].notNumber! > 0 ? context.athletes.value.athleteFilter(athletesFilterName).length : undefined} />
			<Table>
				<thead>
					<tr>
						<TableNameCell text='Nome' isHeader />
						<th className='organizer-athlete-col-age'>Età</th>
						<th className='organizer-athlete-col-code'>Codice</th>
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={context.athletes.value.athleteFilter(athletesFilterName)}
						emptyMesssage='Nessun atleta presente in questa società'
						renderList={athletes => athletes.map(athlete =>
							<OrganaizerAthleteCom key={athlete.id} athlete={athlete} del={deleteRequest} edi={editRequest} />
						)} />
				</tbody>
			</Table>
			<OrganizerAthletesDeleteRequest athlete={athleteToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerAthletesAddRequest association={context.association} show={showAdd} addCompleted={addCompleted} />
			<OrganizerAthletesEditRequest association={context.association} athlete={athleteToPut} show={showEdi} editCompleted={editCompleted} />
		</OrganizerAssociationTable>
	);
}

export default OrganizerAthletesScreen;