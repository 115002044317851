import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { post } from '../../../models/backendReq';
import { Event, FloorMusicMetadata, handleResponse, initFloorMusicMetaData } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerFloorMusicsAddRequest.scss';
import EventEoContext from '../../../contexts/EventEoContext';

interface props {
	event: Event;
	addCompleted: (floorMusicMetadata: FloorMusicMetadata) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerFloorMusicsAddRequest({ event, show, addCompleted }: props) {
	const context = useContext(EventEoContext);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const associationError: ErrorType = { id: "associationId", text: "Selezionare una società" };
	const fileError: ErrorType = { id: "file", text: "Selezionare un brano" };

	let [musicFile, setMusicFile] = useState<File | null>();
	let [associationId, setAssociationId] = useState(context.associations.length === 1 ? context.associations[0].id : 0);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const selectFile = (files: FileList | null) => {
		if (!files || files.length < 1)
			return

		return files[0];
	}

	const clearData = () => {
		setMusicFile(null);
		if (context.associations.length !== 1)
			setAssociationId(0);
	}

	let denied = () => {
		clearData();
		show[1](false);
	}

	let addFloorMusicMetadata = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (associationId === 0) {
			_errors.push(associationError);
		}
		if (!musicFile) {
			_errors.push(fileError);
		}
		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		if (!musicFile)
			return;

		const formData = new FormData();
		formData.append('musicFile', musicFile);

		await post.floorMusic(event.id, associationId, formData)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante il caricamento del brano",
					"Brano caricato correttamente",
					(data) => {
						addCompleted(data);
						clearData();
					},
					setInputErrors
				)
			});
	};

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci utente evento' submit={addFloorMusicMetadata}>
			<p className='organizer-floorMusicMetadata-add-request-form-input-label'>Società</p>
			<InputWithAlert
				errorId="associationId"
				errors={inputErrors}
				className='organizer-floorMusicMetadata-add-request-form-input'
				type='select'
				value={associationId}
				placeholder='Società'
				name="associationId"
				onSelectChange={(e) => setAssociationId(parseInt(e.target.value))}
				setErrors={setInputErrors}
				disabled={context.associations.length === 1}
			>
				<option value={0}>---</option>
				{context.associations.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
			</InputWithAlert>
			<p className='organizer-floorMusicMetadata-add-request-form-input-label'>Brano</p>
			<InputWithAlert
				errorId="file"
				errors={inputErrors}
				className='organizer-floorMusicMetadata-add-request-form-input'
				type='file'
				placeholder='Seleziona brano'
				name="file"
				onChange={(e) => setMusicFile(selectFile(e.target.files))}
				setErrors={setInputErrors}
				accept='.mp3,.aac,.mp4a'
			>
				<option value={0}>---</option>
				{context.associations.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
			</InputWithAlert>
		</CrudForm>
	);
}

export default OrganizerFloorMusicsAddRequest;