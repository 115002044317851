import { useContext, useEffect, useRef, useState } from 'react';
import { get } from '../../../models/backendReq';
import { Athlete, Exercise, Team, getTeamSum, initCompetition, responseGetJson } from '../../../models/models';

import ClassificationTeam from '../../../components/Classifications/ClassificationTeam';

import { useParams } from 'react-router-dom';
import ClassificationBuilderCom from '../../../components/Classifications/ClassificationBuilderCom';
import ConditionalListComponent from '../../../components/ConditionalListComponent';
import LoadingSpinner from '../../../components/LoadingSpinner';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../contexts/EventEoContext';
import { downloadPDFInWorker } from '../../../models/PDF/Worker/downloadPDFInWorker';
import { USER_TYPE, useAuth } from '../../../models/auth/AuthProvider';
import { toolBarElements } from '../SubNavBarLinks';
import './ClassificationCompetitionTeamScreen.scss';
import { usePDFDownload } from '../../../models/DownloadPDFProvider';


export interface ExtendedTeam {
	team: Team;
	athletes: Athlete[];
	exercises: Exercise[];
	sum: number;
}

function ClassificationCompetitionTeamScreen() {
	const eventContext = useContext(EventEoContext);
	const pdf = usePDFDownload();

	const params = useParams();

	const [teamsExt, setTeamsExt] = useState<ExtendedTeam[]>([]);
	const [loadingData, setLoadingData] = useState(true);
	const [competitionRule, setCompetitionRule] = useState(0);
	const [watermarkFile, setWatermarkFile] = useState<FileList | null>();
	const [showClassification, setShowClassification] = useState(false);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const competitionId = parseInt(params.competitionId ?? "");
	const competition = eventContext.competitions.value.find(c => c.id === competitionId) ?? initCompetition;

	const exerciseTypes = eventContext.associatedexercisetypes.value.filter(x => x.competitionId === competitionId);
	const athletes = eventContext.athletes.value.filter(x => x.competitionId === competitionId);
	const exercises = eventContext.exercises.value.filter(x => x.competitionId === competitionId);
	const rosters = eventContext.rosters.value.filter(x => x.competitionId === competitionId);
	const teams = eventContext.teams.value.filter(x => x.competitionId === competitionId);

	const auth = useAuth();

	useEffect(() => {
		if (!showClassification) {
			setTeamsExt([]);
			return;
		}

		let promises = teams.map(async (team) => {
			const athletes: Athlete[] = await get.athletesByTeam(team.id, competitionId).then(r => responseGetJson(r, []));
			const teamExercises = exercises.filter(ex => athletes.map(athlete => athlete.id).includes(ex.athleteId));
			const sum = getTeamSum(teamExercises, exerciseTypes, competitionRule);

			return { team: team, athletes: athletes, exercises: teamExercises, sum: sum };
		})

		Promise.all(promises)
			.then((teamsExt) => {
				let teamsExtSorted = teamsExt.sort((a, b) => b.sum - a.sum);
				setTeamsExt(teamsExtSorted);
				setLoadingData(false);
			});

	}, [showClassification, eventContext.exercises.value]);

	const PDFGeneration = () => {
		const pdfProps = {
			data: teamsExt,
			event: eventContext.event,
			files: watermarkFile,
			competition,
			exerciseTypes,
			rule: competitionRule
		};
		const fileName = "Classifica squadre - " + competition.name.trim() + ".pdf";
		pdf.download("TeamAllAroundClassification", pdfProps, fileName);
	}

	const _toolBarElements: eoToolbarProp[] = []

	if (showClassification)
		_toolBarElements.push(
			{
				...toolBarElements.downloadPDFElement,
				callback: PDFGeneration,
			});

	if (auth.user.type === USER_TYPE.USER)
		_toolBarElements.push(
			{
				...toolBarElements.watermarkElement,
				text: "Carica filigrana",
				selected: watermarkFile?.length! > 0,
				callback: () => {
					if (watermarkFile?.length! > 0)
						setWatermarkFile(undefined);
					else
						fileInputRef.current?.click();
				},
			});

	return (
		<OrganizerEventTable text='CLASSIFICHE PER SQUADRA' >
			<EoToolBar elements={_toolBarElements} />
			<ClassificationBuilderCom
				competitionRule={competitionRule}
				showClassification={showClassification}
				setCompetitionRule={setCompetitionRule}
				setShowClassification={setShowClassification}
				useCompetitionRule />
			{loadingData ? (
				<div className='loading-classification'>
					<p>Caricamento classifica</p>
					<LoadingSpinner />
				</div>
			) : (
				<div className='clssification-competition-team-container'>
					<div className='clssification-competition-team-name-top'>
						<div>{competition.name}</div>
						{teamsExt.length > 0 && <div>{`Atleti: ${teamsExt.length}`}</div>}
					</div>
					<input
						accept="image/jpeg, image/png, image/gif"
						multiple={false}
						onChange={e => setWatermarkFile(e.target.files)}
						ref={fileInputRef}
						style={{ display: 'none' }}
						type='file'
					/>
					<div className='classification-teams-container'>
						{showClassification && <ConditionalListComponent items={teamsExt}
							emptyMesssage='Non ci sono squadre per questa competizione'
							renderList={items => items.map((teamExt, index, array) => {
								let isPM = false;
								if (array.length > 1) {
									if (index < (array.length - 1) && array[index + 1].sum === teamExt.sum) {
										isPM = true;
									}
									if (index > 0 && array[index - 1].sum === teamExt.sum) {
										isPM = true;
									}
								}
								return (
									<ClassificationTeam key={teamExt.team.id} teamExt={teamExt} isPM={isPM} />
								)
							})} />}
					</div>
				</div>
			)}

		</OrganizerEventTable>
	)
}

export default ClassificationCompetitionTeamScreen