import { useEffect, useState } from 'react';
import { Event, responseGetJson } from '../../models/models';
import { get } from '../../models/backendReq';

import './CompetitionSelectEventScreen.scss';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';

function CompetitionSelectEventScreen() {
	let [events, setEvents] = useState<Event[]>([]);
	let [loadingEvents, setLoadingEvents] = useState(true);
	let navigate = useNavigate();

	useEffect(() => {

		let getEvents = async () => {
			let eventsFromServer = await get.events()
				.then(r => responseGetJson(r, []))
				.finally(() => setLoadingEvents(false));

			setEvents(eventsFromServer);
		};

		getEvents();

	}, []);

	return (
		<div>
			{!loadingEvents ? (
				events && events.length > 0 ? (
					<div className='competition-organizer-select-events-container'>
						<h2 style={{ textAlign: "center" }}>SELEZIONA EVENTO</h2>
						{events.map((event) => {

							return (
								<div
									key={event.id}
									className='competition-organizer-select-event-container'
									onClick={() => { navigate(`/Events/${event.id}/Jury/Competition`); }}>
									{event.name}
								</div>
							);
						})}
					</div>
				) : (
					<div className='loading-gif-event-container'>
						<p>Non ci sono eventi disponibili</p>
					</div>
				)
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
}

export default CompetitionSelectEventScreen;