import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { put } from '../../../models/backendReq';
import { Championship, handleResponse, initChampionship } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerChampionshipsEditRequest.scss';

interface props {
	championship?: Championship;
	editCompleted: (championship: Championship) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerChampionshipsEditRequest({ championship, show, editCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per il campionato" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [championshipToPut, setChampionshipToPut] = useState<Championship>(championship ? championship : initChampionship);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (championship)
			setChampionshipToPut(championship);
	}, [championship]);

	let editChampionship = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (championshipToPut.name === "") {
			_errors.push(inputNameError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return


		await put.championships(championshipToPut.id, championshipToPut.name)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica del campionato",
					"Campionato modificato correttamente",
					() => editCompleted(championshipToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica campionato" submit={editChampionship}>
			<p className='organizer-championship-edit-request-form-old-name'>{championship?.name}</p>

			<p className='organizer-championship-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				type='text'
				className='organizer-championship-edit-request-form-input'
				style={{ resize: 'vertical' }}
				value={championshipToPut.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setChampionshipToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerChampionshipsEditRequest;