import { proxy, wrap } from "comlink";
import { downloadFromLink } from "../../../utility/UtilityFunctions";
import type { WorkerType } from "./pdfWorker";

const worker = new Worker(new URL('./pdfWorker.ts', import.meta.url));

export const pdfWorker = wrap<WorkerType>(worker);
pdfWorker.onProgress(proxy((info: any) => console.log(info)));

export const downloadPDFInWorker = async ([type, props]: Parameters<WorkerType["renderPDFInWorker"]>, filename: string) => {
	const url = await pdfWorker.renderPDFInWorker(type, props);
	downloadFromLink(url, filename);
};