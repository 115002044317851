import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExerciseType } from '../../../models/models';

import './OrganizerCompetitionExerciseTypeCom.scss';
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';

interface props {
	exerciseType: ExerciseType;
	isAssociated: boolean;
	toggleExerciseType: (exe: ExerciseType) => void;
}

function OrganizerCompetitionExerciseTypesCom({ exerciseType, isAssociated, toggleExerciseType }: props) {
	return (
		<div className={'organizer-competition-exercisetype-container' + (isAssociated ? " associated" : "")} onClick={() => toggleExerciseType(exerciseType)}>
			<FontAwesomeIcon icon={isAssociated ? faSquareCheck : faSquare} />
			<span>{exerciseType.name.toUpperCase()}</span>
		</div>
	);
}

export default OrganizerCompetitionExerciseTypesCom;