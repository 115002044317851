import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { post, put } from '../../../models/backendReq';
import { Association, HTTP_STATUS_CODES, handleResponse, initAssociation } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerAssociationsAddRequest.scss';

interface props {
	addCompleted: (association: Association) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerAssociationsAddRequest({ show, addCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inesrire il nome per la società" };

	let [associationToAdd, setAssociationToAdd] = useState<Association>(initAssociation);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const firstFocusRef = useRef<HTMLInputElement>(null);

	let denied = () => {
		setAssociationToAdd(initAssociation);
		show[1](false);
	}

	let addAssociation = async (e: React.FormEvent) => {
		e.preventDefault();

		if (associationToAdd.name === "") {
			setInputErrors(prev => [...prev, inputNameError])
			return
		}

		await post.association(associationToAdd.name)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante l'inserimento della società",
					"Societa creata correttamente",
					async (data) => {
						await put.adminInAssociation(data.id, auth.user.info.id);
						addCompleted(data);
						setAssociationToAdd(initAssociation);
					},
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci nuova società' submit={addAssociation}>
			<p className='organizer-association-add-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-association-add-request-form-input'
				type='text'
				value={associationToAdd.name}
				placeholder='Inserisci nome società..'
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setAssociationToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerAssociationsAddRequest;