import { useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMP_TYPE } from '../../models/backendReq';
import { Competition, Event } from '../../models/models';

import EventEoContext from '../../contexts/EventEoContext';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import './ClassificationCompetitionCom.scss';
import { useStateLocation } from '../../models/StateLocationProvider';
import { NavLink } from 'react-router-dom';

interface props {
	competition: Competition;
}

function ClassificationCompetitionCom({ competition }: props) {
	const eventContext = useContext(EventEoContext);

	const location = useStateLocation();
	const ref = useRef<HTMLDivElement>(null);

	useOnClickOutside(ref, () => {
		const lastCompetition = location.read<Competition>("competition");
		const element = document.getElementById(`classification-competiton-${competition.id}`);
		if (element && lastCompetition?.id === competition.id) {
			element!.style.color = '#000';
			element!.style.boxShadow = 'none';
			element!.style.backgroundColor = 'transparent';
			element!.style.textDecoration = 'none';
		}
	});
	useEffect(() => {
		const lastCompetition = location.read<Competition>("competition");
		const element = document.getElementById(`classification-competiton-${competition.id}`);
		if (element && competition.id === lastCompetition?.id) {
			element!.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest'
			});

			element!.style.color = '#2F4858';
			element!.style.boxShadow = '0 0 3px #2F4858';
			element!.style.backgroundColor = '#2F485822';
			element!.style.textDecoration = 'underline';
		}
	}, [location]);

	const stateLocation = {
		...location.state,
		competition: competition
	}

	let buttonClassName = 'classification-competition-link enabled';
	return (
		<div ref={ref} id={`classification-competiton-${competition.id}`} className='classification-competition-container'>
			<div className='classification-competition-name'>{competition.name}</div>
			<strong className='classification-competition-count'>{eventContext.athletes.value.filter(x => x.competition.id === competition.id).length}</strong>
			<NavLink
				className={buttonClassName}
				to={competition.type === COMP_TYPE.Cumulative ? `./${competition.id}/Teams` : `./${competition.id}/Athletes`}
				state={stateLocation}>
				Classifica all around
			</NavLink>
			<NavLink className={buttonClassName} to={`./${competition.id}/ExerciseTypes`} state={stateLocation}>Classifica per attrezzo</NavLink>
		</div>
	)
}

export default ClassificationCompetitionCom