import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import it from "date-fns/locale/it";
registerLocale("it", it);


interface props {
	className?: string | undefined;
	onChange?: (date: string) => void | undefined;
	selected?: string | undefined;
	showTimeInput?: boolean | undefined;
	minDate?: Date;
	maxDate?: Date;
	disabled?: boolean;
}

function DatePicker({ className, onChange, selected, showTimeInput, minDate, maxDate, disabled }: props) {

	let getDateString = (date?: Date | null) => {
		if (date && date !== null) {
			date = new Date(date);
			date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));

			return date.toISOString();
		}

		return "";
	};

	let getStringDate = (string: string) => {
		let retDate: Date;
		if (string !== "") {
			retDate = new Date(string);
			retDate = new Date(retDate.getUTCFullYear(), retDate.getUTCMonth(), retDate.getUTCDate(), retDate.getUTCHours(), retDate.getUTCMinutes());
			return retDate;
		}
		return null;
	};

	const dateFormat = showTimeInput ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy";

	return (
		<ReactDatePicker
			dateFormat={dateFormat}
			calendarStartDay={1}
			className={className}
			locale="it"
			onChange={(e) => { if (onChange) onChange(getDateString(e)) }}
			selected={getStringDate(selected ?? "")}
			disabled={disabled}
			// onChange={(e) => { setEventToAdd(prevState => ({ ...prevState, ["startDate"]: getDateString(e) })); }}
			showTimeInput={showTimeInput}
			minDate={minDate}
			maxDate={maxDate}
		/>
	)
}

export default DatePicker