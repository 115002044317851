import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputWithAlert, { ErrorType } from "../../components/Input/InputWithAlert";
import { MESSAGE_TYPE, useAlert } from "../../models/AlertProvider";
import { post } from "../../models/backendReq";
import './SignonOrganizer.scss';


function SignonOrganizer() {
	const alert = useAlert();
	const navigate = useNavigate();

	const inputEmailError: ErrorType = { id: "name", text: "" };
	const inputDisplayNameError: ErrorType = { id: "displayName", text: "" };
	const inputPasswordError: ErrorType = { id: "password", text: "" };

	const [userMail, setUserMail] = useState("");
	const [userDisplayName, setUserDisplayName] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [userConfirmPassword, setUserConfirmPassword] = useState("");

	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);


	let onSignon = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (userMail === "") {
			console.log(userMail)
			_errors.push(inputEmailError);
		}
		if (userDisplayName === "") {
			console.log(userDisplayName)
			_errors.push(inputDisplayNameError);
		}
		if (userPassword === "") {
			console.log(userPassword)
			_errors.push(inputPasswordError);
		}
		if (userPassword !== userConfirmPassword) {
			console.log(userConfirmPassword)
			_errors.push(inputPasswordError);
		}
		setInputErrors(_errors);
		console.log(_errors)
		if (_errors.length > 0)
			return

		const response = await post.user(userMail, userPassword, userDisplayName);
		if (response.ok)
			navigate('User/Login');
		else {
			alert.show("Errore durante la creazione dell'utente", MESSAGE_TYPE.ERROR, 2000, true, true);
		}

	}

	return (
		<div className='signon-organizer-container'>
			<form
				className='signon-organizer-form'
				onSubmit={(e) => { onSignon(e); }}>
				<div style={{ width: "100%", marginBottom: "20px" }}>
					<p className='p-organizer-signon-title'>
						REGISTRAZIONE
					</p>
				</div>

				<p>Inserisci email</p>
				<InputWithAlert
					errorId="name"
					errors={inputErrors}
					className='input-organizer-signon-field'
					onChange={(e) => setUserMail(e.target.value)}
					placeholder='Email'
					setErrors={setInputErrors}
					type='text'
					value={userMail}
				/>

				<p>Inserisci nome utente</p>
				<InputWithAlert
					errorId="displayName"
					errors={inputErrors}
					className='input-organizer-signon-field'
					onChange={(e) => setUserDisplayName(e.target.value)}
					placeholder='Nome utente'
					setErrors={setInputErrors}
					type='text'
					value={userDisplayName}
				/>

				<p>Inserisci password</p>
				<InputWithAlert
					errorId="password"
					errors={inputErrors}
					className='input-organizer-signon-field'
					onChange={(e) => setUserPassword(e.target.value)}
					placeholder='Password'
					setErrors={setInputErrors}
					type='password'
					value={userPassword}
				/>

				<p>Conferma password</p>
				<InputWithAlert
					className='input-organizer-signon-field'
					onChange={(e) => setUserConfirmPassword(e.target.value)}
					placeholder='Conferma password'
					setErrors={setInputErrors}
					type='password'
					value={userConfirmPassword}
				/>

				<button
					type='submit'
					className='signon-organizer-btn'>
					CONFERMA
				</button>

			</form>
		</div>
	);
}

export default SignonOrganizer;