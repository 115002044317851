import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { Event, handleResponse } from '../../../models/models';
import './OrganizerEventsDeleteRequest.scss';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';

interface props {
	event?: Event;
	deletionCompleted: (event: Event) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerEventsDeleteRequest({ event, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteEvent = async (e: React.FormEvent) => {
		if (!event) {
			show[1](false);
			return
		}
		e.preventDefault();

		await del.event(event.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione dell'evento",
					"Evento cancellato",
					() => deletionCompleted(event)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE L'EVENTO" submit={deleteEvent}>
			<p className='organizer-event-delete-request-form-event-name'>{event?.name}</p>
			<div className='organizer-event-delete-request-form-event-warning'>
				Azione irreversibile, non sarà più possibile recuperare nulla ad esso associato: gare, squadre, giurie, esercizi, classifiche e punteggi
			</div>
		</CrudForm>
	);
}

export default OrganizerEventsDeleteRequest;