import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { post } from '../../../models/backendReq';
import { ExecutionGroup, ExecutionTurn, handleResponse, initExecutionGroup } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import './OrganizerExecutionGroupsAddRequest.scss';

interface props {
	executionturn: ExecutionTurn;
	addCompleted: (executiongroup: ExecutionGroup) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerExecutionGroupsAddRequest({ executionturn, show, addCompleted }: props) {
	const inputNameError: ErrorType = { id: "name", text: "Inserire il nome per il gruppo" };

	let [executiongroupToAdd, setExecutionGroupToAdd] = useState<ExecutionGroup>(initExecutionGroup);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	const firstFocusRef = useRef<HTMLInputElement>(null);

	let denied = () => {
		setExecutionGroupToAdd(initExecutionGroup);
		show[1](false);
	}

	let addExecutionGroup = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (executiongroupToAdd.name === "") {
			_errors.push(inputNameError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await post.executionGroup(executionturn.id, executiongroupToAdd.name)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante l'inserimento del gruppo",
					"Gruppo creato correttamente",
					(data) => {
						addCompleted(data);
						setExecutionGroupToAdd(initExecutionGroup);
					},
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={denied} back={denied} show={show} headerText='Inserisci nuovo gruppo' submit={addExecutionGroup}>
			<p className='organizer-executiongroup-add-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="name"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-executiongroup-add-request-form-input'
				type='text'
				style={{ resize: 'vertical' }}
				value={executiongroupToAdd.name}
				name="name"
				onChange={(e) => {
					const { name, value } = e.target;

					setExecutionGroupToAdd(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
		</CrudForm>
	);
}

export default OrganizerExecutionGroupsAddRequest;