import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { HTTP_STATUS_CODES, Team, handleResponse } from '../../../models/models';
import './OrganizerCompetitionTeamsDeleteRequest.scss';
import { MESSAGE_TYPE, useAlert } from '../../../models/AlertProvider';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';

interface props {
	team?: Team;
	deletionCompleted: (competition: Team) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerCompetitionTeamsDeleteRequest({ team, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteTeam = async (e: React.FormEvent) => {
		if (!team) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.team(team.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione della squadra",
					"Squadra cancellata",
					() => deletionCompleted(team)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE LA SQUADRA" submit={deleteTeam}>
			<p className='organizer-competition-team-delete-request-form-competition-name'>{team?.name}</p>
		</CrudForm>
	);
}

export default OrganizerCompetitionTeamsDeleteRequest;