import { useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import EventEoContext from '../../../contexts/EventEoContext';
import ExecutionTurnEoContext from '../../../contexts/ExecutionTurnEoContext';
import { get } from '../../../models/backendReq';
import { ExecutionGroup, ExecutionTurn, initExecutionTurn, responseGetJson } from '../../../models/models';
import { useStateLocation } from '../../../models/StateLocationProvider';
import { crudBuilder } from '../../../utility/UtilityFunctions';


function OrganizerExecutionTurnLayout() {
	const params = useParams();
	const eventContext = useContext(EventEoContext);

	const turnId = parseInt(params.turnId ?? "");

	const [executionturn, setExecutionturn] = useState(initExecutionTurn);
	const [executiongroups, setExecutionGroups] = useState<ExecutionGroup[]>([]);
	const [loading, setLoading] = useState(true);

	let getExecutionGroups = async () => {
		let executiongroupsFromServer = await get.executionGroupsByTurn(turnId, ["Exercises"])
			.then(r => responseGetJson(r, []));

		setExecutionGroups(executiongroupsFromServer);
	};

	useEffect(() => {
		const fetchData = async () => {
			const executionturnFromServer = await get.executionTurn(turnId).then(r => responseGetJson(r)) as ExecutionTurn;
			setExecutionturn(executionturnFromServer);
			await getExecutionGroups();
			setLoading(false);
		}
		fetchData();
	}, []);

	const contextVal = {
		loading, executionturn,
		executiongroups: { value: executiongroups, crud: crudBuilder(setExecutionGroups, getExecutionGroups) },
	};

	return (
		<ExecutionTurnEoContext.Provider value={contextVal}>
			<Outlet />
		</ExecutionTurnEoContext.Provider>
	)
}

export default OrganizerExecutionTurnLayout