import React, { useEffect, useState } from 'react';
import NavBarCom from '../../components/Organizer/NavBarCom';

import './OrganizerScreen.scss';

import { Outlet } from 'react-router-dom';
import { Association, CompetitionLevel, ExerciseType, responseGetJson } from '../../models/models';
import { useAuth } from '../../models/auth/AuthProvider';
import { get } from '../../models/backendReq';
import { crudBuilder, stringSort } from '../../utility/UtilityFunctions';
import OrganizerConfigurationsContext, { AssociationWithEventorganizer } from '../../contexts/OrganizerConfigurationsContext';
import FullWindowLoading from '../../components/FullWindowLoading';

function OrganizerScreen() {
	const auth = useAuth();

	const [competitionLevels, setCompetitionLevels] = useState<CompetitionLevel[]>([]);
	const [exercisetypes, setExercisetypes] = useState<ExerciseType[]>([]);
	const [associations, setAssociations] = useState<AssociationWithEventorganizer[]>([]);
	const [loading, setLoading] = useState(true);

	const fetchCompetitionLevels = async () => {
		const responsePromises = auth.user.eventOrganizers.map(eventorganizer => get.competitionLevelByEventOrganizer(eventorganizer.id));
		const responses = await Promise.all(responsePromises);
		let _competitionLevels: CompetitionLevel[] = [];
		const fillLevels = responses.map(async (response) => {
			const levels: CompetitionLevel[] = await responseGetJson(response, []);
			_competitionLevels = _competitionLevels.concat(levels.sort((a, b) => stringSort(a.name, b.name)));
		});
		await Promise.all(fillLevels);

		setCompetitionLevels(_competitionLevels);
	}

	const fetchExercisetypes = async () => {
		let exercisetypesFromServer = await get.excerciseTypes(["ScoreComposition"])
			.then(r => responseGetJson(r, []));
		setExercisetypes(exercisetypesFromServer);
	};

	const fetchAssociations = async () => {
		const responsePromises = auth.user.eventOrganizers.map(async (eventorganizer) => {
			const res = await get.eventOrganizerAssociations(eventorganizer.id);
			const associations: Association[] = await responseGetJson(res, []);
			return associations.map(a => ({ ...a, eventorganizer, eventorganizerId: eventorganizer.id }));
		});
		const associationsFromServer = (await Promise.all(responsePromises)).flat();

		setAssociations(associationsFromServer);
	}

	useEffect(() => {
		const fetchData = async () => {
			await fetchCompetitionLevels();
			await fetchExercisetypes();
			await fetchAssociations();
			setLoading(false);
		}
		fetchData();
	}, []);

	const contextVal = {
		loading,
		competitionLevels: { value: competitionLevels, crud: crudBuilder(setCompetitionLevels, fetchCompetitionLevels) },
		exercisetypes: { value: exercisetypes, crud: crudBuilder(setExercisetypes, fetchExercisetypes) },
		associations: { value: associations, crud: crudBuilder(setAssociations, fetchAssociations, undefined, "eventorganizerId") }
	};

	return (
		<OrganizerConfigurationsContext.Provider value={contextVal}>
			<NavBarCom />
			<div className='organizer-outlet-screen'>
				{loading ? <FullWindowLoading active={true} /> : <Outlet />}
			</div>
		</OrganizerConfigurationsContext.Provider>
	);
}

export default OrganizerScreen;