import { NavLink } from 'react-router-dom';
import './NavBarCom.scss';
import { useAuth } from '../../models/auth/AuthProvider';

function NavBarCom() {
	const auth = useAuth();

	return (
		<div className='organizer-topbar-container'>
			<NavLink className='organizer-topbar-element' state={{}} to={'/User/Association'}>Società</NavLink>
			<NavLink className='organizer-topbar-element' state={{}} to={'/User/Events'}>Eventi</NavLink>
			{auth.user.isOrganizer &&
				<NavLink className='organizer-topbar-element' state={{}} to={'/User/Configurations'}>Configurazioni</NavLink>}
			{auth.user.info.userName === "Admin" &&
				<NavLink className='organizer-topbar-element' state={{}} to={'/User/Administration'}>Utenti</NavLink>}
		</div>
	);
}

export default NavBarCom;