import { ReactNode } from "react";

interface props<T> {
	items: T[];
	renderList: (items: T[]) => ReactNode;
	emptyMesssage?: ReactNode;
}

function ConditionalListComponent<T extends {}>(props: props<T>) {
	return (
		<>
			{
				props.items.length === 0 ?
					<div>{props.emptyMesssage}</div>
					:
					props.renderList(props.items)
			}
		</>
	)
}

export default ConditionalListComponent