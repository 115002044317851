import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrganizerCompetitionExerciseTypesCom from '../../../../components/Organizer/CompetitionSetup/OrganizerCompetitionExerciseTypeCom';
import { Competition, Event, ExerciseType } from '../../../../models/models';

import { del, put } from '../../../../models/backendReq';

import ConfirmCancelButtons from '../../../../components/Organizer/ConfirmCancelButtons';
import TableContainer from '../../../../components/Organizer/TableComponents/TableContainer';
import EventEoContext, { ExerciseTypeWithCompetition } from '../../../../contexts/EventEoContext';
import { MESSAGE_TYPE, useAlert } from '../../../../models/AlertProvider';
import { useStateLocation } from '../../../../models/StateLocationProvider';
import '../OrganizerCompetitionExerciseTypesScreen.scss';

function OrganizerCompetitionExerciseTypesMassiveScreen() {
	const eventContext = useContext(EventEoContext);

	let [associatedExerciseTypes, setAssociatedExerciseTypes] = useState<ExerciseType[]>([]);
	let [loadingLog, setLoadingLog] = useState("");

	let location = useStateLocation();
	let navigate = useNavigate();
	let alert = useAlert();

	let competitions = location.read<Competition[]>("competitions");
	let event = eventContext.event;


	let toggleExerciseType = async (exe: ExerciseType) => {
		if (associatedExerciseTypes.map(x => x.name).includes(exe.name)) {
			let newAssociatedExerciseTypes = associatedExerciseTypes.filter(item => item.id !== exe.id);
			setAssociatedExerciseTypes(newAssociatedExerciseTypes);
		} else {
			if (associatedExerciseTypes) {
				setAssociatedExerciseTypes([...associatedExerciseTypes, exe]);
			}
		};
	}


	const confirm = async () => {
		const errors: { description: string, competition: string }[] = [];
		const toDel: ExerciseTypeWithCompetition[] = [];
		const toAdd: ExerciseTypeWithCompetition[] = [];

		if (!competitions)
			return;

		const promiseDelete = competitions.map(async (competition, index, array) => {
			setLoadingLog(`Cancellazione vecchi attrezzi ${index + 1}/${array.length}`);
			const compExeTypes = eventContext.associatedexercisetypes.value.filter(aet => aet.competition.id === competition.id);
			const promiseEt = compExeTypes.map(async (et) => {
				const res = await del.competitionExerciseType(competition.id, et.id);
				if (!res.ok)
					errors.push({ description: `Errore durante l'eliminazione dell'attrezzo dalla gara`, competition: competition.name });
				else
					toDel.push(et);
				return res;
			})

			return await Promise.all(promiseEt);
		});

		await Promise.all(promiseDelete)


		const promiseAdd = competitions.map(async (competition, index, array) => {
			setLoadingLog(`Creazione nuovi attrezzi ${index + 1}/${array.length}`);
			const promiseEt = associatedExerciseTypes.map(async (et) => {
				const res = await put.competitionAddExerciseType(competition.id, et.id);
				if (!res.ok)
					errors.push({ description: `Errore durante l'associazione dell'attrezzo alla gara`, competition: competition.name });
				else
					toAdd.push({ ...et, competition, competitionId: competition.id });

				return res;
			})

			return await Promise.all(promiseEt);
		});

		await Promise.all(promiseAdd)
		eventContext.associatedexercisetypes.crud.delete(toDel);
		eventContext.associatedexercisetypes.crud.add(toAdd);

		navigate(-1);
		if (errors.length === 0)
			alert.show("Operazione eseguita correttamente", MESSAGE_TYPE.VALID, 2000, true, true);
		else {
			let alertMessages = ["Operazione eseguita con errori:"];
			errors.forEach(error => {
				alertMessages.push(`${error.competition}: ${error.description}`)
			});
			alert.show(alertMessages, MESSAGE_TYPE.INFO, 2000, true, true);
		}
	}

	const abort = () => {
		navigate(-1);
	}

	return (
		<div>
			<div className='organizer-competition-exercisetypes-body-child'>
				{
					competitions ? (
						<TableContainer
							headerText='ATTREZZI'
							sectionName={`${event?.name} / ${competitions.length} GARE`}
							sectionBackPath={`/User/Events/${eventContext.event.id}/Competitions`} hasSection>
							<div className='organizer-competition-exercisetypes-body'>
								{eventContext.exercisetypes.map(et => {
									return (
										<OrganizerCompetitionExerciseTypesCom key={et.id} exerciseType={et}
											isAssociated={associatedExerciseTypes.map(x => x.id).includes(et.id)}
											toggleExerciseType={toggleExerciseType} />
									)
								})}
							</div>
							<div>{loadingLog}</div>
							<ConfirmCancelButtons no={abort} yes={confirm} buttonType='button' />
						</TableContainer>
					) : (
						<p>...</p>
					)
				}
			</div>
		</div>
	);
}
export default OrganizerCompetitionExerciseTypesMassiveScreen;