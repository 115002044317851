import { createContext } from "react";
import { Association, Athlete, Competition, Crud, Event, ExecutionTurn, Exercise, ExerciseType, FloorMusicMetadata, Jury, RankingConfiguration, Roster, Team, User } from "../models/models";

export interface AthleteWithCompetition extends Athlete {
	competition: Competition;
	competitionId: number;
}
export interface TeamWithCompetition extends Team {
	competition: Competition;
	competitionId: number;
}
export interface ExerciseWithCompetition extends Exercise {
	competition: Competition;
	competitionId: number;
}
export interface ExerciseTypeWithCompetition extends ExerciseType {
	competition: Competition;
	competitionId: number;
}
export interface RankingConfigurationWithCompetition extends RankingConfiguration {
	competition: Competition;
	competitionId: number;
}

export interface EventEoContextData {
	loading: boolean;
	progress: number;
	event: Event;
	athletes: { value: AthleteWithCompetition[], crud: Crud<AthleteWithCompetition | AthleteWithCompetition[]> }
	teams: { value: TeamWithCompetition[], crud: Crud<TeamWithCompetition | TeamWithCompetition[]> }
	competitions: { value: Competition[], crud: Crud<Competition | Competition[]> };
	executionturns: { value: ExecutionTurn[], crud: Crud<ExecutionTurn | ExecutionTurn[]> };
	eventusers: { value: User[], crud: Crud<User | User[]> };
	juries: { value: Jury[], crud: Crud<Jury | Jury[]> };
	exercises: { value: ExerciseWithCompetition[], crud: Crud<ExerciseWithCompetition | ExerciseWithCompetition[]> };
	rosters: { value: Roster[], crud: Crud<Roster | Roster[]> };
	associatedexercisetypes: { value: ExerciseTypeWithCompetition[], crud: Crud<ExerciseTypeWithCompetition | ExerciseTypeWithCompetition[]> };
	rankingconfigurations: { value: RankingConfigurationWithCompetition[], crud: Crud<RankingConfigurationWithCompetition | RankingConfigurationWithCompetition[]> };
	floormusics: { value: FloorMusicMetadata[], crud: Crud<FloorMusicMetadata | FloorMusicMetadata[]> }
	users: User[];
	exercisetypes: ExerciseType[];
	associations: Association[];
	lastCompetition?: Competition;
	lastExecutionTurn?: ExecutionTurn;
}

export default createContext<EventEoContextData>(null!);