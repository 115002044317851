import { useContext } from 'react';


import OrganizerCompetitionJuryCom from '../../../components/Organizer/CompetitionSetup/OrganizerCompetitionJudgeCom';
import OrganizerCompetitionTable from '../../../components/Organizer/TableComponents/OrganizerCompetitionTable';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import EventEoContext from '../../../contexts/EventEoContext';
import './OrganizerCompetitionJudgesScreen.scss';

function OrganizerCompetitionJurysScreen() {
	const competitionContext = useContext(CompetitionEoContext);
	const eventContext = useContext(EventEoContext);

	return (
		<OrganizerCompetitionTable text='GIURIA'>
			<div className='organizer-competition-judges-body'>
				{eventContext.juries.value.map(x => {
					return (
						<OrganizerCompetitionJuryCom
							key={x.id}
							competitionId={competitionContext.competition.id}
							exerciseTypes={eventContext.associatedexercisetypes.value.filter(x => x.competition.id === competitionContext.competition.id)}
							judge={x} />
					)
				})}
			</div>
		</OrganizerCompetitionTable>
	);
}

export default OrganizerCompetitionJurysScreen;