import { del, post, put } from '../models/backendReq';
import { Score, responseGetJson } from '../models/models';
import { idbKeyval } from './idb';


const addScore = async (score: Score) => {
	const res = await post.scores(score.exerciseId, score.juryId, score.scoring);
	return res.ok && (await responseGetJson(res) as Score);
};

const editScore = async (score: Score) => {
	const res = await put.scores(score.id, score.scoring);
	return res.ok && score;
};

const deleteScore = async (score: Score) => {
	const res = await del.score(score.id);
	return res.ok && score;
};

const promiseScores = async (scores: Score[], callback: (score: Score) => Promise<false | Score>) => {
	const _scores: Score[] = [];
	const promise = scores.map(async score => {
		const _score = await callback(score);
		_score && _scores.push(_score);
	});
	await Promise.all(promise);
	return _scores;
};

export async function syncScores(juryIds: number[]): Promise<[Score[], Score[], Score[]] | undefined> {
	const dataToDel = await idbKeyval.getAll<Score>('scoresToDel') ?? [];
	const dataToPost = await idbKeyval.getAll<Score>('scoresToAdd') ?? [];
	const dataToEdit = await idbKeyval.getAll<Score>('scoresToEdit') ?? [];

	const _scoresToDel = await promiseScores(dataToDel.filter(score => juryIds.includes(score.juryId)), deleteScore);
	const _scoresToEdit = await promiseScores(dataToEdit.filter(score => juryIds.includes(score.juryId)), editScore);
	const _scoresToPost = await promiseScores(dataToPost.filter(score => juryIds.includes(score.juryId)), addScore);
	return [_scoresToPost, _scoresToEdit, _scoresToDel];
}