import { useContext, useState } from 'react';
import ClassificationCompetitionCom from '../../../components/Classifications/ClassificationCompetitionCom';

import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import EventEoContext from '../../../contexts/EventEoContext';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';
import '../../../utility/prototype';
import { competitionsFilterBuilder } from '../../../utility/UtilityFunctions';
import { toolBarElements } from '../SubNavBarLinks';
import './ClassificationScreen.scss';

function ClassificationScreen() {
	const eventContext = useContext(EventEoContext);
	const configurationsContext = useContext(OrganizerConfigurationsContext);


	const showFilters = useState(false);

	const [competitionsFilterName, setCompetitionsFilterName] = useState("");
	const [athleteFilter, setAthleteFilter] = useState("");
	const [categoryFilter, setCategoryFilter] = useState("");
	const [levelFilter, setlevelFilter] = useState(0);
	const [typeFilter, setTypeFilter] = useState("");
	const [genderFilter, setGenderFilter] = useState("");
	const [turnFilter, setTurnFilter] = useState(0);

	const _catFilter = [];
	const _lvlFilter = [];
	if (categoryFilter !== "")
		_catFilter.push(categoryFilter);

	if (levelFilter !== 0)
		_lvlFilter.push(levelFilter);

	const _filters = competitionsFilterBuilder(eventContext, configurationsContext,
		setCompetitionsFilterName, competitionsFilterName,
		setCategoryFilter, categoryFilter,
		setlevelFilter, levelFilter,
		setAthleteFilter, athleteFilter,
		setTypeFilter, typeFilter,
		setGenderFilter, genderFilter);

	_filters.push({
		name: 'Turno',
		setNum: setTurnFilter,
		value: turnFilter,
		type: "select",
		options: [{ label: "", value: 0 },
		...eventContext.executionturns.value.map(l => ({ label: l.name, value: l.id }))
		]
	});

	const _toolBarElements: eoToolbarProp[] = [
		{
			...toolBarElements.filterElement,
			callback: () => showFilters[1](!showFilters[0]),
			notNumber: _filters.filter(f => f.value !== "" && f.value !== 0).length
		}];

	const compToShow = eventContext.competitions.value.sortCompetitions()
		.competitionFilter(competitionsFilterName, _catFilter, _lvlFilter)
		.filter(x => eventContext.athletes.value.filter(y => y.competition.id === x.id)
			.athleteFilter(athleteFilter).length > 0 || athleteFilter === "")
		.filter(x => !x.category || !genderFilter || x.category.allowedGenders?.includes(genderFilter))
		.filter(x => !typeFilter || x.type === typeFilter)
		.filter(x => eventContext.exercises.value
			.filter(ex => ex.executionGroup?.executionTurnId === turnFilter && ex.competitionId === x.id).length > 0 || turnFilter === 0);

	return (
		<OrganizerEventTable text='CLASSIFICHE' >
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar history='classCompetitionsFilters' show={showFilters} filters={_filters} found={_toolBarElements[0].notNumber! > 0 ? compToShow.length : undefined} />
			<div className='classification-competitions-container'>
				<ConditionalListComponent
					items={compToShow}
					emptyMesssage='Non ci sono gare disponibili'
					renderList={competitions => competitions.map(competition =>
						<ClassificationCompetitionCom key={competition.id} competition={competition} />
					)} />
			</div >
		</OrganizerEventTable>
	);
}

export default ClassificationScreen;