import { useRef, useState } from 'react';
import useOnClickOutside from '../../models/hooks/useOnClickOutside';
import { ExtendedTeam } from '../../screens/Organizer/Classification/ClassificationCompetitionTeamScreen';

import './ClassificationTeam.scss';

interface props {
	teamExt: ExtendedTeam;
	isPM: boolean;
}

function ClassificationTeam({ teamExt, isPM }: props) {
	const [selected, setSelected] = useState(false);

	let containerClassName = "classification-team-container";
	if (isPM)
		containerClassName += " PM";

	if (selected)
		containerClassName += " selected";

	return (
		<div onClick={() => setSelected(!selected)} className={containerClassName}>
			<div className='classification-team-info-container'>
				<div className='classification-team-info'>
					{teamExt.team.owner?.name}
				</div>
				<div className='classification-team-name'>
					{teamExt.team.name}
				</div>
			</div>
			<div>
				{
					teamExt.sum !== 0 ? (
						<div className='classification-team-sum'>{teamExt.sum.toFixed(3)}<strong className='classification-team-pm'>{isPM ? "PM" : ""}</strong></div>
					) : (
						<div className='classification-team-sum noScore'>---</div>
					)
				}
			</div>
		</div>
	)
}

export default ClassificationTeam