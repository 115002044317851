import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import './InputWithAlert.scss';
import DatePicker from './DatePicker';
import PasswordInput from './PasswordInput';
import InputDate from './InputDate';
import InputCategory from './InputCategory';
import { Category, Event } from '../../models/models';

export interface ErrorType {
	id: string;
	text: string | string[];
}
interface props {
	children?: ReactNode;
	className?: string;
	dateSelected?: string | undefined;
	errorId?: string;
	errors?: ErrorType[];
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onSelectChange?: React.ChangeEventHandler<HTMLSelectElement>;
	onDateChange?: (date: string) => void | undefined;
	onCategoryChange?: Dispatch<SetStateAction<Category>>;
	name?: string;
	placeholder?: string;
	setErrors?: React.Dispatch<React.SetStateAction<ErrorType[]>>;
	showTimeInput?: boolean | undefined;
	style?: React.CSSProperties | undefined;
	type?: React.HTMLInputTypeAttribute | undefined;
	value?: string | number | readonly string[];
	categoryValue?: Category;
	useDatePicker?: boolean;
	minDate?: Date;
	maxDate?: Date;
	categoryNameErrorId?: string;
	categoryDateMinErrorId?: string;
	categoryDateMaxErrorId?: string;
	disabled?: boolean;
	accept?: string;
}


const InputWithAlert = React.forwardRef<HTMLInputElement, props>((props, ref) => {

	const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.setErrors && props.errorId)
			props.setErrors(prev => prev.filter(x => x.id !== props.errorId));

		if (props.onChange) props.onChange(e);
	};

	const _onDateChange = (date: string) => {
		if (props.setErrors && props.errorId)
			props.setErrors(prev => prev.filter(x => x.id !== props.errorId));

		if (props.onDateChange) props.onDateChange(date);
	};

	const _onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (props.setErrors && props.errorId)
			props.setErrors(prev => prev.filter(x => x.id !== props.errorId));

		if (props.onSelectChange) props.onSelectChange(e);
	};

	useEffect(() => {
		if (props.setErrors && props.errorId)
			props.setErrors(prev => prev.filter(x => x.id !== props.errorId));
	}, [props.categoryValue]);

	const errorClassName = props.errors?.map(x => x.id).includes(props.errorId ?? "") ? "error" : "";
	const errors = props.errors?.filter(x => x.id === props.errorId);

	return (
		<div className={`input-with-alert`}>
			{
				props.type == "date" ? (
					props.useDatePicker ? (
						<DatePicker
							className={"Input " + props.className + " " + errorClassName}
							selected={props.dateSelected}
							onChange={_onDateChange}
							showTimeInput={props.showTimeInput}
							minDate={props.minDate}
							maxDate={props.maxDate}
							disabled={props.disabled}
						/>
					) : (
						<InputDate
							className={"Input " + props.className + " " + errorClassName}
							value={props.dateSelected}
							onChange={_onDateChange}
							time={props.showTimeInput}
							disabled={props.disabled}
						/>
					)
				) : (
					props.type == "select" ? (
						<select
							className={"Input " + props.className + " " + errorClassName}
							value={props.value}
							name={props.name}
							onChange={_onSelectChange}
							disabled={props.disabled}>
							{props.children}
						</select>
					) : (
						props.type === "category" ? (
							<InputCategory
								className={"Input " + props.className + " " + errorClassName}
								setCategory={props.onCategoryChange!}
								category={props.categoryValue!}
								dateMaxErrorId={props.categoryDateMaxErrorId}
								dateMinErrorId={props.categoryDateMinErrorId}
								nameErrorId={props.categoryNameErrorId}
								errors={props.errors}
								setErrors={props.setErrors}
								disabled={props.disabled}
							/>
						) : (
							props.type === "password" ? (
								<PasswordInput
									className={"Input " + props.className + " " + errorClassName}
									value={props.value}
									placeholder={props.placeholder}
									name={props.name}
									onChange={_onChange}
									disabled={props.disabled}
								/>
							) : (
								<input
									className={"Input " + props.className + " " + errorClassName}
									ref={ref}
									type={props.type}
									value={props.value}
									placeholder={props.placeholder}
									name={props.name}
									onChange={_onChange}
									disabled={props.disabled}
									accept={props.accept}
								/>
							)
						)
					)
				)
			}
			{
				errors?.filter((error, index, array) => !array.map(x => x.id).includes(error.id, index + 1)).map((e, index) => {
					return (
						<>
							{
								Array.isArray(e.text) ? (
									<>
										{
											e.text.map(t => <p key={index} className={errorClassName}>{t}</p>)
										}
									</>
								) : (
									<p key={index} className={errorClassName}>{e.text}</p>
								)
							}
						</>
					);
				})
			}
		</div>
	);
});

export default InputWithAlert;