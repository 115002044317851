import React from 'react';
import { useNavigate } from 'react-router-dom';
import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import { del } from '../../../models/backendReq';
import { Championship, handleResponse } from '../../../models/models';
import './OrganizerChampionshipsDeleteRequest.scss';

interface props {
	championship?: Championship;
	deletionCompleted: (championship: Championship) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerChampionshipsDeleteRequest({ championship, show, deletionCompleted }: props) {
	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let deleteChampionship = async (e: React.FormEvent) => {
		if (!championship) {
			show[1](false);
			return
		}
		e.preventDefault();
		await del.championship(championship.id)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la cancellazione del campionato",
					"Campionato cancellato",
					() => deletionCompleted(championship)
				)
			});
	};

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="CANCELLARE IL CAMPIONATO" submit={deleteChampionship}>
			<p className='organizer-championship-delete-request-form-championship-name'>{championship?.name}</p>
		</CrudForm>
	);
}

export default OrganizerChampionshipsDeleteRequest;