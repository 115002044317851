import { SetStateAction } from "react";
import './ClassificationBuilderCom.scss';

interface props {
	useNumberOfExeType?: boolean;
	useCompetitionRule?: boolean;
	showClassification?: boolean;
	numberOfExeType?: number;
	competitionRule?: number;
	setNumberOfExeType?: (value: SetStateAction<number | undefined>) => void;
	setCompetitionRule?: (value: SetStateAction<number>) => void;
	setShowClassification?: (value: SetStateAction<boolean>) => void;
}

function ClassificationBuilderCom(props: props) {

	const _onChangeNumberOfExeType = (e: React.ChangeEvent<HTMLInputElement>) => {
		const _value = parseInt(e.target.value);
		if (_value < 1 || isNaN(_value))
			props.setNumberOfExeType && props.setNumberOfExeType(undefined);
		else
			props.setNumberOfExeType && props.setNumberOfExeType(_value)
	}

	return (
		<div className="classification-builder-container">
			{props.useCompetitionRule && <div className="classification-builder-input-container">
				<div className='classification-builder-input-text'>Calcolo su esercizi multipli: </div>
				<select
					className='classification-builder-input'
					value={props.competitionRule}
					disabled={props.showClassification}
					onChange={(e) => props.setCompetitionRule && props.setCompetitionRule(parseInt(e.target.value))}>
					<option value={0}>Media</option>
					<option value={1}>Migliore</option>
					<option value={2}>Somma</option>
				</select>
			</div>}
			{props.useNumberOfExeType && <div className="classification-builder-input-container">
				<div className='classification-builder-input-text'>Numero dei migliori attrezzi per ogni atleta</div>
				<input
					placeholder='Tutti'
					className='classification-builder-input number'
					value={props.numberOfExeType ?? ""}
					readOnly={props.showClassification}
					onChange={_onChangeNumberOfExeType}
					type="text"
				/>
			</div>}
			<div className='classification-builder-button-container'>
				{props.showClassification !== undefined &&
					<button
						className={`classification-builder-button${props.showClassification ? "" : " build"}`}
						onClick={() => props.setShowClassification && props.setShowClassification(!props.showClassification)}>
						{props.showClassification ? "Cambia classifica" : "Crea classifica"}
					</button>}
			</div>
		</div>
	)
}

export default ClassificationBuilderCom