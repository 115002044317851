import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import FiltersBar from '../../../components/Organizer/FiltersBar';
import OrganaizerAssociationCom from '../../../components/Organizer/OrganaizerAssociationCom';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableContainer from '../../../components/Organizer/TableComponents/TableContainer';
import TableLinkRow from '../../../components/Organizer/TableComponents/TableLinkRow';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import AssociationsEoContext from '../../../contexts/AssociationsEoContext';
import { useAuth } from '../../../models/auth/AuthProvider';
import { Association } from '../../../models/models';
import '../../../utility/prototype';
import { toolBarElements } from '../SubNavBarLinks';
import OrganizerAssociationsAddRequest from './OrganizerAssociationsAddRequest';
import OrganizerAssociationsDeleteRequest from './OrganizerAssociationsDeleteRequest';
import OrganizerAssociationsEditRequest from './OrganizerAssociationsEditRequest';
import './OrganizerAssociationsScreen.scss';
import ConditionalListComponent from '../../../components/ConditionalListComponent';

function OrganizerAssociationsScreen() {
	const context = useContext(AssociationsEoContext);

	let auth = useAuth();
	let navigate = useNavigate();

	let [associationsFilterName, setAssociationsFilterName] = useState("");
	let [athletesFilterName, setAthletesFilterName] = useState("");

	let [associationToDel, setAssociationToDel] = useState<Association>();
	let [associationToPut, setAssociationToPut] = useState<Association>();

	let showDel = useState(false);
	let showAdd = useState(false);
	let showEdi = useState(false);
	let showFilters = useState(false);

	useEffect(() => {
		if (auth.user.info.userName === "Admin")
			return

		if (context.associations.value.length !== 1)
			return

		navigate(`/User/Association/${context.associations.value[0].id}/Athletes`, { state: { association: context.associations.value[0] } });
	}, [context.associations.value])

	let deleteRequest = (association: Association) => {
		showDel[1](true);
		setAssociationToDel(association);
	}
	let deletionCompleted = (association: Association) => {
		context.associations.crud.delete({ ...association, athletes: [], teams: [] });
		showDel[1](false)
	}
	let addCompleted = (association: Association) => {
		context.associations.crud.add({ ...association, athletes: [], teams: [] });
		showAdd[1](false);

		auth.update(auth.user.type);
	}
	let editRequest = (association: Association) => {
		showEdi[1](true);
		setAssociationToPut(association);
	}
	let editCompleted = (association: Association) => {
		const findAssociation = context.associations.value.find(a => a.id === association.id);
		if (findAssociation)
			context.associations.crud.edit({ ...association, athletes: findAssociation.athletes, teams: findAssociation.teams });
		showEdi[1](false)
	}

	const associationsToShow = context.associations.value.filter(a => {
		if (a.athletes)
			return a.athletes?.athleteFilter(athletesFilterName).length > 0 || athletesFilterName === ""
		else return false
	}).associationFilter(associationsFilterName);

	const _filters = [
		{ name: 'Società', set: setAssociationsFilterName, value: associationsFilterName },
		{ name: 'Atleta', set: setAthletesFilterName, value: athletesFilterName }
	];

	const _toolBarElements: eoToolbarProp[] = [];

	if (auth.user.info.userName === "Admin")
		_toolBarElements.push({ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi società' });
	_toolBarElements.push({ ...toolBarElements.filterElement, callback: () => showFilters[1](!showFilters[0]), notNumber: _filters.filter(f => f.value !== "").length });

	return (
		<TableContainer headerText='SOCIETÀ' showLoading={context.loading}>
			<EoToolBar elements={_toolBarElements} />
			<FiltersBar history='associationsFilters' show={showFilters} filters={_filters} found={_filters.filter(f => f.value !== "").length > 0 ? associationsToShow.length : undefined} />
			<Table>
				<thead>
					<tr >
						<TableLinkRow text='Nome' isHeader />
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={associationsToShow}
						emptyMesssage='Nessuna società creata'
						renderList={association => association.map(association =>
							<OrganaizerAssociationCom
								association={association}
								del={deleteRequest}
								edi={editRequest}
								key={association.id}
								lastId={context.lastAssociation?.id}
							/>)} />
				</tbody>
			</Table>
			<OrganizerAssociationsDeleteRequest association={associationToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerAssociationsAddRequest show={showAdd} addCompleted={addCompleted} />
			<OrganizerAssociationsEditRequest association={associationToPut} show={showEdi} editCompleted={editCompleted} />
		</TableContainer>
	);
}

export default OrganizerAssociationsScreen;