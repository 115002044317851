import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useStateLocation } from '../../../models/StateLocationProvider';
import { CompetitionWithAthletes, eventDetailsBuild, EventDetailsScreenData, ExecutionTurnWithAthletes } from '../Events/OrganizerEventInfo';
import { useLocalStorage } from '../../../models/hooks/useLocalStorage';
import SearchInput from '../../../components/Input/SearchInput';
import ExecutionTurnDetails from '../../../components/EventDetails/ExecutionTurnDetails';
import CompetitionDetails from '../../../components/EventDetails/CompetitionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import EventEoContext from '../../../contexts/EventEoContext';
import './OrganizerEventInfoLivescoring.scss'
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import { NavLink } from 'react-router-dom';


function OrganizerEventInfoLivescoring() {
	const eventContext = useContext(EventEoContext);
	const loaderData = eventDetailsBuild(eventContext);

	const location = useStateLocation();
	const navigate = useNavigate();

	const [athletesFilter, setAthletesFilter] = useState("");
	const [showTurn, setShowTurn] = useLocalStorage("showTurn", false);


	let competitionSort = (a: CompetitionWithAthletes, b: CompetitionWithAthletes) => {
		return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
	}

	const competitionsToShow = loaderData?.competitions?.filter(c => c.athletes.athleteFilter(athletesFilter).length > 0).sort(competitionSort)
		.map(c => { return { ...c, athletes: c.athletes.athleteFilter(athletesFilter) } });
	const executionTurnsToShow = loaderData?.executionTurns?.filter(c => c.athletes.athleteFilter(athletesFilter).length > 0)
		.map(c => { return { ...c, athletes: c.athletes.athleteFilter(athletesFilter) } });

	return (
		<OrganizerEventTable text='LIVE'>
			<div className='event-details-competition-turn-container'>
				<div className={`event-details-competition-turn-selection${!showTurn ? " active" : ""}`} onClick={() => setShowTurn(false)}>GARE</div>
				<div className={`event-details-competition-turn-selection${showTurn ? " active" : ""}`} onClick={() => setShowTurn(true)}>TURNI</div>
				<NavLink className={`event-details-competition-turn-selection Live`} to="./AllCompetitions" state={location.state}>LIVE</NavLink>
			</div>
			<div className='event-details-competitions-search-container'>
				<SearchInput className='event-details-competitions-search' placeholder='Cerca atleta' value={athletesFilter} onChange={e => setAthletesFilter(e.target.value)} />
			</div>
			<div className='event-details-competitions-athletes-container'>
				<div className='event-details-competitions-container'>
					{
						showTurn ? (
							executionTurnsToShow?.map(executionTurn => {
								return (
									<ExecutionTurnDetails
										key={executionTurn.id}
										executionturn={executionTurn}
										associations={loaderData.associations ?? []} />
								)
							})
						) : (
							competitionsToShow?.map(competition => {
								return (
									<CompetitionDetails key={competition.id} competition={competition} />
								)
							})
						)
					}
				</div>
			</div>
		</OrganizerEventTable>
	)
}

export default OrganizerEventInfoLivescoring