import { faCopy, faEdit, faEllipsisVertical, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import useOnClickOutside from '../../../models/hooks/useOnClickOutside';
import './TableRemoveEditButtons.scss';

interface props {
	editReq?: VoidFunction;
	deletReq?: VoidFunction;
	duplicateReq?: VoidFunction;
	isHeader?: boolean;
	hideEdit?: boolean;
	hideRemove?: boolean;
	showDuplicate?: boolean;
}

function TableRemoveEditButtons(props: props) {
	const [expand, setExpand] = useState(false);
	const rowRef = useRef<HTMLTableCellElement>(null);

	useOnClickOutside(rowRef, () => setExpand(false));

	const actionsClassName = "organizer-actions-col" + (expand ? " expand" : "");

	return (
		props.isHeader ? (
			<>
				<th className='organizer-actions-col'></th>
				<th className='organizer-table-buttons-responsive'></th>
			</>
		) : (
			<>
				<td ref={rowRef} className={actionsClassName}>
					{!props.hideEdit && <button className='organizer-table-buttons' onClick={props.editReq}>
						<FontAwesomeIcon icon={faEdit} />
					</button>}
					{!props.hideRemove && <button className='organizer-table-buttons' onClick={props.deletReq}>
						<FontAwesomeIcon icon={faRemove} />
					</button>}
					{props.showDuplicate && <button className='organizer-table-buttons' onClick={props.duplicateReq}>
						<FontAwesomeIcon icon={faCopy} />
					</button>}
				</td>
				<td className='organizer-table-buttons-responsive'>
					<button onClick={() => setExpand(true)}>
						<FontAwesomeIcon icon={faEllipsisVertical} />
					</button>
				</td>
			</>
		)
	)
}

export default TableRemoveEditButtons