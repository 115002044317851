import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import OrganizerCompetitionExerciseTypesCom from '../../../components/Organizer/CompetitionSetup/OrganizerCompetitionExerciseTypeCom';
import { ExerciseType, handleResponse } from '../../../models/models';

import { del, post, put } from '../../../models/backendReq';

import OrganizerCompetitionTable from '../../../components/Organizer/TableComponents/OrganizerCompetitionTable';
import CompetitionEoContext from '../../../contexts/CompetitionEoContext';
import EventEoContext from '../../../contexts/EventEoContext';
import { useAlert } from '../../../models/AlertProvider';
import { useAuth } from '../../../models/auth/AuthProvider';
import './OrganizerCompetitionExerciseTypesScreen.scss';

function OrganizerCompetitionExerciseTypesScreen() {
	const competitionContext = useContext(CompetitionEoContext);
	const eventContext = useContext(EventEoContext);

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();


	let toggleExerciseType = async (exe: ExerciseType) => {
		if (competitionContext.associatedexercisetypes.map(x => x.name).includes(exe.name)) {
			await del.competitionExerciseType(competitionContext.competition.id, exe.id)
				.then(res => {
					handleResponse(
						res, auth, alert, navigate,
						"Errore durante la modifica",
						undefined,
						() => eventContext.associatedexercisetypes.crud.delete({ ...exe, competition: competitionContext.competition, competitionId: competitionContext.competition.id })
					)
				});
		} else {
			await put.competitionAddExerciseType(competitionContext.competition.id, exe.id)
				.then(res => {
					handleResponse(
						res, auth, alert, navigate,
						"Errore durante la modifica",
						undefined,
						() => eventContext.associatedexercisetypes.crud.add({ ...exe, competition: competitionContext.competition, competitionId: competitionContext.competition.id })
					)
				});
		}
	};

	return (
		<OrganizerCompetitionTable text='ATTREZZI'>
			<div className='organizer-competition-exercisetypes-body'>
				<div className='organizer-competition-exercisetypes-body-child'>
					{eventContext.exercisetypes.map(et => {
						return (
							<OrganizerCompetitionExerciseTypesCom key={et.id} exerciseType={et}
								isAssociated={eventContext.associatedexercisetypes.value.filter(et => et.competition.id === competitionContext.competition.id)
									.map(x => x.id).includes(et.id)}
								toggleExerciseType={toggleExerciseType} />
						)
					})}
				</div>
			</div>
		</OrganizerCompetitionTable>
	);
}

export default OrganizerCompetitionExerciseTypesScreen;