import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import './PasswordInput.scss';

export interface PasswordInputProps {
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	className?: string;
	placeholder?: string;
	value?: string | number | readonly string[] | undefined;
	name?: string;
	disabled?: boolean;
};

export default function PasswordInput({ onChange, className, placeholder, value, name, disabled }: PasswordInputProps) {
	const [isVisible, setIsVisible] = useState(false);

	return (
		<div className={`${className} pasword-input`}>
			<input name={name} value={value} onChange={onChange} type={isVisible ? "text" : "password"} placeholder={placeholder} disabled={disabled} />
			<FontAwesomeIcon icon={isVisible ? faEyeSlash : faEye} onClick={() => setIsVisible(x => !x)} />
		</div>
	);
}