import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputWithAlert, { ErrorType } from '../../../components/Input/InputWithAlert';
import { useAuth } from '../../../models/auth/AuthProvider';
import { ATHLETE_GENDER, put } from '../../../models/backendReq';
import { Association, Athlete, handleResponse, initAthlete } from '../../../models/models';

import CrudForm from '../../../components/Organizer/TableComponents/CrudForm';
import { useAlert } from '../../../models/AlertProvider';
import { getAthleteGenderString } from '../../../utility/UtilityFunctions';
import '../../../utility/prototype';
import './OrganizerAthletesEditRequest.scss';

interface props {
	association: Association;
	athlete?: Athlete;
	editCompleted: (athlete: Athlete) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function OrganizerAthletesEditRequest({ association, athlete, show, editCompleted }: props) {
	const inputFirstNameError: ErrorType = { id: "firstName", text: "Inerire un nome per l'atleta" };
	const inputLastNameError: ErrorType = { id: "lastName", text: "Inerire un cognome per l'atleta" };
	const inputCodeError: ErrorType = { id: "code", text: "Inerire codice tessera atleta" };
	const inputBirthDateError: ErrorType = { id: "birthDate", text: "Inserire una data di nascita" };
	const inputGenderError: ErrorType = { id: "gender", text: "Selezionare il genere" };

	let navigate = useNavigate();
	let alert = useAlert();
	let auth = useAuth();

	let [athleteToPut, setAthleteToPut] = useState<Athlete>(athlete ? athlete : initAthlete);
	let [inputErrors, setInputErrors] = useState<ErrorType[]>([]);

	const firstFocusRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (athlete)
			setAthleteToPut(athlete);
	}, [athlete]);

	let editAthlete = async (e: React.FormEvent) => {
		e.preventDefault();
		let _errors: ErrorType[] = [];

		if (athleteToPut.firstName === "") {
			_errors.push(inputFirstNameError);
		}

		if (athleteToPut.lastName === "") {
			_errors.push(inputLastNameError);
		}

		if (athleteToPut.code === "") {
			_errors.push(inputCodeError);
		}

		if (athleteToPut.birthDate === "") {
			_errors.push(inputBirthDateError);
		}

		if (athleteToPut.gender === "") {
			_errors.push(inputGenderError);
		}

		setInputErrors(_errors);
		if (_errors.length > 0)
			return

		await put.athlete(athleteToPut.id, athleteToPut.firstName.noMultiSpace(), athleteToPut.lastName.noMultiSpace(), athleteToPut.code.noMultiSpace(), athleteToPut.birthDate, athleteToPut.gender)
			.then(res => {
				handleResponse(
					res, auth, alert, navigate,
					"Errore durante la modifica dell'atleta",
					"Atleta modificato correttamente",
					() => editCompleted(athleteToPut),
					setInputErrors
				)
			});
	};

	useEffect(() => {
		if (show)
			firstFocusRef.current?.focus();
	}, [show])

	return (
		<CrudForm denied={() => show[1](false)} back={() => show[1](false)} show={show} headerText="Modifica atleta" submit={editAthlete}>
			<p className='organizer-athlete-edit-request-form-old-name'>{athlete?.firstName + " " + athlete?.lastName}</p>
			<p className='organizer-athlete-edit-request-form-input-label'>Nome</p>
			<InputWithAlert
				errorId="firstName"
				errors={inputErrors}
				ref={firstFocusRef}
				className='organizer-athlete-edit-request-form-input'
				type='text'
				value={athleteToPut.firstName}
				name="firstName"
				onChange={(e) => {
					const { name, value } = e.target;

					setAthleteToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-athlete-edit-request-form-input-label'>Cognome</p>
			<InputWithAlert
				errorId="lastName"
				errors={inputErrors}
				className='organizer-athlete-edit-request-form-input'
				type='text'
				value={athleteToPut.lastName}
				name="lastName"
				onChange={(e) => {
					const { name, value } = e.target;

					setAthleteToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-athlete-edit-request-form-input-label'>Genere</p>
			<InputWithAlert
				errorId="gender"
				errors={inputErrors}
				className='organizer-athlete-edit-request-form-input'
				value={athleteToPut.gender}
				name='gender'
				onSelectChange={(e) => {
					const { name, value } = e.target;

					setAthleteToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				type='select'
				setErrors={setInputErrors}>
				<option value={ATHLETE_GENDER.Female}>{getAthleteGenderString(ATHLETE_GENDER.Female)}</option>
				<option value={ATHLETE_GENDER.Male}>{getAthleteGenderString(ATHLETE_GENDER.Male)}</option>
			</InputWithAlert>
			<p className='organizer-athlete-edit-request-form-input-label'>Codice</p>
			<InputWithAlert
				errorId="code"
				errors={inputErrors}
				className='organizer-athlete-edit-request-form-input'
				type='text'
				value={athleteToPut.code}
				name="code"
				onChange={(e) => {
					const { name, value } = e.target;

					setAthleteToPut(prevState => ({ ...prevState, [name]: value }));
				}}
				setErrors={setInputErrors}
			/>
			<p className='organizer-athlete-edit-request-form-input-label'>Data di nascita</p>
			<InputWithAlert
				errorId="birthDate"
				errors={inputErrors}
				className='datepicker-classname organizer-athlete-edit-request-form-input'
				dateSelected={athleteToPut.birthDate}
				onDateChange={(e) => { setAthleteToPut(prevState => ({ ...prevState, birthDate: e })); }}
				setErrors={setInputErrors}
				type='date'
			/>
		</CrudForm>
	);
}

export default OrganizerAthletesEditRequest;