import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Competition, Event, ExerciseType, Jury, JuryAssignment, responseGetJson } from '../../../../models/models';

import { del, get, post } from '../../../../models/backendReq';

import OrganizerCompetitionJuryMassiveCom from '../../../../components/Organizer/CompetitionSetup/OrganizerCompetitionJudgeMassiveCom';
import ConfirmCancelButtons from '../../../../components/Organizer/ConfirmCancelButtons';
import TableContainer from '../../../../components/Organizer/TableComponents/TableContainer';
import { MESSAGE_TYPE, useAlert } from '../../../../models/AlertProvider';
import '../OrganizerCompetitionJudgesScreen.scss';
import EventEoContext from '../../../../contexts/EventEoContext';
import { getUnique } from '../../../../utility/UtilityFunctions';
import { useStateLocation } from '../../../../models/StateLocationProvider';

interface JuryWithAssignments extends Jury {
	judgeAssignment?: JuryAssignment[];
}

function OrganizerCompetitionJurysMassiveScreen() {
	const eventContext = useContext(EventEoContext);

	let location = useStateLocation();
	let navigate = useNavigate();
	let alert = useAlert();

	let competitions = location.read<Competition[]>("competitions");
	let event = eventContext.event;

	let [juries, setJuries] = useState<JuryWithAssignments[] | undefined>(eventContext.juries.value);
	let [loadingLog, setLoadingLog] = useState("");


	const confirm = async () => {
		const errors: { description: string, competition: string }[] = [];

		if (!competitions)
			return;

		const promiseDelete = competitions.map(async (competition, index, array) => {

			const assignmentsFromServer = await get.juriesAssignments(competition.id).then(r => responseGetJson(r, [])) as JuryAssignment[];
			const aPromise = assignmentsFromServer.map(async (ja) => {
				return await del.juryAssignments(ja.id).then((res) => {
					if (!res.ok)
						errors.push({ description: `Errore durante l'eliminazione della giuria dalla gara`, competition: competition.name });
					setLoadingLog(`Cancellazione vecchie giurie ${index + 1}/${array.length}...`)
				}
				)
			}) ?? [];

			return await Promise.all(aPromise);
		})

		await Promise.all(promiseDelete);


		const promiseAdd = competitions.map(async (competition, index, array) => {
			const jPromise = juries?.map(async (j) => {
				const aPromise = j.judgeAssignment?.map(async (ja) => {
					const associatedexercisetypes = eventContext.associatedexercisetypes.value.filter(et => et.competition.id === competition.id);
					const exeType = associatedexercisetypes.find(et => et.id === ja.exerciseTypeId);
					const assignments = exeType?.scoreComposition.filter(sc1 => ja.assignedScoreFragmentTypes.includes(sc1.label)).map(sc2 => sc2.id) ?? [];
					if (associatedexercisetypes.map(({ id }) => id).includes(ja.exerciseTypeId))
						await post.jurieAssignments(j.id, competition.id, ja.exerciseTypeId, assignments).then((res) => {
							if (!res.ok)
								errors.push({ description: `Errore durante l'associazione della giuria alla gara`, competition: competition.name });
							setLoadingLog(`Creazione nuove giurie	 ${index + 1}/${array.length}...`)
						}
						);
				}) ?? [];

				await Promise.all(aPromise);
			}) ?? [];

			await Promise.all(jPromise);
		})

		await Promise.all(promiseAdd);
		navigate(-1);
		if (errors.length === 0)
			alert.show("Operazione eseguita correttamente", MESSAGE_TYPE.VALID, 2000, true, true);
		else {
			let alertMessages = ["Operazione eseguita con errori:"];
			errors.forEach(error => {
				alertMessages.push(`${error.competition}: ${error.description}`)
			});
			alert.show(alertMessages, MESSAGE_TYPE.INFO, 2000, true, true);
		}
	}

	const abort = () => {
		navigate(-1);
	}

	return (
		<div>
			{
				competitions ? (
					<TableContainer
						headerText='GIURIA'
						sectionName={`${event?.name} / ${competitions.length} GARE`}
						sectionBackPath={`/User/Events/${eventContext.event.id}/Competitions`} hasSection>
						<div className='organizer-competition-judges-body'>
							{juries?.map(x => {
								return (
									<OrganizerCompetitionJuryMassiveCom key={x.id} exerciseTypes={getUnique(eventContext.associatedexercisetypes.value.filter(x => competitions?.map(c => c.id).includes(x.competitionId)))} jury={x} setJurys={setJuries} />
								)
							})}
						</div>
						<div>{loadingLog}</div>
						<ConfirmCancelButtons no={abort} yes={confirm} buttonType='button' />
					</TableContainer>
				) : (
					<p>...</p>
				)
			}
		</div>
	);
}

export default OrganizerCompetitionJurysMassiveScreen;