import React, { useContext, useEffect, useState } from 'react'
import './OrganizerConfigurationsMembers.scss';
import OrganizerConfigurationsContext from '../../../contexts/OrganizerConfigurationsContext';
import { useAuth } from '../../../models/auth/AuthProvider';
import { Association, EventOrganizer, handleResponse, responseGetJson } from '../../../models/models';
import { del, get, put } from '../../../models/backendReq';
import { useAlert } from '../../../models/AlertProvider';
import { useNavigate } from 'react-router-dom';

function OrganizerConfigurationsMembers() {
	const auth = useAuth();
	const alert = useAlert();
	const navigate = useNavigate();

	const configurationsContext = useContext(OrganizerConfigurationsContext);

	const [eventorganizer, setEventorganizer] = useState(auth.user.eventOrganizers.length === 1 ? auth.user.eventOrganizers[0] : undefined);
	const [associations, setAssociations] = useState<Association[]>([]);

	useEffect(() => {
		const fetchAssociations = async () => {
			const res = await get.associations();
			const associationsFromServer = await responseGetJson(res, []);

			setAssociations(associationsFromServer);
		}

		fetchAssociations();
	}, []);

	const _addEo = async (association: Association) => {
		if (!eventorganizer)
			return;
		const res = await put.associationInEventOrganizer(eventorganizer.id, association.id);
		handleResponse(res, auth, alert, navigate, "Errore durante l'operazione", undefined,
			() => configurationsContext.associations.crud.add({ ...association, eventorganizer, eventorganizerId: eventorganizer.id }));
	};
	const _removeEo = async (association: Association) => {
		if (!eventorganizer)
			return;

		const res = await del.associationInEventOrganizer(eventorganizer.id, association.id);
		handleResponse(res, auth, alert, navigate, "Errore durante l'operazione", undefined,
			() => configurationsContext.associations.crud.delete({ ...association, eventorganizer, eventorganizerId: eventorganizer.id }));
	};

	return (
		<div className='organizer-configuratios-associations-container'>
			{auth.user.eventOrganizers.length > 1 &&
				<div>
					<span className='organizer-configuratios-associations-select-span'>Seleziona organizzatore</span>
					<select onChange={e => setEventorganizer(auth.user.eventOrganizers.find(eo => eo.id === parseInt(e.target.value)))}>
						<option value={0}>---</option>
						{auth.user.eventOrganizers.map(eo => <option key={eo.id} value={eo.id}>{eo.name}</option>)}
					</select>
				</div>}
			<div className='organizer-configuratios-associations-list'>
				{eventorganizer && associations.map(association => {
					const isChecked = configurationsContext.associations.value.find(a => a.id === association.id && a.eventorganizerId === eventorganizer.id) ? true : false;
					return <div key={association.id}><label key={association.id}>
						<input type='checkbox'
							checked={isChecked}
							onChange={e => isChecked ? _removeEo(association) : _addEo(association)} />
						{association.name}
					</label></div>
				})}
			</div>
		</div>
	)
}

export default OrganizerConfigurationsMembers