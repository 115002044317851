import { Athlete, Competition, Event, Team } from '../../models/models';

import './OrganaizerCompetitionCom.scss';

import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { COMP_TYPE, get } from '../../models/backendReq';
import { getCompTypeString } from '../../utility/UtilityFunctions';
import LoadingSpinner from '../LoadingSpinner';
import TableLinkRow from './TableComponents/TableLinkRow';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../contexts/EventEoContext';
import TableTr from './TableComponents/TableTr';
import { useStateLocation } from '../../models/StateLocationProvider';

interface props {
	competition: Competition;
	competitionsMassive: Competition[];
	event: Event;
	del: (competition: Competition) => void;
	edi: (competition: Competition) => void;
	enableMassive: boolean;
	setCompetitionsMassive: React.Dispatch<React.SetStateAction<Competition[]>>
	lastId?: number;
	duplicateReq?: (competition: Competition) => void;
}

function OrganaizerCompetitionCom(props: props) {
	const eventContext = useContext(EventEoContext);
	const location = useStateLocation();
	const state = { ...location.state, competition: props.competition };

	let editReq = () => {
		props.edi(props.competition);
	}

	let deleteReq = () => {
		props.del(props.competition);
	}

	let duplicateReq = () => {
		props.duplicateReq && props.duplicateReq(props.competition);
	}

	let competitors: (Team | Athlete)[] = [];
	if (props.competition.type === COMP_TYPE.Collective)
		competitors = eventContext.teams.value.filter(t => t.competition.id === props.competition.id);
	else
		competitors = eventContext.athletes.value.filter(a => a.competition.id === props.competition.id);

	return (
		<TableTr id={props.competition.id} lastId={props.lastId}>
			<TableLinkRow
				text={props.competition.name}
				selectable={props.enableMassive}
				selectArray={props.competitionsMassive}
				setSelectArray={props.setCompetitionsMassive}
				fullArray={eventContext.competitions.value}
				selectElement={props.competition}
				navigate={{ path: `./${props.competition.id}/ExerciseTypes`, state: state }}
				id={props.competition.id} />
			<td className='organizer-competition-col-type rows'>{getCompTypeString(props.competition.type)}</td>
			<td className='organizer-competition-col-subnum row'>
				{competitors.length}
			</td>
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} duplicateReq={duplicateReq} showDuplicate hideRemove />
		</TableTr>
	);
}

export default OrganaizerCompetitionCom;