import { useContext, useState } from 'react';
import OrganaizerJuryCom from '../../../components/Organizer/OrganaizerJuryCom';
import OrganizerJuriesAddRequest from './OrganizerJuriesAddRequest';
import OrganizerJuriesDeleteRequest from './OrganizerJuriesDeleteRequest';
import OrganizerJuriesEditRequest from './OrganizerJuriesEditRequest';


import { Event, initEvent, Jury, User } from '../../../models/models';


import ConditionalListComponent from '../../../components/ConditionalListComponent';
import EoToolBar, { eoToolbarProp } from '../../../components/Organizer/EoToolBar';
import OrganizerEventTable from '../../../components/Organizer/TableComponents/OrganizerEventTable';
import Table from '../../../components/Organizer/TableComponents/Table';
import TableNameCell from '../../../components/Organizer/TableComponents/TableNameCell';
import TableRemoveEditButtons from '../../../components/Organizer/TableComponents/TableRemoveEditButtons';
import EventEoContext from '../../../contexts/EventEoContext';
import { useStateLocation } from '../../../models/StateLocationProvider';
import { toolBarElements } from '../SubNavBarLinks';
import OrganizerJuriesAssignUser from './OrganizerJuriesAssignUser';
import './OrganizerJuriesScreen.scss';

function OrganizerJuriesScreen() {
	const context = useContext(EventEoContext);

	let [juryToDel, setJuryToDel] = useState<Jury>();
	let [juryToPut, setJuryToPut] = useState<Jury>();
	let [juryUsersEdit, setUsersEdit] = useState<Jury>();

	let showDel = useState(false);
	let showAdd = useState(false);

	let showEdi = useState(false);

	let showUsers = useState(false);

	let location = useStateLocation();

	let event = location.read<Event>("event") ?? initEvent;


	let deleteRequest = (jury: Jury) => {
		showDel[1](true);
		setJuryToDel(jury);
	}
	let deletionCompleted = (jury: Jury) => {
		context.juries.crud.delete(jury);
		showDel[1](false)
	}
	let addCompleted = (jury: Jury) => {
		context.juries.crud.add(jury);
		showAdd[1](false)
	}
	let editRequest = (jury: Jury) => {
		showEdi[1](true);
		setJuryToPut(jury);
	}
	let editCompleted = (jury: Jury) => {
		context.juries.crud.edit(jury);
		showEdi[1](false)
	}

	const usr = (jury: Jury) => {
		setUsersEdit(jury);
		showUsers[1](true);
	}

	const updateUsers = (users: User[], jury?: Jury) => {
		if (!jury)
			return
		context.juries.crud.edit({ ...jury, judges: users });
	}

	const _toolBarElements: eoToolbarProp[] = [
		{ ...toolBarElements.addElement, callback: () => showAdd[1](true), text: 'Aggiungi giuria' }
	];

	return (
		<OrganizerEventTable text='GIURIA'>
			<EoToolBar elements={_toolBarElements} />
			<Table>
				<thead>
					<tr>
						<TableNameCell text='Nome' isHeader />
						<th className='organizer-jury-col-users'></th>
						<TableRemoveEditButtons isHeader />
					</tr>
				</thead>
				<tbody>
					<ConditionalListComponent
						items={context.juries.value}
						emptyMesssage='Nessuna giuria creata'
						renderList={juries => juries.map(jury =>
							<OrganaizerJuryCom key={jury.id} jury={jury} del={deleteRequest} edi={editRequest} usr={usr} />
						)} />
				</tbody>
			</Table>
			<OrganizerJuriesDeleteRequest jury={juryToDel} show={showDel} deletionCompleted={deletionCompleted} />
			<OrganizerJuriesAddRequest event={event} show={showAdd} addCompleted={addCompleted} />
			<OrganizerJuriesEditRequest jury={juryToPut} show={showEdi} editCompleted={editCompleted} />
			<OrganizerJuriesAssignUser show={showUsers} jury={juryUsersEdit} eventId={event.id} updateUsers={updateUsers} />
		</OrganizerEventTable>
	);
}

export default OrganizerJuriesScreen;