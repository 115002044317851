import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AssociationEoContext from '../../../contexts/AssociationEoContext';
import { associationLinks, associationLinksWithMembership } from '../../../screens/Organizer/SubNavBarLinks';
import TableContainer from './TableContainer';
import AssociationsEoContext from '../../../contexts/AssociationsEoContext';
import { useAuth } from '../../../models/auth/AuthProvider';

interface props {
	children: React.ReactNode;
	text?: string;
}
function OrganizerAssociationTable(props: props) {
	const auth = useAuth();

	const associationsContext = useContext(AssociationsEoContext);
	const associationContext = useContext(AssociationEoContext);

	const links = auth.user.isOrganizer ?
		associationLinksWithMembership(associationContext.association.id)
		:
		associationLinks(associationContext.association.id)
	return (
		<TableContainer
			headerText={props.text}
			sectionBackPath='/User/Association'
			sectionName={associationContext.association.name}
			subBarLinks={links}
			hasSection={associationsContext.associations.value.length !== 1}
			showLoading={associationsContext.loading}>
			<div style={{ width: "100%" }}>{props.children}</div>
		</TableContainer>
	)
}

export default OrganizerAssociationTable