import React, { useCallback, useEffect, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import HeaderWithBackButton from '../../components/HeaderWithBackButton';
import PasswordInput from "../../components/Input/PasswordInput";
import { USER_TYPE, useAuth } from '../../models/auth/AuthProvider';
import { get } from '../../models/backendReq';
import { Event, responseGetJson } from '../../models/models';
import './LoginJudge.scss';
import { useStateLocation } from '../../models/StateLocationProvider';

interface LoginJuryScreenData {
	event: Event;
}

export async function LoadLoginJuryScreenData({ params }: LoaderFunctionArgs): Promise<LoginJuryScreenData> {
	const eventId = parseInt(params.eventId ?? "");

	return { event: await get.event(eventId).then(r => responseGetJson(r)) as Event };
}

function Login() {
	let [notFound, setNotFound] = useState<boolean>(false);
	let [password, setPassword] = useState<string>("");
	let navigate = useNavigate();
	let auth = useAuth();

	let { event } = useLoaderData() as LoginJuryScreenData;
	let { read } = useStateLocation();

	let classnameNotFound = notFound ? "login-not-found show" : "login-not-found";

	let onLogin = useCallback(async (e: React.FormEvent) => {
		e.preventDefault();

		setNotFound(false);
		setTimeout(async () => {
			let loginState = await auth.signinJury(event.id, password);
			setPassword("");
			setNotFound(!loginState);
		}, 10)

	}, [event.id, auth, password]);

	useEffect(() => {
		let navigatePathname = read<string>("from") ?? `/Events/${event.id}/JurySelection`;

		if (auth.user.isOrganizer || auth.user.juries?.length! > 0)
			navigate(navigatePathname, { replace: true });

	}, [event.id, auth.user.auth, auth.user.info, read<string>("from"), navigate]);

	return (
		<div>
			<HeaderWithBackButton navigateTo='/Events' />
			<div className='login-container'>
				<form
					className='login-form'
					onSubmit={onLogin}>
					<div style={{ width: "100%", marginBottom: "20px" }}>
						<p className='p-title'>
							BENVENUTO GIUDICE
						</p>
					</div>
					<p className='judge-login-event-name'>{event?.name}</p>
					<p className='p-sub-title'>
						Inserire il proprio ID
					</p>

					<PasswordInput
						className='input-field'
						placeholder='Password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<button
						type='submit'
						className='login-btn'>
						LOGIN
					</button>
					<p className={classnameNotFound}>Codice errato</p>

				</form>
			</div>
		</div>
	);
}

export default Login;