import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './SubNavBarCom.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useStateLocation } from '../../models/StateLocationProvider';

interface props {
	elements: { path: string, name: string, icon?: IconProp }[];
}

function SubNavBarCom(props: props) {
	const location = useLocation();
	const stateLocation = useStateLocation();
	const navigate = useNavigate();

	useEffect(() => {
		props.elements.forEach(element => {
			const _ele = document.getElementById(`sub-nab-bar-el-${element.name}`);
			if (_ele && _ele.className.includes('active')) {
				_ele.scrollIntoView({ inline: 'center' });
			}
		})
	}, [location]);

	const state = { ...stateLocation.state };
	delete state.backReq;

	return (
		<div className='organizer-sub-navigation-bar-container'>
			{props.elements.map(element => (
				<NavLink id={`sub-nab-bar-el-${element.name}`} key={element.name} className='organizer-sub-navigation-bar-link' state={state} to={element.path}>
					<FontAwesomeIcon icon={element.icon ?? faQuestion} />
					<div>{element.name}</div>
				</NavLink>
			))}
			{location.state && Object.hasOwn(location.state, "backReq") &&
				<NavLink id={`sub-nab-bar-el`} className='organizer-sub-navigation-bar-link' to={stateLocation.read<string>("backReq") ?? ""} state={state}>
					<FontAwesomeIcon icon={faArrowLeft} />
					<div>Indietro</div>
				</NavLink>}
		</div>
	)
}

export default SubNavBarCom