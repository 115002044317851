import { faArrowLeft, faChevronDown, faChevronUp, faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalWindow from '../../../../components/ModalWindow';
import AthleteSelection from '../../../../components/Organizer/AthleteSelection';
import FiltersBar from '../../../../components/Organizer/FiltersBar';
import EventEoContext from '../../../../contexts/EventEoContext';
import OrganizerConfigurationsContext from '../../../../contexts/OrganizerConfigurationsContext';
import { useAlert } from '../../../../models/AlertProvider';
import { useAuth } from '../../../../models/auth/AuthProvider';
import { COMP_TYPE, get, put } from '../../../../models/backendReq';
import { Association, Athlete, Competition, ExecutionGroup, Exercise, handleResponse, initAssociation, initAthlete, initCategory, responseGetJson } from '../../../../models/models';
import { getUnique, leadingZeros, stringFilterWithPermutation } from '../../../../utility/UtilityFunctions';
import './OrganizerExecutionTurnsSVAthletesSelection.scss';


function AssociationComp({ association, competitionSelected, setAthletesToInsert, athletesToInsert, selectedAthletes, exercises }: {
	association: Association,
	competitionSelected: Competition,
	setAthletesToInsert: React.Dispatch<React.SetStateAction<Athlete[]>>,
	athletesToInsert: Athlete[],
	selectedAthletes: Athlete[],
	exercises: Exercise[],
}) {
	const eventContext = useContext(EventEoContext);
	const [showAthletes, setShowAthletes] = useState(false);

	const athletes = eventContext.athletes.value.filter(a => a.competition.id === competitionSelected.id && a.ownerId === association.id)
		.athleteFilter();

	const noExercises = (athleteId: number) => {
		return exercises.filter(e => e.athleteId === athleteId).length === 0;
	};

	const checkInCompetition = (athlete: Athlete) => {
		return selectedAthletes.map(a => a.id).includes(athlete.id);
	}

	const allSelected = athletes.filter(x => !checkInCompetition(x)).every(x => athletesToInsert.map(y => y.id).includes(x.id));

	const handleSelection = () => {
		setAthletesToInsert(p => {
			if (allSelected)
				return p.filter(x => x.ownerId !== association.id);
			else
				return [...p, ...athletes.filter(x => !checkInCompetition(x) && !p.map(y => y.id).includes(x.id) && !noExercises(x.id))]
		})
	}
	return <div key={association.id} className='organizer-executionturn-athlteleselection-association-container'>
		<div
			className='organizer-executionturn-athlteleselection-association-name'>
			<input checked={allSelected} onChange={handleSelection} type='checkbox' />
			<button className='organizer-executionturn-athlteleselection-association-button' onClick={() => setShowAthletes(!showAthletes)}>
				<span className='organizer-executionturn-athlteleselection-association-stat'>
					{`${athletes.filter(a => checkInCompetition(a)).length}/${athletes.length}`}
				</span>
				<span className='organizer-executionturn-athlteleselection-association-name'>{association.name}</span>
				<FontAwesomeIcon icon={showAthletes ? faChevronUp : faChevronDown} />
			</button>
		</div>
		<div className={`organizer-executionturn-athlteleselection-association-athletes-container${showAthletes ? " show" : ""}`}>
			{
				athletes.map(athlete => (
					<AthleteSelection
						athlete={athlete}
						setAthletesToInsert={setAthletesToInsert}
						isInThisCompetition={checkInCompetition(athlete) || noExercises(athlete.id)}
						key={athlete.id}
						selected={athletesToInsert.includes(athlete) || checkInCompetition(athlete)}
					/>
				))
			}
		</div>
	</div>
}

interface props {
	executionGroup?: ExecutionGroup;
	usedExercises: Exercise[];
	addRequest: (executionGroup: ExecutionGroup, exercises: Exercise[]) => void;
	show: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
function OrganizerExecutionTurnsSVAthletesSelection(props: props) {
	const eventContext = useContext(EventEoContext);
	const configurationsContext = useContext(OrganizerConfigurationsContext);

	const auth = useAuth();
	const navigate = useNavigate();
	const alert = useAlert();

	const [competitionSelected, setCompetitionSelected] = useState<Competition>();

	const [exercises, setExercises] = useState<Exercise[]>([]);

	const [athletesToInsert, setAthletesToInsert] = useState<Athlete[]>([]);

	const [categoryFilter, setCategoryFilter] = useState("");
	const [levelFilter, setlevelFilter] = useState(0);
	const [athleteFilter, setAthleteFilter] = useState("");
	const [associationFilter, setAssociationFilter] = useState(0);

	const showFilters = useState(false);

	const associations = getUnique([
		...eventContext.athletes.value.filter(a => a.competition.id === competitionSelected?.id),
		...eventContext.teams.value.filter(a => a.competition.id === competitionSelected?.id)
	], "ownerId").map(a => a.owner ?? initAssociation);

	const selectedAthletes = getUnique(props.usedExercises, "athleteId").map(exe => exe.athlete ?? initAthlete).filter(a => a);

	const _catFilter = [];
	const _lvlFilter = [];
	if (categoryFilter !== "")
		_catFilter.push(categoryFilter);

	if (levelFilter !== 0)
		_lvlFilter.push(levelFilter);

	const _filters = [
		{
			name: 'Categorie',
			set: setCategoryFilter,
			value: categoryFilter,
			type: "select",
			options: getUnique(eventContext.competitions.value
				.map(c => c.category ?? initCategory), "name").filter(w => w && w.name !== "")
				.map(x => ({ value: x.name, label: x.name }))
		},
		{
			name: 'Livelli',
			setNum: setlevelFilter,
			value: levelFilter,
			type: "select",
			options: configurationsContext.competitionLevels.value
				.filter(x => eventContext.competitions.value.map(c => c.levelId).includes(x.id))
				.map(l => ({ label: l.name, value: l.id }))
		},
		{
			name: 'Società',
			setNum: setAssociationFilter,
			value: associationFilter,
			type: "select",
			options: eventContext.associations
				.filter(a => eventContext.athletes.value.map(x => x.ownerId).includes(a.id) || eventContext.teams.value.map(x => x.ownerId).includes(a.id))
				.map(a => {
					const tot = eventContext.athletes.value.filter(x => x.ownerId === a.id).length;
					const act = selectedAthletes.filter(x => x.ownerId === a.id).length;
					const sym = act >= tot ? '✔' : '✘';
					return { label: `${sym} ${leadingZeros(act, 2)}/${leadingZeros(tot, 2)} - ${a.name}`, value: a.id }
				})
		},
		{ name: 'Atleta', set: setAthleteFilter, value: athleteFilter }
	];

	useEffect(() => {
		if (!competitionSelected)
			return

		const getCompetitionData = async () => {
			const resExe = await get.excercisesByCompetition(competitionSelected.id, undefined, undefined, undefined, ["Athlete"]);
			const associations: Association[] = await get.associationsByCompetition(competitionSelected.id).then(r => responseGetJson(r, []));

			handleResponse(resExe, auth, alert, navigate, undefined, undefined, (data) => setExercises(data.map((exe: Exercise) => (
				{ ...exe, athlete: { ...exe.athlete, owner: associations.find(a => exe.athlete?.ownerId === a.id) } }
			))));
		}

		getCompetitionData();
	}, [competitionSelected]);


	const addSelected = async () => {
		if (!competitionSelected || athletesToInsert.length === 0 || !props.executionGroup)
			return

		let exercisesToAdd = props.executionGroup.exercises ?? [];
		exercisesToAdd.push(...exercises
			.filter(x => x.competitionId === competitionSelected.id)
			.filter(y => !props.usedExercises.map(e => e.id).includes(y.id))
			.filter(z => athletesToInsert.map(a => a.id).includes(z.athleteId))
		)

		const res = await put.executionGroupAddExercises(props.executionGroup.id, exercisesToAdd.map(exe => exe.id));
		handleResponse(res, auth, alert, navigate, "Errore durante l'inserimento degli atleti", undefined, () => {
			props.addRequest(props.executionGroup!, exercisesToAdd);
			setAthletesToInsert([]);
			setCompetitionSelected(undefined);
		});
	}

	useEffect(() => {
		setAthletesToInsert([]);
		setCompetitionSelected(undefined);
	}, [props.executionGroup]);

	const competitionsToShow = eventContext.competitions.value
		.filter(c => eventContext.athletes.value.some(a => a.competition.id === c.id && stringFilterWithPermutation(`${a.firstName} ${a.lastName} ${a.code}`, athleteFilter) && c.type === COMP_TYPE.Individual))
		.filter(c => eventContext.athletes.value.filter(a => a.competition.id === c.id && (a.ownerId === associationFilter || associationFilter === 0)).length > 0 ||
			eventContext.teams.value.filter(a => a.competition.id === c.id && (a.ownerId === associationFilter || associationFilter === 0)).length > 0)
		.competitionFilter(undefined, _catFilter, _lvlFilter)
		.sortCompetitions();

	return (
		<ModalWindow show={props.show} closeButton>
			{
				competitionSelected ? (
					<div className='organizer-executionturn-athlteleselection-container'>
						<div className='organizer-executionturn-athlteleselection-trunback-container'>
							<button className='organizer-executionturn-athlteleselection-turnback' onClick={() => setCompetitionSelected(undefined)}>
								<FontAwesomeIcon icon={faArrowLeft} />
							</button>
							<span className='organizer-executionturn-athlteleselection-competition-name'>{competitionSelected.name}</span>
						</div>
						{athletesToInsert.length > 0 && <div>
							<div>{`Atleti selezionati: ${athletesToInsert.length}`}</div>
							<button className='organizer-executionturn-athlteleselection-add-button' onClick={addSelected}>AGGIUNGI</button>
						</div>}
						<div className='organizer-executionturn-athlteleselection-athletes-container'>
							{
								associations.filter(a => a?.id === associationFilter || associationFilter === 0).map(association =>
									<AssociationComp
										key={association.id}
										association={association}
										athletesToInsert={athletesToInsert}
										competitionSelected={competitionSelected}
										exercises={exercises}
										selectedAthletes={selectedAthletes}
										setAthletesToInsert={setAthletesToInsert} />
								)
							}
						</div>
					</div>
				) : (
					<div className='organizer-executionturn-athlteleselection-container'>
						<div className='organizer-executionturn-athlteleselection-filters-container'>
							<div className='organizer-executionturn-athlteleselection-filters-show-bar' onClick={() => showFilters[1](!showFilters[0])}>
								<div>Filtri</div>
								<FontAwesomeIcon icon={showFilters[0] ? faChevronUp : faChevronDown} />
							</div>
							<FiltersBar history='turnsCompetitionsFilters' show={showFilters} filters={_filters} found={competitionsToShow.length > 0 ? competitionsToShow.length : undefined} isStatic />
						</div>
						<h4>Seleziona competizione</h4>
						<div>{`Caricati ${selectedAthletes.length} di ${eventContext.athletes.value.length}`}</div>
						<div className='organizer-executionturn-athlteleselection-competitions-container'>
							{competitionsToShow.map(cmp => {
								const athletes = eventContext.athletes.value
									.filter(a => a.competition.id === cmp.id && (a.ownerId === associationFilter || associationFilter === 0));
								const actCount = athletes.filter(a => selectedAthletes.map(x => x.id).includes(a.id)).length;
								const totCount = athletes.length;
								const statClassName = actCount === 0 ? "" : actCount === totCount ? " completed" : " started";
								return (
									<div className='organizer-executionturn-athlteleselection-competition' key={cmp.id} onClick={() => setCompetitionSelected(cmp)}>
										<div className={'organizer-executionturn-athlteleselection-competition-status' + statClassName}>
											<FontAwesomeIcon icon={actCount === totCount ? faCircleCheck : faCircle} />
											<div>{`${actCount} / ${totCount}`}</div>
										</div>
										{cmp.name}
									</div>
								)
							})}
						</div>
					</div>
				)
			}
		</ModalWindow>
	)
}

export default OrganizerExecutionTurnsSVAthletesSelection