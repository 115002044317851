/* REACT */
import { Outlet, Route, RouterProvider, ScrollRestoration, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { LoadLoginJuryScreenData } from './screens/Judge/LoginJudge';




/* MODELS */
import { AlertProvider } from './models/AlertProvider';
import { AuthProvider, USER_TYPE } from './models/auth/AuthProvider';
import { RequireAuth } from './models/auth/RequireAuth';

/* CSS */
import { Suspense } from 'react';
import './App.scss';
import NavBar from './components/NavBar';

/* LAYOUT */
import HomeScreen from './screens/HomeScreen';

/* EVENTI */
import EventsScreen from './screens/EventsScreen';

/* CAMPIONATI */
import ChampionshipsScreen from './screens/ChampionshipsScreen';

/* CLASSIFICHE */
import ClassificationCompetitionAthleteScreen from './screens/Organizer/Classification/ClassificationCompetitionAthleteScreen';
import ClassificationCompetitionTeamScreen from './screens/Organizer/Classification/ClassificationCompetitionTeamScreen';
import ClassificationCompetitionScreen from './screens/Organizer/Classification/ClassificationCompetitionTypeScreen';
import ClassificationScreen from './screens/Organizer/Classification/ClassificationScreen';

/* DETTAGLI EVENTO */
import EventDetailsScoreCompetitionLive from './screens/Organizer/LiveScoring/screens/EventDetailsScoreCompetitionLive';
import EventDetailsScoreCompetitionsLive from './screens/Organizer/LiveScoring/screens/EventDetailsScoreCompetitionsLive';
import EventDetailsScoreTurnLive from './screens/Organizer/LiveScoring/screens/EventDetailsScoreTurnLive';

/* GIURIA */
import CompetitionLayout from './screens/Judge/CompetitionLayout';
import CompetitionSelectEventScreen from './screens/Judge/CompetitionSelectEventScreen';
import CompetitionsJurySelection from './screens/Judge/CompetitionsJurySelection';
import CompetitionsScreen from './screens/Judge/CompetitionsScreen';
import JudgeLayout from './screens/Judge/JudgeLayout';
import LoginJury from './screens/Judge/LoginJudge';
import TurnLayout from './screens/Judge/TurnLayout';

/* SEGRETERIA */
import LoginOrganizer from './screens/Organizer/LoginOrganizer';
import OrganizerScreen from './screens/Organizer/OrganizerScreen';
import SignonOrganizer from './screens/Organizer/SignonOrganizer';

import OrganizerAdministratorScreen from './screens/Organizer/Administrator/OrganizerAdministratorScreen';

import OrganizerChampionshipsScreen from './screens/Organizer/Championships/OrganizerChampionshipsScreen';
import OrganizerEventInfo from './screens/Organizer/Events/OrganizerEventInfo';
import OrganizerEventInfoLivescoring from './screens/Organizer/LiveScoring/OrganizerEventInfoLivescoring';
import OrganizerEventLayout from './screens/Organizer/Events/OrganizerEventLayout';
import OrganizerEventsLayout from './screens/Organizer/Events/OrganizerEventsLayout';
import OrganizerEventsScreen from './screens/Organizer/Events/OrganizerEventsScreen';

import OrganizerCompetitionsScreen from './screens/Organizer/Competitions/OrganizerCompetitionsScreen';
import OrganizerEventusersScreen from './screens/Organizer/EventUsers/OrganizerEventusersScreen';
import OrganizerExecutionGroupsScreen from './screens/Organizer/ExecutionGroups/OrganizerExecutionGroupsScreen';
import OrganizerExecutionTurnInfo from './screens/Organizer/ExecutionTurns/OrganizerExecutionTurnInfo';
import OrganizerExecutionTurnLayout from './screens/Organizer/ExecutionTurns/OrganizerExecutionTurnLayout';
import OrganizerExecutionTurnsScreen from './screens/Organizer/ExecutionTurns/OrganizerExecutionTurnsScreen';
import OrganizerJuriesScreen from './screens/Organizer/Juries/OrganizerJuriesScreen';

import OrganizerAssociationInfo from './screens/Organizer/Associations/OrganizerAssociationInfo';
import OrganizerAssociationLayout from './screens/Organizer/Associations/OrganizerAssociationLayout';
import OrganizerAssociationsLayout from './screens/Organizer/Associations/OrganizerAssociationsLayout';
import OrganizerAssociationsScreen from './screens/Organizer/Associations/OrganizerAssociationsScreen';
import OrganizerAthletesScreen from './screens/Organizer/Athletes/OrganizerAthletesScreen';
import OrganizerMembershipsScreen from './screens/Organizer/Membership/OrganizerMembershipsScreen';
import OrganizerTeamsScreen from './screens/Organizer/Teams/OrganizerTeamsScreen';

import OrganizerCompetitionExerciseTypesMassiveScreen from './screens/Organizer/CompetitionSettings/Massive/OrganizerCompetitionExerciseTypesMassiveScreen';
import OrganizerCompetitionJurysMassiveScreen from './screens/Organizer/CompetitionSettings/Massive/OrganizerCompetitionJudgesMassiveScreen';
import OrganizerCompetitionAthletesScreen from './screens/Organizer/CompetitionSettings/OrganizerCompetitionAthletesScreen';
import OrganizerCompetitionExerciseTypesScreen from './screens/Organizer/CompetitionSettings/OrganizerCompetitionExerciseTypesScreen';
import OrganizerCompetitionJurysScreen from './screens/Organizer/CompetitionSettings/OrganizerCompetitionJudgesScreen';
import OrganizerCompetitionTeamsScreen from './screens/Organizer/CompetitionSettings/OrganizerCompetitionTeamsScreen';
import OrganizerCompetitionInfo from './screens/Organizer/Competitions/OrganizerCompetitionInfo';
import OrganizerCompetitionLayout from './screens/Organizer/Competitions/OrganizerCompetitionLayout';

import OrganizerRankingConfigurations from './screens/Organizer/RankingConfigurations/OrganizerRankingConfigurations';

/* ISCRIZIONI */
import SubscriptionsScreen from './screens/Organizer/Subscriptions/SubscriptionsScreen';
import OrganizerFloorMusicsScreen from './screens/Organizer/FloorMusics/OrganizerFloorMusicsScreen';

/* CONFIGURAZIONI */
import OrganizerConfigurationsExerciseTypes from './screens/Organizer/Configurations/OrganizerConfigurationsExerciseTypes';
import OrganizerConfigurationsLevels from './screens/Organizer/Configurations/OrganizerConfigurationsLevels';
import OrganizerConfigurationsMembers from './screens/Organizer/Configurations/OrganizerConfigurationsMembers';
import OrganizerConfigurationsScreen from './screens/Organizer/Configurations/OrganizerConfigurationsScreen';
import { StateLocationProvider } from './models/StateLocationProvider';
import './i18n';
import { DownloadPDFProvider } from './models/DownloadPDFProvider';


const Layout = () => {
	return (
		<div id="app">
			<StateLocationProvider>
				<AuthProvider>
					<AlertProvider>
						<DownloadPDFProvider>
							<Suspense>
								<NavBar />
								<div className='content'>
									<Outlet />
								</div>
							</Suspense>
						</DownloadPDFProvider>
					</AlertProvider>
				</AuthProvider>
			</StateLocationProvider>
			<ScrollRestoration />
		</div>
	);
};

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/' element={<Layout />}>
			<Route index element={<HomeScreen />} />
			<Route path='/Events' element={<EventsScreen />} />
			<Route path='/Championships' element={<ChampionshipsScreen />} />

			<Route path='/Events/:eventId'>
				{/* GIURIA */}
				<Route path='Jury/Login' loader={LoadLoginJuryScreenData} element={<LoginJury />} />
				<Route path='EventSelection' element={
					<RequireAuth types={[USER_TYPE.EVENTUSER, USER_TYPE.USER]}>
						<CompetitionSelectEventScreen />
					</RequireAuth>
				} />
				<Route path='JurySelection' element={
					<RequireAuth types={[USER_TYPE.EVENTUSER, USER_TYPE.USER]}>
						<CompetitionsJurySelection />
					</RequireAuth>
				} />
				<Route path='Jury' element={
					<RequireAuth types={[USER_TYPE.EVENTUSER, USER_TYPE.USER]}>
						<JudgeLayout />
					</RequireAuth>
				}>
					<Route index element={<CompetitionsScreen />} />
					<Route path='Competition' element={<CompetitionLayout />} />
					<Route path='Turn' element={<TurnLayout />} />
				</Route>
			</Route>





			{/* SEGRETERIA */}
			<Route path='/User/Login' element={<LoginOrganizer />} />
			<Route path='/User/Signon' element={<SignonOrganizer />} />
			<Route path='/User' element={
				<RequireAuth types={[USER_TYPE.USER]}>
					<OrganizerScreen />
				</RequireAuth>
			}>
				<Route path='Events' element={
					<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer', 'Association']}>
						<OrganizerEventsLayout />
					</RequireAuth>
				} >
					<Route index element={<OrganizerEventsScreen />} />
					<Route path=':eventId' element={<OrganizerEventLayout />}>
						<Route path='info' element={
							<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
								<OrganizerEventInfo />
							</RequireAuth>}>
						</Route>
						<Route path='Live' >
							<Route index element={<OrganizerEventInfoLivescoring />} />
							<Route path='AllCompetitions' element={<EventDetailsScoreCompetitionsLive />} />
							<Route path='Competition/:competitionId' element={<EventDetailsScoreCompetitionLive />} />
							<Route path='Turn/:turnId' element={<EventDetailsScoreTurnLive />} />
						</Route>
						<Route path='Classifications' >
							<Route index element={<ClassificationScreen />} />
							<Route path=':competitionId'>
								<Route path='ExerciseTypes' element={<ClassificationCompetitionScreen />} />
								<Route path='Athletes' element={<ClassificationCompetitionAthleteScreen />} />
								<Route path='Teams' element={<ClassificationCompetitionTeamScreen />} />
							</Route>
						</Route>
						<Route path='Subscriptions' element={
							<RequireAuth types={[USER_TYPE.USER]} entities={['Association']}>
								<SubscriptionsScreen />
							</RequireAuth>
						} />
						<Route path='Musics' element={
							<RequireAuth types={[USER_TYPE.USER]} entities={['Association', 'EventOrganizer']}>
								<OrganizerFloorMusicsScreen />
							</RequireAuth>
						} />
						<Route path='Jury' element={
							<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
								<OrganizerJuriesScreen />
							</RequireAuth>
						} />
						<Route path='Users' element={
							<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
								<OrganizerEventusersScreen />
							</RequireAuth>
						} />
						<Route path='RankingConfigurations' element={
							<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
								<OrganizerRankingConfigurations />
							</RequireAuth>
						} />

						<Route path='Competitions'>
							<Route index element={
								<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
									<OrganizerCompetitionsScreen />
								</RequireAuth>
							} />
							<Route path=':competitionId' element={
								<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
									<OrganizerCompetitionLayout />
								</RequireAuth>
							}>
								<Route path='Info' element={<OrganizerCompetitionInfo />} />
								<Route path='Teams' element={<OrganizerCompetitionTeamsScreen />} />
								<Route path='ExerciseTypes' element={<OrganizerCompetitionExerciseTypesScreen />} />
								<Route path='Juries' element={<OrganizerCompetitionJurysScreen />} />
								<Route path='Athletes' element={<OrganizerCompetitionAthletesScreen />} />
							</Route>
							<Route path='Massive'>
								<Route path='ExerciseTypes' element={<OrganizerCompetitionExerciseTypesMassiveScreen />} />
								<Route path='Juries' element={<OrganizerCompetitionJurysMassiveScreen />} />
							</Route>
						</Route>

						<Route path='Turns' >
							<Route index element={
								<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
									<OrganizerExecutionTurnsScreen />
								</RequireAuth>} />
							<Route path=':turnId' element={
								<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
									<OrganizerExecutionTurnLayout />
								</RequireAuth>}>
								<Route path='Info' element={<OrganizerExecutionTurnInfo />} />
								<Route path='Groups' element={<OrganizerExecutionGroupsScreen />} />
							</Route>
						</Route>
					</Route>

				</Route>

				<Route path='Association' element={
					<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer', 'Association']}>
						<OrganizerAssociationsLayout />
					</RequireAuth>
				} >
					<Route index element={<OrganizerAssociationsScreen />} />
					<Route path=':associationId' element={<OrganizerAssociationLayout />}>
						<Route path='Info' element={<OrganizerAssociationInfo />} />
						<Route path='Athletes' element={<OrganizerAthletesScreen />} />
						<Route path='Teams' element={<OrganizerTeamsScreen />} />
						<Route path='Memberships' element={<OrganizerMembershipsScreen />} />
					</Route>
				</Route>

				<Route path='Campionati' element={
					<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
						<OrganizerChampionshipsScreen />
					</RequireAuth>
				} />

				<Route path='Administration' element={
					<RequireAuth types={[USER_TYPE.USER]} entities={['Admin']}>
						<OrganizerAdministratorScreen />
					</RequireAuth>
				} />

				<Route path='Configurations' element={
					<RequireAuth types={[USER_TYPE.USER]} entities={['EventOrganizer']}>
						<OrganizerConfigurationsScreen />
					</RequireAuth>}>
					<Route path='Levels' element={<OrganizerConfigurationsLevels />} />
					<Route path='ExerciseTypes' element={<OrganizerConfigurationsExerciseTypes />} />
					<Route path='Associations' element={<OrganizerConfigurationsMembers />} />
				</Route>
			</Route>
		</Route>
	)
);




export default function App() { return <RouterProvider router={router} />; };
