import { ExecutionGroup } from '../../models/models';

import './OrganaizerExecutionGroupCom.scss';

import TableNameCell from './TableComponents/TableNameCell';
import TableRemoveEditButtons from './TableComponents/TableRemoveEditButtons';
;

interface props {
	executiongroup: ExecutionGroup;
	del: (executiongroup: ExecutionGroup) => void;
	edi: (executiongroup: ExecutionGroup) => void;
	exp: (executiongroup: ExecutionGroup) => void;
}

function OrganaizerExecutionGroupCom({ executiongroup, del, edi, exp }: props) {

	let editReq = () => {
		edi(executiongroup);
	}

	let deleteReq = () => {
		del(executiongroup);
	}

	return (
		<tr>
			<TableNameCell text={executiongroup.name} />
			<td className='organizer-executiongroup-col-count rows' onClick={() => { exp(executiongroup) }}>
				{executiongroup.exercises?.length ?? 0}
			</td>
			<TableRemoveEditButtons deletReq={deleteReq} editReq={editReq} />
		</tr>
	);
}

export default OrganaizerExecutionGroupCom;